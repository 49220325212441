export const PriceIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width={props.width ?? "16"}
            height={props.height ?? "16"}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M4 10.6663C4 12.1391 5.19391 13.333 6.66667 13.333H9.33333C10.8061 13.333 12 12.1391 12 10.6663C12 9.19358 10.8061 7.99967 9.33333 7.99967H6.66667C5.19391 7.99967 4 6.80577 4 5.33301C4 3.86025 5.19391 2.66634 6.66667 2.66634H9.33333C10.8061 2.66634 12 3.86025 12 5.33301M8 1.33301V14.6663"
                stroke={props.stroke}
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

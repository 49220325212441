import { useDispatch, useSelector } from "react-redux"
import Lightbox from "yet-another-react-lightbox"
import Video from "yet-another-react-lightbox/plugins/video"
import Zoom from "yet-another-react-lightbox/plugins/zoom"

import { RuntimeActionCreators } from "../actions/runTime.action"
import { LightBoxStateSelector } from "../selectors/lightBox.selector"

import "yet-another-react-lightbox/styles.css"

export const LightBoxGallery = () => {
    const { openLightBox } = useSelector(LightBoxStateSelector)
    const dispatch = useDispatch()
    return (
        <>
            <Lightbox
                styles={{ root: { "--yarl__color_backdrop": "rgba(0, 0, 0, .6)" } }}
                open={!!openLightBox?.open}
                close={() => dispatch(RuntimeActionCreators.closeLightBox())}
                plugins={[Zoom, Video]}
                slides={
                    openLightBox?.type === "video"
                        ? [
                              {
                                  type: "video",
                                  width: 1000,
                                  height: 650,
                                  autoPlay: true,
                                  sources: openLightBox?.images.map((source) => ({
                                      src: source.src,
                                      type: "video/mp4",
                                  })),
                              },
                          ]
                        : openLightBox?.images
                }
                carousel={{ finite: openLightBox!?.images?.length <= 1 }}
                render={{
                    buttonPrev: openLightBox!?.images?.length <= 1 ? () => null : undefined,
                    buttonNext: openLightBox!?.images?.length <= 1 ? () => null : undefined,
                }}
                video={{ controls: true }}
            />
        </>
    )
}

import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { DashboardBoardConfig } from "../../../../config/dashboard.config"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { TooltipIcon } from "../../../common/tooltipIcons.component"
import { TableTooltipElement } from "./tableTooltipElement"

export const TableTooltip = (props: ITooltipProps) => {
    const [defaultGrid, setDefaultGrid] = useState<boolean>(false)

    const { graphConfig } = DashboardBoardConfig

    const { selectedProductView } = useSelector(ProductStateSelector)
    const { popups } = useSelector(RunTimeStateSelector)

    const { product, color } = props
    const totalIcons =
        product &&
        selectedProductView &&
        Object.keys(product).filter((key) =>
            props.iconNumber === 0
                ? [
                      "actual_asin",
                      "seller_info",
                      "stock",
                      "price",
                      "title",
                      "bullets",
                      "description",
                      "main_image",
                      "carousel_images",
                      "videos",
                  ].includes(key)
                : selectedProductView.selectedViews.includes(key as ProductViewType)
        ).length

    const icons = useMemo(() => {
        return (
            selectedProductView &&
            selectedProductView.selectedViews
                .map((opt, index) => {
                    const iconProps: ITooltipIcons = {
                        key: index,
                        type: opt as ProductViewType,
                        checked:
                            popups?.includes(opt as ProductViewType) &&
                            popups?.includes(props.id) &&
                            popups?.includes(props.product?.timestamp as string),
                        color: color,
                        product: product,
                        totalIcons: totalIcons,
                        iconNumber: props.iconNumber,
                        id: props.id,
                    }

                    switch (opt) {
                        case "price":
                            return product && product.price && <TooltipIcon {...iconProps} />
                        case "title":
                            return product && product.title && <TooltipIcon {...iconProps} />
                        case "bullets":
                            return product && product.bullets && <TooltipIcon {...iconProps} />
                        case "carousel_images":
                            return product && product.carousel_images && <TooltipIcon {...iconProps} />
                        case "description":
                            return product && product.description && <TooltipIcon {...iconProps} />
                        case "main_image":
                            return product && product.main_image && <TooltipIcon {...iconProps} />
                        case "videos":
                            return product && product.videos && <TooltipIcon {...iconProps} />
                        case "seller_info":
                            return product && product.seller_info && <TooltipIcon {...iconProps} />
                        case "stock":
                            return product && product.availability && <TooltipIcon {...iconProps} />
                        default:
                            return null
                    }
                })
                .filter((e) => e)
        )
    }, [selectedProductView, popups, color, product, totalIcons, props.iconNumber, props.id, props.product?.timestamp])

    const staticIcons = useMemo(() => {
        return graphConfig.productDifferentiateItems.map((opt, index) => {
            const iconProps: ITooltipIcons = {
                key: index,
                type: opt.value as ProductViewType,

                checked:
                    popups?.includes(opt.value as ProductViewType) &&
                    popups?.includes(props.id) &&
                    popups?.includes(props.product?.timestamp!),
                id: props.id,
                color: color,
                product: product,
                totalIcons: totalIcons,
                iconNumber: props.iconNumber,
            }
            return <TooltipIcon {...iconProps} />
        })
    }, [
        graphConfig.productDifferentiateItems,
        popups,
        props.id,
        props.iconNumber,
        props.product?.timestamp,
        color,
        product,
        totalIcons,
    ])

    useEffect(() => {
        if (product && graphConfig.productDifferentiateItems) {
            graphConfig.productDifferentiateItems.forEach((opt) => {
                if (
                    product &&
                    product[opt.value as ProductViewType] &&
                    product[opt.value as ProductViewType]?.length &&
                    product[opt.value as ProductViewType]!.length > 2
                ) {
                    setDefaultGrid(true)
                }
            })
        }
    }, [graphConfig.productDifferentiateItems, product])

    return (
        <>
            {/* <div
                onBlur={() => {
                    setSelectedIcon(undefined)
                }}
                className={"relative"}
            > */}
            {totalIcons &&
            totalIcons >= 5 &&
            staticIcons &&
            staticIcons.length > 0 &&
            selectedProductView?.selectedViews.length > 0 ? (
                <TableTooltipElement
                    show={true}
                    backgroundColor={color.hover}
                    // className="!top-[30px] left-[50%]"
                    hideTip
                >
                    <div
                        id="tooltip-parent"
                        className={` grid  ${defaultGrid ? "custom-grid" : "grid-cols-5"} rounded-[7px] p-[4px] 
                        bg-[${color.default}]
                         gap-[2px] justify-center items-center w-max`}
                    >
                        {staticIcons}
                    </div>
                </TableTooltipElement>
            ) : icons && icons?.length > 0 ? (
                <div className={"relative"}>
                    {selectedProductView?.selectedViews.length && (
                        <TableTooltipElement
                            show={true}
                            backgroundColor={color.active}
                            // className="!top-[30px] left-[50%]"
                            hideTip
                        >
                            <div
                                id="tooltip-parent"
                                className={`flex gap-[2px] rounded-[7px] p-[4px] bg-[${color.default}]`}
                            >
                                {icons}
                            </div>
                        </TableTooltipElement>
                    )}
                </div>
            ) : (
                <></>
            )}
            {/* </div> */}
        </>
    )
}

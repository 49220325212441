import { ReactComponent as FrontBubble } from "../../assets/svgs/front-bubble-chat.svg"

export const FrontBubbleIcon = (props: IFrontBubble) => {
    const bubbleIconStyle = () => {
        switch (props.size) {
            case "small":
                return "w-[32px] h-[28px]"
            case "large":
                return "w-[48px] h-[42px]"
            default:
                return ""
        }
    }

    return <FrontBubble className={bubbleIconStyle()} />
}

import { useSelector } from "react-redux"
import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { AuthStateSelector } from "../../../../../selectors/authState.selector"

import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const PtQuickTourStepOne: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    handleCloseDialog,
    handleNext,
    image,
    currentStep,
    totalSteps,
}) => {
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const displayName = getAccessLevel?.first_name
        ? getAccessLevel?.first_name
        : getAccessLevel && getAccessLevel?.username

    return (
        <>
            <div className="relative bg-gray-900 h-screen w-screen">
                <div
                    className=" mx-auto mdpt:max-w-[278px] xlpt:max-w-[348px] bg-[#088AB2] mdpt:rounded-[12px] xlpt:rounded-[16px] mdpt:p-[10px] xlpt:p-[12px] absolute top-[50%] left-[50%] -translate-y-2/4	-translate-x-2/4"
                    style={{
                        boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                    }}
                >
                    {/* Header */}
                    <div className="flex justify-between items-center mdpt:mb-[21.6px] xlpt:mb-[26px]">
                        <div className="flex items-center space-x-2">
                            <img
                                src={Icon}
                                className="mdpt:h-[20.8px] mdpt:w-[22.4px] xlpt:h-[26px] xlpt:w-[28px]"
                                alt="icon"
                            />

                            <PrimaryText
                                size="small"
                                weight="medium"
                                className="text-white mdpt:text-[12px] mdpt:leading-[12px] xlpt:text-[14px]  xlpt:leading-[14px]"
                            >
                                {headerText}
                            </PrimaryText>
                        </div>
                        {handleCloseDialog && (
                            <div onClick={handleCloseDialog} className="cursor-pointer">
                                <TourCloseIcon />
                            </div>
                        )}
                    </div>

                    {/* Image */}
                    <div className="mdpt:w-[259px] xlpt:w-[324px]">
                        <img className="w-full rounded-lg" src={image} alt="Performance Tracker" />
                    </div>

                    {/* Content */}
                    <div className="mdpt:mt-[10px] mdpt:p-[9.6px] xlpt:mt-[12px] xlpt:p-[12px] bg-[#06AED4] mdpt:rounded-[6.4px] xlpt:rounded-[8px]">
                        <PrimaryText
                            weight="book"
                            size="xs"
                            className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] mdpt:mb-[10px] xlpt:mb-[13px] capitalize"
                        >
                            Hey {displayName}!
                        </PrimaryText>
                        <PrimaryText
                            weight="book"
                            size="xs"
                            className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] mdpt:mb-[10px] xlpt:mb-[13px]"
                        >
                            Welcome to my{" "}
                            <PrimaryText
                                size="xs"
                                className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] inline font-[700]"
                            >
                                Performance Tracker.
                            </PrimaryText>
                        </PrimaryText>
                        <PrimaryText
                            weight="book"
                            size="xs"
                            className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px]"
                        >
                            Here you’ll be able to see how the ASINs in each of
                            <br className="mdpt:block xlpt:hidden" /> your Trackpacks are performing against their most
                            relevant competitors. And, more importantly, you’ll be clearer on how those impacted
                            customer purchases and affected market positions.
                        </PrimaryText>

                        {/* Footer */}
                        <div className="flex justify-between items-center mdpt:mt-[19.2px] xlpt:mt-[24px]">
                            <PrimaryText
                                weight="book"
                                size="xs-medium"
                                className="text-white mdpt:text-[8px] xlpt:text-[10px]"
                            >
                                {currentStep} / {totalSteps}
                            </PrimaryText>
                            <ButtonElement
                                size="small"
                                textClass="mdpt:mt-[1px] xlpt:mt-[0px] text-[500] mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] text-white"
                                className="mdpt:!w-[34.8px] mdpt:!h-[20.4px] xlpt:!w-[42px] xlpt:!h-[26px] mdpt:px-[6.4px] mdpt:py-[3.2px]  xlpt:px-[8px] xlpt:py-[4px] !bg-none text-white mdpt:rounded-[4.8px] xlpt:rounded-[6px] !hover:bg-transparent border border-white"
                                onClick={handleNext}
                            >
                                Next
                            </ButtonElement>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PtQuickTourStepOne

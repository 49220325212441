import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ExpandIcon } from "../../../assets/svgs/expandIcon.svg"
import { ImageUnavailable } from "../../../assets/svgs/imageUnavailable.svg"
import { CarouselIconTooltip } from "../../../assets/svgs/tooltips/popups/carousel.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import Slider from "../../elements/slider.component"
export const Carousel = (props: ITooltipData) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("tooltip", {
        keyPrefix: "carousel",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )

    const { active, history, setActive, total } = useProductHistory(props.data, "carousel_images")

    const handleOpenModal = (index: number) => {
        dispatch(
            RuntimeActionCreators.openModal({
                color: props?.color,
                open: true,
                type: "carousal",
                data: props?.data,
                index: index,
            })
        )
    }

    function getUniqueValues(pastArray: string[] | undefined, presentArray: string[] | undefined) {
        if (!pastArray || !presentArray) {
            return { uniqueInPast: [], uniqueInPresent: [] }
        }

        const uniqueInPast = pastArray.filter((pastItem, index) => pastItem !== presentArray[index])
        const uniqueInPresent = presentArray.filter((presentItem, index) => presentItem !== pastArray[index])

        if (props?.data?.carousel_images?.length > 1) {
            const maxLength = Math.max(uniqueInPast?.length, uniqueInPresent.length)
            const paddedUniqueInPast = uniqueInPast.concat(Array(maxLength - uniqueInPast.length).fill(null))
            const paddedUniqueInPresent = uniqueInPresent.concat(Array(maxLength - uniqueInPresent.length).fill(null))
            return { uniqueInPast: paddedUniqueInPast, uniqueInPresent: paddedUniqueInPresent }
        }

        return { uniqueInPast, uniqueInPresent }
    }

    const { uniqueInPast, uniqueInPresent } = getUniqueValues(history.past?.value, history.present?.value)

    return (
        <>
            <div className="w-[232px] py-[12px] bg-gray-900 rounded-2xl pr-0">
                <div className="px-[12px] mb-[32px]">
                    <InnerTooltipHeader
                        title={t("Carousel")}
                        linkText={tCommon("Go to ASIN")}
                        timestamp={tCommon("timestamp", {
                            timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                        })}
                        active={active}
                        total={total}
                        setActive={setActive}
                        color={props.color?.active}
                        id={props.id}
                        icon={<CarouselIconTooltip stroke={props.color?.default} />}
                    />
                </div>
                <Slider color={props.color}>
                    {uniqueInPast.length && uniqueInPresent.length ? (
                        <>
                            {[...Array(Math.max(uniqueInPast.length, uniqueInPresent.length))].map((val, index) => (
                                <div
                                    key={index}
                                    className="embla__slide w-[84px] p-2 bg-slate-600 rounded-lg  mr-[12px]"
                                >
                                    <div className="mb-[14px]">
                                        <div className="flex items-center justify-between w-full mb-[8px] p-[2px]">
                                            <PrimaryText
                                                size={"xs-small"}
                                                weight={"light"}
                                                className="text-[12px] leading-[10px] tracking-[0.96px]"
                                                color={`text-[${props.color?.active}]`}
                                            >
                                                {tCommon("was")}
                                            </PrimaryText>
                                            <ExpandIcon
                                                className="cursor-pointer"
                                                onClick={() => handleOpenModal(index)}
                                                color={props.color?.active}
                                            />
                                        </div>
                                        {uniqueInPast?.[index] ? (
                                            <img
                                                key={index}
                                                className="object-contain bg-gray-900 min-w-[68px] border-gray-400 max-w-[68px] h-[68px] rounded-[4px] border select-none cursor-pointer"
                                                // style={{ borderColor: props.color?.primary }}
                                                src={uniqueInPast?.[index]}
                                                onClick={() => handleOpenModal(index)}
                                                alt="Carousel Images"
                                            />
                                        ) : (
                                            <div
                                                className="object-contain min-w-[68px] max-w-[68px] h-[68px] rounded-[4px] border border-gray-400 select-none flex items-center justify-center bg-gray-900"
                                                // style={{
                                                //     border: `0.8px solid #667085`,
                                                // }}
                                            >
                                                <ImageUnavailable />
                                            </div>
                                        )}
                                    </div>
                                    <div className="mb-[14px]">
                                        <div className="flex-col justify-start items-start mb-[8px] flex">
                                            <PrimaryText
                                                size={"xs-small"}
                                                weight={"light"}
                                                className="self-stretch text-[12px] leading-[10px] p-[2px] tracking-[0.96px] mb-[8px]"
                                                color={`text-[${props.color?.active}]`}
                                            >
                                                {tCommon("now")}
                                            </PrimaryText>
                                            {uniqueInPresent?.[index] ? (
                                                <img
                                                    key={index}
                                                    className="bg-gray-800 object-contain min-w-[68px] border-gray-400 max-w-[68px] h-[68px] rounded-[4px] border select-none cursor-pointer"
                                                    // style={{ borderColor: props.color?.primary }}
                                                    src={uniqueInPresent?.[index]}
                                                    onClick={() => handleOpenModal(index)}
                                                    alt="Carousel Images"
                                                />
                                            ) : (
                                                <div
                                                    className="object-contain min-w-[68px] max-w-[68px] h-[68px] rounded-[4px] border border-gray-400 select-none flex items-center justify-center bg-gray-900"
                                                    // style={{
                                                    //     border: `0.8px solid #667085`,
                                                    // }}
                                                >
                                                    <ImageUnavailable />
                                                </div>
                                            )}
                                        </div>
                                    </div>

                                    <div className=" w-3 h-3 bg-slate-700  rounded-[3px] flex-col justify-center items-center flex">
                                        <div className=" pt-[2px] text-center text-white text-[8px] font-bold font-['F37 Bolton'] leading-[6px]">
                                            {index + 1}
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </>
                    ) : (
                        <>
                            {props?.data?.carousel_images?.length === 1 &&
                                history.present?.value?.map((image: string, index: number) => (
                                    <div data-cy={index} className="min-w-[84px] p-2 bg-slate-600 rounded-lg mr-[8px]">
                                        <div className="flex items-center justify-between w-full mb-[8px] px-[2px] pt-[2px]">
                                            <PrimaryText
                                                size={"xs-small"}
                                                weight={"light"}
                                                className=" text-[12px] leading-[10px] tracking-[0.96px]"
                                                color={`text-[${props.color?.active}]`}
                                            >
                                                {tCommon("now")}
                                            </PrimaryText>
                                            <ExpandIcon
                                                className="cursor-pointer"
                                                onClick={() => handleOpenModal(index)}
                                                color={props.color?.active}
                                            />
                                        </div>
                                        <img
                                            className="object-contain bg-gray-800 w-[68px] border-gray-400 h-[68px] rounded border  cursor-pointer select-none"
                                            // style={{ borderColor: props.color?.primary }}
                                            src={image}
                                            alt=""
                                            onClick={() => handleOpenModal(index)}
                                        />

                                        <div className="w-3 h-3 mt-[16px] bg-slate-700 rounded-[3px] justify-center items-center flex">
                                            <PrimaryText
                                                size="xs-small"
                                                weight="bold"
                                                className=" text-center text-white pt-[2px] leading-[6px] flex items-center justify-center"
                                            >
                                                {index + 1}
                                            </PrimaryText>
                                        </div>
                                    </div>
                                ))}
                        </>
                    )}
                </Slider>
                {/* {isSuccessWarning && (
                    <div className="w-full px-[12px] pb-[12px]">
                        <ErrorTooltipAlert />
                    </div>
                )}
                {isWarning && (
                    <div className="w-full px-[12px] pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
        </>
    )
}

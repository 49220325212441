import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"

export class SettingService {
    static async getAlertSetting() {
        const result = await HttpHelper.sendRequest(API_ROUTES.GET_ALERT_SETTINGS, {}, "GET")

        return HttpHelper.validateResponse<IAlertSettingState>(result)
    }

    static async updateAlertSetting(payload: any): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.GET_ALERT_SETTINGS,
            {
                ...payload,
            },
            "PUT"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }

    static async updateIntialQuickTourCompletion(payload: any): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.UPDATE_INITIAL_QUICK_TOUR_COMPLETION,
            {
                ...payload,
            },
            "PUT"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
}

import { useTranslation } from "react-i18next"

import { REGEX_CONFIG } from "../config/regex.config"

export const useInputValidations = () => {
    const { t } = useTranslation("validations")
    return {
        required: t("required"),
        minLength: {
            value: 1,
            message: t("minLength"),
        },
        maxLength: {
            value: 300,
            message: t("maxLength"),
        },
        maxLengthShortText: {
            value: 140,
            message: t("maxLengthShortText"),
        },
        maxLengthTest: {
            value: 5000,
            message: t("maxLengthTest"),
        },
        minLengthPassword: {
            value: 8,
            message: t("minLengthPassword"),
        },
        maxLengthPassword: {
            value: 30,
            message: t("maxLengthPassword"),
        },
        email: {
            value: REGEX_CONFIG.email,
            message: t("email"),
        },
        zip: {
            value: REGEX_CONFIG.zip,
            message: t("zip"),
        },
        notJustSpaces: {
            value: REGEX_CONFIG.notJustSpaces,
            message: t("notJustSpaces"),
        },
        tel: (data: string): boolean | string => {
            data = data.replace(/[^\d+]/g, "")

            return !data || REGEX_CONFIG.tel.test(data) || t("tel")
        },
        anyTrue: (values: boolean[]): boolean => {
            return values.some((value) => value === true)
        },
        name: {
            value: REGEX_CONFIG?.name,
            message: t("name"),
        },
        last_name: {
            value: REGEX_CONFIG?.last_name,
            message: t("name"),
        },

        minAmount: {
            value: 1,
            message: t("minAmount"),
        },
        code: {
            value: REGEX_CONFIG.code,
            message: t("code"),
        },
        numbersOnly: {
            value: REGEX_CONFIG.numbersOnly,
            message: t("numbersOnly"),
        },
        numbersWithCommasOnly: {
            value: REGEX_CONFIG.numbersWithCommasOnly,
            message: t("numbersOnly"),
        },
        alphanumericUppercaseOnlyTenChars: {
            value: REGEX_CONFIG.alphanumericUppercaseOnlyTenChars,
            message: t("alphanumericUppercaseOnlyTenChars"),
        },
        upToFiveAlphanumericUppercaseASINs: {
            value: REGEX_CONFIG.upToFiveAlphanumericUppercaseChars,
            message: t("upToFiveAlphanumericUppercaseChars"),
        },
        newPassword: {
            value: REGEX_CONFIG.newPassword,
            message: t("newPassword"),
        },
        confirmPassword: {
            value: REGEX_CONFIG.confirmPassword,
            message: t("confirmPassword"),
        },
        newAsin: {
            value: REGEX_CONFIG.newAsin,
            message: t("newAsin"),
        },
        optionalAsin: {
            value: REGEX_CONFIG.optionalAsin,
            message: t("optionalAsin"),
        },
        asinTags: {
            value: REGEX_CONFIG.asinTags,
            message: t("asinTags"),
        },
        trackpackName: {
            value: REGEX_CONFIG?.trackpackName,
            message: t("trackpackName"),
        },
    }
}

import { useEffect, useState } from "react"

const useDebouncedInput = (initialValue: string, delay: number) => {
    const [inputValue, setInputValue] = useState(initialValue)
    const [debouncedValue, setDebouncedValue] = useState(initialValue)
    const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null)

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        const value = event?.target?.value ?? event
        setInputValue(value)
        if (timeoutId) {
            clearTimeout(timeoutId)
        }
        const newTimeoutId = setTimeout(() => {
            setDebouncedValue(value)
        }, delay)
        setTimeoutId(newTimeoutId)
    }

    // Clear timeout on component unmount
    useEffect(() => {
        return () => {
            if (timeoutId) {
                clearTimeout(timeoutId)
            }
        }
    }, [timeoutId])

    return { inputValue, debouncedValue, handleChange }
}

export default useDebouncedInput

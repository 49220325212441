import { AnyAction } from "redux"

export enum AuthAction {
    GET_GUEST_ACCOUNT = "GET_GUEST_ACCOUNT",
    CREATE_GUEST_ACCOUNT = "CREATE_GUEST_ACCOUNT",
    UPDATE_ASIN_USER = " UPDATE_ASIN_USER",
    UPDATE_GUEST_ACCOUNT = "UPDATE_GUEST_ACCOUNT",
    STORE_GUEST_ACCOUNT = "STORE_GUEST_ACCOUNT",
    STORE_TOKEN = "STORE_TOKEN",
    GET_TOKEN = "GET_TOKEN",
    REFRESH_TOKEN = "REFRESH_TOKEN",
    SET_REFRESH_TOKEN = "SET_REFRESH_TOKEN",
    GET_ACCESS_LEVEL = "GET_ACCESS_LEVEL",
    SET_ACCESS_LEVEL_RESPONSE = "SET_ACCESS_LEVEL_RESPONSE",
    USER_SIGNUP = "USER_SIGNUP",
    USER_SIGNUP_SUCCESS = "USER_SIGNUP_SUCCESS",
    RECOVER_PASSWORD = "RECOVER_PASSWORD",
    VERIFY_SIGNUP_EMAIL = "VERIFY_SIGNUP_EMAIL",
    VERIFY_PASSWORD_EMAIL = "VERIFY_PASSWORD_EMAIL",
    RESEND_VERIFY_SIGNUP_EMAIL = "RESEND_VERIFY_SIGNUP_EMAIL",
}

export const AuthActionCreator = {
    getGuestById: (id: string): ISagaAction => {
        return {
            type: AuthAction.GET_GUEST_ACCOUNT,
            payload: { id },
        }
    },

    createGuest: (payload: Omit<IGuestUser, "lead_email_address" | "lead_name" | "guest_id">): ISagaAction => {
        return {
            type: AuthAction.CREATE_GUEST_ACCOUNT,
            payload,
        }
    },
    updateAsinUser: (payload: IAsinUser): ISagaAction => {
        return {
            type: AuthAction.UPDATE_ASIN_USER,
            payload,
        }
    },
    updateGuestAccount: (guest_id: string, payload: Pick<IGuestUser, "name" | "email_address">): ISagaAction => {
        return {
            type: AuthAction.UPDATE_GUEST_ACCOUNT,
            payload: {
                guest_id,
                payload,
            },
        }
    },

    storeGuest: (payload: IGuestAccount): ISagaAction => {
        return {
            type: AuthAction.STORE_GUEST_ACCOUNT,
            payload,
        }
    },
    getToken: (
        payload: { username: string; password: string },
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: AuthAction.GET_TOKEN,
            payload,
            onSuccess,
            onError,
        }
    },
    storeToken: (payload: IObtainToken): ISagaAction => {
        return {
            type: AuthAction.STORE_TOKEN,
            payload,
        }
    },
    getAccessLevel: (onSuccess?: () => void, onError?: (data: { message: string }) => void): ISagaAction => {
        return {
            type: AuthAction.GET_ACCESS_LEVEL,
            onSuccess,
            onError,
        }
    },
    setAccessLevel: (data: IGetAccess) => ({
        type: AuthAction.SET_ACCESS_LEVEL_RESPONSE,
        payload: data,
    }),

    getRefreshToken: (payload: AnyAction[], onSuccess?: () => void): ISagaAction => {
        return {
            type: AuthAction.REFRESH_TOKEN,
            payload: payload,
            onSuccess,
        }
    },

    refreshTokenSuccessAction: (payload: { access: string; refresh: string }): ISagaAction => {
        return {
            type: AuthAction.SET_REFRESH_TOKEN,
            payload: payload,
        }
    },
    userSignUp: (
        payload: Partial<ISignupInputProps>,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: AuthAction.USER_SIGNUP,
            payload,
            onSuccess,
            onError,
        }
    },

    resetPassword: (
        payload: any,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: AuthAction.RECOVER_PASSWORD,
            payload,
            onSuccess,
            onError,
        }
    },
    verifyPasswordEmail: (
        payload: any,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: AuthAction.VERIFY_PASSWORD_EMAIL,
            payload,
            onSuccess,
            onError,
        }
    },
    verifyEmail: (payload: any, onSuccess?: () => void, onError?: (data: { message: string }) => void): ISagaAction => {
        return {
            type: AuthAction.VERIFY_SIGNUP_EMAIL,
            payload,
            onSuccess,
            onError,
        }
    },
    resendVerifyEmail: (
        payload: any,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: AuthAction.RESEND_VERIFY_SIGNUP_EMAIL,
            payload,
            onSuccess,
            onError,
        }
    },
}

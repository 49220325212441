export const MarketIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M3.13324 18.2533L5.35947 16.9681C5.48005 16.8985 5.62156 16.8743 5.75842 16.8999L10.1387 17.7197C10.4986 17.7871 10.8309 17.51 10.8293 17.1438L10.8123 13.3058C10.8118 13.2016 10.8393 13.0991 10.8919 13.009L13.1024 9.22457C13.2174 9.02761 13.2071 8.78166 13.076 8.59502L9.35411 3.29728M22.1656 5.66954C15.7489 8.7507 19.2488 12.834 20.4156 13.4174C22.6055 14.5121 25.6509 14.584 25.6509 14.584C25.6605 14.3907 25.6654 14.1963 25.6654 14.0007C25.6654 7.55733 20.442 2.33398 13.9987 2.33398C7.55538 2.33398 2.33203 7.55733 2.33203 14.0007C2.33203 20.444 7.55538 25.6673 13.9987 25.6673C14.1943 25.6673 14.3888 25.6625 14.582 25.653M19.5494 25.5971L15.8549 15.8568L25.5951 19.5514L21.2759 21.2779L19.5494 25.5971Z"
                stroke="#0E7090"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

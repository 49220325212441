import { WarningTooltipIcon } from "../../../assets/svgs/warningTooltipIcon.svg"
import { getTooltipMessage } from "../../../helpers/util.helper"
import { PrimaryText } from "../../elements/primaryText.element"

const AsinTooltipContent = (currentIssue: any) => {
    const tooltipMessage = getTooltipMessage(currentIssue?.currentIssue)
    const additionalMessage = (() => {
        switch (currentIssue?.currentIssue) {
            case "LISTING_CHANGE":
            case "DIFFERENT_ASIN_RETURNED":
            case "CHILD_ASIN_RETURNED":
                return " returned"
            case "DATA_UNAVAILABLE":
                return " unavailable"
            case "DIFFERENT_LARGE_CATEGORY":
                return "Different"
            default:
                return null
        }
    })()

    return (
        <>
            <div className="z-50">
                {additionalMessage && additionalMessage === "Different" ? (
                    <div className=" mb-[8px]">
                        <PrimaryText weight="book" size="xs-medium" className=" text-[#B54708] leading-[12px]">
                            {additionalMessage}{" "}
                            <PrimaryText
                                weight="medium"
                                size="xs-medium"
                                className="text-[#B54708] inline  leading-[12px]"
                            >
                                {tooltipMessage}
                            </PrimaryText>
                        </PrimaryText>
                    </div>
                ) : (
                    <div className="mb-[8px]">
                        <PrimaryText weight="medium" size="xs-medium" className=" text-[#B54708]  leading-[12px]">
                            {tooltipMessage}
                            <PrimaryText
                                weight="book"
                                size="xs-medium"
                                className="text-[#B54708] inline  leading-[12px]"
                            >
                                {additionalMessage}
                            </PrimaryText>
                        </PrimaryText>
                    </div>
                )}
                {tooltipMessage && tooltipMessage === "BSR L Cat" ? (
                    <div className="bg-[#FFFAEB] p-[6px] pb-[8px] rounded-[3px] flex gap-x-[4px] h-[38px]">
                        <WarningTooltipIcon />
                        <div>
                            <PrimaryText
                                weight="light"
                                size="xs-medium"
                                className="text-[#B54708] leading-[12px] whitespace-nowrap"
                            >
                                Replace&nbsp;
                                <PrimaryText
                                    weight="medium"
                                    size="xs-medium"
                                    className="text-[#B54708] leading-[12px] whitespace-nowrap inline"
                                >
                                    or
                                </PrimaryText>
                                &nbsp;
                                <PrimaryText
                                    weight="light"
                                    size="xs-medium"
                                    className="text-[#B54708] leading-[12px] whitespace-nowrap inline"
                                >
                                    start
                                </PrimaryText>
                                <br />
                                <PrimaryText
                                    weight="light"
                                    size="xs-medium"
                                    className="text-[#B54708] leading-[12px] whitespace-nowrap inline"
                                >
                                    new Trackpack
                                </PrimaryText>
                            </PrimaryText>
                        </div>
                    </div>
                ) : tooltipMessage && tooltipMessage === "Child ASIN" ? (
                    <div className="bg-[#FFFAEB] p-[6px] pb-[8px] rounded-[3px] flex gap-x-[4px] h-[38px]">
                        <WarningTooltipIcon />
                        <div>
                            <PrimaryText
                                weight="light"
                                size="xs-medium"
                                className="text-[#B54708] leading-[12px] whitespace-nowrap"
                            >
                                Track child ASIN <br />
                                <PrimaryText
                                    weight="medium"
                                    size="xs-medium"
                                    className="text-[#B54708] leading-[12px] whitespace-nowrap inline"
                                >
                                    or
                                </PrimaryText>
                                &nbsp;
                                <PrimaryText
                                    weight="light"
                                    size="xs-medium"
                                    className="text-[#B54708] leading-[12px] whitespace-nowrap inline"
                                >
                                    replace ASIN
                                </PrimaryText>
                            </PrimaryText>
                        </div>
                    </div>
                ) : (
                    <div className="bg-[#FFFAEB] p-[6px] pb-[8px] rounded-[3px] flex gap-x-[4px] h-[38px]">
                        <WarningTooltipIcon />
                        <div>
                            <PrimaryText
                                weight="light"
                                size="xs-medium"
                                className="text-[#B54708] leading-[12px] whitespace-nowrap"
                            >
                                Check back later <br />
                                <PrimaryText
                                    weight="medium"
                                    size="xs-medium"
                                    className="text-[#B54708] leading-[12px] whitespace-nowrap inline"
                                >
                                    or
                                </PrimaryText>
                                &nbsp;
                                <PrimaryText
                                    weight="light"
                                    size="xs-medium"
                                    className="text-[#B54708] leading-[12px] whitespace-nowrap inline"
                                >
                                    replace ASIN
                                </PrimaryText>
                            </PrimaryText>
                        </div>
                    </div>
                )}
            </div>
        </>
    )
}

export default AsinTooltipContent

export const LogTitleIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="type-01">
                    <path
                        id="Icon"
                        d="M2 3.5C2 3.03406 2 2.80109 2.07612 2.61732C2.17761 2.37229 2.37229 2.17761 2.61732 2.07612C2.80109 2 3.03406 2 3.5 2H8.5C8.96594 2 9.19891 2 9.38268 2.07612C9.62771 2.17761 9.82239 2.37229 9.92388 2.61732C10 2.80109 10 3.03406 10 3.5M4.5 10H7.5M6 2V10"
                        stroke={props.color}
                        stroke-width="0.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </svg>
        </>
    )
}

export const EmailIcon = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="56" height="56" rx="12" fill="url(#paint0_linear_2587_40409)" />
            <path
                d="M16.3333 22.1667L25.859 28.8348C26.6304 29.3747 27.016 29.6447 27.4356 29.7493C27.8061 29.8416 28.1937 29.8416 28.5643 29.7493C28.9838 29.6447 29.3695 29.3747 30.1408 28.8348L39.6666 22.1667M21.9333 37.3334H34.0666C36.0268 37.3334 37.0069 37.3334 37.7556 36.9519C38.4141 36.6164 38.9496 36.081 39.2851 35.4224C39.6666 34.6737 39.6666 33.6936 39.6666 31.7334V24.2667C39.6666 22.3066 39.6666 21.3265 39.2851 20.5778C38.9496 19.9192 38.4141 19.3838 37.7556 19.0482C37.0069 18.6667 36.0268 18.6667 34.0666 18.6667H21.9333C19.9731 18.6667 18.993 18.6667 18.2443 19.0482C17.5857 19.3838 17.0503 19.9192 16.7147 20.5778C16.3333 21.3265 16.3333 22.3066 16.3333 24.2667V31.7334C16.3333 33.6936 16.3333 34.6737 16.7147 35.4224C17.0503 36.081 17.5857 36.6164 18.2443 36.9519C18.993 37.3334 19.9731 37.3334 21.9333 37.3334Z"
                stroke="white"
                stroke-width="1.4"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_2587_40409"
                    x1="0"
                    y1="0"
                    x2="67.1038"
                    y2="34.2366"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#22CCEE" />
                    <stop offset="1" stop-color="#06AED4" />
                </linearGradient>
            </defs>
        </svg>
    )
}

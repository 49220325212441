import { useController } from "react-hook-form"
import { twMerge } from "tailwind-merge"

import { PrimaryText } from "./primaryText.element"

import { ReactComponent as AlertIcon } from "../../assets/svgs/alert-icon.svg"

export const InputElement = (props: IInputElementProps) => {
    const { field, fieldState } = useController({
        name: props.name,
        control: props.reactHookControl,
        rules: props.reactHookValidations,
        defaultValue: props.defaultValue || "",
    })

    return (
        <div
            className={`
        relative
        ${props.className}
    `}
        >
            <div className="relative">
                <div>
                    {props?.label && (
                        <div className="mb-[6px]">
                            <label>
                                <PrimaryText size="xs" weight="light">
                                    {props.label}
                                </PrimaryText>
                            </label>
                        </div>
                    )}

                    <div className={"flex items-center"}>
                        {props.prefix && <div className={"absolute left-[12px]"}>{props.prefix}</div>}
                        <input
                            {...field}
                            className={twMerge(
                                `${props.className}
        ${props.placeholderStyle ? "!placeholder:text-gray-300" : ""}
        w-full xs:px-[10px] xs:py-[8px] sm:px-[10px] sm:py-[8px] md:px-[10px] md:py-[8px] lg:px-[10px] lg:py-[8px] xl:px-[10px] xl:py-[9px] rounded-[12px] xs:text-[0.875rem] sm:text-[0.875rem] md:text-[1rem] lg:text-[1rem] font-light text-slate-600 border border-gray-300 placeholder:text-gray-400  
        disabled:bg-gray-50 disabled:cursor-not-allowed m-[2px]
        ${
            props.shadowClass
                ? "focus:outline-none focus:outline-[0px] shadow-none text-gray-500 placeholder:italic placeholder:text-[#D0D5DD]"
                : "focus:border-gray-600 focus:outline focus:outline-[2px] focus:outline-cyan-200"
        }
        `,
                                fieldState.error &&
                                    "border-red-600 placeholder:text-red-500 bg-red-50 focus:bg-white focus:border-red-600 focus:outline-red-200",
                                props.prefix && "pl-[34px]",
                                props.postfix && "pr-[30px]"
                            )}
                            disabled={props?.disabled}
                            type={props?.type ? props.type : "text"}
                            placeholder={
                                props.showErrorInPlaceholder
                                    ? fieldState.error
                                        ? fieldState.error.message
                                        : props.placeholder
                                    : props.placeholder
                            }
                            onKeyDown={props.onKeyDown}
                        />

                        {props.postfix && <div className={"absolute right-[12px]"}>{props.postfix}</div>}
                    </div>
                </div>
            </div>

            {props.hint && (
                <PrimaryText typography="small-hint" className="mt-[6px]">
                    {props.hint}
                </PrimaryText>
            )}

            {!props.showErrorInPlaceholder && (
                <>
                    {fieldState.error && (
                        <div className="flex mt-[12px] ml-[3px]">
                            <div className="flex items-start">
                                <AlertIcon />
                            </div>

                            <PrimaryText weight={"light"} typography={"small-error"} className="ml-[3px] leading-[1]">
                                {fieldState.error.message}
                            </PrimaryText>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

import { CustomTooltip } from "../common/tooltip.component"

export const TooltipGallery = (props: { selectedView: "Graph" | "Table" | "Log" }) => {
    return (
        <>
            <CustomTooltip selectedView={props.selectedView} key="price-tooltip" id="price-tooltip" />

            <CustomTooltip selectedView={props.selectedView} key="stock-tooltip" id="stock-tooltip" />

            <CustomTooltip selectedView={props.selectedView} key="actual_asin-tooltip" id="actual_asin-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="description" id="description" />
            <CustomTooltip selectedView={props.selectedView} key="bullets" id="bullets" />
            <CustomTooltip selectedView={props.selectedView} key="title-tooltip" id="title-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="carousal" id="carousal" />
            <CustomTooltip selectedView={props.selectedView} key="main_image-tooltip" id="main_image-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="videos-tooltip" id="videos-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="seller_info-tooltip" id="seller_info-tooltip" />
            <CustomTooltip selectedView={props.selectedView} key="main_video" id="main_video" />
            {/* <CustomTooltip selectedView={props.selectedView} key="keywords" id="keywords" /> */}
        </>
    )
}

export const TrackpackArrowIcon = (props: IArrowProps) => {
    return (
        <>
            <svg width="11" height="12" viewBox="0 0 11 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M0.833252 6.00065H10.1666M10.1666 6.00065L5.49992 1.33398M10.1666 6.00065L5.49992 10.6673"
                    stroke="#17B26A"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}

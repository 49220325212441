import dayjs from "dayjs"
import { useEffect, useMemo, useRef, useState } from "react"
import ReactDOMServer from "react-dom/server"
import Skeleton from "react-loading-skeleton"
import { useDispatch, useSelector } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { Tooltip } from "react-tooltip"
import { AccountActionsCreater } from "../../../actions/account.action"
import { ProductsActionCreator } from "../../../actions/products.action"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { AwatitingDataTrackpack } from "../../../assets/svgs/awaitingDataTrackpack.svg"
import { CheckedIcon } from "../../../assets/svgs/checkedIcon.svg"
import { FilterIcon } from "../../../assets/svgs/filterIcon.svg"
import { FlipArrowIcon } from "../../../assets/svgs/flipBackwardIcon.svg"
import { InputArrow } from "../../../assets/svgs/inputArrow.svg"
import { LinkExternalSvg } from "../../../assets/svgs/link-external.svg"
import { NewEditIcon } from "../../../assets/svgs/newEditIcon.svg"
import { NewTrashIcon } from "../../../assets/svgs/newTrashIcon.svg"
import { ReactComponent as PerfromanceTrackerIcon } from "../../../assets/svgs/performance-trakcer.svg"
import { ReactComponent as Plus } from "../../../assets/svgs/plus-icon.svg"
import { SuccessIcon } from "../../../assets/svgs/successIcon.svg"
import { TrackpackTrashIcon } from "../../../assets/svgs/trashIcon.svg"
import { WarningIcon } from "../../../assets/svgs/warningIcon.svg"
import { img_url } from "../../../config/dashboard.config"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { RuntimeHelper } from "../../../helpers/runtime.helper"
import { getNotificationMessage, handleAmazonProductRedirect } from "../../../helpers/util.helper"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import TrackpackSkeleton from "../../common/skeletonLoader/trackpackSkeleton.component"
import { ButtonElement } from "../../elements/button.element"
import { PrimaryText } from "../../elements/primaryText.element"
import { AMAZON_DOMAINS } from "../../marketing/domains.config"
import AsinTooltipContent from "../tooltip-alerts/asinTooltipAlerts.component"
import CommonTooltip from "../tooltipItems/commonTooltip.component"
import { Root, TrackpackOptions } from "./trackpackOptions.component"

interface ASINsValues {
    asinNo: string
    asinText: string
    imgUrl?: string
}
interface RowData {
    ASINs: ASINsValues
    Status: string[]
    Owner: string
    "Date Added": string
}

export const TrackpackCentral = () => {
    const dispatch = useDispatch()
    const { selectedTrackpacksList, trackpackType, selectedAsinsLength } = useSelector(AccountStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)
    const [selectedRows, setSelectedRows] = useState<string[]>([])
    const intermediateCheckboxRef = useRef<HTMLInputElement>(null)
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<string>("Newest")
    const containerRef = useRef<HTMLInputElement>(null)
    const [isEditing, setIsEditing] = useState(false)
    const [text, setText] = useState(selectedTrackpacksList?.title || "")
    const { loading } = useSelector(RunTimeStateSelector)
    const location = useLocation()
    const queryParams = new URLSearchParams(location.search)
    const pathID = localStorage.getItem("trackpackHubId")
    const navigate = useNavigate()
    const trackpackHubAsin = localStorage.getItem("trackpackHubAsin")

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const isLoading: boolean = useMemo(() => RuntimeHelper.trackpackLoading(), [loading])

    useEffect(() => {
        // @ts-ignore
        if (selectedAsinsLength?.data === 0 && selectedRows.length !== 0) {
            setSelectedRows([])
        }
    }, [selectedAsinsLength])

    useEffect(() => {
        selectedAsinsValues()
        if (intermediateCheckboxRef && intermediateCheckboxRef.current) {
            if (selectedRows.length > 0 && selectedRows.length < selectedTrackpacksListData?.length) {
                setTimeout(() => {
                    // @ts-ignore
                    if (intermediateCheckboxRef && intermediateCheckboxRef.current) {
                        intermediateCheckboxRef.current.indeterminate = true
                    }
                }, 1)
            } else {
                intermediateCheckboxRef.current.indeterminate = false
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRows?.length, selectedTrackpacksList?.trackpackChildrenList?.length])

    const selectedAsinsValues = () => {
        dispatch(AccountActionsCreater.setSelectedAsinsLength(selectedRows.length, selectedRows))
    }
    const toggleAllCheckboxes = () => {
        if (trackpackType === "tracking") {
            const selectActiveAsins = selectedTrackpacksList?.trackpackChildrenList?.filter((row: RowData) =>
                row.Status.includes("ACTIVE")
            )
            setSelectedRows(selectActiveAsins.map((row: RowData) => row.ASINs.asinNo))
            if (selectedRows.length === selectActiveAsins.length) {
                setSelectedRows([])
            }
        } else if (trackpackType === "archived") {
            const selectArchivedAsins = selectedTrackpacksList?.trackpackChildrenList?.filter((row: RowData) =>
                row.Status.includes("ARCHIVED")
            )
            setSelectedRows(selectArchivedAsins.map((row: RowData) => row.ASINs.asinNo))
            if (selectedRows?.length === selectArchivedAsins.length) {
                setSelectedRows([])
            }
        }
    }

    const toggleCheckbox = (asinNo: string) => {
        setSelectedRows((prevSelectedRows: string[]) => {
            if (prevSelectedRows.includes(asinNo)) {
                return prevSelectedRows.filter((asin: string) => asin !== asinNo)
            } else {
                return [...prevSelectedRows, asinNo]
            }
        })
    }

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openAsinAddtionalModal({
                open: true,
                stepNumber: "one",
            })
        )
    }

    const handleOpenArchive = () => {
        dispatch(
            RuntimeActionCreators.openArchiveModal({
                open: true,
                stepNumber: "one",
                isArchiveShow: true,
            })
        )
    }

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleItemClick = (item: string) => {
        setSelectedItem(item)
        setIsOpen(false)
    }

    const handleEditClick = () => {
        setText(selectedTrackpacksList?.title)
        setIsEditing(true)
    }

    const handleSaveClick = () => {
        setIsEditing(false)
        selectedTrackpacksList.title = text

        dispatch(
            ProductsActionCreator.updateTrackpackName({
                id: selectedTrackpacksList.id,
                name: text,
            })
        )
    }

    const handleKeyDown = (e: React.KeyboardEvent) => {
        if (e.key === "Enter") {
            handleSaveClick()
        }
    }

    const selectedTrackpacksListData =
        trackpackType === "tracking"
            ? selectedTrackpacksList?.trackpackChildrenList?.filter((val: any) => {
                  return val.Status.includes("ACTIVE") || val.Status.includes("AWAITING_INITIAL_DATA")
              })
            : selectedTrackpacksList?.trackpackChildrenList?.filter((val: any) => {
                  return val.Status.includes("ARCHIVED") || val.Status.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
              })

    const handleProductRedirect = (asinNo: string, amazon_tld: string) => {
        handleAmazonProductRedirect(amazon_tld, asinNo)
    }

    const isAllChecked = (): boolean => {
        return selectedTrackpacksListData?.length !== 0 && selectedRows?.length === selectedTrackpacksListData?.length
    }

    const selected_country = AMAZON_DOMAINS?.find((val) => {
        return val?.value === selectedTrackpacksList?.amazonDomain
    })

    const Table = () => {
        return (
            <table className="w-full bg-white rounded-[10px]">
                <thead className="bg-gray-100 border-b border-gray-200 ">
                    <tr>
                        <th className="px-6 py-3 text-left text-gray-500 text-xs font-normal  leading-[18px]">
                            <td className="flex-1">
                                <div className="flex gap-[30px]">
                                    <>
                                        <label className="custom-checkbox !pl-0">
                                            <input
                                                id="one"
                                                ref={intermediateCheckboxRef}
                                                type="checkbox"
                                                checked={isAllChecked()}
                                                onChange={toggleAllCheckboxes}
                                                disabled={isLoading}
                                                className="!border chrome:!border-gray-300 safari:!border-gray-300"
                                            />
                                        </label>
                                    </>

                                    <PrimaryText size="xs" weight="medium" className="text-gray-500">
                                        ASINs
                                    </PrimaryText>
                                </div>
                            </td>
                        </th>
                        <th className="w-[80px] text-center">
                            <td className="py-[13px] px-[22px] text-center">
                                <PrimaryText size="xs" weight="medium" className="text-gray-500">
                                    Status
                                </PrimaryText>
                            </td>
                        </th>
                        <th className="w-[80px] text-center">
                            <td className="py-[13px] px-[22px] text-center">
                                <PrimaryText size="xs" weight="medium" className="text-gray-500">
                                    Owner
                                </PrimaryText>
                            </td>
                        </th>
                        <th className="w-[128px] text-center">
                            <td className="w-[128px] text-center ">
                                <PrimaryText size="xs" weight="medium" className="text-gray-500">
                                    {trackpackType === "tracking" ? "Date Added" : "Time to Deletion"}
                                </PrimaryText>
                            </td>
                        </th>
                    </tr>
                </thead>
                {isLoading ? (
                    <TrackpackSkeleton />
                ) : (
                    <tbody>
                        {selectedTrackpacksListData &&
                            selectedTrackpacksListData.length > 0 &&
                            selectedTrackpacksListData?.map((row: any, rowIndex: number) => (
                                <>
                                    <tr key={rowIndex} className="border-b last:border-b-0  border-gray-200">
                                        <td className="px-[24px] py-[17px] text-left text-gray-500 text-xs font-normal font-['F37 Bolton'] leading-[18px]">
                                            <div className="flex gap-[30px] items-center">
                                                <div className="flex items-center justify-center ml-[1px]">
                                                    <input
                                                        className="relative float-left cursor-pointer appearance-none border-[0.125rem] border-solid border-secondary-500  after:absolute after:chrome:top-[1.5px] after:safari:top-[1.5px] after:chrome:left-[4.5px] after:safari:left-[5px]  checked:border-primary checked:bg-[#ecfdff]  checked:after:absolute  checked:after:block checked:after:h-[8px] checked:after:w-[4px] checked:after:rotate-45 checked:after:border-[1px] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-[#088ab2] checked:after:bg-transparent checked:after:content-['']"
                                                        type="checkbox"
                                                        value=""
                                                        id="checkboxDefault"
                                                        checked={selectedRows.includes(row.ASINs.asinNo)}
                                                        onChange={() => toggleCheckbox(row.ASINs.asinNo)}
                                                        disabled={row.Status.includes("AWAITING_INITIAL_DATA")}
                                                    />
                                                </div>

                                                <div className="flex gap-3">
                                                    {row.Status.includes("AWAITING_INITIAL_DATA") ||
                                                    row.ASINs.imgUrl === null ||
                                                    row.ASINs.imgUrl === undefined ? (
                                                        <Skeleton
                                                            baseColor="#D0D5DD"
                                                            count={1}
                                                            width={40}
                                                            height={40}
                                                        />
                                                    ) : (
                                                        <div className="min-w-[40px] max-w-[40px] overflow-hidden h-[40px] rounded-[4px] border border-gray-300">
                                                            <img
                                                                src={`${img_url}${row.ASINs.imgUrl}`}
                                                                alt="img"
                                                                className="h-full w-full object-contain"
                                                            />
                                                        </div>
                                                    )}
                                                    <div className="flex flex-col">
                                                        {row.Status.includes("AWAITING_INITIAL_DATA") ||
                                                        row.ASINs.asinText === null ||
                                                        row.ASINs.imgUrl === undefined ? (
                                                            <Skeleton
                                                                baseColor="#D0D5DD"
                                                                count={1}
                                                                width={272}
                                                                height={20}
                                                            />
                                                        ) : (
                                                            <PrimaryText
                                                                size="xs"
                                                                weight="light"
                                                                className="leading-[18px] text-[#101828] line-clamp-1 ml-[4px]"
                                                            >
                                                                {row.ASINs.asinText}
                                                            </PrimaryText>
                                                        )}
                                                        <div
                                                            onClick={() =>
                                                                handleProductRedirect(
                                                                    row?.ASINs?.asinNo,
                                                                    row?.amazon_tld
                                                                )
                                                            }
                                                            className="mt-[4px] cursor-pointer px-[4px] py-[1.25px] flex  gap-[3px] items-center rounded-[3px] bg-transparent border border-gray-400 w-fit"
                                                        >
                                                            <PrimaryText
                                                                weight="medium"
                                                                size="xs-medium"
                                                                className=" text-gray-400 tracking-[0.7px] leading-[11px]"
                                                            >
                                                                {row.ASINs.asinNo}
                                                            </PrimaryText>
                                                            <LinkExternalSvg color={"#98A2B3"} className="mb-[0.5px]" />
                                                            {/* <AsinCodeIcon /> */}
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </td>
                                        <td className="w-[80px] px-[32px] py-[28px] text-gray-500 text-xs font-normal font-['F37 Bolton'] leading-[18px]">
                                            <div className="w-fit flex items-center justify-center">
                                                {row.Status.includes("AWAITING_INITIAL_DATA") ? (
                                                    <AwatitingDataTrackpack />
                                                ) : row.currentIssue !== null ? (
                                                    <>
                                                        <div
                                                            data-tooltip-id={`status-warning-tooltip${row.ASINs.asinNo}`}
                                                        >
                                                            <div className="cursor-pointer">
                                                                <WarningIcon />

                                                                <Tooltip
                                                                    id={`status-warning-tooltip${row.ASINs.asinNo}`}
                                                                    style={{
                                                                        padding: "8px",
                                                                        backgroundColor: "#FEF0C7",
                                                                        borderRadius: "6px",
                                                                        zIndex: 1000,
                                                                        display: "inline-block",
                                                                        textAlign: "center",
                                                                        boxShadow:
                                                                            "0px 2px 4px -2px #1018280F, 0px 4px 8px -2px #1018281A",
                                                                    }}
                                                                    place="right"
                                                                    delayShow={200}
                                                                    classNameArrow="custom-arrow yellow"
                                                                    render={() => {
                                                                        return (
                                                                            <AsinTooltipContent
                                                                                currentIssue={row.currentIssue}
                                                                            />
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : trackpackType === "tracking" ? (
                                                    <>
                                                        <div
                                                            data-tooltip-id={`status-success-tooltip${row.ASINs.asinNo}`}
                                                        >
                                                            <div className="cursor-pointer">
                                                                <SuccessIcon />

                                                                <Tooltip
                                                                    id={`status-success-tooltip${row.ASINs.asinNo}`}
                                                                    style={{
                                                                        padding: "6px 8px",
                                                                        backgroundColor: "#DCFAE6",
                                                                        borderRadius: "6px",
                                                                        zIndex: 1000,
                                                                        display: "inline-block",
                                                                        boxShadow:
                                                                            "0px 2px 4px -2px rgba(16, 24, 40, 0.04), 0px 4px 8px -2px rgba(16, 24, 40, 0.08)",
                                                                    }}
                                                                    place="right"
                                                                    delayShow={200}
                                                                    classNameArrow="custom-arrow green"
                                                                    render={() => {
                                                                        return (
                                                                            <div className="flex items-center gap-[2px] mt-[1px]">
                                                                                <PrimaryText
                                                                                    weight="medium"
                                                                                    size="xs-medium"
                                                                                    className=" text-[#067647] leading-[12px]"
                                                                                >
                                                                                    Tracking
                                                                                </PrimaryText>
                                                                                <PrimaryText
                                                                                    weight="light"
                                                                                    size="xs-medium"
                                                                                    className="text-[#067647] leading-[12px]"
                                                                                >
                                                                                    OK
                                                                                </PrimaryText>
                                                                            </div>
                                                                        )
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>
                                                    </>
                                                ) : (
                                                    <NewTrashIcon />
                                                )}

                                                {/* for warning icon */}
                                            </div>
                                        </td>
                                        <td className="px-[24px] py-[17px] text-center">
                                            {row.Owner !== "" ? (
                                                <PrimaryText
                                                    size="xs"
                                                    weight="light"
                                                    className="w-[24px] uppercase h-[24px] bg-[#475467] text-gray-200 flex justify-center items-center rounded-[6px] ml-[1.5px]"
                                                >
                                                    {row.Owner}
                                                </PrimaryText>
                                            ) : (
                                                ""
                                            )}
                                        </td>
                                        <td className="px-[24px] py-[17px] text-center text-gray-500 text-xs font-normal font-['F37 Bolton'] leading-[18px]">
                                            {row.Status.includes("AWAITING_INITIAL_DATA") ? (
                                                <Skeleton baseColor="#D0D5DD" count={1} width={64} height={16} />
                                            ) : trackpackType === "tracking" ? (
                                                dayjs(row["Date Added"])
                                                    .format("MMM-DD-YYYY")
                                                    ?.replace(/^\w+/, (month) => month.toUpperCase())
                                            ) : (
                                                <>{`${row.time_deletion} days `}</>
                                            )}
                                        </td>
                                    </tr>
                                </>
                            ))}
                    </tbody>
                )}
            </table>
        )
    }

    const hasOnlyActiveOrAwaiting = (trackpacks: any[]) => {
        return (
            trackpacks &&
            trackpacks.every((val) => val?.Status?.includes("ACTIVE") || val?.Status?.includes("AWAITING_INITIAL_DATA"))
        )
    }

    const isArchiveDisabled = useMemo(() => {
        return hasOnlyActiveOrAwaiting(selectedTrackpacksList?.trackpackChildrenList)
    }, [selectedTrackpacksList?.trackpackChildrenList])

    const hasOnlyArchivedOrAwaiting = (trackpacks: any[]) => {
        return (
            trackpacks &&
            trackpacks.every(
                (val) => val?.Status?.includes("ARCHIVED") || val?.Status?.includes("ARCHIVED_DURING_PACK_ARCHIVAL")
            )
        )
    }

    const isTrackingDisabled = useMemo(() => {
        return hasOnlyArchivedOrAwaiting(selectedTrackpacksList?.trackpackChildrenList)
    }, [selectedTrackpacksList?.trackpackChildrenList])

    useEffect(() => {
        if (selectedTrackpacksListData) {
            if (isTrackingDisabled) {
                dispatch(AccountActionsCreater.setTrackpackType("archived"))
            } else if (isArchiveDisabled) {
                dispatch(AccountActionsCreater.setTrackpackType("tracking"))
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArchiveDisabled, isTrackingDisabled])

    const handlePtRedirect = (trackpackList: any) => {
        localStorage.setItem("selectedTrackpackID", trackpackList?.id!.toString())
        // @ts-ignore
        if (typeof window.updateSelectedTrackpackID === "function") {
            // @ts-ignore
            window.updateSelectedTrackpackID(trackpackId.toString())
        }
        dispatch(
            RuntimeActionCreators.openNotificaitonAlert({
                open: true,
                trackpackName: selectedTrackpacksList?.title,
                notificationType: "view_in_tp",
                id: trackpackList?.id,
            })
        )
        navigate(ROUTES_CONFIG.dashboard)
    }

    const handleRestoreAsin = () => {
        if (selectedAsinsLength && selectedAsinsLength?.data > 0) {
            dispatch(
                ProductsActionCreator.restoreAsins(
                    {
                        trackpack_id: selectedTrackpacksList.id,
                        asins: selectedAsinsLength?.selectedRows,
                    },
                    (data: any) => {
                        updateDropdownList(data)
                        dispatch(
                            RuntimeActionCreators.openNotificaitonAlert({
                                open: true,
                                notificationType: "restore",
                                trackpackName: selectedTrackpacksList?.title,
                                duration: 30000,
                                id: selectedTrackpacksList.id,
                                isAsinRestore: true,
                                asinsLength: selectedAsinsLength?.selectedRows?.length,
                                restoreMessage: getNotificationMessage(selectedTrackpacksList, data),
                            })
                        )
                        setSelectedRows([])
                    }
                )
            )
        }
    }
    const updateDropdownList = (updatedData: Root) => {
        const updatedDropdownList = selectedDropdownList?.trackpacks?.map((item: TrackpackDatum) =>
            item.metadata.id === selectedTrackpacksList.id ? updatedData : item
        )
        dispatch(ProductsActionCreator.setDropdownList(updatedDropdownList))
    }

    useEffect(() => {
        if (pathID && trackpackHubAsin) {
            const selectedObject = selectedDropdownList?.trackpacks?.find(
                (item: { metadata: { id: number | string } }) => item.metadata.id == pathID
            )

            const matchingTracking = selectedObject?.trackings.find((tracking: any) =>
                trackpackHubAsin.includes(tracking.asin)
            )

            if (matchingTracking && matchingTracking.status === "ARCHIVED") {
                dispatch(AccountActionsCreater.setTrackpackType("archived"))
            }
            setTimeout(() => {
                localStorage.removeItem("trackpackHubAsin")
            }, 6000)
        } else if (pathID && selectedTrackpacksList) {
            const matchingTrackpack = selectedTrackpacksList?.id == pathID
            if (matchingTrackpack) {
                if (
                    (selectedTrackpacksList.status === "ACTIVE" ||
                        selectedTrackpacksList.status === "AWAITING_INITIAL_DATA") &&
                    !isTrackingDisabled
                ) {
                    dispatch(AccountActionsCreater.setTrackpackType("tracking"))
                } else if (
                    selectedTrackpacksList.status === "ARCHIVED" ||
                    selectedTrackpacksList.status === "ARCHIVED_DURING_PACK_ARCHIVAL"
                ) {
                    dispatch(AccountActionsCreater.setTrackpackType("archived"))
                }
            }
        }
    }, [pathID, selectedTrackpacksList])

    return (
        <>
            <div className={"flex gap-[20px] items-start w-full h-full"}>
                <TrackpackOptions setSelectedRows={setSelectedRows} />
                <div
                    className="p-[12px] rounded-[16px] bg-gray-50 pb-0 flex-1 pr-0"
                    style={{
                        border: "1px solid",
                        borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                    }}
                >
                    <div className="w-full mb-[24px] justify-between items-center inline-flex pr-[12px]">
                        <div className="w-full ml-[10px] py-[6px] justify-start items-center gap-1 flex">
                            <div className="w-full flex items-center gap-4">
                                {isEditing ? (
                                    <div className="flex items-center gap-2">
                                        <input
                                            type="text"
                                            className="min-w-full max-w-auto border-0 bg-[#F9FAFB] border-b border-[#088AB2]  text-[#088AB2] text-base font-medium leading-normal placeholder:text-[16px] placeholder:font-[400] placeholder:text-[#06AED4]"
                                            value={text}
                                            placeholder="Insert name..."
                                            onChange={(e) => setText(e.target.value)}
                                            onKeyDown={handleKeyDown}
                                        />
                                        <span
                                            className="cursor-pointer text-cyan-500 text-xs font-light leading-[18px]"
                                            onClick={handleSaveClick}
                                        >
                                            <InputArrow />
                                        </span>
                                    </div>
                                ) : (
                                    <>
                                        <span className="text-[#088AB2] text-base font-medium leading-normal">
                                            {selectedTrackpacksList && selectedTrackpacksList?.title}
                                        </span>
                                        <span
                                            className="cursor-pointer text-cyan-500 text-xs font-light leading-[18px]"
                                            onClick={handleEditClick}
                                        >
                                            <NewEditIcon />
                                        </span>
                                    </>
                                )}
                            </div>
                        </div>

                        <div className="flex items-center">
                            <div
                                className="market-icon mr-[12px]"
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom"
                                data-tooltip-offset={-15}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip className="ml-[80px] mt-[13px]" label="Marketplace" />
                                )}
                            >
                                {selected_country?.icon}
                            </div>

                            <div
                                data-tooltip-id="dashboard-tooltip"
                                data-tooltip-place="bottom"
                                data-tooltip-offset={0}
                                data-tooltip-html={ReactDOMServer.renderToStaticMarkup(
                                    <CommonTooltip className="ml-[110px] mt-[-10px]" label="View Performance" />
                                )}
                                onClick={() => handlePtRedirect(selectedTrackpacksList)}
                                className="p-[10px] cursor-pointer bg-[#ECFDFF] border border-[#A5F0FC] rounded-[10px] disabled:bg-gray-100
                                     mr-[8px]"
                            >
                                <PerfromanceTrackerIcon className="cursor-pointer outline-none" />
                            </div>

                            <ButtonElement
                                onClick={handleOpen}
                                size="medium"
                                // prefix={<Plus />}
                                className="px-[12px] py-[8px] pt-[10px] rounded-[10px] w-[88px] h-[36px] flex items-center"
                                parentClass="justify-start items-center gap-2 inline-flex  "
                                textClass="text-[#088AB2] flex item-center gap-[8px]"
                            >
                                <Plus />
                                ASINs
                            </ButtonElement>
                        </div>
                    </div>
                    <div className="flex items-center justify-between w-full pr-[12px] mb-[16px]">
                        <div className="flex gap-4 items-center">
                            <div className="flex border border-gray-200 rounded-[8px] w-[108px] h-[16px] ml-[10px]">
                                <div
                                    className={`overflow-hidden rounded-tl-[8px] rounded-bl-[8px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] border-r border-gray-200 h-full ${
                                        trackpackType === "tracking"
                                            ? "bg-[#DCFAE6]"
                                            : isTrackingDisabled
                                            ? "bg-white"
                                            : "bg-white"
                                    }`}
                                >
                                    <PrimaryText
                                        onClick={() => {
                                            !isTrackingDisabled &&
                                                dispatch(AccountActionsCreater.setTrackpackType("tracking"))
                                            dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                            setSelectedRows([])
                                        }}
                                        weight={trackpackType === "tracking" ? "medium" : "light"}
                                        size="xs-small"
                                        className={`${
                                            isTrackingDisabled ? "cursor-not-allowed !text-gray-300" : "cursor-pointer"
                                        } ${
                                            trackpackType === "tracking" ? "text-[#067647] " : "text-[#344054]"
                                        }  leading-[12px] h-full align-middle`}
                                    >
                                        TRACKING
                                    </PrimaryText>
                                </div>
                                <div
                                    className={`overflow-hidden rounded-tr-[8px] rounded-br-[8px] flex items-center justify-center pl-[7px] pr-[6px] py-[2px] ${
                                        trackpackType === "archived"
                                            ? "bg-[#FEE4E2]"
                                            : isArchiveDisabled
                                            ? "bg-white "
                                            : "bg-white"
                                    }`}
                                >
                                    <PrimaryText
                                        onClick={() => {
                                            !isArchiveDisabled &&
                                                dispatch(AccountActionsCreater.setTrackpackType("archived"))
                                            dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
                                            setSelectedRows([])
                                        }}
                                        weight={trackpackType === "archived" ? "medium" : "light"}
                                        size="xs-small"
                                        className={` ${
                                            trackpackType === "archived" ? "text-[#B42318]" : "text-[#344054]"
                                        } ${
                                            isArchiveDisabled ? "cursor-not-allowed text-gray-300" : "cursor-pointer"
                                        } leading-[12px] h-full align-middle`}
                                    >
                                        ARCHIVED
                                    </PrimaryText>
                                </div>
                            </div>

                            {selectedRows?.length > 0 && (
                                <div className="w-[30px] h-[30px] flex justify-center items-center rounded-full bg-gray-200 cursor-pointer">
                                    {trackpackType === "tracking" ? (
                                        <div
                                            onClick={(e: React.SyntheticEvent) => {
                                                e.stopPropagation()
                                                handleOpenArchive()
                                            }}
                                        >
                                            <TrackpackTrashIcon />
                                        </div>
                                    ) : (
                                        <div
                                            onClick={(e: React.SyntheticEvent) => {
                                                e.stopPropagation()
                                                handleRestoreAsin()
                                            }}
                                        >
                                            <FlipArrowIcon />
                                        </div>
                                    )}
                                </div>
                            )}
                        </div>
                        <div className="relative hidden">
                            <div
                                onClick={toggleDropdown}
                                className="flex items-center cursor-pointer mr-[10px] my-[6px]"
                            >
                                <FilterIcon />
                            </div>
                            {isOpen && (
                                <div
                                    className="absolute cursor-pointer right-0 top-[100%] w-[180px]  bg-white rounded-[10px] p-[6px]"
                                    style={{
                                        boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
                                        border: "1px solid",
                                        borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                                    }}
                                >
                                    <ul className="p-0 m-0">
                                        <li
                                            className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                                                selectedItem === "Newest" ? "bg-[#F2F4F7]" : ""
                                            }`}
                                            onClick={() => handleItemClick("Newest")}
                                        >
                                            <PrimaryText
                                                weight={selectedItem === "Newest" ? "medium" : "light"}
                                                size="sm-medium"
                                                className="text-gray-700  cursor-pointer"
                                            >
                                                Newest
                                            </PrimaryText>

                                            {selectedItem === "Newest" && <CheckedIcon />}
                                        </li>
                                        <li
                                            className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                                                selectedItem === "Oldest" ? "bg-[#F2F4F7]" : ""
                                            }`}
                                            onClick={() => handleItemClick("Oldest")}
                                        >
                                            <PrimaryText
                                                weight={selectedItem === "Oldest" ? "medium" : "light"}
                                                size="sm-medium"
                                                className="text-gray-700  cursor-pointer"
                                            >
                                                Oldest
                                            </PrimaryText>

                                            {selectedItem === "Oldest" && <CheckedIcon />}
                                        </li>
                                        <li
                                            className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                                                selectedItem === "A to Z" ? "bg-[#F2F4F7]" : ""
                                            }`}
                                            onClick={() => handleItemClick("A to Z")}
                                        >
                                            <PrimaryText
                                                weight={selectedItem === "A to Z" ? "medium" : "light"}
                                                size="sm-medium"
                                                className="text-gray-700  cursor-pointer"
                                            >
                                                A to Z
                                            </PrimaryText>

                                            {selectedItem === "A to Z" && <CheckedIcon />}
                                        </li>
                                        <li
                                            className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                                                selectedItem === "Z to A" ? "bg-[#F2F4F7]" : ""
                                            }`}
                                            onClick={() => handleItemClick("Z to A")}
                                        >
                                            <PrimaryText
                                                weight={selectedItem === "Z to A" ? "medium" : "light"}
                                                size="sm-medium"
                                                className="text-gray-700  cursor-pointer"
                                            >
                                                Z to A
                                            </PrimaryText>

                                            {selectedItem === "Z to A" && <CheckedIcon />}
                                        </li>
                                    </ul>
                                </div>
                            )}
                        </div>
                    </div>
                    <div
                        ref={containerRef}
                        className="overflow-y-scroll -mr-[17px]"
                        style={{ height: "calc(100vh - 271px)" }}
                    >
                        <div
                            className="bg-white rounded-[16px] border border-gray-200 overflow-hidden"
                            style={{ width: "calc(100% - 24px)" }}
                        >
                            <Table />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

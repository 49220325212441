import { NotificationsAction } from "../actions/notifications.action"
import { NotificationStateClass } from "../classes/notificationState.class"

export const NotificationsReducer = (
    state: INotificationState = new NotificationStateClass(),
    action: ISagaAction
): Partial<INotificationState> => {
    switch (action.type) {
        case NotificationsAction.GET_ALL_NOTIFICATIONS: {
            return {
                ...state,
            }
        }
        case NotificationsAction.SET_NOTIFICATIONS_DATA:
            return {
                ...state,
                notificationsData: action.payload,
            }
        case NotificationsAction.NOTIFI_SELECTION:
            return {
                ...state,
                notifiSelection: action.payload,
            }
        case NotificationsAction.GRAPH_NOTIFI_READ:
            return {
                ...state,
                graphNotifiRead: action.payload,
            }
        case NotificationsAction.READ_NOTIFICATION:
            return {
                ...state,
            }
            case NotificationsAction.UNREAD_NOTIFICATION:
                return {
                    ...state,
                }
        default:
            return state
    }
}

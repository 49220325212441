export const DescriptionIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path
                d="M23.0663 23.4038H14.8573M4.08301 23.9168L9.77723 21.7267C10.1414 21.5867 10.3235 21.5166 10.4939 21.4252C10.6453 21.3439 10.7895 21.2502 10.9252 21.1449C11.078 21.0263 11.216 20.8884 11.4919 20.6124L23.0663 9.03806C24.1997 7.90463 24.1997 6.06699 23.0663 4.93356C21.9329 3.80014 20.0952 3.80014 18.9618 4.93356L7.38741 16.5079C7.11148 16.7839 6.97351 16.9218 6.85496 17.0746C6.74966 17.2103 6.65591 17.3546 6.57467 17.5059C6.48322 17.6763 6.41317 17.8584 6.27309 18.2226L4.08301 23.9168ZM4.08301 23.9168L6.19489 18.426C6.34601 18.0331 6.42157 17.8366 6.55118 17.7466C6.66444 17.668 6.80459 17.6382 6.94003 17.6641C7.09502 17.6937 7.24385 17.8425 7.54153 18.1402L9.85963 20.4583C10.1573 20.756 10.3061 20.9048 10.3357 21.0598C10.3616 21.1953 10.3319 21.3354 10.2532 21.4487C10.1632 21.5783 9.96677 21.6539 9.57385 21.805L4.08301 23.9168Z"
                stroke={props.stroke}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export enum RuntimeActions {
    APP_LOADING_START = "APP_LOADING_START",
    APP_LOADING_STOP = "APP_LOADING_STOP",
    OPEN_LIGHTBOX = "OPEN_LIGHTBOX",
    CLOSE_LIGHTBOX = "CLOSE_LIGHTBOX",
    OPEN_MODAL = "OPEN_MODAL_CHARTS",
    CLOSE_MODAL = "CLOSE_MODAL_CHARTS",
    OPEN_POPUP = "OPEN_POPUP",
    CLOSE_POPUP = "CLOSE_POPUP",
    CLOSE_All_POPUP = "CLOSE_All_POPUP",
    OPEN_TRACKPACK_MODAL = "OPEN_TRACKPACK_MODAL",
    CLOSE_TRACKPACK_MODAL = "CLOSE_TRACKPACK_MODAL",
    ASIN_TRACKPACK_NEW_MODAL_OPEN = "ASIN_TRACKPACK_NEW_MODAL_OPEN",
    ASIN_TRACKPACK_NEW_MODAL_CLOSE = "ASIN_TRACKPACK_NEW_MODAL_CLOSE",
    ADDITIONAL_MODAL_OPEN = "ADDITIONAL_MODAL_OPEN",
    ADDITIONAL_MODAL_CLOSE = "ADDITIONAL_MODAL_CLOSE",
    OPEN_ARCHIVE_MODAL = "OPEN_ARCHIVE_MODAL",
    CLOSE_ARCHIVE_MODAL = "CLOSE_ARCHIVE_MODAL",
    OPEN_PERFORMANCE_TOUR_MODAL = "OPEN_PERFORMANCE_TOUR_MODAL",
    CLOSE_PERFORMANCE_TOUR_MODAL = "CLOSE_PERFORMANCE_TOUR_MODAL",
    OPEN_TRACKPACK_TOUR_MODAL = "OPEN_TRACKPACK_TOUR_MODAL",
    CLOSE_TRACKPACK_TOUR_MODAL = "CLOSE_TRACKPACK_TOUR_MODAL",
    OPEN_NOTIFICATION_ALERT = "OPEN_NOTIFICATION_ALERT",
    CLOSE_NOTIFICATION_ALERT = "CLOSE_NOTIFICATION_ALERT",
    ADD_TRACKPACK_FLOW_MODAL_OPEN = "ADD_TRACKPACK_FLOW_MODAL_OPEN",
    CLOSE_ADD_TRACKPACK_FLOW_MODAL_OPEN = "CLOSE_ADD_TRACKPACK_FLOW_MODAL_OPEN",
    SEMI_AUTOMATED_MODAL_OPEN = "SEMI_AUTOMATED_MODAL_OPEN",
    CLOSE_SEMI_AUTOMATED_MODAL_OPEN = "CLOSE_SEMI_AUTOMATED_MODAL_OPEN",
}

export const RuntimeActionCreators = {
    startLoading: (loadingTarget: string, isLoading: boolean): ISagaAction => {
        return {
            type: RuntimeActions.APP_LOADING_START,
            payload: {
                loadingTarget,
                isLoading,
            },
        }
    },

    stopLoading: (loadingTarget: string): ISagaAction => {
        return {
            type: RuntimeActions.APP_LOADING_STOP,
            payload: {
                loadingTarget,
            },
        }
    },

    openLightBox: (payload: ILightBox): ISagaAction => {
        return {
            type: RuntimeActions.OPEN_LIGHTBOX,
            payload,
        }
    },

    closeLightBox: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_LIGHTBOX,
        }
    },
    openModal: (payload: IModalOptions): ISagaAction => {
        return {
            type: RuntimeActions.OPEN_MODAL,
            payload,
        }
    },
    closeModal: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_MODAL,
        }
    },
    openPopup: (name: string): ISagaAction => {
        return {
            type: RuntimeActions.OPEN_POPUP,
            payload: {
                name,
            },
        }
    },
    closePopup: (name: string): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_POPUP,
            payload: {
                name,
            },
        }
    },
    closeAllPopup: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_All_POPUP,
        }
    },
    openTrackpackModal: (payload: IModalTrackPackOptions): ISagaAction => {
        return {
            type: RuntimeActions.OPEN_TRACKPACK_MODAL,
            payload,
        }
    },
    closeTrackpackModal: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_TRACKPACK_MODAL,
        }
    },
    closeNewTrackPackAsinModal: (): ISagaAction => {
        return {
            type: RuntimeActions.ASIN_TRACKPACK_NEW_MODAL_CLOSE,
        }
    },
    openNewTrackPackAsinModal: (data: IAsinTrackPack): ISagaAction => {
        return {
            type: RuntimeActions.ASIN_TRACKPACK_NEW_MODAL_OPEN,
            payload: data,
        }
    },
    closeAsinAddtionalModal: (): ISagaAction => {
        return {
            type: RuntimeActions.ADDITIONAL_MODAL_CLOSE,
        }
    },
    openAsinAddtionalModal: (data: IAdditionalProps): ISagaAction => {
        return {
            type: RuntimeActions.ADDITIONAL_MODAL_OPEN,
            payload: data,
        }
    },
    closeArchiveModal: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_ARCHIVE_MODAL,
        }
    },
    openArchiveModal: (data: IArchiveProps): ISagaAction => {
        return {
            type: RuntimeActions.OPEN_ARCHIVE_MODAL,
            payload: data,
        }
    },
    closePerformanceTourModal: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_PERFORMANCE_TOUR_MODAL,
        }
    },
    openPerformanceTourModal: (data: IPerfomanceTrackerQuickTourProps): ISagaAction => {
        return {
            type: RuntimeActions.OPEN_PERFORMANCE_TOUR_MODAL,
            payload: data,
        }
    },
    closeTrackpackHubTourModal: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_TRACKPACK_TOUR_MODAL,
        }
    },
    openTrackpackHubTourModal: (data: ITrackpackHubQuickTourProps): ISagaAction => {
        return {
            type: RuntimeActions.OPEN_TRACKPACK_TOUR_MODAL,
            payload: data,
        }
    },
    // notificaiton Alerts

    closeNotificaitonAlert: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_NOTIFICATION_ALERT,
        }
    },
    openNotificaitonAlert: (data: INotificationAlertProps): ISagaAction => {
        return {
            type: RuntimeActions.OPEN_NOTIFICATION_ALERT,
            payload: data,
        }
    },
    // Add Trackpack Semi-automated / Manual flow
    openTrackPackFlowModal: (data: IAsinTrackPack): ISagaAction => {
        return {
            type: RuntimeActions.ADD_TRACKPACK_FLOW_MODAL_OPEN,
            payload: data,
        }
    },
    closeTrackPackFlowModal: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_ADD_TRACKPACK_FLOW_MODAL_OPEN,
        }
    },
    openSemiAutomatedTrackpackModal: (data: IAsinTrackPack): ISagaAction => {
        return {
            type: RuntimeActions.SEMI_AUTOMATED_MODAL_OPEN,
            payload: data,
        }
    },
    closeSemiAutomatedTrackpackModal: (): ISagaAction => {
        return {
            type: RuntimeActions.CLOSE_SEMI_AUTOMATED_MODAL_OPEN,
        }
    },
}

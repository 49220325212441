import dayjs from "dayjs"
import { useMemo } from "react"
import { useSelector } from "react-redux"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { Header } from "./header.component"
import { ProductListItem } from "./productListItem.component"
export const LogView = ({ infoVisible, selectedRange }: ILogViewProps) => {
    const { selectedLogProduct, selectedProductRange } = useSelector(ProductStateSelector)
    const range = useMemo(() => {
        return selectedProductRange?.selectedRange || 0
    }, [selectedProductRange?.selectedRange])
    const selectedASINProduct: IGroupProductData | undefined = useMemo(() => {
        return selectedLogProduct
    }, [selectedLogProduct])

    const compiledProductData = useMemo(() => {
        let startDate: dayjs.Dayjs
        const endDate: dayjs.Dayjs = dayjs().startOf("day")
        startDate = endDate.subtract(range, "day").startOf("day")

        const data =
            range === 0
                ? selectedASINProduct?.product_data ?? []
                : selectedASINProduct?.product_data?.filter((record) => {
                      const timestamp: dayjs.Dayjs = dayjs(record.timestamp)
                      return timestamp.isAfter(startDate) && timestamp.isBefore(endDate.add(1, "day"))
                  }) ?? []
        return [...data].reverse()
    }, [range, selectedASINProduct])

    return (
        <>
            <div
                className={`${
                    infoVisible
                        ? "h-calc-359 overflow-auto scroll-hidden -mr-[30px] "
                        : "h-calc-227 overflow-auto scroll-hidden -mr-[30px]"
                }`}
            >
                <Header />
                <div className={"overflow-auto h-full"}>
                    <div
                        className={"log-w-calc-10 h-full"}
                        style={{
                            width:
                                selectedRange === 7 || selectedRange === 14 ? "calc(100% - 30px)" : "calc(100% - 25px)",
                        }}
                    >
                        <div className="h-full">
                            {selectedASINProduct && (
                                <div className={"h-full"}>
                                    {(() => {
                                        return compiledProductData?.map((product, index) => {
                                            const isLastItem = compiledProductData?.length - 1 === index
                                            return (
                                                <ProductListItem
                                                    key={index}
                                                    product={product}
                                                    color={selectedASINProduct?.color}
                                                    isFirst={index === 0}
                                                    isLast={isLastItem}
                                                />
                                            )
                                        })
                                    })()}
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation } from "react-router-dom"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { AlertDotIconSvg } from "../../../assets/svgs/alertDotIcon.svg"
import { TrackpackArrowIcon } from "../../../assets/svgs/trackpackArrow.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { PrimaryText } from "../../elements/primaryText.element"

const RestoreNotificationAlert = (props: INotificationAlertProps) => {
    const location = useLocation()

    const handleActionClick = () => {
        if (props?.id) {
            localStorage.setItem("trackpackHubId", props?.id)
        }
        dispatch(RuntimeActionCreators.closeNotificaitonAlert())
    }
    const dispatch = useDispatch()

    useEffect(() => {
        const timer = setTimeout(() => {
            dispatch(RuntimeActionCreators.closeNotificaitonAlert())
        }, props.duration)
        if (ROUTES_CONFIG.trackpackCentral !== location.pathname) {
            dispatch(RuntimeActionCreators.closeNotificaitonAlert())
        }

        return () => clearTimeout(timer)
    }, [dispatch, props.duration, location])

    return (
        <div
            className={`py-[4px] pl-[4px] pr-[8px] m-auto max-w-[496px] rounded-[12px] border z-10 absolute left-[50%] top-[38px] translate-x-[-50%] border-[#75E0A7] bg-[#ECFDF3] `}
            style={{ boxShadow: "0px 4px 8px 4px #1018281A" }}
        >
            <div className="flex items-center gap-[12px]">
                <div
                    className={`flex items-center gap-[4px] rounded-[8px] border py-[2px] px-[8px] pl-[4px] border-[#75E0A7] bg-white `}
                >
                    <AlertDotIconSvg fillColor="#17B26A" color="#ABEFC6" />
                    {props.isAsinRestore === true && props?.restoreMessage === "ASINMessage" ? (
                        <PrimaryText
                            weight="light"
                            size="small"
                            className={`text-[#067647] pt-[2px] whitespace-nowrap`}
                        >
                            {props.asinsLength && props.asinsLength > 1 ? `${props.asinsLength} ASINs ` : "1 ASIN"} in{" "}
                            <span className="font-medium">{props.trackpackName}</span> has been moved to Tracking
                        </PrimaryText>
                    ) : props.isAsinRestore === true && props?.restoreMessage === "trackpackMessage" ? (
                        <PrimaryText
                            weight="light"
                            size="small"
                            className={`text-[#067647] pt-[2px] whitespace-nowrap`}
                        >
                            <span className="font-medium">{props.trackpackName}</span> has been moved to Tracking
                        </PrimaryText>
                    ) : (
                        <PrimaryText
                            weight="light"
                            size="small"
                            className={`text-[#067647] pt-[2px] whitespace-nowrap`}
                        >
                            <span className="font-medium">{props.trackpackName}</span> has been moved to Tracking
                        </PrimaryText>
                    )}
                </div>
                <div className="flex items-center gap-[4px] py-[2px]">
                    <PrimaryText
                        weight="light"
                        size="small"
                        className={`text-[#067647] whitespace-nowrap cursor-pointer`}
                        onClick={handleActionClick}
                    >
                        View now
                    </PrimaryText>
                    <TrackpackArrowIcon />
                </div>
            </div>
        </div>
    )
}

export default RestoreNotificationAlert

import { AnimatedAmaizingIcon } from "./assets/svgs/amaizing-bubble.svg"

const LoadingComponent = () => {
    return (
        <div className="flex justify-center items-center h-[100vh] w-full">
            <AnimatedAmaizingIcon className={"h-[56px] w-[60px]"} />
        </div>
    )
}

export default LoadingComponent

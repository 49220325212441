import { useForm } from "react-hook-form"

import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { AccountActionsCreater } from "../../../actions/account.action"
import { FilterIcon } from "../../../assets/svgs/filterIcon.svg"
import { SearchIcon } from "../../../assets/svgs/searchIcon.svg"
import { useInputValidations } from "../../../hooks/inputValidations.hook"
import { AccountStateSelector } from "../../../selectors/accountState.selector"
import FilterDropdown from "../../common/filterDrowpdown.component"
import { PrimaryText } from "../../elements/primaryText.element"
import useDebouncedInput from "../../elements/useDebouncedInput.element"
import { UserAccountsInput } from "../../elements/userAccountsInput.element"

export const UserAccountsHeader = () => {
    const validationConfig = useInputValidations()
    const { control, watch } = useForm()
    const [isOpen, setIsOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState<string>("Newest")
    const { data } = useSelector(AccountStateSelector)
    const dispatch = useDispatch()

    const searchInput = watch("search")
    const { debouncedValue: debouncedSearchInput, handleChange } = useDebouncedInput(searchInput, 500)

    const [filteredData, setFilteredData] = useState(data)

    useEffect(() => {
        handleChange(searchInput)
    }, [searchInput])

    useEffect(() => {
        if (debouncedSearchInput) {
           
            const searchTerms = debouncedSearchInput.toLowerCase().split(" ")
            setFilteredData(
                data.filter((account) => {
                    const fullString = `${account.first_name} ${account.last_name} ${account.email}`.toLowerCase()
                    return searchTerms.some((term) => fullString.includes(term))
                })
            )
        } else {
            setFilteredData(data)
        }
    }, [debouncedSearchInput, data])

    useEffect(() => {
        if (selectedItem === "Oldest") {
            setFilteredData((prevData) =>
                [...prevData].sort((a, b) => new Date(a.date_joined).getTime() - new Date(b.date_joined).getTime())
            )
        } else if (selectedItem === "Email A to Z") {
            setFilteredData((prevData) =>
                [...prevData].sort((a, b) =>
                    `${a?.first_name} ${a?.last_name} ${a?.email}`.localeCompare(
                        `${b?.first_name} ${b?.last_name} ${b?.email}`
                    )
                )
            )
        } else if (selectedItem === "Email Z to A") {
            setFilteredData((prevData) =>
                [...prevData].sort((a, b) =>
                    `${b.first_name} ${b.last_name} ${b.email}`.localeCompare(
                        `${a.first_name} ${a.last_name} ${a.email}`
                    )
                )
            )
        } else if (selectedItem === "Newest") {
            setFilteredData((prevData) =>
                [...prevData].sort((a, b) => new Date(b.date_joined).getTime() - new Date(a.date_joined).getTime())
            )
        }
    }, [selectedItem, data])

    useEffect(() => {
        dispatch(AccountActionsCreater.setSearchData(filteredData))
    }, [filteredData, dispatch])

    const toggleDropdown = () => {
        setIsOpen(!isOpen)
    }

    const handleItemClick = (item: string) => {
        setSelectedItem(item)
        setIsOpen(false)
    }

    const dropdownOptions = [
        { label: "Newest", value: "Newest" },
        { label: "Oldest", value: "Oldest" },
        { label: "Email A to Z", value: "Email A to Z" },
        { label: "Email Z to A", value: "Email Z to A" },
    ]

    return (
        <>
            <div className={"flex justify-between items-center w-full mb-[14px]"}>
                <PrimaryText size="lg" weight="medium" className="leading-7 pl-[10px] py-[2px]">
                    User Accounts
                </PrimaryText>
                <div className="flex items-center gap-[12px] relative">
                    <UserAccountsInput
                        name="search"
                        type="text"
                        defaultValue=""
                        reactHookControl={control}
                        reactHookValidations={{
                            pattern: validationConfig.alphanumericUppercaseOnlyTenChars,
                            required: validationConfig.required,
                        }}
                        placeholder="Search"
                        className=" w-[320px]"
                        prefix={<SearchIcon />}
                    />
                    <div className="mr-[8px]" onClick={toggleDropdown}>
                        <FilterIcon />
                    </div>
                    {isOpen && (
                        <FilterDropdown
                            options={dropdownOptions}
                            selectedItem={selectedItem}
                            handleItemClick={handleItemClick}
                        />
                    )}
                </div>
            </div>
        </>
    )
}

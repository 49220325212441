export const NewAlertIcon = () => {
    return (
        <svg width="16" height="14" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M12 9.70348V14.088M12 16.7187H12.0088M10.7858 5.22403L3.57337 17.6818C3.17332 18.3728 2.9733 18.7183 3.00286 19.0019C3.02865 19.2492 3.15823 19.4739 3.35935 19.6202C3.58994 19.7878 3.98916 19.7878 4.7876 19.7878H19.2124C20.0108 19.7878 20.4101 19.7878 20.6406 19.6202C20.8418 19.4739 20.9714 19.2492 20.9971 19.0019C21.0267 18.7183 20.8267 18.3728 20.4266 17.6818L13.2142 5.22403C12.8156 4.53552 12.6163 4.19126 12.3563 4.07564C12.1295 3.97479 11.8705 3.97479 11.6437 4.07564C11.3837 4.19126 11.1844 4.53552 10.7858 5.22403Z"
                fill="white"
                stroke="#F79009"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export const VideoIconSidebar = (props: IArrowProps) => {
    return (
        // <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <path
        //         d="M9.9974 17.5L9.91402 17.3749C9.33515 16.5066 9.04571 16.0725 8.66331 15.7582C8.32478 15.4799 7.9347 15.2712 7.5154 15.1438C7.04177 15 6.51999 15 5.47642 15H4.33073C3.39731 15 2.9306 15 2.57408 14.8183C2.26047 14.6586 2.00551 14.4036 1.84572 14.09C1.66406 13.7335 1.66406 13.2668 1.66406 12.3333V5.16667C1.66406 4.23325 1.66406 3.76654 1.84572 3.41002C2.00551 3.09641 2.26047 2.84144 2.57408 2.68166C2.9306 2.5 3.39731 2.5 4.33073 2.5H4.66406C6.5309 2.5 7.46433 2.5 8.17736 2.86331C8.80457 3.18289 9.31451 3.69282 9.63408 4.32003C9.9974 5.03307 9.9974 5.96649 9.9974 7.83333M9.9974 17.5V7.83333M9.9974 17.5L10.0808 17.3749C10.6596 16.5066 10.9491 16.0725 11.3315 15.7582C11.67 15.4799 12.0601 15.2712 12.4794 15.1438C12.953 15 13.4748 15 14.5184 15H15.6641C16.5975 15 17.0642 15 17.4207 14.8183C17.7343 14.6586 17.9893 14.4036 18.1491 14.09C18.3307 13.7335 18.3307 13.2668 18.3307 12.3333V5.16667C18.3307 4.23325 18.3307 3.76654 18.1491 3.41002C17.9893 3.09641 17.7343 2.84144 17.4207 2.68166C17.0642 2.5 16.5975 2.5 15.6641 2.5H15.3307C13.4639 2.5 12.5305 2.5 11.8174 2.86331C11.1902 3.18289 10.6803 3.69282 10.3607 4.32003C9.9974 5.03307 9.9974 5.96649 9.9974 7.83333"
        //         stroke={props.color}
        //         stroke-linecap="round"
        //         stroke-linejoin="round"
        //     />
        // </svg>

        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M9.99984 17.5L9.91646 17.3749C9.33759 16.5066 9.04816 16.0725 8.66576 15.7582C8.32722 15.4799 7.93714 15.2712 7.51784 15.1438C7.04421 15 6.52243 15 5.47886 15H4.33317C3.39975 15 2.93304 15 2.57652 14.8183C2.26292 14.6586 2.00795 14.4036 1.84816 14.09C1.6665 13.7335 1.6665 13.2668 1.6665 12.3333V5.16667C1.6665 4.23325 1.6665 3.76654 1.84816 3.41002C2.00795 3.09641 2.26292 2.84144 2.57652 2.68166C2.93304 2.5 3.39975 2.5 4.33317 2.5H4.6665C6.53335 2.5 7.46677 2.5 8.17981 2.86331C8.80701 3.18289 9.31695 3.69282 9.63653 4.32003C9.99984 5.03307 9.99984 5.96649 9.99984 7.83333M9.99984 17.5V7.83333M9.99984 17.5L10.0832 17.3749C10.6621 16.5066 10.9515 16.0725 11.3339 15.7582C11.6725 15.4799 12.0625 15.2712 12.4818 15.1438C12.9555 15 13.4772 15 14.5208 15H15.6665C16.5999 15 17.0666 15 17.4232 14.8183C17.7368 14.6586 17.9917 14.4036 18.1515 14.09C18.3332 13.7335 18.3332 13.2668 18.3332 12.3333V5.16667C18.3332 4.23325 18.3332 3.76654 18.1515 3.41002C17.9917 3.09641 17.7368 2.84144 17.4232 2.68166C17.0666 2.5 16.5999 2.5 15.6665 2.5H15.3332C13.4663 2.5 12.5329 2.5 11.8199 2.86331C11.1927 3.18289 10.6827 3.69282 10.3631 4.32003C9.99984 5.03307 9.99984 5.96649 9.99984 7.83333"
                stroke={props.color}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

import { PreviousArrow } from "../../assets/svgs/previousArrow.svg"
import { RightArrow } from "../../assets/svgs/rightArrow.svg"

const ArrowModalActions = ({ active, total, setActive }: IModalArrowActionsProps) => {
    return (
        <div>
            <div className="flex gap-[4px] items-center">
                <button
                    className={` rounded-[3px] ${active <= 1 ? "bg-gray-200" : "bg-gray-400"} `}
                    onClick={() => {
                        if (active <= 1) {
                            return
                        }
                        setActive(active - 1)
                    }}
                    disabled={active <= 1}
                >
                    <PreviousArrow width={12} height={12} color={`${active <= 1 ? "bg-white" : "bg-white"}`} />
                </button>

                <button
                    className={` rounded-[3px]  ${!(active < total) ? "bg-gray-200" : "bg-gray-400"}`}
                    onClick={() => {
                        if (!(active < total)) {
                            return
                        }
                        setActive(active + 1)
                    }}
                    disabled={!(active < total)}
                >
                    <RightArrow width={12} height={12} color={`${active <= 1 ? "bg-white" : "bg-white"}`} />
                </button>
                {/* <ButtonElement
                    onClick={() => {
                        if (active <= 1) {
                            return
                        }
                        setActive(active - 1)
                    }}
                    viewType="only-icon"
                >
                    <ArrowLeft />
                </ButtonElement>
                <ButtonElement
                    onClick={() => {
                        if (!(active < total)) {
                            return
                        }
                        setActive(active + 1)
                    }}
                    viewType="only-icon"
                >
                    <ArrowRight />
                </ButtonElement> */}
            </div>
        </div>
    )
}
export default ArrowModalActions

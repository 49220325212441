export const AdminLogo = () => {
    return (
        <svg width="60" height="54" viewBox="0 0 60 54" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_217_178038)">
                <g clip-path="url(#clip1_217_178038)">
                    <path
                        d="M51.0584 54.0046C50.881 54.0046 50.7119 53.9261 50.5894 53.7866L47.8089 50.2165C47.1708 49.4842 46.2538 49.0614 45.303 49.0614H39.2265C36.2051 49.0614 33.75 46.5287 33.75 43.412V35.0469C33.75 31.9301 36.2051 29.3975 39.2265 29.3975H54.5235C57.5449 29.3975 60 31.9301 60 35.0469V43.412C60 46.5287 57.5449 49.0614 54.5235 49.0614H52.867C52.229 49.0614 51.7092 49.5976 51.7092 50.2558V53.342C51.7092 53.7561 51.3796 54.0002 51.0669 54.0002L51.0584 54.0046Z"
                        fill="url(#paint0_linear_217_178038)"
                    />
                </g>
                <path
                    d="M19.4609 49C18.7413 49 18.0004 48.453 18.0004 47.5341V41.1346C18.0004 39.7712 16.8997 39 15.5493 39H11.9509C5.36372 39 0 33.6499 0 27L0 12.0647C0 5.4148 5.36372 0 11.9509 0H41C47.5872 0 53 5.41053 53 12.0604V27C53 33.6499 47.5872 39 41 39H31.9579C29.9047 39 27.9404 39.5447 26.5646 41.0875L20.5235 48.5128C20.2441 48.8248 19.8674 49 19.4609 49Z"
                    fill="url(#paint1_linear_217_178038)"
                />
                <path
                    d="M40 22C38.3448 22 37 20.6671 37 19.0267V12.9733C37 11.3329 38.3448 10 40 10C41.6552 10 43 11.3329 43 12.9733V19.0267C43 20.6671 41.6552 22 40 22Z"
                    fill="url(#paint2_linear_217_178038)"
                />
                <path
                    d="M42 13.5012C42 13.7788 41.7736 14 41.5 14C41.2264 14 41 13.7741 41 13.5012V12.4988C41 12.2212 41.2264 12 41.5 12C41.7736 12 42 12.2259 42 12.4988V13.5012Z"
                    fill="white"
                />
                <path
                    d="M13 21.7529C11.3448 21.7529 10 20.4195 10 18.7784V12.7226C10 11.0814 11.3448 9.74805 13 9.74805C14.6552 9.74805 16 11.0814 16 12.7226V18.7784C16 20.4195 14.6552 21.7529 13 21.7529Z"
                    fill="url(#paint3_linear_217_178038)"
                />
                <path
                    d="M15 13.5012C15 13.7788 14.7736 14 14.5 14C14.2264 14 14 13.7741 14 13.5012V12.4988C14 12.2212 14.2264 12 14.5 12C14.7736 12 15 12.2259 15 12.4988V13.5012Z"
                    fill="white"
                />
                <path
                    fill-rule="evenodd"
                    clip-rule="evenodd"
                    d="M23.0049 26.5781C22.9626 26.2726 23.1979 26 23.5084 26H30.4916C30.8021 26 31.0374 26.2773 30.9951 26.5781C30.7127 28.5147 29.0328 30 27 30C24.9672 30 23.2873 28.5147 23.0049 26.5781ZM24.8778 28.9988C25.4989 29.4265 26.2377 29.6616 27 29.6616C27.7623 29.6616 28.5011 29.4312 29.1222 28.9988C28.5011 28.5664 27.7623 28.3314 27 28.3314C26.2377 28.3314 25.4989 28.5664 24.8778 28.9988Z"
                    fill="#6938EF"
                />
            </g>
            <defs>
                <linearGradient
                    id="paint0_linear_217_178038"
                    x1="33.7499"
                    y1="41.6599"
                    x2="54.1873"
                    y2="30.7607"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1D2939" />
                    <stop offset="1" stop-color="#475467" />
                </linearGradient>
                <linearGradient
                    id="paint1_linear_217_178038"
                    x1="0"
                    y1="0"
                    x2="56.4999"
                    y2="44.5626"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#D9D6FE" />
                    <stop offset="1" stop-color="#BDB4FE" />
                </linearGradient>
                <linearGradient
                    id="paint2_linear_217_178038"
                    x1="37"
                    y1="15.9799"
                    x2="42.6471"
                    y2="14.5684"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1D2939" />
                    <stop offset="1" stop-color="#475467" />
                </linearGradient>
                <linearGradient
                    id="paint3_linear_217_178038"
                    x1="9.99997"
                    y1="15.7304"
                    x2="15.6474"
                    y2="14.3194"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#1D2939" />
                    <stop offset="1" stop-color="#475467" />
                </linearGradient>
                <clipPath id="clip0_217_178038">
                    <path d="M0 0H60V54H0V0Z" fill="white" />
                </clipPath>
                <clipPath id="clip1_217_178038">
                    <rect width="60" height="54" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

import React from "react"
import { CustomTableBodyProps, MouseNChangeEvent } from "../../@types/app/data"

const TableBody = ({ rows, loading, columns, actions, rowClickHandle }: CustomTableBodyProps) => {
    const onRow = (e: MouseNChangeEvent, row: { id: number }) => {
        !loading && e.target.id && rowClickHandle && rowClickHandle(e, row)
    }

    return (
        <tbody className="">
            {rows &&
                rows?.map((row, rowIndex) => {
                    const rowId = `table-checkbox-${rowIndex}`

                    return (
                        <React.Fragment key={row?.id}>
                            <tr className=" bg-white" id={row?.id?.toString()} onClick={(e) => onRow(e, row)}>
                                {columns?.map((column) => (
                                    <td
                                        id={rowId}
                                        key={column}
                                        className="px-[24px] py-[26px] font-[300] border-b border-gray-200 last:border-b-0"
                                    >
                                        {row[column]}
                                    </td>
                                ))}
                                {actions && (
                                    <td
                                        align="center"
                                        id={row?.id?.toString()}
                                        className="p-[24px] py-[30px] border-b border-gray-200 flex items-center justify-end"
                                    >
                                        <div className="flex justify-center">{actions(row)}</div>
                                    </td>
                                )}
                            </tr>
                        </React.Fragment>
                    )
                })}
        </tbody>
    )
}

export default TableBody

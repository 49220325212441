export const SellerInfoIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path
                d="M13.8545 17.3052C10.3695 17.3052 7.27031 18.9918 5.29721 21.609C4.87254 22.1723 4.66021 22.454 4.66716 22.8346C4.67252 23.1287 4.85678 23.4997 5.08764 23.6813C5.38647 23.9163 5.80057 23.9163 6.62877 23.9163H21.0802C21.9084 23.9163 22.3225 23.9163 22.6213 23.6813C22.8522 23.4997 23.0365 23.1287 23.0418 22.8346C23.0488 22.454 22.8364 22.1723 22.4118 21.609C20.4387 18.9918 17.3395 17.3052 13.8545 17.3052Z"
                stroke={props.stroke}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.8545 13.9997C16.5867 13.9997 18.8015 11.7798 18.8015 9.04134C18.8015 6.30293 16.5867 4.08301 13.8545 4.08301C11.1223 4.08301 8.90745 6.30293 8.90745 9.04134C8.90745 11.7798 11.1223 13.9997 13.8545 13.9997Z"
                stroke={props.stroke}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

import { useEffect } from "react"
import { useDispatch } from "react-redux"
import { useLocation, useNavigate } from "react-router-dom"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { AlertDotIconSvg } from "../../../assets/svgs/alertDotIcon.svg"
import { FlipArrowIcon } from "../../../assets/svgs/flipBackwardIcon.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { PrimaryText } from "../../elements/primaryText.element"

const TrackpackHubNotificationAlert = (props: INotificationAlertProps) => {
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const location = useLocation()

    useEffect(() => {
        if(location.pathname === ROUTES_CONFIG.trackpackCentral) {
            dispatch(RuntimeActionCreators.closeNotificaitonAlert())
        }

    },[location.pathname])

    const handleActionClick = () => {
        if (props?.id) {
            localStorage.setItem("trackpackHubId", props?.id)
        }
        dispatch(RuntimeActionCreators.closeNotificaitonAlert())
        navigate(ROUTES_CONFIG.trackpackCentral)
    }

    return (
        <div
            className={`py-[4px] pl-[4px] pr-[8px] m-auto min-w-[360px] rounded-[12px] border z-10 absolute left-[50%] top-[38px] translate-x-[-50%] border-[#D0D5DD] bg-white `}
            style={{ boxShadow: "0px 4px 8px 4px #1018281A" }}
        >
            <div className="flex items-center gap-[12px]">
                <div className="flex items-center gap-[4px] rounded-[8px] border border-[#D0D5DD] bg-white py-[2px] px-[8px] pl-[4px]">
                    <AlertDotIconSvg fillColor="#17B26A" color="#ABEFC6" />
                    <PrimaryText weight="light" size="small" className=" pt-[2px] whitespace-nowrap">
                        Want to go back?
                    </PrimaryText>
                </div>
                <PrimaryText weight="light" size="small" className={`text-[#344054] pt-[2px] whitespace-nowrap`}>
                    View <span className="font-medium">{props.trackpackName}</span> in Trackpack Hub
                </PrimaryText>
                <div className="flex items-center gap-[4px] py-[2px] cursor-pointer" onClick={handleActionClick}>
                    <FlipArrowIcon />
                </div>
            </div>
        </div>
    )
}

export default TrackpackHubNotificationAlert

import dayjs from "dayjs"
import { useMemo, useState } from "react"
import { useSelector } from "react-redux"

import { useContainsChanges } from "../../../../hooks/containsChanges.hook"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { PrimaryText } from "../../../elements/primaryText.element"
import { LogIcons } from "./LogIcons.component"
import { ProductListingData } from "./productListingData.component"

import { ReactComponent as ChevronRight } from "../../../../assets/svgs/log-chevron-right.svg"

export const ProductListItem = ({ product, color, isFirst, isLast }: IProductListItem) => {
    const containsChange = useContainsChanges(product)
    const [showProductDetails, setShowProductDetails] = useState<boolean>(false)
    const { selectedProductView } = useSelector(ProductStateSelector)

    // const isSuccessWarning = product?.warnings?.includes("success")

    // let isWarning = false
    // if (!isSuccessWarning) {
    //     isWarning = !!product?.warnings && product?.warnings.length > 0
    // }

    const formattedTime = useMemo(() => {
        let formattedTime = product.timestamp
        if (formattedTime) {
            formattedTime = dayjs(formattedTime).format("MMM-DD-YY")
        }

        return formattedTime
    }, [product.timestamp])

    const noData = useMemo(() => {
        return product?.success?.every((v) => v.value === false)
    }, [product])

    const icons = useMemo(() => {
        return (
            selectedProductView &&
            selectedProductView?.selectedViews?.map((opt, index) => {
                const iconProps = {
                    key: index,
                    type: opt as ProductViewType,
                    color: color,
                    product: product,
                }

                switch (opt) {
                    case "stock":
                        return (
                            product &&
                            product.availability &&
                            product.availability!.length > 0 && <LogIcons {...iconProps} />
                        )

                    default:
                        return (
                            product &&
                            product[iconProps.type] &&
                            product[iconProps.type]!.length > 0 && <LogIcons {...iconProps} />
                        )
                }
            })
        )
    }, [color, product, selectedProductView])
    return (
        <>
            <div
                className={`last-child w-full bg-white h-[44px] border border-[#EEE] border-t-0 ${
                    isFirst ? " border-t-0" : ""
                } ${
                    isLast && showProductDetails ? " " : isLast ? "" : ""
                } border-b border-[#EEE] justify-start items-center inline-flex py-2`}
            >
                <div className={"min-w-[95px] pl-[24px]"}>
                    <PrimaryText size={"xs"} weight={"medium"} className="text-slate-700 uppercase">
                        {formattedTime}
                    </PrimaryText>
                </div>

                <div className="min-w-[40px] flex-col justify-center items-start inline-flex">
                    {(containsChange || noData) && (
                        <ChevronRight
                            onClick={() => setShowProductDetails((prev) => !prev)}
                            className={`cursor-pointer ${showProductDetails ? "rotate-90" : ""}`}
                        />
                    )}
                </div>
                <div className={"w-[10px]"}></div>

                <div className="w-[100px] flex-col justify-center items-start inline-flex">
                    {isLast ? (
                        <>
                            <PrimaryText size={"xs"} weight={"light"} className="text-gray-700">
                                Tracking begins
                            </PrimaryText>
                        </>
                    ) : (
                        icons &&
                        icons?.some((element) => typeof element === "object" && element !== null) && (
                            <div
                                className={`h-[28px] w-auto p-[4px] flex justify-center items-center rounded-[7px] gap-[2px]`}
                                style={{
                                    backgroundColor: `${color.default}`,
                                }}
                            >
                                {icons}
                            </div>
                        )
                    )}

                    {/* {noData && !isSuccessWarning && <NewErrorIcon />} */}
                </div>
                {/* <div className="w-[50px] grow  items-start inline-flex">
                    <div className="mt-[6px]">
                        {isSuccessWarning ? (
                            <div
                                data-tooltip-id={"tracking-error-card-element"}
                                data-tooltip-content={JSON.stringify({
                                    warnings: product.warnings,
                                })}
                            >
                                <ErrorProduct />
                            </div>
                        ) : (
                            isWarning && (
                                <div
                                    data-tooltip-id={"tracking-off-element"}
                                    data-tooltip-content={JSON.stringify({
                                        warnings: product.warnings,
                                    })}
                                >
                                    <WarningProductIcon />
                                </div>
                            )
                        )}
                    </div>
                </div> */}

                <div className="w-[396px] grow  items-start inline-flex"></div>

                <div className="w-[90px] flex-col justify-center items-start inline-flex">
                    <PrimaryText size={"xs"} weight={"light"} className="text-center text-slate-700 self-stretch">
                        {noData ? "-" : product?.bsr_large?.toLocaleString() ?? "-"}
                    </PrimaryText>
                </div>

                <div className="min-w-[85px] flex-col justify-center items-start inline-flex">
                    <PrimaryText size={"xs"} weight={"light"} className=" self-stretch text-center text-slate-700">
                        {noData ? "-" : product?.bsr_small?.toLocaleString() ?? "-"}
                    </PrimaryText>
                </div>

                <div className="min-w-[85px] flex-col justify-center items-start inline-flex">
                    <PrimaryText size={"xs"} weight={"light"} className="self-stretch text-center text-slate-700">
                        {noData ? "-" : product?.ratings_count?.toLocaleString() ?? "-"}
                    </PrimaryText>
                </div>

                <div className="min-w-[85px] flex-col justify-start items-start inline-flex">
                    <PrimaryText size={"xs"} weight={"light"} className="self-stretch text-center text-slate-700">
                        {noData ? "-" : product?.rating?.toLocaleString() ?? "-"}
                    </PrimaryText>
                </div>
            </div>
            {showProductDetails && <ProductListingData product={product} color={color} />}
        </>
    )
}

export class OnboardingUserStateClass implements IOnboardingState {
    name?: string
    email?: string
    selectedJobRoles?: JobRole[]
    customJobRole?: string
    listingsManage?: string
    istrackCompetitor?: string
    listingsTrack?: string
    asinNumber?: string
    asinBelonging?: string
    amazonTld?: string
    searchedCompitator?: ISearchedCompitator
}

import dayjs from "dayjs"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import ReactPlayer from "react-player"
import { useSelector } from "react-redux"
import { ReactComponent as SmallVideoPlayIcon } from "../../../../src/assets/svgs/small-play-video.svg"
import { ModalVideoUnavailable } from "../../../assets/svgs/modalVideoUnavailable.svg"
import { PopupPlayIcon } from "../../../assets/svgs/popupPlayIcon.svg"
import { extractVideoId } from "../../../helpers/util.helper"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ArrowModalActions from "../../common/arrowModalActions.component"
import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { ChartsWrapper } from "./chartsWrapper.component"

export const VideoModal = (data: IDialogOptionsProps) => {
    const [playingIndex, setPlayingIndex] = useState<number | null>(null)
    const [currentVideo, setCurrentVideo] = useState<IProductDataVideo | null>(null)
    const [playing, setPlaying] = useState<boolean>(false)
    const { modal } = useSelector(RunTimeStateSelector)
    const { active, history, setActive, total } = useProductHistory(data.data as IProductData, "videos")
    const { t: tCommon } = useTranslation("tooltip", { keyPrefix: "commonLabels" })
    const [view, setView] = useState<"added" | "removed">("added")
    const videoIndex = modal?.index
    const [hasScroll, setHasScroll] = useState(false)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)
    const [showTopBorder, setShowTopBorder] = useState(false)
    const [showBottomBorder, setShowBottomBorder] = useState(false)

    const pastVideos = history?.past?.value || []
    const presentVideos = history?.present?.value || []

    const reorderVideos = (videos: IProductDataVideo[], index: number | undefined): IProductDataVideo[] => {
        if (!videos || videos.length === 0 || index === undefined || index < 0 || index >= videos.length) {
            return videos
        }
        const reordered = [...videos]
        const [firstVideo] = reordered.splice(index, 1)
        reordered.unshift(firstVideo)
        return reordered
    }

    const getUniqueValues = (array1: IProductDataVideo[], array2: IProductDataVideo[]): IProductDataVideo[] => {
        return array1.filter(
            (item1) => !array2.some((item2) => extractVideoId(item2?.video_url) === extractVideoId(item1?.video_url))
        )
    }

    const reorderAndFilterUnique = (
        pastVideos: IProductDataVideo[],
        presentVideos: IProductDataVideo[],
        index: number | undefined
    ): { reorderedPastVideos: IProductDataVideo[]; reorderedPresentVideos: IProductDataVideo[] } => {
        const reorderedPastVideos = reorderVideos(pastVideos, index)
        const reorderedPresentVideos = reorderVideos(presentVideos, index)

        const uniqueReorderedPastVideos = getUniqueValues(reorderedPastVideos, reorderedPresentVideos)
        const uniqueReorderedPresentVideos = getUniqueValues(reorderedPresentVideos, reorderedPastVideos)

        return { reorderedPastVideos: uniqueReorderedPastVideos, reorderedPresentVideos: uniqueReorderedPresentVideos }
    }

    const showUnavailablePast = data?.data?.videos?.length && !history.past
    const pastVideosPlaceholder = showUnavailablePast
        ? Array(presentVideos.length).fill({ video_url: null, thumbnail_url: null })
        : pastVideos
    const { reorderedPastVideos, reorderedPresentVideos } = reorderAndFilterUnique(
        pastVideosPlaceholder,
        presentVideos,
        videoIndex
    )

    const categorizeVideos = (videos: any) => ({
        videosForProduct: videos.filter((video: any) => video.type === "videos_for_this_product" || video.type === ""),
        relatedVideos: videos.filter((video: any) => video.type === "related_videos"),
    })

    const pastCategories = categorizeVideos(reorderedPastVideos)
    const presentCategories = categorizeVideos(reorderedPresentVideos)
    const videoCategories = view === "added" ? presentCategories : pastCategories

    useEffect(() => {
        if (!currentVideo) {
            if (videoCategories.videosForProduct.length) {
                setCurrentVideo(videoCategories.videosForProduct[0])
            } else if (videoCategories.relatedVideos.length) {
                setCurrentVideo(videoCategories.relatedVideos[0])
            }
        }
    }, [currentVideo, videoCategories, view])

    useEffect(() => {
        if (scrollableDivRef.current) {
            setHasScroll(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight)
        }
    }, [videoCategories])

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current) {
                const scrollTop = scrollableDivRef.current.scrollTop
                const clientHeight = scrollableDivRef.current.clientHeight
                const scrollHeight = scrollableDivRef.current.scrollHeight

                const isAtTop = scrollTop === 0
                const isAtBottom = scrollTop + clientHeight >= scrollHeight

                // Only bottom border at the top
                if (isAtTop) {
                    setShowTopBorder(false)
                    setShowBottomBorder(true)
                }
                // Only top border at the bottom
                else if (isAtBottom) {
                    setShowTopBorder(true)
                    setShowBottomBorder(false)
                }
                // Both borders in the middle
                else {
                    setShowTopBorder(true)
                    setShowBottomBorder(true)
                }
            }
        }

        // Initial check and adding event listeners
        handleScroll()
        window.addEventListener("resize", handleScroll)
        scrollableDivRef.current?.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("resize", handleScroll)
            scrollableDivRef.current?.removeEventListener("scroll", handleScroll)
        }
    }, [])

    // useEffect(() => {
    //     if (!currentVideo) {
    //         if (videoCategories.videosForProduct.length) {
    //             setCurrentVideo(videoCategories.videosForProduct[0])
    //         } else if (videoCategories.relatedVideos.length) {
    //             setCurrentVideo(videoCategories.relatedVideos[0])
    //         }
    //     }
    // }, [currentVideo, videoCategories])

    const handleVideoSelect = (index: number, isForProduct: boolean) => {
        const selectedVideo = isForProduct
            ? videoCategories.videosForProduct[index]
            : videoCategories.relatedVideos[index]
        setCurrentVideo(selectedVideo)
        setPlayingIndex(index)
        setPlaying(false)
    }
    console.log("current video", videoCategories)

    const currentDisplayedVideo = playing ? currentVideo : null

    return (
        <ChartsWrapper className="p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto w-[1108px] shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all">
            <InnerModalHeader color={modal?.color} type="video" />
            <PrimaryText className="text-[#344054] text-[24px] ml-[6px] font-[500] leading-[32px] mb-[4px]">
                Product Videos
            </PrimaryText>
            <div className="ml-[8px] flex items-center gap-[10px]">
                <ArrowModalActions total={total} active={active} setActive={setActive} />
                <div className="flex items-center justify-between w-full">
                    <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                        {tCommon("timestamp", {
                            timestamp: dayjs(history?.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                        })}
                    </PrimaryText>
                </div>
            </div>

            <div className="mt-[40px]">
                <div className="flex gap-[12px]">
                    <div className="flex flex-col w-[316px]">
                        <div
                            className={`w-[303px] border-t
                  ${hasScroll && showTopBorder ? "border-gray-100" : "border-white"}`}
                        />
                        <div
                            ref={scrollableDivRef}
                            className={`min-h-[404px] max-h-[404px] overflow-y-auto pr-[8px] carousel-scroll`}
                        >
                            {videoCategories?.videosForProduct.length > 0 && (
                                <div className="mb-[24px]">
                                    <PrimaryText
                                        size="xs"
                                        weight="medium"
                                        className=" text-gray-700 px-[8px] py-[2px] bg-gray-100 rounded-[4px] mb-[8px]"
                                    >
                                        Videos for this product
                                    </PrimaryText>
                                    {videoCategories.videosForProduct.map((video: any, index: number) => (
                                        <div
                                            key={`product-${index}`}
                                            onClick={() => handleVideoSelect(index, true)}
                                            className="flex gap-[8px] items-start mb-[8px] ml-[8px] cursor-pointer"
                                        >
                                            <div className="relative">
                                                <img
                                                    className="w-[100px] h-[56px] rounded object-cover border-2"
                                                    style={{
                                                        borderColor:
                                                            currentVideo?.video_url === video.video_url
                                                                ? modal?.color?.default
                                                                : "#98A2B3",
                                                    }}
                                                    src={video.thumbnail_url}
                                                    alt="Video Thumbnail"
                                                />
                                                <div className="absolute left-[-2px] bottom-[6px] w-[12px] h-[12px]">
                                                    <SmallVideoPlayIcon className="" />
                                                </div>
                                            </div>
                                            <div>
                                                {video?.title ? (
                                                    <PrimaryText
                                                        size="xs"
                                                        weight="medium"
                                                        className="text-gray-700 text-[10px] leading-[14px] max-w-[154px] text-ellipsis overflow-hidden line-clamp-1"
                                                    >
                                                        {video.title}
                                                        {/* Men's Casual Blazer Lightweight Suit Jackets Regular Fit Cotton Blend
                                                Fit Cotton Blend */}
                                                    </PrimaryText>
                                                ) : (
                                                    <PrimaryText
                                                        size="xs"
                                                        weight="medium"
                                                        className="text-gray-300 text-[10px] leading-[14px] max-w-[192px] italic text-ellipsis overflow-hidden line-clamp-1"
                                                    >
                                                        Video title unavailable
                                                    </PrimaryText>
                                                )}
                                                {video?.publisher ? (
                                                    <PrimaryText
                                                        size="xs"
                                                        weight="medium"
                                                        className="text-gray-500 max-w-[154px] text-[8px] text-ellipsis overflow-hidden line-clamp-1 uppercase tracking-[0.4px] leading-[10px] mt-[2px] ml-[1px]"
                                                    >
                                                        {video.publisher}
                                                        {/* Men's Casual Blazer Lightweight Suit Jackets Regular Fit Cotton Blend
                                                Fit Cotton Blend */}
                                                    </PrimaryText>
                                                ) : (
                                                    <PrimaryText
                                                        size="xs"
                                                        weight="book"
                                                        className="text-gray-300 text-[8px] leading-[10px] max-w-[192px] uppercase italic text-ellipsis overflow-hidden line-clamp-1 ml-[1px] mt-[2px]"
                                                    >
                                                        PUBLISHER NAME UNAVAILABLE
                                                    </PrimaryText>
                                                )}
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}

                            {videoCategories?.relatedVideos.length > 0 && (
                                <div>
                                    <PrimaryText
                                        size="xs"
                                        weight="medium"
                                        className=" text-gray-700 px-[8px] py-[2px] bg-gray-100 rounded-[4px] mb-[8px]"
                                    >
                                        Related videos for this product
                                    </PrimaryText>
                                    {videoCategories.relatedVideos.map((video: any, index: number) => (
                                        <div
                                            key={`related-${index}`}
                                            onClick={() => handleVideoSelect(index, false)}
                                            className="flex gap-[8px] items-start mb-[8px] ml-[8px] cursor-pointer"
                                        >
                                            <div className="relative">
                                                <img
                                                    className="w-[100px] h-[56px] rounded object-cover border-2"
                                                    style={{
                                                        borderColor:
                                                            currentVideo?.video_url === video.video_url
                                                                ? modal?.color?.default
                                                                : "#98A2B3",
                                                    }}
                                                    src={video.thumbnail_url}
                                                    alt="Video Thumbnail"
                                                />
                                                <div className="absolute left-[-2px] bottom-[6px] w-[12px] h-[12px]">
                                                    <SmallVideoPlayIcon className="" />
                                                </div>
                                            </div>
                                            <div className="flex flex-col">
                                                <div>
                                                    {video?.title ? (
                                                        <PrimaryText
                                                            size="xs"
                                                            weight="medium"
                                                            className="text-gray-700 text-[10px] leading-[14px] max-w-[154px] text-ellipsis overflow-hidden line-clamp-1"
                                                        >
                                                            {video.title}
                                                            {/* Men's Casual Blazer Lightweight Suit Jackets Regular Fit Cotton Blend
                                                Fit Cotton Blend */}
                                                        </PrimaryText>
                                                    ) : (
                                                        <PrimaryText
                                                            size="xs"
                                                            weight="medium"
                                                            className="text-gray-300 text-[10px] leading-[14px] max-w-[192px] italic text-ellipsis overflow-hidden line-clamp-1"
                                                        >
                                                            Video title unavailable
                                                        </PrimaryText>
                                                    )}
                                                </div>
                                                <div>
                                                    {video?.publisher ? (
                                                        <PrimaryText
                                                            // size="xs-extra-small"
                                                            weight="book"
                                                            className="text-gray-500 mt-[2px] max-w-[154px] text-[8px] text-ellipsis overflow-hidden line-clamp-1 uppercase tracking-[0.4px] leading-[10px] ml-[1px]"
                                                        >
                                                            {video.publisher}
                                                        </PrimaryText>
                                                    ) : (
                                                        <PrimaryText
                                                            size="xs"
                                                            weight="book"
                                                            className="text-gray-300 mt-[2px] text-[8px] leading-[10px] max-w-[192px] italic uppercase text-ellipsis overflow-hidden line-clamp-2 ml-[1px]"
                                                        >
                                                            Publisher name unavailable
                                                        </PrimaryText>
                                                    )}
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div
                            className={`w-[294px] border-b ml-[8px]
                ${hasScroll && showBottomBorder ? "border-gray-100 " : "border-white"}
                
                `}
                        ></div>
                        <div className="mt-4 flex gap-2">
                            <button
                                onClick={() => {
                                    setView("added")
                                    setCurrentVideo(null)
                                }}
                                className={`px-[12px] py-[4px] rounded-[16px] text-[12px]  ${
                                    view === "added"
                                        ? `bg-[${modal?.color?.active}] font-[500] text-[${modal?.color?.default}]`
                                        : "bg-gray-200 text-gray-800 font-[400]"
                                }`}
                            >
                                Added
                            </button>
                            <button
                                onClick={() => {
                                    setView("removed")
                                    setCurrentVideo(null)
                                }}
                                className={`px-[12px] py-[4px] rounded-[16px] text-[12px]  ${
                                    view === "removed"
                                        ? `bg-[${modal?.color?.active}] font-[500] text-[${modal?.color?.default}]`
                                        : "bg-gray-200 text-gray-800 font-[400]"
                                }`}
                                disabled={!history.past}
                            >
                                Removed
                            </button>
                        </div>
                    </div>
                    <div className="bg-gray-100 p-4 rounded-[16px] min-w-[672px] relative">
                        <div
                            className="relative w-full h-[360px] overflow-hidden border border-gray-200 rounded-[8px]"
                            style={
                                {
                                    // height: `${currentVideo?.title ? "360px" : "408px"}`,
                                }
                            }
                        >
                            {playing && currentDisplayedVideo ? (
                                <ReactPlayer
                                    url={currentDisplayedVideo.video_url}
                                    controls
                                    onEnded={() => setPlaying(false)}
                                    width="100%"
                                    height="100%"
                                    playing={true}
                                    style={{ backgroundColor: "#101828" }}
                                />
                            ) : (
                                <>
                                    {currentVideo?.thumbnail_url ? (
                                        <>
                                            <img
                                                className="w-full h-full object-cover bg-gray-900"
                                                src={currentVideo.thumbnail_url}
                                                alt="thumbnail"
                                            />
                                            <button
                                                onClick={() => setPlaying(true)}
                                                className="absolute left-[18px] bottom-[18px]"
                                            >
                                                <PopupPlayIcon />
                                            </button>
                                        </>
                                    ) : (
                                        <div className="bg-gray-800 w-full h-full flex items-center justify-center">
                                            <ModalVideoUnavailable />
                                        </div>
                                    )}
                                </>
                            )}
                        </div>
                        {currentVideo?.title ? (
                            <PrimaryText
                                size="lg"
                                weight="medium"
                                className="text-gray-700 leading-[22px] ml-[4px]"
                                style={{
                                    marginTop: `${currentVideo?.title ? "16px" : "0"}`,
                                }}
                            >
                                {currentVideo?.title}
                            </PrimaryText>
                        ) : (
                            <PrimaryText
                                size="xs"
                                weight="medium"
                                className="text-gray-300 text-[18px] leading-[22px] max-w-[192px] italic text-ellipsis overflow-hidden line-clamp-2 mt-[16px] ml-[4px]"
                            >
                                Video title unavailable
                            </PrimaryText>
                        )}

                        {currentVideo?.publisher ? (
                            <PrimaryText
                                size="xs"
                                weight="book"
                                className="text-gray-500 text-[10px] max-w-[192px] text-ellipsis capitalize overflow-hidden line-clamp-2 mt-[2px] ml-[5px]"
                            >
                                {currentVideo.publisher}
                            </PrimaryText>
                        ) : (
                            <PrimaryText
                                size="xs"
                                weight="book"
                                className="text-gray-300 text-[10px] max-w-[192px] italic text-ellipsis capitalize overflow-hidden line-clamp-2 mt-[2px] ml-[5px]"
                            >
                                PUBLISHER NAME UNAVAILABLE
                            </PrimaryText>
                        )}
                    </div>
                </div>
            </div>
        </ChartsWrapper>
    )
}

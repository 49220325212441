import { DashboardBoardConfig } from "../../../../config/dashboard.config"
import { PrimaryText } from "../../../elements/primaryText.element"

export const TableProductDataItem = (props: ITableProductDataItem) => {
    const { graphConfig } = DashboardBoardConfig
    return (
        <>
            {graphConfig.productResults.map((product, index) => (
                <div
                    className={
                        (index === 0 || graphConfig.productResults.length) === index
                            ? ""
                            : "flex py-[8px] border-b border-[#EEE] min-w-fit bg-[#F9FAFB] h-[28px] z-50 relative"
                    }
                    key={index}
                >
                    <div className={"flex items-center justify-start pl-[42px] text-start sticky left-0 bg-[#F9FAFB] "}>
                        <PrimaryText size={"xs"} weight={"light"} className={"text-gray-700 w-[190px] bg-[#F9FAFB]"}>
                            {product.label}
                        </PrimaryText>
                    </div>

                    {props?.products.map((innerProduct, index) => {
                        const noData = innerProduct.data?.success?.every((v) => v.value === false)

                        return (
                            <div key={index} className={"min-w-[172px] flex flex-col items-center justify-center"}>
                                <PrimaryText size={"xs"} weight={"light"}>
                                    {noData
                                        ? "-"
                                        : typeof innerProduct.data === "object"
                                        ? product.value === "bsr_large"
                                            ? innerProduct.data.bsr_large?.toLocaleString() ?? "-"
                                            : product.value === "bsr_small"
                                            ? innerProduct.data.bsr_small?.toLocaleString() ?? "-"
                                            : product.value === "ratings_count"
                                            ? innerProduct.data.ratings_count?.toLocaleString() ?? "-"
                                            : product.value === "rating"
                                            ? innerProduct.data.rating?.toLocaleString() ?? "-"
                                            : ""
                                        : "-"}
                                </PrimaryText>
                            </div>
                        )
                    })}
                </div>
            ))}
        </>
    )
}

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"

import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ExpandIcon } from "../../../assets/svgs/expandIcon.svg"
import { ImageUnavailable } from "../../../assets/svgs/imageUnavailable.svg"
import { MainImageTooltip } from "../../../assets/svgs/tooltips/popups/main-image.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"

export const MainImage = (props: ITooltipData) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("tooltip", {
        keyPrefix: "mainImage",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )

    const { active, history, setActive, total } = useProductHistory(props.data, "main_image")
    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openModal({
                color: props?.color,
                open: true,
                type: "main_image",
                data: props?.data,
            })
        )
    }

    return (
        <>
            <div
                className={`min-w-[224px] p-[12px] bg-white rounded-2xl`}
                style={{ border: `1px solid ${props.color?.default}` }}
            >
                <InnerTooltipHeader
                    title={t("Main Image")}
                    linkText={tCommon("Go to ASIN")}
                    timestamp={tCommon("timestamp", {
                        timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                    active={active}
                    total={total}
                    setActive={setActive}
                    color={props.color?.active}
                    textColor={props.color?.default}
                    id={props.id}
                    icon={<MainImageTooltip stroke={props.color?.default} />}
                />

                <div className="flex items-center justify-between w-full gap-[8px] mt-[32px]">
                    {history.past && (
                        <>
                            <div
                                className={`w-[200px] p-2 bg-[${props.color?.bgHundred}] rounded-lg flex-col inline-flex`}
                            >
                                <div className="flex items-center justify-between w-full mb-[8px] ">
                                    <PrimaryText
                                        size={"xs-small"}
                                        weight={"medium"}
                                        className="text-[12px] leading-[10px] tracking-[0.96px] ml-[2px] mt-[2px]"
                                        color={`text-[${props.color?.default}]`}
                                    >
                                        {tCommon("was")}
                                    </PrimaryText>
                                    <ExpandIcon
                                        className="cursor-pointer mt-[2px] mr-[2px]"
                                        onClick={handleOpen}
                                        color={props?.color?.default}
                                    />
                                </div>

                                <div className="w-[184px] h-[184px]">
                                    {history.past.value === " " ? (
                                        <div
                                            style={{ border: `1px solid ${props.color?.default}` }}
                                            className="object-contain min-w-[184px] max-w-[184px] h-[184px] rounded-[5px] select-none flex items-center justify-center bg-gray-900"
                                        >
                                            <ImageUnavailable />
                                        </div>
                                    ) : (
                                        <img
                                            className="w-full h-full rounded-[5px] object-contain cursor-pointer bg-white"
                                            src={history.past.value}
                                            onClick={handleOpen}
                                            alt="mainIMage"
                                            style={{ border: `1px solid ${props.color?.default}` }}
                                        />
                                    )}
                                </div>
                            </div>
                        </>
                    )}

                    <div
                        className={`w-[200px] ${!history.past ? "" : ""} p-2 bg-[${props.color
                            ?.bgHundred}] rounded-lg flex-col inline-flex`}
                    >
                        <div className="flex items-center justify-between w-full mb-[8px]">
                            <PrimaryText
                                size={"xs-small"}
                                weight={"medium"}
                                className=" text-[12px] leading-[10px] tracking-[0.96px] ml-[2px] mt-[2px]"
                                color={`text-[${props.color?.default}]`}
                            >
                                {tCommon("now")}
                            </PrimaryText>
                            <ExpandIcon
                                className="cursor-pointer mr-[2px] mt-[2px]"
                                onClick={handleOpen}
                                color={props?.color?.default}
                            />
                        </div>
                        <div className="w-[184px] h-[184px]">
                            {history.present?.value === " " ? (
                                <div
                                    className={`object-contain min-w-[184px] max-w-[184px] h-[184px] rounded-[5px] select-none flex items-center justify-center bg-gray-900`}
                                    style={{ border: `1px solid ${props.color?.default}` }}
                                >
                                    <ImageUnavailable />
                                </div>
                            ) : (
                                <img
                                    className={`w-full h-full rounded object-contain cursor-pointer bg-white`}
                                    src={history.present?.value}
                                    onClick={handleOpen}
                                    alt="mainIMage"
                                    style={{ border: `1px solid ${props.color?.default}` }}
                                />
                            )}
                        </div>
                    </div>
                </div>
                {/* {isSuccessWarning && (
                    <div className="w-full  pt-[8px]">
                        <ErrorTooltipAlert />
                    </div>
                )}

                {isWarning && (
                    <div className="w-full  pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
        </>
    )
}

import { CustomTableProps } from "../../@types/app/data"
import TableBody from "../elements/tableBody.element"
import TableHeader from "../elements/tableHeader.element"

const CustomTable = (props: CustomTableProps) => {
    return (
        <table
            style={{ borderCollapse: "initial" }}
            className="border-spacing-0 h-full text-sm  text-gray-500 w-full m-0 border border-gray-200 rounded-[10px] border-b-0 overflow-hidden"
        >
            <TableHeader {...props} />
            <TableBody {...props} />
        </table>
    )
}

export default CustomTable

import { useSelector } from "react-redux"
import { NewAlertIcon } from "../../../assets/svgs/newAlertIcon.svg"
import { ProductStateSelector } from "../../../selectors/product.selector"
import { PrimaryText } from "../../elements/primaryText.element"

const WarningTooltipAlert = (prop: IPropsIcon) => {
    const { selectedProductResult } = useSelector(ProductStateSelector)
    const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    let warningString = ""
    switch (selectedValue) {
        case "bsr_large":
            warningString = "BSR L Cat."
            break
        case "bsr_small":
            warningString = "BSR S Cat."
            break
        case "rating":
            warningString = "Review Score"
            break
        case "ratings_count":
            warningString = "Ratings Count"
            break
        case "actual_asin":
            warningString = "Different ASIN"
            break
    }
    return (
        <>
            <div
                className={`bg-[#FEF0C7]  flex items-center gap-[11px] justify-between  ${
                    prop.className ?? "px-[5px] py-[4px]"
                } rounded-[6px]`}
            >
                <NewAlertIcon />
                <PrimaryText className="text-[#B54708] font-[300] text-[10px] leading-[14px] flex items-center justify-center whitespace-nowrap">
                    <PrimaryText className="font-[500] text-[10px] leading-[14px] pr-[1px] whitespace-nowrap">
                        {warningString}{" "}
                    </PrimaryText>{" "}
                    unavailable
                </PrimaryText>
                <NewAlertIcon />
            </div>
        </>
    )
}

export default WarningTooltipAlert

export const GraphTooltipIcon = ({ color }: IArrowProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
            <g clipPath="url(#clip0_2560_192491)">
                <path
                    d="M7.02744 -1.48328C7.73822 -1.4832 8.22201 -0.762442 7.95285 -0.104596L4.92567 7.29397C4.5867 8.12244 3.41346 8.12238 3.07458 7.29387L0.0480979 -0.105391C-0.221009 -0.763314 0.262949 -1.48406 0.973779 -1.48397L7.02744 -1.48328Z"
                    fill={color}
                />
            </g>
            <defs>
                <clipPath id="clip0_2560_192491">
                    <rect width="8" height="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    )
}

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"

export class AccountService {
    static async getAllUsers() {
        const result = await HttpHelper.sendRequest(API_ROUTES.GET_ALL_USERS, {}, "GET")

        return HttpHelper.validateResponse<IGetAccess>(result)
    }
}

import { useDispatch, useSelector } from "react-redux"
import { AccountActionsCreater } from "../../../../actions/account.action"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { ArchiveIcon } from "../../../../assets/svgs/archiveIcon.svg"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import StepIndicator from "../../../common/stepIndicator.component"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"

const ArchiveStepTwo: React.FC = () => {
    const dispatch = useDispatch()
    const { selectedTrackpacksList, selectedAsinsLength } = useSelector(AccountStateSelector)
    const { archiveState } = useSelector(RunTimeStateSelector)
    const trackpackName = localStorage.getItem("archivedTrackpackName")

    const totalSteps = ["one", "two"]
    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closeArchiveModal())
    }
    const handleStepOne = () => {
        dispatch(
            RuntimeActionCreators.openArchiveModal({
                open: true,
                stepNumber: "one",
                isArchiveShow: selectedAsinsLength && selectedAsinsLength?.data > 0 ? true : false,
            })
        )
    }
    const handleNextClick = () => {
        dispatch(AccountActionsCreater.setSelectedAsinsLength(0))
        localStorage.removeItem("archivedTrackpackName")
        handleCloseDialog()
    }

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#FECDCA] rounded-[12px] p-[10px]">
                    <ArchiveIcon />
                </div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText weight="medium" className="text-gray-700 text-[24px] mt-[24px]">
                Ok, no problem!
            </PrimaryText>
            {!archiveState?.isArchiveShow ? (
                <>
                    <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] mb-[14px]">
                        Your Trackpack called
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            {trackpackName}
                        </PrimaryText>
                        has been Archived.
                        <br />
                        All data on this ASINs within this Trackpack will be kept for
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            90 days.
                        </PrimaryText>
                    </PrimaryText>
                    <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline">
                        If you want to resume tracking for this Trackpack, you can restore it via the
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            Trackpack Hub Archived
                        </PrimaryText>
                        area within this
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            90 day
                        </PrimaryText>
                        period.
                    </PrimaryText>
                </>
            ) : (
                <>
                    <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] mb-[14px]">
                        Your
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            {archiveState?.isArchiveShow && selectedAsinsLength?.data}{" "}
                            {selectedAsinsLength?.data === 1 ? "ASIN" : "ASINs"}
                        </PrimaryText>
                        in
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            {trackpackName}
                        </PrimaryText>
                        has been Archived. All data on {selectedAsinsLength?.data === 1 ? "this ASIN" : "these ASINs"}{" "}
                        will be kept for
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            90 days.
                        </PrimaryText>
                    </PrimaryText>
                    <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] inline">
                        If you want to resume tracking on{" "}
                        {selectedAsinsLength?.data === 1 ? "this ASIN" : "these ASINs"}, you can restore them via the
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            Trackpack Hub Archived ASINs
                        </PrimaryText>
                        area within this
                        <PrimaryText size="sm-medium" weight="medium" className="inline px-[3px] text-gray-700">
                            90 day period.
                        </PrimaryText>
                    </PrimaryText>
                </>
            )}

            <div className=" mt-[48px]">
                <div className="flex justify-center items-center">
                    <StepIndicator currentStep={2} totalSteps={totalSteps} strokeColor="#667085" />
                </div>
                <div className="flex gap-2 items-center justify-between mt-[48px]">
                    <div className="flex items-center ">
                        <BackChevronIcon />
                        <button
                            onClick={handleStepOne}
                            className={
                                "bg-transparent !p-0 text-[#667085] text-[18px] font-medium !hover:bg-transparent"
                            }
                        >
                            Back
                        </button>
                    </div>
                    <div className="flex justify-center items-center">
                        <ButtonElement
                            viewType="hover-state"
                            size="large"
                            onClick={handleNextClick}
                            className={"w-[99px] bg-modal-btn-gradient  rounded-[12px] h-[44px] text-white text-[14px]"}
                        >
                            Done
                        </ButtonElement>
                    </div>
                </div>
            </div>
        </>
    )
}

export default ArchiveStepTwo

import { CustomTableHeadProps } from "../../@types/app/data";

const TableHeader = ({ headItems, actions }: CustomTableHeadProps) => {
    return (
        <thead>
            <tr className="text-xs text-gray-700 bg-[#F2F4F7]">
                {headItems.map((item: { name: string; width: string }, index: number) => {
                    return (
                        <td className="font-[500] px-6 py-3" key={index} style={{ width: item.width }}>
                            {item.name}
                        </td>
                    )
                })}
                {actions && (
                    <td className="text-center font-[500] flex items-center justify-center px-[24px] py-[13px] w-[172px] ml-auto">
                        Admin. Control
                    </td>
                )}
            </tr>
        </thead>
    )
}
export default TableHeader

import i18n from "i18next"
import { initReactI18next } from "react-i18next"

import LanguageDetector from "i18next-browser-languagedetector"
import Backend from "i18next-http-backend"
import { i18nextPlugin } from "translation-check"

i18n.use(i18nextPlugin)
    .use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        fallbackLng: "en-US",
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
    })

export default i18n

import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"

export class OnBoardingServiceService {
    static async postOnBoardingAccount(payload: IOnBoardUser): Promise<IOnBoardedAccountRes> {
        const result = { success: true }

        return result
    }
    static async verifyAlphaEmail(payload: { email: string }): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.VERIFY_ALPHA_EMAIL,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }

    static async alphaUserRegister(payload: AlphaUser): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.ALPHA_USER_REGISTER,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
}

export const CarouselIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path
                d="M3.5 14L13.6243 19.0922C13.7621 19.1615 13.831 19.1961 13.9032 19.2098C13.9672 19.2219 14.0328 19.2219 14.0968 19.2098C14.169 19.1961 14.2379 19.1615 14.3757 19.0922L24.5 14M3.5 19.2812L13.6243 24.3734C13.7621 24.4427 13.831 24.4773 13.9032 24.4909C13.9672 24.503 14.0328 24.503 14.0968 24.4909C14.169 24.4773 14.2379 24.4427 14.3757 24.3734L24.5 19.2812M3.5 8.71883L13.6243 3.62661C13.7621 3.55733 13.831 3.52269 13.9032 3.50906C13.9672 3.49698 14.0328 3.49698 14.0968 3.50906C14.169 3.52269 14.2379 3.55733 14.3757 3.62661L24.5 8.71883L14.3757 13.8111C14.2379 13.8803 14.169 13.915 14.0968 13.9286C14.0328 13.9407 13.9672 13.9407 13.9032 13.9286C13.831 13.915 13.7621 13.8803 13.6243 13.8111L3.5 8.71883Z"
                stroke={props.stroke}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

import { CurrencyDollarIcon } from "../../../../assets/svgs/currencyDollar.svg"
import { LogAsinChangeIcon } from "../../../../assets/svgs/logAsinChange.svg"
import { LogBulletsIcon } from "../../../../assets/svgs/logBulletsIcon.svg"
import { LogCarusolIcon } from "../../../../assets/svgs/logCarusolIcon.svg"
import { LogDescriptionIcon } from "../../../../assets/svgs/logDescriptionIcon.svg"
import { LogSellerInfoIcon } from "../../../../assets/svgs/logSellerIcon.svg"
import { LogTitleIcon } from "../../../../assets/svgs/logTitleIcon.svg"
import { LogVideoIcon } from "../../../../assets/svgs/LogVideoIcon.svg"
import { LogMainImageIcon } from "../../../../assets/svgs/mainImageIcon.svg"
import { TooltipStockIcon } from "../../../../assets/svgs/tooltipStockIcon.svg"

import "../../../../styles/tooltipIcons.component.css"
import { PrimaryText } from "../../../elements/primaryText.element"

export const LogIcons = (props: ILogIcons) => {
    const { type, product } = props

    return (
        <>
            <div
                className={`flex  items-center  rounded-[3px] 
                justify-center ${type}
                ${
                    type && product && product[type] && product[type]?.length && product[type]!.length > 2
                        ? " justify-between"
                        : "justify-center"
                }
                ${
                    type &&
                    product &&
                    product["availability"] &&
                    product["availability"]?.length &&
                    product["availability"]!.length > 2
                        ? " justify-between "
                        : "justify-center"
                }
                `}
            >
                <div className="flex">
                    <LogIconWrapper color={props.color} type={type} product={product}>
                        {(() => {
                            switch (type) {
                                case "price":
                                    return <CurrencyDollarIcon color={props.color?.active} />
                                case "main_image":
                                    return <LogMainImageIcon color={props.color?.active} />
                                case "carousel_images":
                                    return <LogCarusolIcon color={props.color?.active} />
                                case "title":
                                    return <LogTitleIcon color={props.color?.active} />
                                case "bullets":
                                    return <LogBulletsIcon color={props.color?.active} />
                                case "description":
                                    return <LogDescriptionIcon color={props.color?.active} />
                                case "videos":
                                    return <LogVideoIcon color={props.color?.active} />
                                case "seller_info":
                                    return <LogSellerInfoIcon color={props.color?.active} />
                                case "stock":
                                    return <TooltipStockIcon color={props.color?.active} />
                                case "actual_asin":
                                    return <LogAsinChangeIcon color={props.color?.active} />

                                default:
                                    return null
                            }
                        })()}
                    </LogIconWrapper>
                </div>
            </div>
        </>
    )
}

const LogIconWrapper = (props: {
    children: any
    type: ProductViewType
    color: TAsinProductColor
    product?: IProductData
}) => {
    const { type, product } = props

    return (
        <>
            <div className={`flex items-center gap-[6px] p-[2px] rounded-[4px]`}>
                {props.children}

                {type && product && product[type] && product[type]?.length && product[type]!.length > 2 && (
                    <div
                        className={` pt-[2.5px] pb-[2px] rounded-[3px] w-[16px] h-[14px] flex items-center justify-center `}
                        style={{ backgroundColor: props.color.active }}
                    >
                        <PrimaryText
                            weight={"light"}
                            className={`text-[7.54px] leading-normal pt-[0.9px] text-[${props.color.default}]  `}
                        >
                            {product && product[type]!.length - 1}
                        </PrimaryText>
                    </div>
                )}
            </div>
        </>
    )
}

import { useSelector } from "react-redux"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import BulletModal from "./bulletModal.component"
import { CarousalModal } from "./carousalModal.component"
import DescriptionModal from "./descriptionModal.component"
import MainImgModal from "./mainImgModal.component"
import { MainVideoModal } from "./mainVideoModal.component"
import { VideoModal } from "./videoModal.component"

const ChartModal = () => {
    const { modal } = useSelector(RunTimeStateSelector)
    const getModalBody = () => {
        switch (modal?.type) {
            case "carousal":
                return <CarousalModal data={modal?.data} />
            case "video":
                return <VideoModal data={modal?.data} />
            case "main_image":
                return <MainImgModal data={modal?.data} />
            case "bullets":
                return <BulletModal data={modal?.data} />
            case "description":
                return <DescriptionModal data={modal?.data} />
            case "main_video":
                return <MainVideoModal data={modal?.data} />
            // case "keywords":
            //     return <MainVideoModal data={modal?.data} />
            default:
                return <></>
        }
    }
    return <>{modal?.open ? <>{getModalBody()}</> : null}</>
}

export default ChartModal

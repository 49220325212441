import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"

export class AuthService {
    static async getToken(payload: {
        username: string
        password: string
    }): Promise<{ message: string } | IObtainToken> {
        const result = await HttpHelper.sendRequest(API_ROUTES.GET_TOKEN, payload, "POST")
        return HttpHelper.validateResponse<IObtainToken>(result)
    }
    static async getGuessAccount(payload: { guest_id: string }): Promise<IGuestAccount> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.GET_USER_ACCOUNT_DETAIL,
            {
                data: payload,
            },
            "GET"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }

    static async postGuestAccount(payload: IGuestUser): Promise<IGuestAccount> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.GUEST_USER,
            {
                data: payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async updateAsinUser(payload: IAsinUser): Promise<IGuestAccount> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.UPDATE_ASIN_USER,
            {
                data: payload,
            },
            "PUT"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async updateGuestAccount(payload: IGuestUser): Promise<IGuestAccount> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.UPDATE_GUEST_USER_ACCOUNT,
            {
                data: payload,
            },
            "PATCH"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async getAccessLevel() {
        const result = await HttpHelper.sendRequest(API_ROUTES.GET_ACCESS_LEVEL, {}, "GET")

        return HttpHelper.validateResponse<IGetAccess>(result)
    }

    static async refreshTokensApi(refresh_token: string | undefined) {
        const result = await HttpHelper.sendRequest(API_ROUTES.GET_REFRESH_TOKEN, { refresh: refresh_token }, "POST")
        const response: { access: string; refresh: string } = await result.json()
        return response

        // return HttpHelper.validateResponse<{success: string , refresh : string}>(result)
    }

    static async userSignupPost(payload: ISignupInputProps): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.SIGNUP_USER,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async resetPasswordPost(payload: { password: string }): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.RECOVER_PASSWORD,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async verifyEmailPost(payload: ISignupInputProps): Promise<IGuestAccount> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.VERIFY_EMAIL,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async verifyPasswordPost(payload: ISignupInputProps): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.VERIFY_PASSWORD_EMAIL,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async resendVerifyEmailPost(payload: ISignupInputProps): Promise<IGuestAccount> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.RESEND_VERIFY_EMAIL,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
}

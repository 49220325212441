import { configureStore } from "@reduxjs/toolkit"

import { persistReducer, persistStore } from "redux-persist"
import storage from "redux-persist/lib/storage"
import createSagaMiddleware from "redux-saga"
import tokenMiddleware from "./middleware/tokenMiddleware"
import { rootReducer } from "./reducers/root.reducer"
import { rootSagas } from "./sagas/root.saga"
const sagaMiddleware = createSagaMiddleware()
// Configure persist options
const persistConfig = {
    key: "root",
    storage,
    whitelist: ["onBoarding", "auth", "userActionData"],
}
const persistedReducer = persistReducer(persistConfig, rootReducer)

const store = configureStore({
    reducer: persistedReducer,
    middleware: [tokenMiddleware, sagaMiddleware],
})

sagaMiddleware.run(rootSagas)
export const persistor = persistStore(store)
export { store }


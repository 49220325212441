import { NextButton, PageButton, Pagination, PrevButton } from "react-headless-pagination"
import { PaginationBackwardArrow } from "../../assets/svgs/paginationArrowBackward.svg"
import { PaginationForwardArrow } from "../../assets/svgs/paginationArrowForward.svg"
import "../../styles/pagination.element.css"
import { PrimaryText } from "./primaryText.element"

const PaginationElement = ({ currentPage, totalPages, onPageChange }: any) => {
    return (
        <Pagination
            className="flex items-center w-full h-10 px-[24px] text-sm select-none mt-[22px] mb-[48px]"
            currentPage={currentPage}
            edgePageCount={1}
            middlePagesSiblingCount={1}
            setCurrentPage={onPageChange}
            totalPages={totalPages}
            truncableClassName="w-10 px-0.5 text-center"
            truncableText="..."
        >
            <PrevButton
                className="flex items-center gap-[8px] px-[14px] py-[8px] h-[36px] rounded-[8px] border border-gray-300 cursor-pointer"
                style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
            >
                <PaginationBackwardArrow />
                <PrimaryText size="small" weight="medium" className="text-gray-700 pt-[2px]">
                    Previous
                </PrimaryText>
            </PrevButton>
            <div className="page-button-container flex items-center justify-center flex-grow">
                <PageButton
                    activeClassName="bg-gray-200 text-gray-700 text-[14px] rounded-[8px] font-[400]"
                    className="flex items-center justify-center h-10 w-10  cursor-pointer"
                    inactiveClassName="text-gray-700 text-[14px] font-[400]"
                />
            </div>
            <NextButton
                style={{ boxShadow: "0px 1px 2px 0px #1018280D" }}
                className="flex gap-[8px] px-[14px] py-[8px] border items-center h-[36px] rounded-[8px] border-gray-300 cursor-pointer"
            >
                <PrimaryText size="small" weight="medium" className="text-gray-700 pt-[2px]">
                    Next
                </PrimaryText>
                <PaginationForwardArrow />
            </NextButton>
        </Pagination>
    )
}

export default PaginationElement

export const NewEditIcon = () => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M5.5 2.00011H3.4C2.55992 2.00011 2.13988 2.00011 1.81901 2.1636C1.53677 2.30741 1.3073 2.53689 1.16349 2.81913C1 3.14 1 3.56004 1 4.40011V8.60011C1 9.44019 1 9.86023 1.16349 10.1811C1.3073 10.4633 1.53677 10.6928 1.81901 10.8366C2.13988 11.0001 2.55992 11.0001 3.4 11.0001H7.6C8.44008 11.0001 8.86012 11.0001 9.18099 10.8366C9.46323 10.6928 9.6927 10.4633 9.83651 10.1811C10 9.86023 10 9.44019 10 8.60011V6.50011M3.99999 8.00011H4.83726C5.08185 8.00011 5.20414 8.00011 5.31923 7.97248C5.42127 7.94799 5.51881 7.90758 5.60828 7.85275C5.7092 7.79091 5.79568 7.70444 5.96863 7.53148L10.75 2.75011C11.1642 2.3359 11.1642 1.66433 10.75 1.25011C10.3358 0.8359 9.66421 0.8359 9.25 1.25011L4.46862 6.03148C4.29567 6.20444 4.20919 6.29091 4.14735 6.39183C4.09252 6.4813 4.05211 6.57885 4.02762 6.68088C3.99999 6.79597 3.99999 6.91827 3.99999 7.16286V8.00011Z"
                stroke="#667085"
                stroke-width="0.6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export const ThumbIcon = () => {
    return (
        <>
            <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect width="48" height="48" rx="12" fill="#A5F0FC" />
                <path
                    d="M18.1654 35.6666V22.8333M12.332 25.1666V33.3333C12.332 34.6219 13.3767 35.6666 14.6654 35.6666H30.3293C32.0568 35.6666 33.5259 34.4062 33.7886 32.6988L35.045 24.5321C35.3712 22.412 33.7308 20.4999 31.5857 20.4999H27.4987C26.8544 20.4999 26.332 19.9776 26.332 19.3333V15.2101C26.332 13.6212 25.044 12.3333 23.4552 12.3333C23.0763 12.3333 22.7328 12.5564 22.5789 12.9027L18.4733 22.1404C18.286 22.5617 17.8682 22.8333 17.4072 22.8333H14.6654C13.3767 22.8333 12.332 23.8779 12.332 25.1666Z"
                    stroke="#0E7090"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}

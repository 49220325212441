export const TableTooltipElement = (props: ITooltipElementProps) => {
    const inlineStyleVar: any = {
        "--color": props.backgroundColor,
        "--tipOpacity": props.hideTip ? 0 : 1,
        "--boxShadow": props.showBoxShadow === true ? "0 10px 10px rgba(0, 0, 0, 0.1)" : "none",
    }

    return (
        <div className={`tooltip ${props.show && "show"} ${props.customClass} `} style={inlineStyleVar}>
            {props.children}
        </div>
    )
}

import { useEffect, useState } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { RightChevronIcon } from "../../../assets/svgs/RightChevronIcon.svg"
import { SettingNewIcon } from "../../../assets/svgs/settingSideIcon.svg"
import { ROUTES_CONFIG } from "../../../config/routes.config"
import { PrimaryText } from "../../elements/primaryText.element"

export const UserSettings = () => {
    const navigate = useNavigate()

    const [activeTab, setActiveTab] = useState("Alerts")

    const handleTabClick = (tab: string) => {
        setActiveTab(tab)

        if (tab === "Alerts") {
            navigate(ROUTES_CONFIG.userSettingAlerts)
        }
    }

    useEffect(() => {
        if (activeTab === "Alerts") {
            navigate(ROUTES_CONFIG.userSettingAlerts)
        }
    }, [activeTab, navigate])

    const tabs = ["My Details", "Company", "Security", "Subscription", "Billing", "Alerts"]

    return (
        <>
            <div
                style={{
                    height: "calc(100vh - 162px)",
                    border: "1px solid",
                    borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                }}
                className="p-[32px] rounded-[16px] bg-gray-50"
            >
                <div className="flex items-center gap-[8px]">
                    <SettingNewIcon color={"#0E7090"} />
                    <RightChevronIcon />
                    <PrimaryText weight="medium" size="md" className="text-[#0E7090]">
                        Setting
                    </PrimaryText>
                </div>
                <div className="flex gap-4 py-[28px] border-b-[1px] border-gray-200">
                    {tabs.map((tab) => (
                        <button
                            key={tab}
                            className={`pb-2 text-[14px] font-[500] px-[12px] py-[8px]  ${
                                activeTab === tab
                                    ? "rounded-[8px]  text-[#0E7090] bg-gradient-to-r from-[#CFF9FE] to-[#A5F0FC]"
                                    : "text-gray-700 "
                            } ${tab !== "Alerts" ? "cursor-not-allowed text-gray-400" : ""}`}
                            onClick={() => handleTabClick(tab)}
                            disabled={tab !== "Alerts"}
                        >
                            {tab}
                        </button>
                    ))}
                </div>

                <div className="mt-6">
                    {activeTab === "My Details" && <div>My Details Content</div>}
                    {activeTab === "Company" && <div>Company Content</div>}
                    {activeTab === "Security" && <div>Security Content</div>}
                    {activeTab === "Subscription" && <div>Subscription Content</div>}
                    {activeTab === "Billing" && <div>Billing Content</div>}
                    {activeTab === "Alerts" && <Outlet />}
                </div>
            </div>
        </>
    )
}

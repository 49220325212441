import { AsinSelectOptions } from "./asinSelectOptions.component"
import { GroupOptions } from "./groupOptions.component"

export const PerformanceTracker = () => {
    return (
        <>
            <div
                className={"flex gap-[20px] items-start w-full "}
                // style={{ width: "calc(100% - 60px)" }}
            >
                <GroupOptions />
                <AsinSelectOptions />
            </div>
        </>
    )
}

import { useDispatch, useSelector } from "react-redux"
import { AuthActionCreator } from "../../../../../actions/auth.action"
import { SettingsActionsCreater } from "../../../../../actions/settings.action"
import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { AuthStateSelector } from "../../../../../selectors/authState.selector"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const PtQuickTourStepTwelve: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    handleCloseDialog,
    totalSteps,
    currentStep,
}) => {
    const dispatch = useDispatch()
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const handleClose = () => {
        const payload = {
            initial_qt_completed: true,
        }

        dispatch(
            SettingsActionsCreater.updateInitialQuickTour({ ...payload }, () => {
                dispatch(AuthActionCreator.getAccessLevel())
            })
        )

        handleCloseDialog && handleCloseDialog()
    }

    return (
        <>
            {/* Header */}
            <div className="flex justify-between items-center mdpt:mb-[20px] xlpt:mb-[26px]">
                <div className="flex items-center space-x-2">
                    <img
                        src={Icon}
                        className="mdpt:h-[20.8px] mdpt:w-[22.4px] xlpt:h-[26px] xlpt:w-[28px]"
                        alt="icon"
                    />

                    <PrimaryText
                        size="small"
                        weight="medium"
                        className="text-white mdpt:text-[12px] mdpt:leading-[12px] xlpt:text-[14px] xlpt:leading-[14px]"
                    >
                        {headerText}
                    </PrimaryText>
                </div>
                {getAccessLevel && getAccessLevel?.user_settings?.initial_qt_completed === true && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mdpt:p-[10px]  xlpt:p-[12px] bg-[#06AED4] mdpt:rounded-[6.4px] xlpt:rounded-[8px]">
                <PrimaryText
                    weight="book"
                    size="xs"
                    className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] mb-[10px]"
                >
                    Anything I need to make you aware of sits in your Alerts dropdown.
                    <br className="mdpt:block xlpt:hidden" /> This can be anything from changes to ASINs in your
                    Trackpacks, to changes to your account, profile
                    <br className="mdpt:block xlpt:hidden" /> or billing.
                </PrimaryText>
                <PrimaryText
                    weight="book"
                    size="xs"
                    className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] "
                >
                    I’ll also let you know when I’ve got news or new features to share.
                </PrimaryText>
                {/* Footer */}
                <div className="flex justify-between items-center mdpt:mt-[20px] xlpt:mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white mdpt:text-[8px] xlpt:text-[10px]">
                        {currentStep} / {totalSteps}
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="mdpt:mt-[0.5px] xlpt:mt-[0px] text-[500] mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] text-white"
                        className=" xlpt:!w-[42px] xlpt:!h-[26px] mdpt:px-[6.4px] mdpt:py-[3.2px]  xlpt:px-[8px] xlpt:py-[4px] !bg-none text-white mdpt:rounded-[4.8px] xlpt:rounded-[6px] mdpt:mt-[1px] xlpt:mt-[0px] !hover:bg-transparent border border-white"
                        onClick={handleClose}
                    >
                        Finish
                    </ButtonElement>
                </div>
            </div>
        </>
    )
}

export default PtQuickTourStepTwelve

import { PrimaryText } from "../../elements/primaryText.element"

function CommonTooltip(props: CommonTooltipProps) {
    return (
        <div className={`${props.className}`} style={{ width: props.width ? props.width : "" }}>
            <div
                className=" px-[6px] py-[3px] bg-gray-400 rounded-[4px] flex items-center justify-center"
                style={{
                    boxShadow: "0px 4px 8px 3px #1018281A",
                }}
            >
                <PrimaryText
                    size="xs-small"
                    weight="medium"
                    className="text-center text-white tracking-wider leading-[10px] h-full align-middle pt-[1px]"
                >
                    {props.label}
                </PrimaryText>
            </div>
        </div>
    )
}

export default CommonTooltip

export enum SettingsAction {
    GET_ALERT_SETTING = "GET_ALERT_SETTING",
    SET_ALERT_SETTING = "SET_ALERT_SETTING",
    UPDATE_ALERT_SETTING = "UPDATE_ALERT_SETTING",
    UPDATE_INITIAL_QUICK_TOUR = "UPDATE_INITIAL_QUICK_TOUR",
}

export const SettingsActionsCreater = {
    getAlertSetting: () => {
        return {
            type: SettingsAction.GET_ALERT_SETTING,
        }
    },
    setAlertSetting: (data: IAlertSettingState) => ({
        type: SettingsAction.SET_ALERT_SETTING,
        payload: data,
    }),

    updateAlertSetting: (
        payload: Partial<IAlertSettingState>,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: SettingsAction.UPDATE_ALERT_SETTING,
            payload,
            onSuccess,
            onError,
        }
    },
    updateInitialQuickTour: (
        payload: Partial<IAlertSettingState>,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: SettingsAction.UPDATE_INITIAL_QUICK_TOUR,
            payload,
            onSuccess,
            onError,
        }
    },
}

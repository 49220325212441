import { all, call, fork, put, takeLatest } from "typed-redux-saga"
import { NotificationsAction, NotificationsActionsCreator } from "../actions/notifications.action"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { updateReadAtForNotifications } from "../helpers/util.helper"
import { NotificationService } from "../services/notifications.service"
import { store } from "../store"

function* getAllNotifications() {
    yield takeLatest(NotificationsAction.GET_ALL_NOTIFICATIONS, getAllNotificationsHandler)
}

function* getAllNotificationsHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getAllNotificationsHandler", !action.ignorePreloader))
        const { pathParams, queryParams } = action.payload
        const result = yield* call(NotificationService.getAllNotifications, pathParams, queryParams)
        if (result) {
            yield put(NotificationsActionsCreator.setNotificationsData(result as any))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getAllNotificationsHandler"))
        action.onFinally && action.onFinally()
    }
}

function* markNotificationAsRead() {
    yield takeLatest(NotificationsAction.READ_NOTIFICATION, markNotificationAsReadHandler)
}

function* markNotificationAsReadHandler<
    T extends ISagaAction & {
        payload: { id: number | number[]; isUnread?: boolean }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("markNotificationAsReadHandler", !action.ignorePreloader))

        const result = yield* call(NotificationService.readNotifications, action.payload)

        if (result.status === 200) {
            const reduxStates = store.getState()
            // @ts-ignore
            const notificationsData = reduxStates?.notifications!?.notificationsData?.notifications
            const updatedNotificationsData = updateReadAtForNotifications(notificationsData, action?.payload)

            yield put(
                NotificationsActionsCreator.setNotificationsData({ notifications: updatedNotificationsData } as any)
            )

            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("markNotificationAsReadHandler"))

        action.onFinally && action.onFinally()
    }
}

function* markNotificationAsUnRead() {
    yield takeLatest(NotificationsAction.UNREAD_NOTIFICATION, markNotificationAsUnReadHandler)
}

function* markNotificationAsUnReadHandler<
    T extends ISagaAction & {
        payload: { id: number | number[]; isUnread?: boolean }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("markNotificationAsUnReadHandler", !action.ignorePreloader))

        const result = yield* call(NotificationService.unreadNotifications, action.payload)

        if (result.status === 200) {
            const reduxStates = store.getState()
            // @ts-ignore
            const notificationsData = reduxStates?.notifications!?.notificationsData?.notifications
            const updatedNotificationsData = updateReadAtForNotifications(notificationsData, action?.payload)

            yield put(
                NotificationsActionsCreator.setNotificationsData({ notifications: updatedNotificationsData } as any)
            )
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("markNotificationAsUnReadHandler"))

        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([fork(getAllNotifications), fork(markNotificationAsRead), fork(markNotificationAsUnRead)])
}

import { useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"

import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { InputElement } from "../elements/input.element"
import { MarketingFooter } from "./marketingFooter.component"
import { useInputValidations } from "../../hooks/inputValidations.hook"

import { ReactComponent as FrontBubble } from "../../assets/svgs/front-bubble.svg"
import { Transition } from "@headlessui/react"

export const SignupForm = () => {
    const validationConfig = useInputValidations()

    const { t } = useTranslation("marketing", {
        keyPrefix: "signUpForm.conversation",
    })

    const [completed, setCompleted] = useState(false)
    const [isLoading, setIsLoading] = useState<Boolean>(false)

    const { control, handleSubmit } = useForm<IInputsExampleProps>()

    const onSubmit: SubmitHandler<IInputsExampleProps> = (data) => {
        setIsLoading(true)
        console.log(data)
        setIsLoading(false)
    }
    return (
        <>
            <div className="xl:pt-[28px] lg:pt-[28px] md:pt-[20px] sm:pt-[20px] xs:pt-[20px]">
                <div className="flex justify-center 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] md:gap-[16px] sm:gap-[0px] xs:gap-[0px]">
                    <FrontBubble className={"h-[56px] w-[60px] xs:hidden sm:hidden md:block "} />
                    <div
                        className="flex flex-col justify-start items-start overflow-y-auto md:pr-[20px] lg:pr-[20px] w-[504px] md:h-md-calc-onboarding lg:h-lg-calc-onboarding xl:h-xl-calc-onboarding"
                        // style={{ height: "calc(175vh - 150px)" }}
                    >
                        <div>
                            <BubbleListToTop>
                                <BubblesList
                                    interval={1000}
                                    onFinish={() => {
                                        setCompleted(true)
                                    }}
                                    conversationItems={[
                                        {
                                            text: t("1"),
                                        },
                                        {
                                            text: t("2"),
                                        },
                                        {
                                            text: t("3"),
                                        },
                                        {
                                            text: t("4"),
                                        },
                                    ]}
                                />
                            </BubbleListToTop>
                        </div>
                        <Transition
                            show={completed}
                            enter="transition ease-out duration-300 transform"
                            enterFrom="translate-y-full opacity-0"
                            enterTo="translate-y-0 opacity-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="translate-y-0 opacity-100"
                            leaveTo="translate-y-full opacity-0"
                        >
                            <div className="flex-1 xl:min-w-[375px] lg:min-w-[375px] md:min-w-[375px] sm:min-w-[240px] xs:min-w-[240px]">
                                <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                                    <Container>
                                        <InputElement
                                            placeholder="Email address here..."
                                            name="email"
                                            reactHookControl={control}
                                            reactHookValidations={{
                                                required: validationConfig.required,
                                                pattern: validationConfig.email,
                                            }}
                                            className=""
                                        />

                                        {completed && (
                                            <ButtonElement
                                                type="submit"
                                                loading={isLoading || undefined}
                                                size="large"
                                                className={
                                                    "sm:mt-[36px] md:mt-[24px] lg:mt-[24px] xs:mt-[36px] xl:mt-[24px] text-[#0E7090]"
                                                }
                                            >
                                                {t("button")}
                                            </ButtonElement>
                                        )}
                                    </Container>
                                </form>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
            <MarketingFooter currentStep={2} />
        </>
    )
}

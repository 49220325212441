export const CrossNewIcon = (props: IPropsIcon) => {
    return (
        <svg
            onClick={() => props.onClick && props.onClick()}
            className={props.className}
            width={props?.width? props.width : "14"}
            height={props?.height? props.height : "14"}
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path d="M13 1L1 13M1 1L13 13" stroke={props?.color? props.color : "#667085" } stroke-linecap="round" stroke-linejoin="round" />
        </svg>
    )
}

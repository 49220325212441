import { ReactComponent as AUSFlagIcon } from "../../assets/svgs/flags/aus-icon.svg"
import { ReactComponent as BEFlagIcon } from "../../assets/svgs/flags/belgium-icon.svg"
import { ReactComponent as BRlagIcon } from "../../assets/svgs/flags/brazil-icon.svg"
import { ReactComponent as USFlagIcon } from "../../assets/svgs/flags/us-flag.svg"

import { ReactComponent as CA } from "../../assets/svgs/flags/ca.svg"
import { ReactComponent as CN } from "../../assets/svgs/flags/cn.svg"
import { ReactComponent as DE } from "../../assets/svgs/flags/de.svg"
import { ReactComponent as EG } from "../../assets/svgs/flags/eg.svg"
import { ReactComponent as FR } from "../../assets/svgs/flags/fr.svg"
import { ReactComponent as IN } from "../../assets/svgs/flags/in.svg"
import { ReactComponent as IT } from "../../assets/svgs/flags/it.svg"
import { ReactComponent as JP } from "../../assets/svgs/flags/jp.svg"
import { ReactComponent as MX } from "../../assets/svgs/flags/mx.svg"
import { ReactComponent as NL } from "../../assets/svgs/flags/nl.svg"
import { ReactComponent as PL } from "../../assets/svgs/flags/pl.svg"
import { ReactComponent as SA } from "../../assets/svgs/flags/sa.svg"
import { ReactComponent as SG } from "../../assets/svgs/flags/sg.svg"

import { ReactComponent as AE } from "../../assets/svgs/flags/ae.svg"
import { ReactComponent as ES } from "../../assets/svgs/flags/es.svg"
import { ReactComponent as UK } from "../../assets/svgs/flags/gb.svg"
import { ReactComponent as SE } from "../../assets/svgs/flags/se.svg"
import { ReactComponent as TR } from "../../assets/svgs/flags/tr.svg"

export const AMAZON_DOMAINS = [
    {
        icon: <USFlagIcon />,
        value: "com",
        label: "United States",
    },
    {
        icon: <AUSFlagIcon />,
        value: "com.au",
        label: "Australia",
    },
    {
        icon: <BEFlagIcon />,
        value: "com.be",
        label: "Belgium",
    },
    { icon: <BRlagIcon />, value: "com.br", label: "Brazil" },
    { icon: <CA />, value: "ca", label: "Canada" },
    { icon: <CN />, value: "cn", label: "China" },
    { icon: <EG />, value: "eg", label: "Egypt" },
    { icon: <FR />, value: "fr", label: "France" },
    { icon: <DE />, value: "de", label: "Germany" },
    { icon: <IN />, value: "in", label: "India" },
    { icon: <IT />, value: "it", label: "Italy" },
    { icon: <JP />, value: "co.jp", label: "Japan" },
    { icon: <MX />, value: "com.mx", label: "Mexico" },
    { icon: <NL />, value: "nl", label: "Netherlands" },
    { icon: <PL />, value: "pl", label: "Poland" },
    { icon: <SA />, value: "sa", label: "Saudi Arabia" },
    { icon: <SG />, value: "sg", label: "Singapore" },
    { icon: <ES />, value: "es", label: "Spain" },
    { icon: <SE />, value: "se", label: "Sweden" },
    { icon: <TR />, value: "com.tr", label: "Turkey" },
    { icon: <AE />, value: "ae", label: "UAE" },
    { icon: <UK />, value: "co.uk", label: "United Kingdom" },
]

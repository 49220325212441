export const ChevronDownIcon = (props: IArrowProps) => {
    return (
        <svg
            className={props.className}
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M5 7.5L10 12.5L15 7.5"
                stroke={props.color}
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

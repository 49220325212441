const ProductVideoFilterIcon = ({ strokeColor }: { strokeColor?: string }) => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M4.88 14.1055H11.12C12.1281 14.1055 12.6321 14.1055 13.0172 13.912C13.3559 13.7418 13.6312 13.4702 13.8038 13.1362C14 12.7564 14 12.2593 14 11.2651V7.10547H2V11.2651C2 12.2593 2 12.7564 2.19619 13.1362C2.36876 13.4702 2.64413 13.7418 2.98282 13.912C3.36786 14.1055 3.87191 14.1055 4.88 14.1055Z"
                stroke={strokeColor || "#667085"}
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M5.2287 2.68504L5.42729 5.73766M9.27377 1.60117L9.47236 4.65378M9.96721 1.41536L3.9574 3.02569C2.98649 3.28584 2.50104 3.41592 2.18098 3.70478C1.89944 3.95888 1.70551 4.29593 1.62696 4.66761C1.49016 5.31498 1.79485 6.05 1.96009 6.66669L13.5174 3.56991C13.3522 2.95323 13.2486 2.16433 12.8064 1.67209C12.5525 1.38948 12.216 1.19456 11.8452 1.11527C11.4236 1.02513 10.9381 1.15521 9.96721 1.41536Z"
                stroke={strokeColor || "#667085"}
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}
export default ProductVideoFilterIcon

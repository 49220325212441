import { useController } from "react-hook-form"
import { twMerge } from "tailwind-merge"

import { NewInputAlertIcon } from "../../assets/svgs/newInputAlertIcon.svg"
import { PrimaryText } from "./primaryText.element"

export const AuthInputElement = (props: IInputElementProps) => {
    const { field, fieldState } = useController({
        name: props.name,
        control: props.reactHookControl,
        rules: props.reactHookValidations,
        defaultValue: props.defaultValue || "",
    })

    return (
        <div
            className={`
        relative
        ${props.className}
    `}
        >
            <div className="relative">
                <div>
                    {props?.label && (
                        <div className="mb-[6px]">
                            <label>
                                <PrimaryText size="small" weight="medium" className="text-gray-700">
                                    {props.label}
                                </PrimaryText>
                            </label>
                        </div>
                    )}

                    <div className={"flex items-center"}>
                        {props.prefix && <div className={"absolute left-[12px]"}>{props.prefix}</div>}
                        <input
                            {...field}
                            className={twMerge(
                                `
                                w-full px-[14px] py-[10px] rounded-[8px]  font-light text-gray-600 border border-[#A5F0FC] placeholder:text-gray-500  
                                disabled:bg-gray-50 disabled:cursor-not-allowed
                              `,
                                fieldState.error && "border-[#FDA29B] placeholder:text-gray-500 bg-white"
                            )}
                            disabled={props?.disabled}
                            type={props?.type ? props.type : "text"}
                            placeholder={
                                props.showErrorInPlaceholder
                                    ? fieldState.error
                                        ? fieldState.error.message
                                        : props.placeholder
                                    : props.placeholder
                            }
                        />

                        {props.postfix && <div className={"absolute right-[14px] cursor-pointer"}>{props.postfix}</div>}
                    </div>
                </div>
            </div>

            {props.hint && (
                <PrimaryText typography="small-hint" className="mt-[6px]">
                    {props.hint}
                </PrimaryText>
            )}

            {!props.showErrorInPlaceholder && (
                <>
                    {fieldState.error && (
                        <div className="flex mt-[6px]">
                            {field.name !== "newPassword" && field.name !== "confirmPassword" && (
                                <div className="absolute right-[14px] top-[25%] flex items-start">
                                    <NewInputAlertIcon />
                                </div>
                            )}

                            <PrimaryText weight={"light"} typography={"small-error"} className="ml-[14px] leading-[1]">
                                {fieldState.error.message}
                            </PrimaryText>
                        </div>
                    )}
                </>
            )}
        </div>
    )
}

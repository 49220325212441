import React from "react"

import { twMerge } from "tailwind-merge"
import { UtilHelper } from "../../helpers/util.helper"
import { Container } from "./container.element"

export const PrimaryText: React.FunctionComponent<IPrimaryTextProps> = (props) => {
    const getSize = (size: AvailableTextSizes | undefined) => {
        let weight = ""

        switch (size) {
            case "xl":
                weight = "text-xl leading-[1.875rem]"
                break
            case "lg":
                weight = "text-lg"
                break
            case "md":
                weight = "text-base"
                break
            case "small":
                weight = "text-sm"
                break
            case "sm-medium":
                weight = `text-[16px]`
                break
            case "xs":
                weight = "text-xs leading-[1.125rem]"
                break
            case "xs-medium":
                weight = `text-[10px]`
                break
            case "xs-small":
                weight = `text-[8px]`
                break
            case "xs-extra-small":
                weight = `text-[6px]`
                break
            case "onboarding-responsive":
                weight =
                    "lg:text-[1rem] md:text-[1rem] sm:text-[0.875rem] xs:text-[0.875rem] xs:leading-[18px] sm:leading-[18px] md:leading-[20px]"
                break
        }
        return weight
    }

    const getWeight = (weightType: "light" | "medium" | "bold" | "book" | undefined) => {
        let weight = ""

        switch (weightType) {
            case "bold":
                weight = "font-bold"
                break
            case "medium":
                weight = "font-medium"
                break
            case "book":
                weight = "font-normal"
                break
            case "light":
                weight = "font-light"
                break
        }
        return weight
    }

    let typography = ""

    switch (props.typography) {
        case "h1":
            typography = ""
            break
        case "h2":
            typography = ""
            break
        case "h3":
            typography = ""
            break
        case "small-error":
            typography = `text-red-600 ${getWeight("book")} ${getSize("xs")} leading-[14px]`
            break
        case "small-hint":
            typography = `text-gray-500 ${getWeight("light")} text-[0.5rem] leading-3`
            break
        case "p:round":
            typography = `w-fit py-[8px] px-[10px] rounded-xl text-gray-700
             ${getWeight("light")} ${getSize("lg")}`
            break
        case "p:round-onboarding-responsive":
            typography = `w-fit  rounded-xl text-gray-700
                 ${getWeight("light")} ${getSize("onboarding-responsive")}`
            break
        case "3xl":
            typography = `text-gray-700 ${getWeight("medium")} text-[1.875rem]`
            break
        default:
            typography = `${getWeight("light")} ${getSize("lg")}`
    }

    return (
        <Container
            className={twMerge(
                "whitespace-pre-line",
                "outline-none",
                UtilHelper.toString(props.color),
                typography,
                getSize(props.size),
                UtilHelper.toString(props.centered && "text-center"),
                UtilHelper.toString(props.underline && "underline"),
                getWeight(props.weight),
                UtilHelper.toString(props.className),
                UtilHelper.toString(props.style),
                props.uppercase && "uppercase"
            )}
            onClick={props.onClick}
            style={props.style}
        >
            {props.children}
        </Container>
    )
}

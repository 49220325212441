import TrackpackHubTourOutlet from "./thQuickTourOutlet.component"
import { TrackpackHubQuickTourWrapper } from "./thQuickTourWrapper.Modal.component"

const TrackpackHubQuickTourModalComponent = () => {
    return (
        <TrackpackHubQuickTourWrapper>
            <TrackpackHubTourOutlet />
        </TrackpackHubQuickTourWrapper>
    )
}

export default TrackpackHubQuickTourModalComponent

import React from "react"
import { CheckedIcon } from "../../assets/svgs/checkedIcon.svg"
import { PrimaryText } from "../elements/primaryText.element"

interface DropdownOption {
    label: string
    value: string
}

interface DropdownProps {
    options: DropdownOption[]
    selectedItem: string
    handleItemClick: (item: string) => void
    className?: string
}

const FilterDropdown: React.FC<DropdownProps> = ({ options, selectedItem, handleItemClick, className }) => {
    return (
        <div
            className={`${
                className ? "w-[192px] right-[-4px]" : ""
            } absolute cursor-pointer right-0 top-[100%] w-[180px] z-10 bg-white rounded-[10px] border border-gray-200 p-[6px]`}
            style={{
                boxShadow: "0px 4px 6px -2px #10182808, 0px 12px 16px -4px #10182814",
            }}
        >
            <ul className="p-0 m-0">
                {options.map((option) => (
                    <li
                        key={option.value}
                        className={`flex items-center justify-between rounded-[4px] p-[8px] ${
                            selectedItem === option.value ? "bg-[#F2F4F7]" : ""
                        }`}
                        onClick={() => handleItemClick(option.value)}
                    >
                        <PrimaryText
                            weight={selectedItem === option.value ? "medium" : "light"}
                            size="small"
                            className="text-gray-700 cursor-pointer"
                        >
                            {option.label}
                        </PrimaryText>

                        {selectedItem === option.value && <CheckedIcon />}
                    </li>
                ))}
            </ul>
        </div>
    )
}

export default FilterDropdown

import { all, call, fork, put, takeLatest } from "typed-redux-saga"
import { RuntimeActionCreators } from "../actions/runTime.action"
import { SettingsAction, SettingsActionsCreater } from "../actions/settings.action"
import { SettingService } from "../services/settings.service"

function* getUsersAlertSetting() {
    yield takeLatest(SettingsAction.GET_ALERT_SETTING, getUserAlertSettingHandler)
}

function* getUserAlertSettingHandler<T extends ISagaAction>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("getUserAlertSettingHandler", !action.ignorePreloader))
        const result = yield* call(SettingService.getAlertSetting)

        if (result) {
            yield put(SettingsActionsCreater.setAlertSetting(result as IAlertSettingState))
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
    } finally {
        yield put(RuntimeActionCreators.stopLoading("getUserAlertSettingHandler"))
        action.onFinally && action.onFinally()
    }
}

function* updateAlertSetting() {
    yield takeLatest(SettingsAction.UPDATE_ALERT_SETTING, updateAlertSettingHandler)
}

function* updateAlertSettingHandler<
    T extends ISagaAction & {
        payload: { id: string; name: string }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("updateAlertSettingHandler", !action.ignorePreloader))

        const result = yield* call(SettingService.updateAlertSetting, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("updateAlertSettingHandler"))

        action.onFinally && action.onFinally()
    }
}

////////////////////////// Update Intial Quick Tour //////////////////////////

function* updateQuickTourSetting() {
    yield takeLatest(SettingsAction.UPDATE_INITIAL_QUICK_TOUR, updateIntialQuickTourCompletedHandler)
}

function* updateIntialQuickTourCompletedHandler<
    T extends ISagaAction & {
        payload: { initial_qt_completed: boolean }
        onError?: (error: any) => void
        onFinally?: () => void
    },
>(action: T) {
    try {
        yield put(RuntimeActionCreators.startLoading("updateIntialQuickTourCompletedHandler", !action.ignorePreloader))

        const result = yield* call(SettingService.updateIntialQuickTourCompletion, action.payload)

        if (result.status === 200) {
            action.onSuccess && action.onSuccess()
        } else {
            action.onError && action.onError(result)
        }
    } catch (error) {
        action.onError && action.onError(error)
    } finally {
        yield put(RuntimeActionCreators.stopLoading("updateIntialQuickTourCompletedHandler"))

        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([fork(getUsersAlertSetting), fork(updateAlertSetting), fork(updateQuickTourSetting)])
}

export const TrackPackNameIcon = () => {
    return (
        <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M21.9779 16.0215L20.4296 8.27995C20.3431 7.8475 20.2999 7.63127 20.1947 7.45519C20.1018 7.29952 19.9745 7.16712 19.8226 7.06809C19.6508 6.95607 19.4365 6.90433 19.0078 6.80085L2.9165 2.91675M2.9165 2.91675L6.8006 19.008C6.90408 19.4367 6.95582 19.6511 7.06784 19.8229C7.16688 19.9747 7.29927 20.102 7.45494 20.195C7.63103 20.3001 7.84725 20.3434 8.27971 20.4299L16.0212 21.9782M2.9165 2.91675L11.954 11.9543M19.7518 25.3957L25.3955 19.752C25.8672 19.2802 26.1031 19.0443 26.1915 18.7723C26.2693 18.533 26.2693 18.2753 26.1915 18.036C26.1031 17.764 25.8672 17.5281 25.3955 17.0563L24.5171 16.178C24.0453 15.7062 23.8094 15.4703 23.5374 15.3819C23.2982 15.3042 23.0404 15.3042 22.8011 15.3819C22.5291 15.4703 22.2932 15.7062 21.8214 16.178L16.1778 21.8217C15.706 22.2935 15.4701 22.5294 15.3817 22.8014C15.3039 23.0407 15.3039 23.2984 15.3817 23.5377C15.4701 23.8097 15.706 24.0456 16.1778 24.5174L17.0561 25.3957C17.5279 25.8675 17.7638 26.1034 18.0358 26.1918C18.2751 26.2695 18.5328 26.2695 18.7721 26.1918C19.0441 26.1034 19.28 25.8675 19.7518 25.3957ZM16.0212 13.6388C16.0212 14.9547 14.9545 16.0215 13.6386 16.0215C12.3226 16.0215 11.2559 14.9547 11.2559 13.6388C11.2559 12.3229 12.3226 11.2561 13.6386 11.2561C14.9545 11.2561 16.0212 12.3229 16.0212 13.6388Z"
                stroke="#0E7090"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export const competitorAsins = [
    {
        name: "competitorAsin",
        placeholder: "Add ASIN numbers separated by commas here...",
        defaultValue: "",
    },
]
export const IOnBoardUserKeys: (keyof IOnBoardUser)[] = [
    "amazonTld",
    "asinBelonging",
    "asinNumber",
    "customJobRole",
    "email",
    "istrackCompetitor",
    "listingsManage",
    "listingsTrack",
    "name",
    "searchedCompitator",
    "selectedJobRoles",
]
export const JOB_ROLES = [
    {
        type: "jobRole1",
        value: false,
        label: "I work for an Amazon Seller managing their listings",
        translationKey: "jobRole1Label",
    },
    {
        type: "jobRole2",
        value: false,
        label: "I work for an Agency managing client e-commerce listings",
        translationKey: "jobRole2Label",
    },
    {
        type: "jobRole3",
        value: false,
        label: "I work freelance advising e-commerce clients",
        translationKey: "jobRole3Label",
    },
    {
        type: "jobRole4",
        value: false,
        label: "I work for myself selling direct on Amazon",
        translationKey: "jobRole4Label",
    },
    { type: "jobRole5", value: false, label: "Other", translationKey: "jobRole5Label" },
] as {
    type: JobRole
    value: boolean
    label: string
    translationKey: JobRoleTranslationKeys
}[]

export const ASIN_LIST = [
    {
        type: "B08S6XWCN5",
        value: false,
        label: "B07QPT8FGK",
        translationKey: "B08S6XWCN5",
    },
    {
        type: "B073PY7WC9",
        value: false,
        label: "B07QPT8FGK",
        translationKey: "B073PY7WC9",
    },
    {
        type: "B07QPT8FGK",
        value: false,
        label: "B07QPT8FGK",
        translationKey: "B07QPT8FGK",
    },
    {
        type: "B01DWIFRA4",
        value: false,
        label: "B01DWIFRA4",
        translationKey: "B01DWIFRA4",
    },
    { type: "B0B87LCZSX", value: false, label: "B0B87LCZSX", translationKey: "B0B87LCZSX" },
    { type: "B0C274G3BX", value: false, label: "B0C274G3BX", translationKey: "B0C274G3BX" },
    { type: "B0CHRVF787", value: false, label: "B0CHRVF787", translationKey: "B0CHRVF787" },
    { type: "B08S6XWCN5", value: false, label: "B08S6XWCN5", translationKey: "B08S6XWCN5" },
]

import { ButtonElement } from "../elements/button.element"
import { PrimaryText } from "../elements/primaryText.element"

import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../actions/runTime.action"
import { PreviousArrow } from "../../assets/svgs/previousArrow.svg"
import { RightArrow } from "../../assets/svgs/rightArrow.svg"
import { ReactComponent as XCloseIcon } from "../../assets/svgs/x-close.svg"

export const InnerTooltipHeader = (props: IInnerTooltipNavigationProps) => {
    const dispatch = useDispatch()
    return (
        <>
            <div className="flex gap-[8px] w-full">
                <div
                    className={`min-w-[40px] h-[40px] bg-[${props.color}] rounded-lg justify-center items-center flex`}
                >
                    {props.icon}
                </div>
                <div className="w-full">
                    <div className="mt-[8px]">
                        <PrimaryText
                            // weight="medium"
                            className={`text-[${props.textColor}] text-[12px] font-[700] leading-[10px] h-[10px] uppercase`}
                        >
                            {props.title}
                        </PrimaryText>
                    </div>
                    <div className="absolute right-[12px] top-[6px]">
                        <ButtonElement
                            viewType="only-icon"
                            onClick={() => {
                                dispatch(RuntimeActionCreators.closeAllPopup())
                            }}
                        >
                            <XCloseIcon />
                        </ButtonElement>
                    </div>
                    <div className="mt-[6px] flex items-center justify-between">
                        <PrimaryText
                            weight={"light"}
                            className="text-[9px] pt-[1px] flex-1 text-gray-500 leading-[9px] tracking-[0.28px] uppercase"
                        >
                            {props.timestamp}
                        </PrimaryText>

                        {/* {props.total > 1 && ( */}
                        <div className="flex gap-[2px] items-center">
                            <button
                                className={` rounded-[3px] ${props.active <= 1 ? "bg-gray-200" : "bg-gray-400"} `}
                                onClick={() => {
                                    if (props.active <= 1) {
                                        return
                                    }
                                    props.setActive(props.active - 1)
                                }}
                                disabled={props.active <= 1}
                            >
                                <PreviousArrow color={`${props.active <= 1 ? "bg-white" : "bg-white"}`} />
                            </button>

                            <button
                                className={` rounded-[3px]  ${
                                    !(props.active < props.total) ? "bg-gray-200" : "bg-gray-400"
                                }`}
                                onClick={() => {
                                    if (!(props.active < props.total)) {
                                        return
                                    }
                                    props.setActive(props.active + 1)
                                }}
                                disabled={!(props.active < props.total)}
                            >
                                <RightArrow color={`${props.active <= 1 ? "bg-white" : "bg-white"}`} />
                            </button>
                        </div>
                        {/* )} */}
                    </div>
                </div>
            </div>
        </>
    )
}

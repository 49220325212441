import dayjs from "dayjs"
import { Image } from "image-js"
import { useEffect, useRef, useState } from "react"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { ImageUnavailable } from "../../../assets/svgs/imageUnavailable.svg"
import { NewArrowWithStroke } from "../../../assets/svgs/newArrowWithStroke"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ArrowModalActions from "../../common/arrowModalActions.component"
import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { ChartsWrapper } from "./chartsWrapper.component"

const colorList = [
    { name: "CFF9FE", color: [207, 249, 254] },
    { name: "FEE4E2", color: [254, 228, 226] },
    { name: "FEF0C7", color: [254, 240, 199] },
    { name: "DCFAE6", color: [220, 250, 230] },
    { name: "EE46BC", color: [238, 70, 188] },
]

// Component for when isFirst is true
const NowImages = ({ presentImages, carouselIndex, handleImageClick, modalColor }: any) => {
    const [hasScroll, setHasScroll] = useState(false)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)

    useEffect(() => {
        if (scrollableDivRef.current) {
            setHasScroll(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight)
        }
    }, [presentImages])

    return (
        <div className="flex flex-col items-start">
            <PrimaryText
                size="xs"
                weight="medium"
                className="py-[2px] pl-[8px] pr-[30px] ml-[2px] bg-gray-100 w-[68px] mb-[8px] text-[#344054] rounded-[4px]"
            >
                Now
            </PrimaryText>
            <div
                ref={scrollableDivRef}
                className={`max-h-[372px] min-h-[372px] carousel-scroll overflow-y-auto flex flex-col gap-[8px] ${
                    hasScroll ? "pr-[8px]" : "pr-[0px]"
                }`}
            >
                {presentImages.map((image: any, index: any) => {
                    return (
                        <div
                            key={index}
                            onClick={() => handleImageClick(index)}
                            className={`cursor-pointer min-w-[68px] m-[2px] max-w-[68px] min-h-[68px] rounded-[4px] overflow-hidden flex items-center justify-center `}
                            style={{
                                outline: carouselIndex === index ? `2px solid ${modalColor}` : "1px solid #98A2B3",
                            }}
                        >
                            {image === "" ? (
                                <ImageUnavailable width={68} height={68} />
                            ) : (
                                <img
                                    src={image}
                                    alt=""
                                    className="object-cover overflow-visible min-w-[68px] h-[68px] rounded-[4px]"
                                />
                            )}
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

// Component for when isFirst is false
const WasNowImages = ({ paddedPastImages, paddedPresentImages, carouselIndex, handleImageClick, modalColor }: any) => {
    const [hasScroll, setHasScroll] = useState(false)
    const scrollableDivRef = useRef<HTMLDivElement | null>(null)
    const [showTopBorder, setShowTopBorder] = useState(false)
    const [showBottomBorder, setShowBottomBorder] = useState(false)

    useEffect(() => {
        if (scrollableDivRef.current) {
            setHasScroll(scrollableDivRef.current.scrollHeight > scrollableDivRef.current.clientHeight)
        }
    }, [paddedPastImages, paddedPresentImages])

    useEffect(() => {
        const handleScroll = () => {
            if (scrollableDivRef.current) {
                const scrollTop = scrollableDivRef.current.scrollTop
                const clientHeight = scrollableDivRef.current.clientHeight
                const scrollHeight = scrollableDivRef.current.scrollHeight

                const isAtTop = scrollTop === 0
                const isAtBottom = scrollTop + clientHeight >= scrollHeight

                // Only bottom border at the top
                if (isAtTop) {
                    setShowTopBorder(false)
                    setShowBottomBorder(true)
                }
                // Only top border at the bottom
                else if (isAtBottom) {
                    setShowTopBorder(true)
                    setShowBottomBorder(false)
                }
                // Both borders in the middle
                else {
                    setShowTopBorder(true)
                    setShowBottomBorder(true)
                }
            }
        }

        // Initial check and adding event listeners
        handleScroll()
        window.addEventListener("resize", handleScroll)
        scrollableDivRef.current?.addEventListener("scroll", handleScroll)

        return () => {
            window.removeEventListener("resize", handleScroll)
            scrollableDivRef.current?.removeEventListener("scroll", handleScroll)
        }
    }, [])

    return (
        <div className="">
            <div
                className={`flex items-start chrome:gap-[36px] mx-[2px] ${
                    hasScroll ? "safari:gap-[33px]" : "safari:gap-[36px]"
                } `}
            >
                <PrimaryText
                    size="xs"
                    weight="medium"
                    className="py-[2px] h-[22px] w-[68px] pl-[8px] pr-[30px] bg-gray-100 mb-[12px] text-[#344054] rounded-[4px]"
                >
                    Was
                </PrimaryText>
                <PrimaryText
                    size="xs"
                    weight="medium"
                    className="py-[2px] h-[22px] pl-[8px] w-[68px] pr-[30px] bg-gray-100 mb-[12px] text-[#344054] rounded-[4px]"
                >
                    Now
                </PrimaryText>
            </div>
            <div
                className={`w-[168px] border-t  
                ${showTopBorder ? "border-gray-100" : "border-white"}`}
            />
            <div
                ref={scrollableDivRef}
                className={`max-h-[444px] min-h-[444px] carousel-scroll overflow-y-auto  
                    ${hasScroll ? "pr-[8px]" : "pr-[0px]"}

                `}
            >
                <div className="flex gap-[8px]">
                    {["Was", "Now"].map((label, idx) => {
                        const images = idx === 0 ? paddedPastImages : paddedPresentImages
                        return (
                            <div key={label} className="flex flex-col gap-[12px] min-w-[68px]">
                                {images.map((image: any, index: any) => (
                                    <div key={index} className="flex items-center gap-[8px]">
                                        <div
                                            onClick={() => handleImageClick(index)}
                                            className={`cursor-pointer min-w-[68px] m-[2px] max-w-[68px] min-h-[68px] max-h-[68px] rounded-[4px] overflow-hidden flex items-center justify-center ${
                                                image === "" ? "bg-gray-900 flex items-center justify-center" : ""
                                            }`}
                                            style={{
                                                outline:
                                                    carouselIndex === index
                                                        ? `2px solid ${modalColor}`
                                                        : "1px solid #98A2B3",
                                            }}
                                        >
                                            {image === "" ? (
                                                <div className="cursor-pointer min-w-[68px] max-w-[68px] min-h-[68px] max-h-[68px] rounded-[4px] bg-gray-900 overflow-hidden flex items-center justify-center">
                                                    <ImageUnavailable width={40} height={40} />
                                                </div>
                                            ) : (
                                                <img
                                                    src={image}
                                                    alt={label}
                                                    className="object-cover min-w-[68px] max-w-[68px] min-h-[68px] max-h-[68px] overflow-visible"
                                                />
                                            )}
                                        </div>

                                        {/* Arrow for 'Was' images */}
                                        {label === "Was" && (
                                            <NewArrowWithStroke
                                                color={`${carouselIndex === index ? `${modalColor}` : "#98A2B3"}`}
                                                strokeWidth={`${carouselIndex === index ? `${"2"}` : "1"}`}
                                            />
                                        )}
                                    </div>
                                ))}
                            </div>
                        )
                    })}
                </div>
            </div>
            <div
                className={`
                    w-[168px]
                    border-b 
                ${showBottomBorder ? " border-gray-100 " : "border-white"}
                
                `}
            ></div>
        </div>
    )
}

export const CarousalModal = (data: any) => {
    const { active, history, setActive, total } = useProductHistory(data?.data, "carousel_images")
    const { modal } = useSelector(RunTimeStateSelector)
    const { t: tCommon } = useTranslation("tooltip", { keyPrefix: "commonLabels" })
    const [selectedColor, setSelectedColor] = useState(null)
    const [showOnlyUnread, setShowOnlyUnread] = useState(false)
    const [image, setImage] = useState(null)
    const [coloredImageSrc, setColoredImageSrc] = useState<string | null>(null)

    const defaultColor = colorList.find((color) => color.name === "EE46BC")?.color || [238, 70, 188]

    const [carouselIndex, setCarouselIndex] = useState(0)
    const presentImages = history?.present?.value || []
    const pastImages = history?.past?.value || []
    const isFirst = data?.data?.isFirst

    const maxLength = Math.max(pastImages.length, presentImages.length)
    const paddedPastImages = [...pastImages, ...Array(maxLength - pastImages.length).fill("")]
    const paddedPresentImages = [...presentImages, ...Array(maxLength - presentImages.length).fill("")]

    const selectedWasImage = paddedPastImages[carouselIndex] || ""
    const selectedNowImage = paddedPresentImages[carouselIndex] || ""

    const handleImageClick = (index: any) => setCarouselIndex(index)

    useEffect(() => {
        const loadImage = async () => {
            if (selectedNowImage) {
                try {
                    const img = await Image.load(selectedNowImage)
                    // @ts-ignore
                    setImage(img)
                    setColoredImageSrc(selectedNowImage)
                } catch (error) {
                    console.error("Error loading image:", error)
                }
            }
        }
        loadImage()
    }, [selectedNowImage])
    useEffect(() => {
        if (selectedColor) {
            recolorImageDifference()
        }
    }, [selectedColor])

    const recolorImageDifference = async () => {
        if (!image || !selectedColor || !selectedWasImage || !showOnlyUnread) return

        try {
            const presentImage = await Image.load(selectedNowImage)
            const pastImage = await Image.load(selectedWasImage)

            // Resize to match dimensions if necessary
            const [width, height] = [presentImage.width, presentImage.height]
            const resizedPastImage = pastImage.resize({ width, height })

            // Clone the present image to apply the difference color
            const coloredImage = presentImage.clone()
            // @ts-ignore
            const [newR, newG, newB] = selectedColor

            for (let x = 0; x < width; x++) {
                for (let y = 0; y < height; y++) {
                    const [r1, g1, b1] = resizedPastImage.getPixelXY(x, y)
                    const [r2, g2, b2, a2] = presentImage.getPixelXY(x, y)

                    // Apply color if there's a difference
                    if (Math.abs(r1 - r2) + Math.abs(g1 - g2) + Math.abs(b1 - b2) > 0) {
                        coloredImage.setPixelXY(x, y, [newR, newG, newB, a2])
                    }
                }
            }

            const blob = await coloredImage.toBlob()
            const newImageURL = URL.createObjectURL(blob)
            setColoredImageSrc(newImageURL)
        } catch (error) {
            console.error("Error applying color difference:", error)
        }
    }
    const handleToggle = () => {
        setShowOnlyUnread((prev) => {
            if (!prev) {
                // @ts-ignore
                setSelectedColor(defaultColor)
                return true
            } else {
                setColoredImageSrc(selectedNowImage)
                return false
            }
        })
    }
    const handleColorSelection = (color: any) => {
        setSelectedColor(color)
    }

    return (
        <ChartsWrapper
            className={`p-[48px] pb-[42px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] ${
                history?.present?.value.length && history?.past?.value.length ? "w-[1144px]" : "w-[628px]"
            } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
        >
            <InnerModalHeader color={modal?.color} type="carousel" />
            <PrimaryText className="text-[#344054] ml-[6px] text-[24px] font-[500] leading-[32px] mb-[4px]">
                Carousel Images
            </PrimaryText>
            <div className={`ml-[8px] flex items-center gap-[10px]`}>
                <ArrowModalActions total={total} active={active} setActive={setActive} />
                <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                    {tCommon("timestamp", {
                        timestamp: dayjs(history?.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                </PrimaryText>
            </div>
            <div className="flex gap-[60px]">
                <div className="flex gap-[60px] mt-[40px]">
                    {isFirst ? (
                        <NowImages
                            presentImages={presentImages}
                            carouselIndex={carouselIndex}
                            handleImageClick={handleImageClick}
                            modalColor={modal?.color?.default}
                        />
                    ) : (
                        <WasNowImages
                            paddedPastImages={paddedPastImages}
                            paddedPresentImages={paddedPresentImages}
                            carouselIndex={carouselIndex}
                            handleImageClick={handleImageClick}
                            modalColor={modal?.color?.default}
                        />
                    )}

                    <div className="flex gap-[20px]">
                        {isFirst ? (
                            <div className="bg-gray-100 p-4 rounded-[16px] flex-1">
                                <div className="w-[372px] h-[372px] overflow-hidden border border-gray-200 rounded-[8px]">
                                    {selectedNowImage === "" ? (
                                        <div className="cursor-pointer w-[364px] h-[364px] rounded-[8px] border-gray-200  border overflow-hidden flex items-center justify-center">
                                            <ImageUnavailable width={156} height={156} />
                                        </div>
                                    ) : (
                                        <img
                                            className="w-full h-full object-contain bg-white"
                                            src={selectedNowImage}
                                            alt="Now"
                                        />
                                    )}
                                </div>
                            </div>
                        ) : (
                            <>
                                <div className="bg-gray-100 p-4 rounded-[16px] flex-1">
                                    <PrimaryText
                                        weight="medium"
                                        size="xs-medium"
                                        className="px-[6px] py-[6px] max-w-[32px] leading-[9px] bg-[#F04438] rounded-[6px] text-white mb-[16px]"
                                    >
                                        Was
                                    </PrimaryText>
                                    <div
                                        className={`w-[364px] h-[364px] overflow-hidden rounded-[8px] border-gray-200  ${
                                            selectedWasImage === "" ? " border-none bg-gray-900" : "border "
                                        }`}
                                    >
                                        {selectedWasImage === "" ? (
                                            <div className="w-[364px] h-[364px]  rounded-[8px] border-gray-200  border bg-gray-900 flex items-center justify-center">
                                                <ImageUnavailable width={156} height={156} />
                                            </div>
                                        ) : (
                                            <img
                                                className="w-full h-full object-contain bg-white"
                                                src={selectedWasImage}
                                                alt="Was"
                                            />
                                        )}
                                    </div>
                                </div>
                                <div className="bg-gray-100 p-4 rounded-[16px] flex-1">
                                    <PrimaryText
                                        weight="medium"
                                        size="xs-medium"
                                        className="px-[6px] py-[6px] max-w-[32px] leading-[9px] bg-[#47CD89] rounded-[6px] text-white mb-[16px]"
                                    >
                                        Now
                                    </PrimaryText>
                                    <div
                                        className={`w-[364px] h-[364px] overflow-hidden rounded-[8px] border-gray-200  ${
                                            selectedNowImage === "" ? "border-none bg-gray-900" : "border "
                                        }`}
                                    >
                                        {selectedNowImage === "" ? (
                                            <div className="w-[364px] h-[364px]  rounded-[8px] border-gray-200  border bg-gray-900 flex items-center justify-center">
                                                <ImageUnavailable width={156} height={156} />
                                            </div>
                                        ) : (
                                            <img
                                                className="w-full h-full object-cover bg-white"
                                                src={coloredImageSrc || selectedNowImage}
                                                alt="Now"
                                            />
                                        )}
                                    </div>
                                    {data?.data?.isFirst === false && (
                                        <div className="flex items-center gap-[24px] mt-[16px] justify-center">
                                            <div className="flex items-center gap-[4px]">
                                                <div className="whitespace-pre-line outline-none my-[1px] text-xs leading-[1.125rem] font-medium text-gray-500">
                                                    Show changes
                                                </div>
                                                <label className="switches">
                                                    <input
                                                        id="toggle-input"
                                                        type="checkbox"
                                                        checked={showOnlyUnread}
                                                        onChange={handleToggle}
                                                    />
                                                    <span className="sliders rounds"></span>
                                                </label>
                                            </div>

                                            <div className="flex items-center gap-[4px]">
                                                <label className="text-xs leading-[1.125rem] my-[1px] font-medium text-gray-500">
                                                    Highlight
                                                </label>
                                                <div className="flex gap-[2px] bg-white rounded-[12px] p-[2px] border border-gray-200">
                                                    {colorList.map(({ name, color }) => (
                                                        <button
                                                            key={name}
                                                            style={{ backgroundColor: `#${name}` }}
                                                            className="w-[16px] h-[16px] rounded-full"
                                                            // @ts-ignore
                                                            onClick={() => handleColorSelection(color)}
                                                        >
                                                            <span className="sr-only">{name}</span>
                                                        </button>
                                                    ))}
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </>
                        )}
                    </div>
                </div>
            </div>
        </ChartsWrapper>
    )
}

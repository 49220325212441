import { AdditionalWrapperModal } from "./additionalWrapperModal.component"
import AdditionalTrackPackOutlet from "./additonalOutlet.component"

const AdditionalTrackPackMain = () => {
    return (
        <AdditionalWrapperModal
            className={`p-[48px] pb-[36px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] w-[600px]  shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
        >
            <AdditionalTrackPackOutlet />
        </AdditionalWrapperModal>
    )
}

export default AdditionalTrackPackMain

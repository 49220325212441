import { useTranslation } from "react-i18next"

import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"
import { MarketingFooter } from "./marketingFooter.component"

import FoxGame from "../../assets/gifs/Fox-game.gif"
import { MarketingHeader } from "./marketingHeader.component"

export const ASINTrackerChatComponent = () => {
    const { t } = useTranslation("marketing", {
        keyPrefix: "asinTrackerChat.conversation",
    })

    return (
        <>
            <div className="xl:pt-[28px] lg:pt-[28px] md:pt-[20px] sm:pt-[20px] xs:pt-[20px]">
                <div className="flex justify-center 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] md:gap-[16px] sm:gap-[0px] xs:gap-[0px]">
                    <MarketingHeader />

                    <div
                        className="flex flex-col justify-start hidden-scrollbar items-start overflow-y-auto md:pr-[20px] lg:pr-[20px] w-[504px] md:h-md-calc-onboarding lg:h-lg-calc-onboarding xl:h-xl-calc-onboarding"
                        // style={{ height: "calc(100vh - 252px)" }}
                    >
                        <div>
                            <BubbleListToTop>
                                <BubblesList
                                    interval={500}
                                    conversationItems={[
                                        {
                                            text: t("1"),
                                        },
                                        {
                                            text: t("2"),
                                        },
                                        {
                                            src: FoxGame,
                                            srcType: "custom",
                                            className:
                                                "lg:w-[322.39px] lg:h-[180px] md:w-[322.39px] md:h-[180px]  sm:w-[214.93px] sm:h-[120px] xs:w-[214.93px] xs:h-[120px]",
                                        },
                                        {
                                            text: t("3"),
                                            className: "xs:w-[260px] md:w-full",
                                        },
                                        {
                                            text: t("4"),
                                        },
                                        {
                                            text: t("5"),
                                        },
                                        {
                                            text: t("6"),
                                        },
                                    ]}
                                />
                            </BubbleListToTop>
                        </div>
                    </div>
                </div>
            </div>
            <MarketingFooter currentStep={7} />
        </>
    )
}

import parse from "html-react-parser"
import { useMemo, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import toast from "react-hot-toast"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { OnboardingUserActionCreators } from "../../actions/onBoardingUser.action"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { OnboardingUserStateSelector } from "../../selectors/onBoardingState.selector"
import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"
import { ButtonElement } from "../elements/button.element"
import { Container } from "../elements/container.element"
import { InputElement } from "../elements/input.element"
import { MarketingFooter } from "./marketingFooter.component"

import { Transition } from "@headlessui/react"
import { RuntimeHelper } from "../../helpers/runtime.helper"
import { RunTimeStateSelector } from "../../selectors/RunTimeStateSelector"
import { MarketingHeader } from "./marketingHeader.component"

export const EmailChatForm = () => {
    const validationConfig = useInputValidations()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { t } = useTranslation("marketing", {
        keyPrefix: "emailChat.conversation",
    })

    const userOnBoard = useSelector(OnboardingUserStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)

    const { control, handleSubmit, setError } = useForm<IEmailChatInput>({
        defaultValues: { email: userOnBoard?.email ?? "" },
        criteriaMode: "all",
    })

    const [completed, setCompleted] = useState(false)

    const notifyError = (text: string) => toast.error(text)

    const isLoading: boolean = useMemo(() => RuntimeHelper.isAlphaEmailVerification(), [loading])

    const onSubmit: SubmitHandler<IEmailChatInput> = (data) => {
        dispatch(
            OnboardingUserActionCreators.verifyAlphaEmail(
                { email: data.email },
                () => {
                    navigate(ROUTES_CONFIG.jobRoleForm)
                },
                (res: { message: string; code?: number }) => {
                    if (res.code === 409) {
                        setError("email", {
                            message: parse(
                                "Email already registered. For support email <span class='font-[500] text-[#D92D20]  text-[12px]'>hello@amaizing.io</span>"
                            ) as string,
                        })
                    } else {
                        notifyError(res.message)
                    }
                }
            )
        )
    }

    return (
        <>
            <div className="xl:pt-[28px] lg:pt-[28px] md:pt-[20px] sm:pt-[20px] xs:pt-[20px]">
                <div className="flex justify-center 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] md:gap-[16px] sm:gap-[0px] xs:gap-[0px]">
                    <MarketingHeader />

                    <div
                        className="flex flex-col justify-start items-start overflow-y-auto md:pr-[20px] lg:pr-[20px] w-[504px]  md:h-md-calc-onboarding lg:h-lg-calc-onboarding xl:h-xl-calc-onboarding"
                        // style={{ height: "calc(100vh - 252px)" }}
                    >
                        <div>
                            <BubbleListToTop>
                                <BubblesList
                                    interval={500}
                                    maxSleep={500}
                                    onFinish={() => {
                                        setCompleted(true)
                                    }}
                                    conversationItems={[
                                        {
                                            text: t("1"),
                                        },
                                        {
                                            text: t("2"),
                                        },
                                        {
                                            text: t("3"),
                                        },
                                        {
                                            text: t("4"),
                                        },
                                    ]}
                                />
                            </BubbleListToTop>
                        </div>

                        <Transition
                            show={completed}
                            enter="transition ease-out duration-300 transform"
                            enterFrom="translate-y-full opacity-0"
                            enterTo="translate-y-0 opacity-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="translate-y-0 opacity-100"
                            leaveTo="translate-y-full opacity-0"
                        >
                            <div className="flex-1 xl:min-w-[375px] lg:min-w-[375px] md:min-w-[375px] sm:min-w-[240px] xs:min-w-[240px]">
                                <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                                    <Container>
                                        <InputElement
                                            placeholder="Email address"
                                            name="email"
                                            reactHookControl={control}
                                            reactHookValidations={{
                                                required: validationConfig.required,
                                                pattern: validationConfig.email,
                                            }}
                                            className=""
                                        />

                                        {completed && (
                                            <ButtonElement
                                                type="submit"
                                                loading={isLoading || undefined}
                                                size="large"
                                                className={
                                                    "sm:mt-[36px] md:mt-[24px] lg:mt-[24px] xs:mt-[36px] xl:mt-[24px] text-[#0E7090]"
                                                }
                                            >
                                                {t("button")}
                                            </ButtonElement>
                                        )}
                                    </Container>
                                </form>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>

            <MarketingFooter currentStep={2} />
        </>
    )
}

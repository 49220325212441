import { AnyAction, Dispatch, Middleware, MiddlewareAPI } from 'redux';
import { AuthAction, AuthActionCreator } from '../actions/auth.action';
import { isMatchingAttributeFormat, isTokenExpired } from '../helpers/util.helper';
import { RootState } from "../reducers/root.reducer";

const notAllowed = [AuthAction.GET_TOKEN, AuthAction.REFRESH_TOKEN, AuthAction.SET_REFRESH_TOKEN];
const queue: AnyAction[] = [];
let isRefreshing = false;

const tokenMiddleware: Middleware<{}, RootState> = ({ getState, dispatch }: MiddlewareAPI<Dispatch, RootState>) => next => (action: AnyAction) => {
    const state = getState();
    const token = state?.auth?.token?.access;

    if (notAllowed.includes(action.type)) {
        return next(action);
    } else {
        let isMatching: boolean = isMatchingAttributeFormat(action.type);
        if (token && isMatching && isTokenExpired(token)) {
            queue.push(action);

            if (!isRefreshing) {
                isRefreshing = true;
                setTimeout(() => {
                    dispatch(AuthActionCreator.getRefreshToken(JSON.parse(JSON.stringify(queue)), () => {isRefreshing = false; queue.length = 0}));
                },0)
            }
            return;
        }
        return next(action);
    }
};

export default tokenMiddleware;
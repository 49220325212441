import { LoaderFunctionArgs, redirect } from "react-router-dom"
import { ROUTES_CONFIG } from "./config/routes.config"

export function useAuth({ request }: LoaderFunctionArgs) {
    const token = localStorage.getItem("token")
    // If the user is not logged in and tries to access `/protected`, we redirect
    // them to `/login` with a `from` parameter that allows login to redirect back
    // to this page upon successful authentication
    if (!token) {
        let params = new URLSearchParams()
        params.set("from", new URL(request.url).pathname)
        return redirect(`${ROUTES_CONFIG.welcome}?` + params.toString())
    }

    return null
}

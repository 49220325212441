import { useEffect } from "react"
import { useSelector } from "react-redux"
import { useContainsChanges } from "../../hooks/containsChanges.hook"
import { NotificationStateSelector } from "../../selectors/notificationStateSelector"

let timeout: any = null
export const CustomDotSvg = (props: ICustomSvgDot) => {
    const { notifiSelection } = useSelector(NotificationStateSelector)
    const payload: ISelectedProduct = {
        timestamp: props.payload!.timestamp,
        color: props.color,
        product: props.payload![props.id!],
        id: props.id!,
    }

    useEffect(() => {
        const storedData = sessionStorage.getItem("timeStamp")
        if (
            storedData &&
            notifiSelection &&
            payload.id == notifiSelection?.notifiAsin?.[0] &&
            payload?.timestamp.split("T")[0] === storedData?.split("T")[0]
        ) {
            timeout && clearInterval(timeout)
            timeout = setTimeout(() => {
                handleMouseOver()
                sessionStorage.removeItem("timeStamp")
            }, 1000)
        }
    }, [notifiSelection])

    const handleMouseOver = () => {
        if (payload)
            props.customMoveClick!({
                cx: props.cx,
                cy: props.cy,
                payload,
            })
    }
    const isFirst = props.initialTimestamp && props.initialTimestamp === props.payload!.timestamp
    const { hasActualAsin, hasChanges } = useContainsChanges(payload.product)

    if (hasChanges || isFirst) {
        return (
            <>
                <svg
                    id={props.dataKey!.split("[")[0]}
                    x={isFirst ? props.cx! - 6.5 : props.cx! - 8}
                    y={isFirst ? props.cy! - 7 : props.cy! - 7.5}
                    width={hasActualAsin && !isFirst ? 11 : 33}
                    height={hasActualAsin && !isFirst ? 11 : 33}
                    viewBox={hasActualAsin && !isFirst ? "0 0 12 10" : "0 0 200 200"}
                    onClick={handleMouseOver}
                    className={`cursor-pointer hover:${props?.color?.default}`}
                >
                    {hasActualAsin && !isFirst ? (
                        <path
                            d="M5.576 9.265C5.66737 9.41119 5.8276 9.5 6 9.5C6.17239 9.5 6.33263 9.41119 6.424 9.265L11.424 1.265C11.5203 1.11087 11.5254 0.916593 11.4373 0.757616C11.3492 0.598639 11.1818 0.5 11 0.5L0.999999 0.500001C0.818237 0.500001 0.65079 0.598639 0.562678 0.757617C0.474565 0.916594 0.479666 1.11087 0.576 1.265L5.576 9.265Z"
                            fill="white"
                            stroke={props?.color?.default}
                            stroke-linejoin={10}
                            strokeWidth={1.5}
                        />
                    ) : (
                        <circle
                            cx={isFirst ? "40" : "46"}
                            cy={isFirst ? "46" : "46"}
                            r={isFirst ? "25" : "25"}
                            strokeWidth={isFirst ? "9" : "8"}
                            fill={isFirst ? "white" : props?.color?.default}
                            stroke={isFirst ? props?.color?.default : "white"}
                        />
                    )}
                </svg>
            </>
        )
    }
    return null
}

// import dayjs from "dayjs"
// import { useTranslation } from "react-i18next"
// import { useSelector } from "react-redux"
// import { useProductHistory } from "../../../hooks/productHistory.hook"
// import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
// import ArrowModalActions from "../../common/arrowModalActions.component"
// import InnerModalHeader from "../../common/innerModalHeader.component"
// import { PrimaryText } from "../../elements/primaryText.element"
// import { StringDiffElement } from "../../elements/stringDiff.element"
// import { ChartsWrapper } from "./chartsWrapper.component"

// const DescriptionModal = (data: IDialogOptionsProps) => {
//     const { modal } = useSelector(RunTimeStateSelector)

//     const { active, history, setActive, total } = useProductHistory(data?.data as IProductData, "description")
//     const { t: tCommon } = useTranslation("tooltip", {
//         keyPrefix: "commonLabels",
//     })

//     return (
//         <>
//             <ChartsWrapper
//                 className={`p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto ${
//                     data?.data?.isFirst === true
//                         ? "w-[528px]"
//                         : history?.past?.value === "" ||
//                           history?.present?.value === "" ||
//                           (history?.past?.value?.length && history.present?.value?.length)
//                         ? "w-[972px]"
//                         : "w-[528px]"
//                 } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
//             >
//                 <InnerModalHeader color={modal?.color} type="description" />
//                 <PrimaryText className="text-[#344054] ml-[6px] text-[24px] font-[500] leading-[32px] mb-[4px]">
//                     Description
//                 </PrimaryText>
//                 <div className={` ${total > 1 ? "ml-[8px] flex items-center gap-[10px]" : "ml-[6px]"}`}>
//                     <ArrowModalActions total={total} active={active} setActive={setActive} />

//                     <div className="flex items-center justify-between w-full">
//                         <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
//                             {tCommon("timestamp", {
//                                 timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
//                             })}
//                         </PrimaryText>
//                     </div>
//                 </div>

//                 <div className=" mt-[40px]">
//                     <div className="flex gap-[12px]">
//                         {history.past && (
//                             <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[432px] pb-[20px]">
//                                 <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
//                                     Was
//                                 </PrimaryText>
//                                 <PrimaryText className="text-[12px] leading-[16px] font-[300] max-h-[296px] pr-[4px] modal-scrollbar-pr overflow-y-auto text-[#344054]">
//                                     {/* {history?.past?.value} */}
//                                     <StringDiffElement
//                                         pastValue={history.past.value}
//                                         showType="removed"
//                                         presentValue={history.present && history.present.value}
//                                     />
//                                 </PrimaryText>
//                             </div>
//                         )}

//                         {/* {history.past && history.present && (
//                             <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[260px] pb-[20px]">
//                                 <PrimaryText className="w-[53px] py-[5.5px] px-[6px] bg-[#667085] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
//                                     Changes
//                                 </PrimaryText>
//                                 <PrimaryText className="text-[12px] leading-[16px] font-[300] max-h-[296px] modal-scrollbar-pr pr-[4px] overflow-y-auto text-[#344054]">
//                                     <StringDiffElement
//                                         pastValue={(history?.past as { value: string })?.value}
//                                         presentValue={history?.present?.value}
//                                     />
//                                 </PrimaryText>
//                             </div>
//                         )} */}
//                         {history.present && (
//                             <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[432px] pb-[20px]">
//                                 <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#47CD89] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
//                                     Now
//                                 </PrimaryText>
//                                 <PrimaryText className="text-[12px] leading-[16px] font-[300] pr-[4px] max-h-[296px] modal-scrollbar-pr overflow-y-auto text-[#344054]">
//                                     {/* {history?.present?.value} */}
//                                     <StringDiffElement
//                                         pastValue={history.past && history.past.value}
//                                         showType="added"
//                                         presentValue={history.present.value}
//                                     />
//                                 </PrimaryText>
//                             </div>
//                         )}
//                     </div>
//                 </div>
//             </ChartsWrapper>
//         </>
//     )
// }

// export default DescriptionModal

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"
import { useSelector } from "react-redux"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { RunTimeStateSelector } from "../../../selectors/RunTimeStateSelector"
import ArrowModalActions from "../../common/arrowModalActions.component"
import InnerModalHeader from "../../common/innerModalHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { StringDiffElement } from "../../elements/stringDiff.element"
import { ChartsWrapper } from "./chartsWrapper.component"

const DescriptionModal = (data: IDialogOptionsProps) => {
    const { modal } = useSelector(RunTimeStateSelector)

    const { active, history, setActive, total } = useProductHistory(data?.data as IProductData, "description")
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const isFirst = data?.data?.isFirst === true

    return (
        <>
            <ChartsWrapper
                className={`p-[48px] absolute top-[50%] left-[50%] -translate-y-2/4 -translate-x-2/4 bg-white rounded-[24px] m-auto ${
                    isFirst
                        ? "w-[528px]"
                        : history?.past?.value === "" ||
                          history?.present?.value === "" ||
                          (history?.past?.value?.length && history.present?.value?.length)
                        ? "w-[972px]"
                        : "w-[528px]"
                } shadow-modal-shadow border-[1px] border-solid border-[#E7EBEF] transition-all`}
            >
                <InnerModalHeader color={modal?.color} type="description" />
                <PrimaryText className="text-[#344054] ml-[6px] text-[24px] font-[500] leading-[32px] mb-[4px]">
                    Description
                </PrimaryText>
                <div className={`ml-[8px] flex items-center gap-[10px]`}>
                    <ArrowModalActions total={total} active={active} setActive={setActive} />

                    <div className="flex items-center justify-between w-full">
                        <PrimaryText className="text-[12px] uppercase leading-[12px] text-[#667085] font-[400]">
                            {tCommon("timestamp", {
                                timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                            })}
                        </PrimaryText>
                    </div>
                </div>

                <div className="mt-[40px]">
                    <div className="flex gap-[12px]">
                        {!isFirst && history.past && (
                            <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[432px] pb-[20px]">
                                <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#F04438] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                    Was
                                </PrimaryText>
                                {history?.past?.value === "" ? (
                                    <PrimaryText className="text-[12px] italic leading-[16px] font-[300] pr-[4px] max-h-[296px] text-[#344054]">
                                        No description
                                    </PrimaryText>
                                ) : (
                                    <PrimaryText className="text-[12px] leading-[16px] font-[300] max-h-[296px] pr-[4px] modal-scrollbar-pr overflow-y-auto text-[#344054]">
                                        <StringDiffElement
                                            pastValue={history.past.value}
                                            showType="removed"
                                            presentValue={history.present && history.present.value}
                                        />
                                    </PrimaryText>
                                )}
                            </div>
                        )}

                        {!isFirst && history.present && (
                            <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[432px] pb-[20px]">
                                <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#47CD89] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                    Now
                                </PrimaryText>
                                {history?.present?.value === "" ? (
                                    <PrimaryText className="text-[12px] italic leading-[16px] font-[300] pr-[4px] max-h-[296px] text-[#344054]">
                                        No description
                                    </PrimaryText>
                                ) : (
                                    <PrimaryText className="text-[12px] leading-[16px] font-[300] pr-[4px] max-h-[296px] modal-scrollbar-pr overflow-y-auto text-[#344054]">
                                        <StringDiffElement
                                            pastValue={history.past && history.past.value}
                                            showType="added"
                                            presentValue={history.present.value}
                                        />
                                    </PrimaryText>
                                )}
                            </div>
                        )}
                        {isFirst && history.present && (
                            <div className="bg-[#F2F4F7] rounded-[16px] p-4 w-[432px] pb-[20px]">
                                <PrimaryText className="w-[32px] py-[5.5px] px-[6px] bg-[#47CD89] text-white rounded-[6px] mb-[16px] text-[10px] leading-[9px] font-[500] flex items-center justify-center">
                                    Now
                                </PrimaryText>
                                {history.present.value === "" ? (
                                    <PrimaryText className="text-[12px] italic leading-[16px] font-[300] pr-[4px] max-h-[296px] text-[#344054]">
                                        No description
                                    </PrimaryText>
                                ) : (
                                    <PrimaryText className="text-[12px] leading-[16px] font-[300] pr-[4px] max-h-[296px] modal-scrollbar-pr overflow-y-auto text-[#344054]">
                                        {history.present.value}
                                    </PrimaryText>
                                )}
                            </div>
                        )}
                    </div>
                </div>
            </ChartsWrapper>
        </>
    )
}

export default DescriptionModal

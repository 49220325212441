export const EyeOffSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M7.16196 4.28941C7.4329 4.24935 7.7124 4.22786 8.00028 4.22786C11.4036 4.22786 13.6369 7.23109 14.3871 8.41908C14.4779 8.56287 14.5233 8.63476 14.5488 8.74565C14.5678 8.82892 14.5678 8.96031 14.5487 9.04358C14.5233 9.15447 14.4776 9.22684 14.3861 9.37158C14.1862 9.68796 13.8814 10.1326 13.4777 10.6148M4.48288 5.37122C3.0415 6.349 2.06297 7.70745 1.61407 8.41805C1.52286 8.56245 1.47725 8.63464 1.45183 8.74552C1.43273 8.82879 1.43272 8.96016 1.45181 9.04344C1.47722 9.15432 1.52262 9.22621 1.61342 9.36998C2.36369 10.558 4.59694 13.5612 8.00028 13.5612C9.37255 13.5612 10.5546 13.0729 11.5259 12.4123M2.00028 2.89453L14.0003 14.8945M6.58606 7.48032C6.22413 7.84225 6.00028 8.34225 6.00028 8.89453C6.00028 9.9991 6.89571 10.8945 8.00028 10.8945C8.55256 10.8945 9.05256 10.6707 9.41449 10.3087"
                stroke="#98A2B3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>

        // <svg width="16" height="16" viewBox="0 0 16 16" opacity="0.2" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <g style={{ mixBlendMode: "multiply" }}>
        //         <rect x="0.5" y="1.39453" width="15" height="15" rx="2.5" fill="#F9FAFB" />
        //         <rect x="0.5" y="1.39453" width="15" height="15" rx="2.5" stroke="#EAECF0" />
        //         <path
        //             d="M7.47623 6.01633C7.64556 5.99129 7.82025 5.97786 8.00017 5.97786C10.1273 5.97786 11.523 7.85488 11.992 8.59737C12.0487 8.68724 12.0771 8.73217 12.093 8.80148C12.1049 8.85353 12.1049 8.93564 12.093 8.98769C12.0771 9.05699 12.0485 9.10222 11.9913 9.19268C11.8664 9.39043 11.6759 9.66832 11.4235 9.96972M5.8018 6.69246C4.90094 7.30357 4.28935 8.15261 4.00879 8.59673C3.95179 8.68698 3.92328 8.7321 3.90739 8.8014C3.89546 8.85345 3.89545 8.93555 3.90738 8.9876C3.92326 9.0569 3.95164 9.10183 4.00839 9.19169C4.47731 9.93418 5.87309 11.8112 8.00017 11.8112C8.85784 11.8112 9.59662 11.506 10.2037 11.0931M4.25017 5.14453L11.7502 12.6445M7.11629 8.01065C6.89008 8.23685 6.75017 8.54935 6.75017 8.89453C6.75017 9.58489 7.30982 10.1445 8.00017 10.1445C8.34535 10.1445 8.65785 10.0046 8.88406 9.77841"
        //             stroke="#344054"
        //             stroke-width="0.6"
        //             stroke-linecap="round"
        //             stroke-linejoin="round"
        //         />
        //     </g>
        // </svg>
    )
}

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"

import { PriceIconTooltip } from "../../../assets/svgs/tooltips/popups/popup-price-icon.svg"
import { PrimaryText } from "../../elements/primaryText.element"

import { UtilHelper } from "../../../helpers/util.helper"

export const PriceInfo = (props: ITooltipData) => {
    const { t } = useTranslation("tooltip", {
        keyPrefix: "priceInfo",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })
    const currency = Array.isArray(props?.data?.currency) ? props?.data?.currency?.[0]?.value : props?.data?.currency

    const { active, history, setActive, total } = useProductHistory(props.data, "price")
    const currencySymbol = UtilHelper.getCurrencySymbol(currency)
    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )
    return (
        <>
            <div
                className={`p-[12px] bg-white rounded-2xl w-[216px]`}
                style={{ border: `1px solid ${props.color?.default}` }}
            >
                <InnerTooltipHeader
                    title={t("price")}
                    linkText={tCommon("Go to ASIN")}
                    timestamp={tCommon("timestamp", {
                        timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                    icon={<PriceIconTooltip stroke={props.color?.default} />}
                    active={active}
                    total={total}
                    setActive={setActive}
                    textColor={props.color?.default}
                    color={props.color?.active}
                    id={props.id}
                />

                <div className="mt-[32px] flex gap-[8px] justify-between">
                    {history.past && (
                        <>
                            <div className={`w-full p-[8px] bg-[${props.color?.bgHundred}] rounded-lg`}>
                                <PrimaryText
                                    size="xs"
                                    weight="medium"
                                    color={`text-[${props.color?.default}]`}
                                    className="text-[12px] mx-[2px] mt-[2px] mb-[6px] leading-[10px]"
                                >
                                    {tCommon("was")}
                                </PrimaryText>

                                <PrimaryText
                                    size={"sm-medium"}
                                    weight={"light"}
                                    className=" w-full text-gray-600  leading-tight mx-[2px]"
                                >
                                    {currencySymbol}
                                    {history.past?.value}
                                </PrimaryText>
                            </div>
                        </>
                    )}
                    <div className={`w-full p-[8px] bg-[${props.color?.bgHundred}] rounded-lg`}>
                        <PrimaryText
                            size="xs"
                            weight="medium"
                            color={`text-[${props.color?.default}]`}
                            className="text-[12px] mx-[2px] mt-[2px] mb-[6px] leading-[10px]"
                        >
                            {tCommon("now")}
                        </PrimaryText>

                        <PrimaryText
                            size={"sm-medium"}
                            weight={"light"}
                            className=" w-full text-gray-600 leading-tight mx-[2px]"
                        >
                            {currencySymbol}
                            {history.present?.value}
                        </PrimaryText>
                    </div>
                </div>
                {/* {isSuccessWarning && (
                    <div className="w-full  pt-[8px]">
                        <ErrorTooltipAlert />
                    </div>
                )}

                {isWarning && (
                    <div className="w-full  pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
        </>
    )
}

import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const ThQuickTourStepSeven: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    icon,
    handleCloseDialog,
    handleNext,
    image,
}) => {
    return (
        <>
            {/* <div className="relative bg-gray-900 h-screen" id="trackpack-hub">
                <img
                    src={image}
                    alt="PT Step Three"
                    className=" svg_image w-[1308px] h-[800px] absolute left-[104px] top-[136px]"
                />
                <div
                    className=" max-w-[248px] w-full bg-[#088AB2] rounded-[16px]  p-[12px] absolute top-[145px] left-[858px] right_custom_arrow step7 translate-x-[0%] translate-y-[0%]"
                    style={{
                        boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                    }}
                > */}
            {/* Header */}
            <div className="flex justify-between items-center">
                <div className="flex items-center space-x-2">
                    <img
                        src={Icon}
                        className="xlpt:h-[26px] xlpt:w-[28px] mdpt:h-[20.8px] mdpt:w-[22.4px]"
                        alt="icon"
                    />
                    <PrimaryText
                        size="small"
                        weight="medium"
                        className="text-white mdpt:text-[12px] mdpt:leading-[12px] xlpt:text-[14px] xlpt:leading-[14px]"
                    >
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="xlpt:mt-[26px] mdpt:mt-[20px] mdpt:p-[9.6px] xlpt:p-[12px] bg-[#06AED4] mdpt:rounded-[6.4px] xlpt:rounded-[8px]">
                <PrimaryText
                    weight="book"
                    size="xs"
                    className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] mb-[10px]"
                >
                    If tracking is all good for an ASIN,
                    <br /> I’ll show a green tick in the ‘Status’ column of the Trackpack tables. Sometimes though, I
                    can’t retrieve data for ASINs for these 4 reasons:
                    <br />
                    <ul className="list-decimal	pl-[22px] my-[10px]">
                        <li className="mb-[2px]">
                            <PrimaryText
                                weight="book"
                                size="xs"
                                className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] "
                            >
                                Data is unavailable;
                            </PrimaryText>
                        </li>
                        <li>
                            <PrimaryText
                                weight="book"
                                size="xs"
                                className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] "
                            >
                                A different ASIN is returned;
                            </PrimaryText>
                        </li>
                        <li>
                            <PrimaryText
                                weight="book"
                                size="xs"
                                className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] "
                            >
                                A child ASIN is returned; or
                            </PrimaryText>
                        </li>
                        <li>
                            <PrimaryText
                                weight="book"
                                size="xs"
                                className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] "
                            >
                                The ASIN returned has a different BSR L Cat. to the{" "}
                                <br className="mdpt:block xlpt:hidden" /> one provided.
                            </PrimaryText>
                        </li>
                    </ul>
                    You’ll be alerted to any tracking errors as I highlight the Trackpack cards in yellow and display a
                    yellow warning symbol next to the ASIN <br className="mdpt:block xlpt:hidden" /> in Trackpack
                    tables. Hovering over these symbols reveals what the issue is and suggested actions{" "}
                    <br className="mdpt:block xlpt:hidden" /> you can take to resolve.
                </PrimaryText>
                {/* Footer */}
                <div className="flex justify-between items-center mdpt:mt-[20px] xlpt:mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white mdpt:text-[8px] xlpt:text-[10px]">
                        7 / 9
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="mdpt:mt-[2px] xlpt:mt-[0px] text-[500] !mdpt:mt-[1px] !xlpt:mt-[0px] mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] text-white"
                        className="mdpt:!w-[34.8px] mdpt:!h-[20.4px] xlpt:!w-[42px] xlpt:!h-[26px] mdpt:px-[6.4px] mdpt:py-[3.2px]  xlpt:px-[8px] xlpt:py-[4px] !bg-none text-white mdpt:rounded-[4.8px] xlpt:rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
            {/* </div>
            </div> */}
        </>
    )
}

export default ThQuickTourStepSeven

export const LogVideoIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="play">
                    <path
                        id="Icon"
                        d="M2.5 2.49476C2.5 2.00917 2.5 1.76638 2.60125 1.63255C2.68945 1.51595 2.82426 1.4438 2.9702 1.43509C3.13772 1.42509 3.33973 1.55976 3.74376 1.82912L9.00154 5.3343C9.33538 5.55686 9.5023 5.66815 9.56047 5.80841C9.61133 5.93103 9.61133 6.06885 9.56047 6.19148C9.5023 6.33174 9.33538 6.44302 9.00154 6.66558L3.74376 10.1708C3.33973 10.4401 3.13772 10.5748 2.9702 10.5648C2.82426 10.5561 2.68945 10.4839 2.60125 10.3673C2.5 10.2335 2.5 9.99071 2.5 9.50513V2.49476Z"
                        stroke={props.color}
                        stroke-width="0.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </svg>
        </>
    )
}

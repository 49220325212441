import { Transition } from "@headlessui/react"
import { useEffect, useState } from "react"
import { SubmitHandler, useForm } from "react-hook-form"
import { useTranslation } from "react-i18next"
import { useDispatch, useSelector } from "react-redux"
import { useNavigate } from "react-router-dom"

import { OnboardingUserActionCreators } from "../../actions/onBoardingUser.action"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { useInputValidations } from "../../hooks/inputValidations.hook"
import { OnboardingUserStateSelector } from "../../selectors/onBoardingState.selector"
import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"
import { ButtonElement } from "../elements/button.element"
import { CheckboxElement } from "../elements/checkbox.element"
import { Container } from "../elements/container.element"
import { InputElement } from "../elements/input.element"
import { MarketingFooter } from "./marketingFooter.component"

import { JOB_ROLES } from "../../config/onBoarding.config"
import { MarketingHeader } from "./marketingHeader.component"

export const JobRoleForm = () => {
    const validationConfig = useInputValidations()

    const { t } = useTranslation("marketing", {
        keyPrefix: "jobRoleChat.conversation",
    })

    const userOnBoard = useSelector(OnboardingUserStateSelector)
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const [completed, setCompleted] = useState<boolean>(false)

    const defaultJobRoleValues = JOB_ROLES.reduce(
        (acc, jobRole) => {
            acc[jobRole.type] = jobRole.value
            return acc
        },
        {} as Record<JobRole, boolean>
    )

    const { control, handleSubmit, watch, setValue } = useForm<IJobRoleFormInputs>({
        defaultValues: {
            ...defaultJobRoleValues,
            customJobRole: "",
        },
    })
    const watchListingOption5 = watch("jobRole5", false)

    useEffect(() => {
        if (userOnBoard?.selectedJobRoles) {
            userOnBoard?.selectedJobRoles?.forEach((jobRole: JobRole) => {
                setValue(jobRole, true) // Set the value of the checkbox to true based on the stored job roles
            })
        }
        if (userOnBoard?.customJobRole?.length) {
            setValue("customJobRole", userOnBoard?.customJobRole) // Set the value of customJobRole if it exists
        }
    }, [userOnBoard, setValue])

    const onSubmit: SubmitHandler<IJobRoleFormInputs> = (data) => {
        const selectedJobRoles = [] as JobRole[]
        // Iterate over the submitted data
        for (const [key, value] of Object.entries(data)) {
            // Check if the value is true and it's not customJobRole
            if (value && key !== "customJobRole") {
                selectedJobRoles.push(key as JobRole)
            }
        }
        dispatch(
            OnboardingUserActionCreators.createOnboardingUser({
                selectedJobRoles: selectedJobRoles,
                customJobRole: data.jobRole5 && data.customJobRole ? data.customJobRole : "",
            })
        )
        navigate(ROUTES_CONFIG.listingManageForm)
    }

    const getDefaultValue = (key: JobRole) => {
        const watchListingOption = watch(key, false)
        return watchListingOption
    }

    const watchListingOptions = JOB_ROLES.map((jobRole) => watch(jobRole.type, jobRole.value))

    return (
        <>
            <div className="xl:pt-[28px] lg:pt-[28px] md:pt-[20px] sm:pt-[20px] xs:pt-[20px]">
                <div className="flex justify-center 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] md:gap-[16px] sm:gap-[0px] xs:gap-[0px]">
                    <MarketingHeader />

                    <div
                        className="flex flex-col justify-start items-start overflow-y-auto md:pr-[20px] lg:pr-[20px] w-[504px] md:h-md-calc-onboarding lg:h-lg-calc-onboarding xl:h-xl-calc-onboarding"
                        // style={{ height: "calc(100vh - 250px)" }}
                    >
                        <div className={""}>
                            <BubbleListToTop>
                                <BubblesList
                                    interval={500}
                                    onFinish={() => {
                                        setCompleted(true)
                                    }}
                                    conversationItems={[
                                        {
                                            text: t("1"),
                                        },
                                        {
                                            text: t("2"),
                                        },
                                        {
                                            text: t("3"),
                                        },
                                    ]}
                                />
                            </BubbleListToTop>
                        </div>

                        <Transition
                            show={completed}
                            enter="transition ease-out duration-300 transform"
                            enterFrom="translate-y-full opacity-0"
                            enterTo="translate-y-0 opacity-100"
                            leave="transition ease-in duration-200 transform"
                            leaveFrom="translate-y-0 opacity-100"
                            leaveTo="translate-y-full opacity-0"
                        >
                            <div className="flex-1 xl:min-w-[375px] lg:min-w-[375px] md:min-w-[375px] sm:min-w-[240px] xs:min-w-[240px]">
                                <form onSubmit={handleSubmit(onSubmit)} className={"w-full"}>
                                    <Container>
                                        {JOB_ROLES.map((el, index) => (
                                            <CheckboxElement
                                                defaultValue={getDefaultValue(el.type)}
                                                name={el.type}
                                                label={t(el.translationKey)}
                                                onChange={(event) => {
                                                    if (el.type === "jobRole5" && event.target?.checked === false) {
                                                        setValue("customJobRole", "") // Set the value of customJobRole if it exists
                                                    }
                                                }}
                                                reactHookControl={control}
                                                labelMarginBottom={true}
                                                reactHookValidations={{
                                                    required:
                                                        !validationConfig.anyTrue(watchListingOptions) && index === 0
                                                            ? validationConfig.required
                                                            : undefined,
                                                }}
                                            />
                                        ))}

                                        <InputElement
                                            name="customJobRole"
                                            type="text"
                                            reactHookControl={control}
                                            placeholder="Please specify..."
                                            className="xs:pl-[20px] sm:pl-[20px] md:pl-[40px] xl:md:pl-[40px]"
                                            disabled={!watchListingOption5}
                                            reactHookValidations={{
                                                required:
                                                    watchListingOption5 === true
                                                        ? validationConfig.required
                                                        : undefined,
                                            }}
                                        />

                                        {completed && (
                                            <ButtonElement
                                                type="submit"
                                                size="large"
                                                className={
                                                    "xs:mt-[36px] sm:mt-[36px] md:mt-[24px] lg:mt-[24px]  xl:mt-[24px] text-[#0E7090]"
                                                }
                                                viewType={"primary"}
                                            >
                                                {t("button")}
                                            </ButtonElement>
                                        )}
                                    </Container>
                                </form>
                            </div>
                        </Transition>
                    </div>
                </div>
            </div>
            <MarketingFooter currentStep={3} />
        </>
    )
}

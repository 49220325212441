import { useState } from "react"
import { useController } from "react-hook-form"

import AlertIcon from "../../assets/svgs/alert-icon.svg"
import { PrimaryText } from "./primaryText.element"

export const RadioGroupElement = (props: IRadioGroupElementProps) => {
    const { field, fieldState } = useController({
        name: props.name,
        control: props.reactHookControl,
        rules: props.reactHookValidations,
        defaultValue: props.defaultValue,
    })

    const [currentValue, setCurrentValue] = useState<string | undefined>(props.defaultValue)

    const onInputChange = async (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
        originalOnChange?: (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => void
    ) => {
        setCurrentValue(event.target?.value || currentValue)
        originalOnChange && originalOnChange(event)
    }

    return (
        <div
            className={`
        relative
        ${props.className}
    `}
        >
            <div className="flex gap-[12px]">
                {props.options.map((option, key) => (
                    <label key={key} className="flex-none flex items-center cursor-pointer">
                        <input
                            type="radio"
                            className="mr-[8px] !border-[#D0D5DD] !border-[1px]"
                            {...field}
                            onChange={(e: any) => onInputChange(e, field.onChange)}
                            checked={currentValue === option.value}
                            value={option.value}
                        />

                        <PrimaryText className="xs:text-[0.875rem] sm:text-[0.875rem] md:text-[1rem] lg:text-[1rem]">{option.label}</PrimaryText>
                    </label>
                ))}
            </div>

            {fieldState.error && (
                <div className="flex items-center mt-[8px] lg:mt-[3px] md:mt-[3px] sm:mt-[3px] xs:mt-[3px]">
                    <span className="">
                        <img src={AlertIcon} alt="alert icon" />
                    </span>

                    <PrimaryText size="xs" weight="book" className="ml-[5px]" color="text-red-600">
                        {fieldState.error.message}
                    </PrimaryText>
                </div>
            )}
        </div>
    )
}

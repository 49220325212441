import { combineReducers } from "redux"

// App Reducers
import { AccountsReducers } from "./account.reducer"
import { AuthReducer } from "./auth.reducer"
import { NotificationsReducer } from "./notifications.reducer"
import { OnBoardingUserReducer } from "./onBoarding.reducer"
import { ProductReducer } from "./product.reducer"
import { RunTimeReducer } from "./runTime.reducer"
import { SettingsReducer } from "./settings.reducer"

export const rootReducer = combineReducers({
    auth: AuthReducer,
    product: ProductReducer,
    runTime: RunTimeReducer,
    onBoarding: OnBoardingUserReducer,
    accounts: AccountsReducers,
    settings: SettingsReducer,
    notifications: NotificationsReducer,
})
export type RootState = ReturnType<typeof rootReducer>

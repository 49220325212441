import parse from "html-react-parser"
import { useTranslation } from "react-i18next"

import { ButtonElement } from "../elements/button.element"
import { PrimaryText } from "../elements/primaryText.element"
import { Stepper } from "../stepper/stepper.component"

import RightArrow from "../../assets/svgs/arrow-right.svg"
import { ENVIRONMENT_VARIABLES } from "../../config/common.config"

export const MarketingFooter = ({ currentStep }: IMarketingFootertProps) => {
    const { t } = useTranslation("marketing", {
        keyPrefix: "marketingFooter",
    })
    const handleNavigate = () => {
        window.location.href = ENVIRONMENT_VARIABLES.REACT_APP_BASE_URL
    }
    return (
        <>
            <div
                className={
                    "flex onBoardingFooter md:gap-[134px] md:flex-row lg:flex-row xl:flex-row 2xl:flex-row xs:gap-[0px] sm:gap-[0px] md:items-center md:justify-center sm:justify-between xs:justify-between xs:flex-row sm:flex-row md:ml-[60px] mt-[10px] lg:mt-[0px] md:mt-[10px] sm:mt-[20px] xs:mt-[20px]"
                }
            >
                <div className={"flex items-center"}>
                    <Stepper currentStep={currentStep} />
                </div>

                <ButtonElement
                    size={"small"}
                    viewType={"secondary"}
                    postfix={RightArrow}
                    parentClass="sm:hidden xs:hidden md:relative md:flex"
                    className={" sm:hidden xs:hidden md:flex gap-[8px]"}
                    onClick={handleNavigate}
                >
                    <PrimaryText className={"text-[#344054] text-[0.75rem] font-[300]"}>
                        {parse(t("findMoreAboutAmaizing"))}
                    </PrimaryText>
                </ButtonElement>

                <ButtonElement
                    parentClass={"xs:relative xs:flex sm:relative sm:flex md:hidden lg:hidden xl:hidden 2xl:hidden"}
                    size={"small"}
                    viewType={"only-text"}
                    postfix={RightArrow}
                    className={" md:hidden lg:hidden xl:hidden 2xl:hidden xs:flex sm:flex gap-[4px] "}
                    onClick={handleNavigate}
                >
                    <PrimaryText className={"text-[#344054] text-[0.625rem] font-[300]"}>
                        {parse(t("findMoreAboutAmaizingMobile"))}
                    </PrimaryText>
                </ButtonElement>
            </div>
        </>
    )
}

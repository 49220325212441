import Skeleton from "react-loading-skeleton"
import { AwatitingDataTrackpack } from "../../../assets/svgs/awaitingDataTrackpack.svg"
import { LinkExternalSvg } from "../../../assets/svgs/link-external.svg"
import { PrimaryText } from "../../elements/primaryText.element"

const TrackpackSkeleton = ({ asinId }: { asinId?: string }) => {
    return (
        <>
            <tr className="border-b last:border-b-0  border-gray-200">
                <td className="px-[24px] py-[17px] text-left text-gray-500 text-xs font-normal leading-[18px]">
                    <div className="flex gap-[30px] items-center ">
                        <div className="flex items-center justify-center ml-[1px]">
                            <input
                                className="relative cursor-pointer float-left appearance-none border-[0.125rem] border-solid border-secondary-500  after:absolute after:chrome:top-[1.5px] after:safari:top-[1.5px] after:chrome:left-[4.5px] after:safari:left-[5px]  checked:border-primary checked:bg-[#ecfdff]  checked:after:absolute  checked:after:block checked:after:h-[8px] checked:after:w-[4px] checked:after:rotate-45 checked:after:border-[1px] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-[#088ab2] checked:after:bg-transparent checked:after:content-['']"
                                type="checkbox"
                                value=""
                                id="checkboxDefault"
                                // checked={selectedRows.includes(row.ASINs.asinNo)}
                                // onChange={() => toggleCheckbox(row.ASINs.asinNo)}
                            />
                        </div>
                        <div className="flex gap-3">
                            <div className="w-[40px] h-[40px] rounded-[4px]">
                                <Skeleton baseColor="#D0D5DD" count={1} width={40} height={40} />
                            </div>
                            <div className="flex flex-col">
                                <div>
                                    <Skeleton baseColor="#D0D5DD" count={1} width={272} height={20} />
                                </div>
                                {asinId ? (
                                    <div
                                        // onClick={() => handleProductRedirect(row?.ASINs?.asinNo, row?.amazon_tld)}
                                        className="mt-[4px] cursor-pointer px-[4px] py-[1.25px] flex  gap-[3px] items-center rounded-[3px] bg-transparent border border-gray-400 w-fit"
                                    >
                                        <PrimaryText
                                            weight="medium"
                                            size="xs-medium"
                                            className=" text-gray-400 tracking-[0.7px] leading-[11px]"
                                        >
                                            {asinId}
                                            {/* {row.ASINs.asinNo} */}
                                        </PrimaryText>
                                        <LinkExternalSvg color={"#98A2B3"} className="mb-[0.5px]" />
                                    </div>
                                ) : (
                                    <div className="mt-[4px]">
                                        <Skeleton baseColor="#D0D5DD" count={1} width={108} height={16} />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </td>
                <td className="px-[24px] py-[17px] text-gray-500 text-xs font-normal  leading-[18px]">
                    <div className="w-fit flex items-center justify-center ">
                        <AwatitingDataTrackpack />
                    </div>
                </td>
                <td className="px-[24px] py-[17px] text-center"></td>
                <td className="px-[24px] py-[17px] text-center">
                    <div>
                        <Skeleton baseColor="#D0D5DD" count={1} width={64} height={16} />
                    </div>
                </td>
            </tr>
        </>
    )
}

export default TrackpackSkeleton

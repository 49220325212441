import { RadioGroup } from "@headlessui/react"
import { Fragment, useEffect, useState } from "react"
import { Controller, useForm } from "react-hook-form"

export const RadioGroupButton = (props: IRadioGroupButtonElement) => {
    const { control } = useForm()
    const [selectedOption, setSelectedOption] = useState(props?.options![0])

    useEffect(() => {
        if (props?.selectedOption) setSelectedOption(props?.selectedOption?.selectedOption ?? props.selectedOption)
    }, [props?.selectedOption])

    return (
        <Controller
            name={props.name || ""}
            control={control}
            defaultValue={props.defaultValue}
            render={({ field }) => (
                <RadioGroup
                    value={field?.value}
                    disabled={props?.disabled}
                    onChange={(value) => {
                        field.onChange(value)
                        if (props.onChange) {
                            props.onChange(value)
                            setSelectedOption(value)
                        }
                    }}
                    className={`flex bg-white rounded-[10px] h-[36px] overflow-hidden border border-gray-200`}
                    style={
                        {
                            // boxShadow: "0px 1px 2px 0px rgba(16, 24, 40, 0.05)",
                        }
                    }
                >
                    {props.options?.map((option: string, index: number, array) => (
                        <RadioGroup.Option key={option} value={option} as={Fragment}>
                            {() => (
                                <div
                                    className={`flex justify-center items-center  px-[16px] py-[8px] ${
                                        option === selectedOption
                                            ? `bg-gradient-to-br from-cyan-100 to-cyan-200 text-cyan-700  font-medium text-sm `
                                            : ""
                                    }
                                            ${props.disabled ? "cursor-not-allowed" : "cursor-pointer"}
                                    ${index === 0 && "h-full right-border"}
                                    ${index === array.length - 1 && " h-full left-border"}

                                    `}
                                >
                                    {/* {index > 0 && <div className={`border-r h-full border-gray-200`}></div>} */}
                                    <RadioGroup.Label
                                        className={`${
                                            props.disabled ? "cursor-not-allowed" : "cursor-pointer"
                                        } pt-[2px] capitalize ${
                                            option === selectedOption
                                                ? "font-medium text-sm leading-5 text-[#0E7090]"
                                                : "font-normal text-sm leading-5 text-gray-300"
                                        } `}
                                    >
                                        {option}
                                    </RadioGroup.Label>
                                </div>
                            )}
                        </RadioGroup.Option>
                    ))}
                </RadioGroup>
            )}
        />
    )
}

export const GreenArrow = () => {
    return (
        <>
            <svg width="8" height="8" viewBox="0 0 8 8" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M1.66699 3.99984H6.33366M6.33366 3.99984L4.00033 1.6665M6.33366 3.99984L4.00033 6.33317"
                    stroke="#088AB2"
                    stroke-width="0.8"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}

export const TrackpackTrashIcon = (props: IArrowProps) => {
    return (
        <>
            <svg
                onClick={props.onClick}
                width="16"
                height="16"
                viewBox="0 0 16 16"
                fill={props.fillColor ?? "none"}
                xmlns="http://www.w3.org/2000/svg"
                className={props.className}
            >
                <path
                    d="M2.66671 5.33106C2.55737 5.32824 2.47797 5.32191 2.40659 5.30771C1.87767 5.2025 1.4642 4.78904 1.35899 4.26012C1.33337 4.13132 1.33337 3.97644 1.33337 3.66667C1.33337 3.3569 1.33337 3.20201 1.35899 3.07321C1.4642 2.54429 1.87767 2.13083 2.40659 2.02562C2.53539 2 2.69027 2 3.00004 2H13C13.3098 2 13.4647 2 13.5935 2.02562C14.1224 2.13083 14.5359 2.54429 14.6411 3.07321C14.6667 3.20201 14.6667 3.3569 14.6667 3.66667C14.6667 3.97644 14.6667 4.13132 14.6411 4.26012C14.5359 4.78904 14.1224 5.2025 13.5935 5.30771C13.5221 5.32191 13.4427 5.32824 13.3334 5.33106M6.66671 8.66667H9.33337M2.66671 5.33333H13.3334V10.8C13.3334 11.9201 13.3334 12.4802 13.1154 12.908C12.9236 13.2843 12.6177 13.5903 12.2414 13.782C11.8135 14 11.2535 14 10.1334 14H5.86671C4.7466 14 4.18655 14 3.75873 13.782C3.3824 13.5903 3.07644 13.2843 2.88469 12.908C2.66671 12.4802 2.66671 11.9201 2.66671 10.8V5.33333Z"
                    stroke={props.color ?? "#667085"}
                    stroke-width="0.6"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}

export const SemiAutomatedLoader = () => {
    return (
        <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M28.2641 29.3334C28.6374 29.3334 28.8241 29.3334 28.9667 29.2608C29.0922 29.1968 29.1942 29.0949 29.2581 28.9694C29.3307 28.8268 29.3307 28.6401 29.3307 28.2667V14.4001C29.3307 14.0267 29.3307 13.84 29.2581 13.6974C29.1942 13.572 29.0922 13.47 28.9667 13.4061C28.8241 13.3334 28.6374 13.3334 28.2641 13.3334L25.0641 13.3334C24.6907 13.3334 24.504 13.3334 24.3614 13.4061C24.236 13.47 24.134 13.572 24.0701 13.6974C23.9974 13.84 23.9974 14.0267 23.9974 14.4001V17.6001C23.9974 17.9734 23.9974 18.1601 23.9247 18.3027C23.8608 18.4282 23.7588 18.5302 23.6334 18.5941C23.4908 18.6667 23.3041 18.6667 22.9307 18.6667H19.7307C19.3574 18.6667 19.1707 18.6667 19.0281 18.7394C18.9026 18.8033 18.8006 18.9053 18.7367 19.0308C18.6641 19.1734 18.6641 19.36 18.6641 19.7334V22.9334C18.6641 23.3068 18.6641 23.4935 18.5914 23.6361C18.5275 23.7615 18.4255 23.8635 18.3001 23.9274C18.1574 24.0001 17.9708 24.0001 17.5974 24.0001H14.3974C14.024 24.0001 13.8373 24.0001 13.6947 24.0727C13.5693 24.1367 13.4673 24.2386 13.4034 24.3641C13.3307 24.5067 13.3307 24.6934 13.3307 25.0667V28.2667C13.3307 28.6401 13.3307 28.8268 13.4034 28.9694C13.4673 29.0948 13.5693 29.1968 13.6947 29.2608C13.8373 29.3334 14.024 29.3334 14.3974 29.3334L28.2641 29.3334Z"
                stroke="#088AB2"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M13.3307 9.06675C13.3307 8.69338 13.3307 8.5067 13.4034 8.36409C13.4673 8.23865 13.5693 8.13666 13.6947 8.07274C13.8373 8.00008 14.024 8.00008 14.3974 8.00008H17.5974C17.9708 8.00008 18.1574 8.00008 18.3001 8.07274C18.4255 8.13666 18.5275 8.23865 18.5914 8.36409C18.6641 8.5067 18.6641 8.69338 18.6641 9.06675V12.2667C18.6641 12.6401 18.6641 12.8268 18.5914 12.9694C18.5275 13.0949 18.4255 13.1968 18.3001 13.2608C18.1574 13.3334 17.9708 13.3334 17.5974 13.3334H14.3974C14.024 13.3334 13.8373 13.3334 13.6947 13.2608C13.5693 13.1968 13.4673 13.0949 13.4034 12.9694C13.3307 12.8268 13.3307 12.6401 13.3307 12.2667V9.06675Z"
                stroke="#088AB2"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M3.9974 17.0667C3.9974 16.6934 3.9974 16.5067 4.07006 16.3641C4.13397 16.2386 4.23596 16.1367 4.3614 16.0727C4.50401 16.0001 4.69069 16.0001 5.06406 16.0001H8.26406C8.63743 16.0001 8.82412 16.0001 8.96672 16.0727C9.09216 16.1367 9.19415 16.2386 9.25807 16.3641C9.33073 16.5067 9.33073 16.6934 9.33073 17.0667V20.2667C9.33073 20.6401 9.33073 20.8268 9.25807 20.9694C9.19415 21.0949 9.09216 21.1968 8.96672 21.2608C8.82412 21.3334 8.63743 21.3334 8.26406 21.3334H5.06406C4.69069 21.3334 4.50401 21.3334 4.3614 21.2608C4.23596 21.1968 4.13397 21.0949 4.07006 20.9694C3.9974 20.8268 3.9974 20.6401 3.9974 20.2667V17.0667Z"
                stroke="#088AB2"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M2.66406 3.73341C2.66406 3.36005 2.66406 3.17336 2.73672 3.03075C2.80064 2.90531 2.90263 2.80333 3.02807 2.73941C3.17068 2.66675 3.35736 2.66675 3.73073 2.66675H6.93073C7.3041 2.66675 7.49078 2.66675 7.63339 2.73941C7.75883 2.80333 7.86082 2.90531 7.92473 3.03075C7.9974 3.17336 7.9974 3.36005 7.9974 3.73341V6.93341C7.9974 7.30678 7.9974 7.49347 7.92473 7.63608C7.86082 7.76152 7.75883 7.8635 7.63339 7.92742C7.49078 8.00008 7.3041 8.00008 6.93073 8.00008H3.73073C3.35736 8.00008 3.17068 8.00008 3.02807 7.92742C2.90263 7.8635 2.80064 7.76152 2.73672 7.63608C2.66406 7.49347 2.66406 7.30678 2.66406 6.93341V3.73341Z"
                stroke="#088AB2"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

import { twMerge } from "tailwind-merge"
import { GraphTooltipIcon } from "../../assets/svgs/GraphTooltipIcon.svg"
import "../../styles/tooltip.element.css"

export const TooltipElement = (props: ITooltipElementProps) => {
    const inlineStyleVar: any = {
        "--color": props.backgroundColor,
        "--tipOpacity": props.hideTip ? 0 : 1,
        "--boxShadow": props.showBoxShadow === true ? "0 10px 10px rgba(0, 0, 0, 0.1)" : "none",
    }

    return (
        <div className={twMerge("wrapper", props.className)} ref={props.hookElement}>
            <div className="icon">
                {props.show && <GraphTooltipIcon color={props.backgroundColor} />}
                <div className={`tooltip ${props.show && "show"} ${props.customClass} `} style={inlineStyleVar}>
                    {props.children}
                </div>
            </div>
        </div>
    )
}

export const NotificationHalfRedIcon = () => {
    return (
        <>
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                <circle cx="10" cy="10" r="3.5" fill="white" stroke="#F04438" />
                <path d="M14 10C14 12.2091 12.2091 14 10 14V6C12.2091 6 14 7.79086 14 10Z" fill="#F04438" />
            </svg>
        </>
    )
}

import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../../actions/runTime.action"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"

interface QuickTourContentProps {
    onClose: () => void
    displayCloseIcon: boolean
}

const TrackpackHubQuickTourContent: React.FC<QuickTourContentProps> = ({ onClose, displayCloseIcon }) => {
    const dispatch = useDispatch()
    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openTrackpackHubTourModal({
                open: true,
                stepNumber: "one",
            })
        )
        onClose()
    }

    return (
        <div className="rounded-md p-[12px] bg-[#06AED4]">
            <PrimaryText weight="book" size="xs" className="text-white mb-[24px]">
                Let me walk you through all of the features of my{" "}
                <PrimaryText weight="bold" size="xs" className="inline">
                    Trackpack Hub.
                </PrimaryText>
            </PrimaryText>

            <div className="flex justify-end space-x-[8px]">
                {displayCloseIcon && (
                    <ButtonElement
                        size="medium"
                        onClick={onClose}
                        textClass="!font-[500] !text-[12px]"
                        className="w-[64px] h-[26px] text-white !px-[4px] bg-none !font-[500] !text-[12px]  !py-[8px] border-none hover:!bg-none"
                    >
                        No thanks
                    </ButtonElement>
                )}
                <ButtonElement
                    size="medium"
                    textClass="!font-[500] !text-[12px]"
                    className="w-[59px] h-[26px] bg-transparent text-white hover:!bg-none !px-[4px] !font-[500] !text-[12px] rounded-[6px] !py-[8px] border border-white"
                    onClick={handleOpen}
                >
                    Let’s go
                </ButtonElement>
            </div>
        </div>
    )
}

export default TrackpackHubQuickTourContent

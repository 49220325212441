import { EmblaCarouselType } from "embla-carousel"
import useEmblaCarousel from "embla-carousel-react"
import { PropsWithChildren, useCallback, useEffect, useState } from "react"

import { twMerge } from "tailwind-merge"
import { BackArrowIcon } from "../../assets/svgs/BackArrowIcon.svg"
import { ForwardArrowIcon } from "../../assets/svgs/ForwardArrowIcon.svg"
import { PreviousArrow } from "../../assets/svgs/previousArrow.svg"
import { RightArrow } from "../../assets/svgs/rightArrow.svg"
import "../../styles/slider.element.css"

function Slider({ children, className, isLargeIcons, type, color }: ISliderProps) {
    const [emblaRef, emblaApi] = useEmblaCarousel({ slidesToScroll: "auto", align: "start", loop: true })
    const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(emblaApi)
    const { onPrevButtonClick, onNextButtonClick } = usePrevNextButtons(emblaApi)
    const inlineStyleVar: any = {
        "--color": color?.active || "red",
    }

    return (
        <section className="embla" ref={emblaRef} style={inlineStyleVar}>
            <div className={twMerge("embla__container", className)}>{children}</div>

            {!isLargeIcons && scrollSnaps.length > 1 && (
                <>
                    <div className="embla__controls pl-[12px]">
                        <div className="flex gap-[2px] items-center">
                            <div className={`bg-slate-600 rounded-[3px] cursor-pointer`} onClick={onPrevButtonClick}>
                                <PreviousArrow />
                            </div>
                            <div className={`bg-slate-600 rounded-[3px] cursor-pointer`} onClick={onNextButtonClick}>
                                <RightArrow />
                            </div>
                        </div>
                        <div className="embla__dots">
                            {scrollSnaps.map((_, index) => (
                                <DotButton
                                    key={index}
                                    onClick={() => onDotButtonClick(index)}
                                    className={"embla__dot".concat(
                                        index === selectedIndex ? " embla__dot--selected" : ""
                                    )}
                                />
                            ))}
                        </div>
                    </div>
                </>
            )}

            {isLargeIcons && (
                <div className="flex items-center justify-between mt-[24px] w-full">
                    <button
                        onClick={onPrevButtonClick}
                        type="button"
                        className={`w-[32px] h-[32px] rounded-[8px] p-[8px] bg-[${color?.active}]`}
                    >
                        <BackArrowIcon color={color?.default} />
                    </button>
                    <p className="text-[#344054] text-[12px] font-[300] leading-[18px]">
                        {type} <span> {selectedIndex + 1} </span>of <span>{scrollSnaps?.length}</span>
                    </p>
                    <button
                        onClick={onNextButtonClick}
                        type="button"
                        className={`w-[32px] h-[32px] rounded-[8px] p-[8px] bg-[${color?.active}]`}
                    >
                        <ForwardArrowIcon color={color?.default} />
                    </button>
                </div>
            )}
        </section>
    )
}

type UseDotButtonType = {
    selectedIndex: number
    scrollSnaps: number[]
    onDotButtonClick: (index: number) => void
}

export const useDotButton = (emblaApi: EmblaCarouselType | undefined): UseDotButtonType => {
    const [selectedIndex, setSelectedIndex] = useState(0)
    const [scrollSnaps, setScrollSnaps] = useState<number[]>([])

    const onDotButtonClick = useCallback(
        (index: number) => {
            if (!emblaApi) return
            emblaApi.scrollTo(index)
        },
        [emblaApi]
    )

    const onInit = useCallback((emblaApi: EmblaCarouselType) => {
        setScrollSnaps(emblaApi.scrollSnapList())
    }, [])

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setSelectedIndex(emblaApi.selectedScrollSnap())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onInit(emblaApi)
        onSelect(emblaApi)
        emblaApi.on("reInit", onInit)
        emblaApi.on("reInit", onSelect)
        emblaApi.on("select", onSelect)
    }, [emblaApi, onInit, onSelect])

    return {
        selectedIndex,
        scrollSnaps,
        onDotButtonClick,
    }
}

type PropType = PropsWithChildren<
    React.DetailedHTMLProps<React.ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>
>

export const DotButton: React.FC<PropType> = (props) => {
    const { children, ...restProps } = props

    return (
        <button type="button" {...restProps}>
            {children}
        </button>
    )
}

type UsePrevNextButtonsType = {
    prevBtnDisabled: boolean
    nextBtnDisabled: boolean
    onPrevButtonClick: () => void
    onNextButtonClick: () => void
}

export const usePrevNextButtons = (emblaApi: EmblaCarouselType | undefined): UsePrevNextButtonsType => {
    const [prevBtnDisabled, setPrevBtnDisabled] = useState(true)
    const [nextBtnDisabled, setNextBtnDisabled] = useState(true)

    const onPrevButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollPrev()
    }, [emblaApi])

    const onNextButtonClick = useCallback(() => {
        if (!emblaApi) return
        emblaApi.scrollNext()
    }, [emblaApi])

    const onSelect = useCallback((emblaApi: EmblaCarouselType) => {
        setPrevBtnDisabled(!emblaApi.canScrollPrev())
        setNextBtnDisabled(!emblaApi.canScrollNext())
    }, [])

    useEffect(() => {
        if (!emblaApi) return

        onSelect(emblaApi)
        emblaApi.on("reInit", onSelect)
        emblaApi.on("select", onSelect)
    }, [emblaApi, onSelect])

    return {
        prevBtnDisabled,
        nextBtnDisabled,
        onPrevButtonClick,
        onNextButtonClick,
    }
}

export default Slider

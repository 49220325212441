export const AwatitingDataTrackpack = (props: IArrowProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="0.5" y="0.5" width="15" height="15" rx="2.5" fill="#EAECF0" stroke="#667085" />
                <path
                    d="M5 10.5V12.5M11 6V12.5M8 8.5V12.5"
                    stroke="#667085"
                    stroke-width="1.2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M3.5 6L5 7.5M5 7.5L6.5 6M5 7.5V4"
                    stroke="#667085"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}

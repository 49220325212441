export const EmailVerify = () => {
    return (
        <svg width="56" height="56" viewBox="0 0 56 56" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="56" height="56" rx="12" fill="url(#paint0_linear_2587_41308)"/>
        <path d="M22.7502 27.9999L26.2502 31.4999L33.2502 24.4999M39.6668 27.9999C39.6668 34.4432 34.4435 39.6666 28.0002 39.6666C21.5568 39.6666 16.3335 34.4432 16.3335 27.9999C16.3335 21.5566 21.5568 16.3333 28.0002 16.3333C34.4435 16.3333 39.6668 21.5566 39.6668 27.9999Z" stroke="white" stroke-width="1.4" stroke-linecap="round" stroke-linejoin="round"/>
        <defs>
        <linearGradient id="paint0_linear_2587_41308" x1="0" y1="0" x2="67.1038" y2="34.2366" gradientUnits="userSpaceOnUse">
        <stop stop-color="#22CCEE"/>
        <stop offset="1" stop-color="#06AED4"/>
        </linearGradient>
        </defs>
        </svg>
        
    )
}





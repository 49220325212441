import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"

import { LinkExternalSvg } from "../../../assets/svgs/link-external.svg"
import { SellerInfoIconTooltip } from "../../../assets/svgs/tooltips/popups/seller-info.svg"

export const SellerInfo = (props: ITooltipData) => {
    const { t } = useTranslation("tooltip", {
        keyPrefix: "sellerInfo",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })

    const { active, history, setActive, total } = useProductHistory(props.data, "seller_info")
    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )

    const handleSellerRedirect = (details: { id: string; link: string; name: string }) => {
        if (details.link) {
            window.open(details.link, "_blank")
        }
    }
    return (
        <>
            <div
                className={`w-[216px] p-[12px] bg-white rounded-2xl`}
                style={{ border: `1px solid ${props.color?.default}` }}
            >
                <InnerTooltipHeader
                    title={t("Seller Info")}
                    linkText={tCommon("Go to ASIN")}
                    timestamp={tCommon("timestamp", {
                        timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                    active={active}
                    total={total}
                    setActive={setActive}
                    color={props.color?.active}
                    id={props.id}
                    textColor={props.color?.default}
                    icon={<SellerInfoIconTooltip stroke={props.color?.default} />}
                />

                <div className="mt-[32px]">
                    {history.past && history.present ? (
                        <div className="w-full flex-col gap-[8px] inline-flex">
                            <div
                                onClick={() => handleSellerRedirect(history?.past?.value)}
                                className={`cursor-pointer w-full p-2 bg-[${props.color?.bgHundred}] rounded-lg flex-col flex`}
                            >
                                <PrimaryText
                                    size={"xs-small"}
                                    color={`text-[${props.color?.default}]`}
                                    weight={"medium"}
                                    className="text-[12px] leading-[10px] mx-[2px] mt-[2px]  mb-[6px] tracking-[0.96px]"
                                >
                                    {tCommon("was")}
                                </PrimaryText>

                                <div className="w-full cursor-pointer justify-between items-center gap-2.5 inline-flex">
                                    <PrimaryText
                                        size={"sm-medium"}
                                        weight={"light"}
                                        className=" w-full text-gray-600 mx-[2px] leading-[18px]"
                                    >
                                        {history?.past?.value?.name ?? "N/A"}
                                    </PrimaryText>
                                    <div className=" relative mr-[4px]">
                                        <LinkExternalSvg color={props.color?.default} />
                                    </div>
                                </div>
                            </div>
                            <div
                                onClick={() => handleSellerRedirect(history?.present?.value)}
                                className={`cursor-pointer w-full p-2 bg-[${props.color?.bgHundred}] rounded-lg flex-col flex`}
                            >
                                <PrimaryText
                                    size={"xs-small"}
                                    color={`text-[${props.color?.default}]`}
                                    weight={"medium"}
                                    className="text-[12px] leading-[10px] mx-[2px] mt-[2px]  mb-[6px] tracking-[0.96px]"
                                >
                                    {tCommon("now")}
                                </PrimaryText>

                                <div className="w-full cursor-pointer justify-start items-center gap-2.5 inline-flex">
                                    <PrimaryText
                                        size={"sm-medium"}
                                        weight={"light"}
                                        className=" w-full text-gray-600 mx-[2px] leading-[18px]"
                                    >
                                        {history?.present?.value?.name ?? "N/A"}
                                    </PrimaryText>
                                    <div className="w-3 h-3 relative mr-[4px]">
                                        <LinkExternalSvg color={props.color?.default} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className="w-full flex-col justify-start items-start gap-2 inline-flex">
                            <div
                                onClick={() => handleSellerRedirect(history?.present?.value)}
                                className={`cursor-pointer w-full p-2 bg-[${props.color?.bgHundred}] rounded-lg flex-col flex`}
                            >
                                <PrimaryText
                                    size={"xs-small"}
                                    color={`text-[${props.color?.default}]`}
                                    weight={"medium"}
                                    className="text-[12px] leading-[10px] mx-[2px] mt-[2px] tracking-[0.96px] mb-[6px]"
                                >
                                    {tCommon("now")}
                                </PrimaryText>
                                <div className="w-full  justify-start items-center gap-2.5 inline-flex">
                                    <PrimaryText
                                        size={"sm-medium"}
                                        weight={"light"}
                                        className=" w-full text-gray-600 leading-[18px] mx-[2px]"
                                    >
                                        {history?.present?.value?.name ?? "N/A"}
                                    </PrimaryText>

                                    <div className=" relative mr-[4px]">
                                        <LinkExternalSvg color={props.color?.default} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* {isSuccessWarning && (
                    <div className="w-full  pt-[8px]">
                        <ErrorTooltipAlert />
                    </div>
                )}

                {isWarning && (
                    <div className="w-full  pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
        </>
    )
}

import { useMemo, useState } from "react"

export const useProductHistory = (product: IProductData, key: keyof IProductData) => {
    const [active, setActive] = useState<number>(0)

    const listOfData = product[key] as IProductHistoryBase<any>[]

    const total = useMemo<number>(() => {
        if (listOfData?.length) {
            const total = listOfData.length - 1

            setActive(total)
            return total
        }
        return 1
    }, [listOfData])
    const history = useMemo(() => {
        let past = undefined
        let present = undefined
        if (listOfData) {
            if (key === "price") {
                if (listOfData[active]) {
                    present = { ...listOfData[active], value: Number(listOfData[active].value).toFixed(2) }
                }
                if (listOfData[active - 1]) {
                    past = { ...listOfData[active - 1], value: Number(listOfData[active - 1].value).toFixed(2) }
                }
            } else {
                present = listOfData![active]
                past = listOfData![active - 1]
            }
        }

        return { present, past }
    }, [active, listOfData])

    return { history, total, active, setActive }
}

import { useDispatch, useSelector } from "react-redux"

import { RunTimeStateSelector } from "../../../../../selectors/RunTimeStateSelector"

import { useEffect } from "react"
import { RuntimeActionCreators } from "../../../../../actions/runTime.action"
import PTImage from "../../../../../assets/images/PT_QT_Step_1.png"
import PTStep10Bg from "../../../../../assets/images/PT_QT_Step_10.png"
import PTStep11Bg from "../../../../../assets/images/PT_QT_Step_11.png"
import PTStep12Bg from "../../../../../assets/images/PT_QT_Step_12.png"
import PTStep2Bg from "../../../../../assets/images/PT_QT_Step_2.png"
import PTStep3Bg from "../../../../../assets/images/PT_QT_Step_3.png"
import PTStep4Bg from "../../../../../assets/images/PT_QT_Step_4.png"
import PTStep5Bg from "../../../../../assets/images/PT_QT_Step_5.png"
import PTStep6Bg from "../../../../../assets/images/PT_QT_Step_6.png"
import PTStep7Bg from "../../../../../assets/images/PT_QT_Step_7.png"
import PTStep8Bg from "../../../../../assets/images/PT_QT_Step_8.png"
import PTStep9Bg from "../../../../../assets/images/PT_QT_Step_9.png"
import { NotificationIcon } from "../../../../../assets/svgs/notificationIcon.svg"
import { AuthStateSelector } from "../../../../../selectors/authState.selector"
import { ProductStateSelector } from "../../../../../selectors/product.selector"
import PtQuickTourStepEight from "./ptQuickTourStepEight.component"
import PtQuickTourStepEleven from "./ptQuickTourStepEleven.component"
import PtQuickTourStepFive from "./ptQuickTourStepFive.component"
import PtQuickTourStepFour from "./ptQuickTourStepFour.component"
import PtQuickTourStepNine from "./ptQuickTourStepNine.component"
import PtQuickTourStepOne from "./ptQuickTourStepOne.component"
import PtQuickTourStepSeven from "./ptQuickTourStepSeven.component"
import PtQuickTourStepSix from "./ptQuickTourStepSix.component"
import PtQuickTourStepTen from "./ptQuickTourStepTen.component"
import PtQuickTourStepThree from "./ptQuickTourStepThree.component"
import PtQuickTourStepTwelve from "./ptQuickTourStepTwelve.component"
import PtQuickTourStepTwo from "./ptQuickTourStepTwo.component"

const PerformanceTrackerTourOutlet = () => {
    const { performanceTrackerQuickTour } = useSelector(RunTimeStateSelector)
    const { getAccessLevel } = useSelector(AuthStateSelector)
    const { selectedDropdownList } = useSelector(ProductStateSelector)

    const dispatch = useDispatch()

    const handleNextStep = (stepNumber: string) => {
        dispatch(
            RuntimeActionCreators.openPerformanceTourModal({
                open: true,
                stepNumber: stepNumber,
            })
        )
    }

    useEffect(() => {
        const images = [
            PTImage,
            PTStep2Bg,
            PTStep3Bg,
            PTStep4Bg,
            PTStep5Bg,
            PTStep6Bg,
            PTStep7Bg,
            PTStep8Bg,
            PTStep9Bg,
            PTStep10Bg,
            PTStep11Bg,
            PTStep12Bg,
        ]

        images.forEach((src) => {
            const img = new Image()
            img.src = src
        })
    }, [])

    const totalStepsData = [
        {
            stepNumber: "one",
            component: PtQuickTourStepOne,
            headerText: "Performance Tracker",
            image: PTImage,
        },
        { stepNumber: "two", component: PtQuickTourStepTwo, headerText: "Trackpacks" },
        { stepNumber: "three", component: PtQuickTourStepThree, headerText: "ASIN cards" },
        { stepNumber: "four", component: PtQuickTourStepFour, headerText: "Graph view" },
        { stepNumber: "five", component: PtQuickTourStepFive, headerText: "Changes" },
        { stepNumber: "six", component: PtQuickTourStepSix, headerText: "Viewing your data" },
        { stepNumber: "seven", component: PtQuickTourStepSeven, headerText: "Change icons" },
        { stepNumber: "eight", component: PtQuickTourStepEight, headerText: "Time" },
        { stepNumber: "nine", component: PtQuickTourStepNine, headerText: "Tracking status" },
        { stepNumber: "ten", component: PtQuickTourStepTen, headerText: "Table view" },
        { stepNumber: "eleven", component: PtQuickTourStepEleven, headerText: "Log view" },
        { stepNumber: "twelve", component: PtQuickTourStepTwelve, headerText: "High alert" },
    ]

    const filteredSteps = totalStepsData.filter((step) => {
        if (
            step.stepNumber === "nine" &&
            selectedDropdownList?.trackpacks?.length === 0 &&
            getAccessLevel &&
            getAccessLevel?.user_settings?.initial_qt_completed === false
        ) {
            return false
        }
        return true
    })

    const currentStepIndex = filteredSteps.findIndex(
        (step) => step.stepNumber === performanceTrackerQuickTour?.stepNumber
    )
    const currentStepData = filteredSteps[currentStepIndex]
    const totalSteps = filteredSteps.length

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closePerformanceTourModal())
    }

    const getModalBody = () => {
        if (!currentStepData) return null

        const CurrentComponent = currentStepData.component
        const handleCloseProp =
            selectedDropdownList?.trackpacks?.length > 0 ||
            performanceTrackerQuickTour?.stepNumber === "twelve" ||
            (performanceTrackerQuickTour?.stepNumber !== "twelve" &&
                getAccessLevel?.user_settings?.initial_qt_completed === true)
                ? { handleCloseDialog }
                : {}

        return (
            <CurrentComponent
                icon={<NotificationIcon />}
                headerText={currentStepData.headerText}
                handleNext={() => handleNextStep(filteredSteps[currentStepIndex + 1]?.stepNumber || "")}
                currentStep={currentStepIndex + 1}
                totalSteps={totalSteps}
                image={currentStepData.image}
                {...handleCloseProp}
            />
        )
    }

    const getImageAndCss = () => {
        switch (performanceTrackerQuickTour?.stepNumber) {
            case "two":
                return {
                    image: PTStep2Bg,
                    imageCss:
                        "mdpt:w-[228px] mdpt:left-[56px] mdpt:top-[76px] xlpt:w-[340px] xlpt:left-[90px] xlpt:top-[120px]",
                    modalCss:
                        "mdpt:top-[116px] mdpt:left-[295px] xlpt:top-[145px] xlpt:left-[440px] custom_arrow step2",
                }
            case "three":
                return {
                    image: PTStep3Bg,
                    imageCss:
                        "mdpt:w-[228px] mdpt:left-[56px] mdpt:top-[166px] xlpt:w-[340px] xlpt:left-[86px] xlpt:top-[250px]",
                    modalCss:
                        " mdpt:top-[144px] mdpt:left-[295px] xlpt:top-[166px] xlpt:left-[440px] custom_arrow step3 xlpt:translate-x-[0%] xlpt:translate-y-[0%]",
                }
            case "four":
                return {
                    image: PTStep4Bg,
                    imageCss:
                        " mdpt:w-[681.33px] mdpt:right-[6px] mdpt:top-[78px] xlpt:w-[1020px] xlpt:right-[10px] xlpt:top-[118px]",
                    modalCss:
                        "mdpt:top-[232px] mdpt:right-[694px] xlpt:top-[395px] xlpt:right-[1043px] right_custom_arrow step4 translate-x-[0%] translate-y-[0%]",
                }
            case "five":
                return {
                    image: PTStep5Bg,
                    imageCss:
                        "mdpt:w-[681px] mdpt:right-[6px] mdpt:top-[78px] xlpt:w-[1020px] xlpt:right-[10px] xlpt:top-[118px]",
                    modalCss:
                        "mdpt:top-[330px] mdpt:right-[332px] xlpt:top-[498px] xlpt:right-[526px] right_custom_arrow step5 translate-x-[0%] translate-y-[0%]",
                }
            case "six":
                return {
                    image: PTStep6Bg,
                    imageCss:
                        "mdpt:w-[660px] mdpt:right-[18px] mdpt:top-[86px] xlpt:w-[984px]  xlpt:right-[28px] xlpt:top-[132px]",
                    modalCss:
                        "mdpt:top-[84px] mdpt:right-[685px] xlpt:top-[135px] xlpt:right-[1025px] right_custom_arrow step6 translate-x-[0%] translate-y-[0%]",
                }
            case "seven":
                return {
                    image: PTStep7Bg,
                    imageCss:
                        "mdpt:w-[656px] mdpt:right-[18px] mdpt:top-[86px] xlpt:w-[984px] xlpt:right-[28px] xlpt:top-[130px]",
                    modalCss:
                        " mdpt:top-[74px] mdpt:right-[580px] xlpt:top-[128px] xlpt:right-[872px]  right_custom_arrow step7 translate-x-[0%] translate-y-[0%]",
                }
            case "eight":
                return {
                    image: PTStep8Bg,
                    imageCss:
                        "mdpt:w-[656px] mdpt:right-[18px] mdpt:top-[86px] xlpt:w-[984px] xlpt:right-[28px] xlpt:top-[130px]",
                    modalCss:
                        "mdpt:top-[80px] mdpt:right-[430px] xlpt:top-[132px] xlpt:right-[648px]  right_custom_arrow step8 translate-x-[0%] translate-y-[0%]",
                }
            case "nine":
                return {
                    image: PTStep9Bg,
                    imageCss:
                        "mdpt:right-[28px] mdpt:top-[90px] mdpt:w-[872px] xlpt:left-[104px] xlpt:top-[130px] xlpt:w-[1308px]",
                    modalCss:
                        "mdpt:max-w-[200px] mdpt:top-[270px] mdpt:right-[95px]  xlpt:max-w-[249px] xlpt:top-[400px] xlpt:right-[280px]  right_custom_arrow step9 translate-x-[0%] translate-y-[0%]",
                }
            case "ten":
                return {
                    image: PTStep10Bg,
                    imageCss:
                        "mdpt:w-[662px] mdpt:right-[12px] mdpt:top-[86px] xlpt:w-[984px] xlpt:right-[18px] xlpt:top-[130px]",
                    modalCss:
                        " mdpt:top-[144px] mdpt:right-[48px] xlpt:top-[216px] xlpt:right-[84px]  right_custom_arrow step10 translate-x-[0%] translate-y-[0%]",
                }

            case "eleven":
                return {
                    image: PTStep11Bg,
                    imageCss:
                        "mdpt:w-[662px] mdpt:right-[13px] mdpt:top-[86px] xlpt:w-[984px] xlpt:right-[18px] xlpt:top-[130px]",
                    modalCss:
                        "mdpt:max-w-[200px] mdpt:top-[144px] mdpt:right-[8px] xlpt:max-w-[249px] xlpt:top-[209px] xlpt:right-[20px]  right_custom_arrow step11 translate-x-[0%] translate-y-[0%]",
                }
            case "twelve":
                return {
                    image: PTStep12Bg,
                    imageCss:
                        "mdpt:w-[281px] mdpt:right-[6px] mdpt:top-[35px] xlpt:w-[424px] xlpt:right-[11px] xlpt:top-[54px]",
                    modalCss:
                        "mdpt:max-w-[200px] mdpt:top-[120px] mdpt:right-[294px]  xlpt:max-w-[249px] xlpt:top-[186px] xlpt:right-[448px]  right_custom_arrow step12 translate-x-[0%] translate-y-[0%]",
                }

            default:
                return {
                    image: "",
                    imageCss: "",
                    modalCss: "",
                }
        }
    }

    const modalClassNames =
        performanceTrackerQuickTour?.stepNumber !== "one"
            ? `mdpt:max-w-[198px] xlpt:max-w-[248px] w-full bg-[#088AB2] mdpt:rounded-[12.8px] xlpt:rounded-[16px] mdpt:pt-[10px] mdpt:pl-[10px] mdpt:pr-[8px] mdpt:pb-[8.8px] xlpt:p-[12px] absolute ${
                  getImageAndCss().modalCss
              }`
            : ""

    return (
        <>
            {performanceTrackerQuickTour?.open ? (
                <>
                    <div className="relative bg-gray-900 h-screen w-screen">
                        {getImageAndCss().image && (
                            <img
                                src={getImageAndCss().image}
                                alt="PT Step"
                                className={`absolute ${getImageAndCss().imageCss}`}
                            />
                        )}
                        <div
                            className={modalClassNames}
                            style={{
                                boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                            }}
                        >
                            {getModalBody()}
                        </div>
                    </div>
                </>
            ) : null}
        </>
    )
}

export default PerformanceTrackerTourOutlet

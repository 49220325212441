import { FunctionComponent } from "react"
import { ROUTES_CONFIG } from "../../config/routes.config"

export const Stepper: FunctionComponent<IStepperProps> = (props) => {
    // const navigate = useNavigate()
    // const handleNavigation = (e: React.MouseEvent<HTMLLIElement, MouseEvent>, url: string) => {
    //     e.preventDefault() // Prevent the default behavior of the event
    //     navigate(url)
    // }
    const totalSteps = (totalSteps: string[]) => {
        const steps = []
        for (let i = 1; i <= totalSteps.length; i++) {
            const isActive = i === props.currentStep
            steps.push(
                <li
                    // onClick={(e) =>  handleNavigation(e, totalSteps[i -1])}
                    key={i}
                    className={
                        // "cursor-pointer " +
                        `xl:w-2.5 xl:h-2.5 lg:w-2.5 lg:h-2.5 md:w-2.5 md:h-2.5 sm:w-[0.375rem] sm:h-[0.375rem] xs:w-[0.375rem] xs:h-[0.375rem] relative rounded-md ${
                            isActive ? "bg-cyan-300" : "mix-blend-multiply bg-gray-200"
                        }`
                    }
                />
            )
        }
        return steps
    }

    return (
        <ul className="flex items-center  xl:gap-4 lg:gap-4 md:gap-4 sx::gap-[7px] xs:gap-[7px]">
            {totalSteps([
                ROUTES_CONFIG.marketing,
                ROUTES_CONFIG.emailChat,
                ROUTES_CONFIG.jobRoleForm,
                ROUTES_CONFIG.listingManageForm,
                ROUTES_CONFIG.trackingChatForm,
                ROUTES_CONFIG.searchCompetitorChat,
                ROUTES_CONFIG.asinTrackerChat,
            ])}
        </ul>
    )
}

import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"

import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const ThQuickTourStepFour: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    icon,
    handleCloseDialog,
    handleNext,
    image,
}) => {
    return (
        <>
            {/* <div className="relative bg-gray-900 h-screen" id="trackpack-hub">
                <img src={image} alt="PT Step One" className="  w-[984px] absolute left-[428px] top-[136px]" />

                <div
                    className=" mx-auto max-w-[248px] bg-[#088AB2] rounded-[16px] p-[12px] absolute top-[170px] left-[158px] right_custom_arrow step4"
                    style={{
                        boxShadow: "0px 8px 8px -4px #10182808, 0px 20px 24px -4px #10182814",
                    }}
                > */}
            {/* Header */}
            <div className="flex justify-between items-center xlpt:mb-[26px] mdpt:mb-[20px]">
                <div className="flex items-center space-x-2">
                    <img
                        src={Icon}
                        className="xlpt:h-[26px] xlpt:w-[28px] mdpt:h-[20.8px] mdpt:w-[22.4px]"
                        alt="icon"
                    />
                    <PrimaryText
                        size="small"
                        weight="medium"
                        className="text-white mdpt:text-[12px] mdpt:leading-[12px] xlpt:text-[14px] xlpt:leading-[14px]"
                    >
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mdpt:mt-[10px] mdpt:p-[9.6px] xlpt:mt-[12px] xlpt:p-[12px] bg-[#06AED4] mdpt:rounded-[6.4px] xlpt:rounded-[8px]">
                <PrimaryText
                    weight="book"
                    size="xs"
                    className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px]"
                >
                    You can also choose to Archive specific ASINs within a Trackpack using the checkboxes to the left of
                    each ASIN in the list. These ASINs remain linked to their original Trackpack, which can be viewed
                    using the ‘Tracking’ and ‘Archived’ toggle menu above the Trackpack.
                </PrimaryText>

                {/* Footer */}
                <div className="flex justify-between items-center mdpt:mt-[20px] xlpt:mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white mdpt:text-[8px] xlpt:text-[10px]">
                        4 / 9
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="mdpt:mt-[2px] xlpt:mt-[0px] text-[500] !mdpt:mt-[1px] !xlpt:mt-[0px] mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] text-white"
                        className="mdpt:!w-[34.8px] mdpt:!h-[20.4px] xlpt:!w-[42px] xlpt:!h-[26px] mdpt:px-[6.4px] mdpt:py-[3.2px]  xlpt:px-[8px] xlpt:py-[4px] !bg-none text-white mdpt:rounded-[4.8px] xlpt:rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
            {/* </div>
            </div> */}
        </>
    )
}

export default ThQuickTourStepFour

import { getJwtToken } from "./util.helper"

export class HttpHelper {
    /**
     * Send request to server
     *
     * @param url
     * @param data
     * @param method
     * @param headers
     * @param appendApiUrl
     */
    static async sendRequest(
        url: string,
        data: any = {},
        method:
            | "GET"
            | "POST"
            | "DELETE"
            | "PUT"
            | "PATCH"
            | "get"
            | "post"
            | "delete"
            | "put" = "GET",
        headers = {},
        appendApiUrl: boolean = false
    ): Promise<any> {
        method = (method as any).toUpperCase()

        if (appendApiUrl && url[0] !== "/") {
            url += "/"
        }

        const params: any = {
            method,
            headers: Object.assign({}, headers),
        }

        const token = getJwtToken()

        if (typeof window !== "undefined" && token) {
            params.headers["Authorization"] = `Bearer ${token}`
        }

        if (method === "GET") {
            url = HttpHelper.appendQueryParams(url, data)
        } else {
            params.body = JSON.stringify(data)
            params.headers["Content-Type"] = "application/json"
        }

        let result

        try {
            result = await fetch(
                (appendApiUrl ? process.env.REACT_APP_API_URL : "") + url,
                params
            )

            return result
        } catch (e) {
            console.log("Failed to access API")
        }
    }

    /**
     * Append query param to url and get new url
     *
     * @param url
     * @param data
     */
    private static appendQueryParams(url: string, data: any = {}): string {
        const keys = Object.keys(data)

        if (keys.length) {
            url += "?"
        }

        const params = [] as string[]
        keys.forEach(key => {
            if (typeof data[key] !== "undefined") {
                params.push(`${key}=${encodeURIComponent(data[key])}`)
            }
        })

        return url + params.join("&")
    }
    static async validateResponse<T>(result: { status: number; statusText: string; json: () => T | PromiseLike<T> }): Promise<T | { message: string }> {
        if (result.status === 404) {
            return { message: result.statusText }
        }

        const response: T = await result.json()
        return response
    }
}

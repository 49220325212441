export const EyeSvg = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 17" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1.61342 9.37063C1.52262 9.22688 1.47723 9.15499 1.45182 9.04413C1.43273 8.96085 1.43273 8.82952 1.45182 8.74624C1.47723 8.63537 1.52262 8.56349 1.61341 8.41973C2.36369 7.23174 4.59693 4.22852 8.00027 4.22852C11.4036 4.22852 13.6369 7.23174 14.3871 8.41973C14.4779 8.56349 14.5233 8.63537 14.5487 8.74624C14.5678 8.82952 14.5678 8.96085 14.5487 9.04413C14.5233 9.15499 14.4779 9.22687 14.3871 9.37063C13.6369 10.5586 11.4036 13.5618 8.00027 13.5618C4.59693 13.5618 2.36369 10.5586 1.61342 9.37063Z"
                stroke="#98A2B3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <path
                d="M8.00027 10.8952C9.10484 10.8952 10.0003 9.99975 10.0003 8.89518C10.0003 7.79061 9.10484 6.89518 8.00027 6.89518C6.8957 6.89518 6.00027 7.79061 6.00027 8.89518C6.00027 9.99975 6.8957 10.8952 8.00027 10.8952Z"
                stroke="#98A2B3"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>

        // <svg width="16" height="18" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        //     <g style={{ mixBlendMode: "multiply" }}>
        //         <rect x="0.5" y="1.39453" width="15" height="15" rx="2.5" fill="#F9FAFB" />
        //         <rect x="0.5" y="1.39453" width="15" height="15" rx="2.5" stroke="#EAECF0" />
        //         <path
        //             d="M4.00838 9.19185C3.95164 9.102 3.92327 9.05708 3.90738 8.98778C3.89546 8.93574 3.89546 8.85365 3.90738 8.8016C3.92327 8.73231 3.95164 8.68739 4.00838 8.59754C4.4773 7.85504 5.87308 5.97803 8.00017 5.97803C10.1273 5.97803 11.523 7.85504 11.992 8.59754C12.0487 8.68739 12.0771 8.73231 12.093 8.8016C12.1049 8.85365 12.1049 8.93574 12.093 8.98778C12.0771 9.05708 12.0487 9.102 11.992 9.19185C11.523 9.93434 10.1273 11.8114 8.00017 11.8114C5.87308 11.8114 4.4773 9.93434 4.00838 9.19185Z"
        //             stroke="#344054"
        //             strokeWidth="0.6"
        //             strokeLinecap="round"
        //             strokeLinejoin="round"
        //         />
        //         <path
        //             d="M8.00017 10.1447C8.69052 10.1447 9.25017 9.58505 9.25017 8.89469C9.25017 8.20434 8.69052 7.64469 8.00017 7.64469C7.30981 7.64469 6.75017 8.20434 6.75017 8.89469C6.75017 9.58505 7.30981 10.1447 8.00017 10.1447Z"
        //             stroke="#344054"
        //             strokeWidth="0.6"
        //             strokeLinecap="round"
        //             strokeLinejoin="round"
        //         />
        //     </g>
        // </svg>
    )
}

import { FocusEvent, useState } from "react"
import { Controller } from "react-hook-form"
// @ts-ignore
import CreatableSelect from "react-select/creatable"
import { ReactComponent as AlertIcon } from "../../assets/svgs/alert-icon.svg"

const createOption = (label: string) => ({
    label,
    value: label,
})

const createOptionsFromString = (value: string) => {
    return value
        ? value?.split(",").map((value: string) => {
              return createOption(value)
          })
        : []
}

// const extractASINAndTruncate = (input: string) => {
//     const asinRegex = /(?:\/dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})/
//     const match = input.match(asinRegex)
//     if (match) {
//         const asin = match[1]
//         const truncatedURL = `amazon.com/.../${asin}`
//         return { asin, truncatedURL }
//     }

//     return { asin: input, truncatedURL: input }
// }

const TagInputElement = (props: ITagInputElementProps) => {
    const [inputValue, setInputValue] = useState("")

    const extractASIN = (input: string) => {
        const asinRegex = /(?:\/dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})/
        const match = input.match(asinRegex)
        return match ? match[1] : input
    }

    const isValidASINOrURL = (input: string) => {
        const asinRegex = /^[A-Z0-9]{10}$/
        const amazonAsinRegex =
            /^(?:https?:\/\/)?(?:www\.)?amazon\.(com|com\.au|com\.be|com\.br|ca|cn|eg|fr|de|in|it|co\.jp|com\.mx|nl|pl|sa|sg|es|se|com\.tr|ae|co\.uk)(?:\/(?:[^\/]+\/)?dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})(?:[/?]|$)/

        return asinRegex.test(input) || amazonAsinRegex.test(input)
    }

    const truncateURL = (url: string) => {
        const asinRegex = /(?:\/dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})(?:[/?]|$)/
        const match = url.match(asinRegex)
        const asin = match ? match[1] : null

        if (asin) {
            const domain = url.match(/amazon\.[a-z\.]+/i)?.[0] || ""
            return `${domain}/.../${asin}`
        }

        return url
    }

    const handleSpaceSeparatedInput = (input: string) => {
        const items = input.split(" ").map((item) => item.trim())

        const truncatedUrlRegex =
            /^(?:https?:\/\/)?(?:www\.)?(?:amazon\.(?:co\.uk|com|com\.au|com\.be|com\.br|ca|cn|eg|fr|de|in|it|co\.jp|com\.mx|nl|pl|sa|sg|es|se|com\.tr|ae))\/\.\.\.\/([A-Z0-9]{10})$/

        return items
            .map((item) => {
                // Check if the item matches the URL truncatedUrlRegex
                const matches = item.match(truncatedUrlRegex)
                const asinOrUrl = extractASIN(item)

                return {
                    display: matches ? matches[1] : asinOrUrl,
                    value: matches ? matches[1] : asinOrUrl,
                }
            })
            .filter((item) => item.value.length === 10)
    }

    const validateTags = (value: string) => {
        if (!value || value.trim() === "") {
            return "Please enter at least one ASIN number to continue"
        }

        const tags = value.split(",").map((tag) => tag.trim())
        const uniqueTags = new Set()
        const duplicates = []

        for (let tag of tags) {
            const extractedASIN = extractASIN(tag)

            if (uniqueTags.has(extractedASIN)) {
                duplicates.push(extractedASIN)
            } else {
                uniqueTags.add(extractedASIN)
            }

            if (extractedASIN.length !== 10) {
                return "ASIN numbers must contain 10 characters"
            }

            if (!isValidASINOrURL(tag)) {
                return "Please enter a valid ASIN or URL to continue"
            }
        }

        if (duplicates.length > 0) {
            return "ASIN already entered"
        }

        return true
    }

    function findInvalidIndexes(options: { label: string; value: string }[]) {
        const invalidIndexes = []
        const uniqueTags = new Set()

        for (let i = 0; i < options.length; i++) {
            const extractedASIN = extractASIN(options[i].label)

            if (extractedASIN.length !== 10) {
                invalidIndexes.push(i)
            }

            if (uniqueTags.has(extractedASIN)) {
                invalidIndexes.push(i)
            } else {
                uniqueTags.add(extractedASIN)
            }
        }
        return invalidIndexes
    }

    return (
        <Controller
            rules={{ validate: validateTags }}
            control={props.reactHookControl}
            name={props.name}
            render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
                const options = createOptionsFromString(value)
                const isSelectedError = error && findInvalidIndexes(options)

                const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
                    event.preventDefault()

                    if (inputValue.trim()) {
                        const items = handleSpaceSeparatedInput(inputValue)
                        const asins = items.map((item) => item.value)
                        const updatedValue = value ? `${value},${asins.join(",")}` : asins.join(",")
                        onChange(updatedValue)
                        setInputValue("")
                    }
                }

                const handleInputValue = (newValue: string) => {
                    if (/^[A-Z0-9]{10}$/.test(newValue)) {
                        setInputValue(newValue)
                    } else {
                        let newValueArray = newValue.split(" ")
                        const updatedStructure = newValueArray?.map((item: string) => {
                            if (isValidASINOrURL(item)) {
                                return truncateURL(item)
                            } else {
                                return item
                            }
                        })

                        setInputValue(updatedStructure.join(" "))
                    }
                }

                return (
                    <>
                        <CreatableSelect
                            styles={{
                                control: (baseStyles) => ({
                                    ...baseStyles,
                                    borderColor: error?.message ? "#FECDCA" : "#D0D5DD",
                                    boxShadow: value && "0px 1px 2px 0px #1018280D",
                                    borderRadius: "12px",
                                    padding: "8px 10px",
                                    "&:hover": {
                                        borderColor: "#67E8F9",
                                    },
                                }),
                                input: (baseStyles) => ({
                                    ...baseStyles,
                                    height: 37,
                                    minHeight: 37,
                                    position: "relative",
                                    top: 0,
                                }),
                                multiValue: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    backgroundColor: isSelectedError?.includes(state.index)
                                        ? "#FEE4E2"
                                        : baseStyles.backgroundColor,
                                    color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                }),
                                multiValueLabel: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    color: isSelectedError?.includes(state.index) ? "#F04438" : baseStyles.color,
                                }),
                                multiValueRemove: (baseStyles, state) => ({
                                    ...baseStyles,
                                    borderRadius: "6px",
                                    color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                    "&:hover": {
                                        backgroundColor: isSelectedError?.includes(state.index)
                                            ? "#FEE4E2"
                                            : baseStyles.backgroundColor,
                                        color: isSelectedError?.includes(state.index) ? "#B42318" : baseStyles.color,
                                    },
                                }),
                            }}
                            ref={ref}
                            name={name}
                            value={options}
                            components={{
                                DropdownIndicator: null,
                                ClearIndicator: () => (
                                    <>
                                        {error?.message ? <AlertIcon /> : ""}
                                        <div className="pr-2">
                                            {props.postIcon && !error?.message && props.postIcon}
                                        </div>
                                    </>
                                ),
                            }}
                            inputValue={inputValue}
                            onBlur={handleBlur}
                            isClearable
                            menuIsOpen={false}
                            onInputChange={(newValue: string) => handleInputValue(newValue)}
                            onChange={(newValue) => {
                                const updatedValue = newValue?.map((option) => extractASIN(option.label)).join(",")
                                onChange(updatedValue)
                            }}
                            onKeyDown={(event) => {
                                if (!inputValue) return
                                if (["Enter", "Tab"].includes(event.key)) {
                                    const items = handleSpaceSeparatedInput(inputValue)
                                    const asins = items.map((item) => item.value)
                                    const updatedValue = value ? `${value},${asins.join(",")}` : asins.join(",")
                                    onChange(updatedValue)
                                    setInputValue("")
                                    event.preventDefault()
                                }
                            }}
                            placeholder={props.placeholder}
                            className={props.className}
                            isMulti={true}
                            isDisabled={props.disabled}
                        />
                        {error && (
                            <span
                                className="text-[#D92D20] pl-[14px] pt-2 text-[12px] font-[300]"
                                style={{ display: "block" }}
                            >
                                {error.message}
                            </span>
                        )}
                    </>
                )
            }}
        />
    )
}

export default TagInputElement

import React from "react"

const StepIndicator: React.FC<StepProps> = ({ currentStep, totalSteps, strokeColor }) => {
    const renderSteps = () => {
        return totalSteps?.map((step, index) => {
            const isActive = index + 1 === currentStep
            return (
                <li
                    key={index}
                    className={`xl:w-2.5 xl:h-2.5 lg:w-2.5 lg:h-2.5 md:w-2.5 md:h-2.5 sm:w-[0.375rem] sm:h-[0.375rem] xs:w-[0.375rem] xs:h-[0.375rem] relative rounded-md ${
                        isActive
                            ? `${strokeColor ? `bg-[${strokeColor}]` : "bg-[#06AED4]"}`
                            : "mix-blend-multiply bg-gray-200"
                    }`}
                />
            )
        })
    }

    return <ul className="flex space-x-2">{renderSteps()}</ul>
}

export default StepIndicator

export const Arrow = (props: IArrowProps) => {
   
    return (
        <svg width="12" height="12" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M2.6665 8H13.3332M13.3332 8L9.33317 4M13.3332 8L9.33317 12"
                stroke={props.color}
                stroke-linejoin="round"
            />
        </svg>
    )
}
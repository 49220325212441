import Icon from "../../../../../assets/gifs/amaizing-icon.gif"
import { TourCloseIcon } from "../../../../../assets/svgs/tourCloseIcon.svg"
import { ButtonElement } from "../../../../elements/button.element"
import { PrimaryText } from "../../../../elements/primaryText.element"
const PtQuickTourStepFive: React.FC<PerformanceTrackerQuickTourProps> = ({
    headerText,
    currentStep,
    handleCloseDialog,
    handleNext,
    totalSteps,
}) => {
    return (
        <>
            {/* Header */}
            <div className="flex justify-between items-center mdpt:mb-[20px] xlpt:mb-[26px]">
                <div className="flex items-center space-x-2">
                    <img
                        src={Icon}
                        className="mdpt:h-[20.8px] mdpt:w-[22.4px] xlpt:h-[26px] xlpt:w-[28px]"
                        alt="icon"
                    />

                    <PrimaryText
                        size="small"
                        weight="medium"
                        className="text-white mdpt:text-[12px] mdpt:leading-[12px] xlpt:text-[14px] xlpt:leading-[14px]"
                    >
                        {headerText}
                    </PrimaryText>
                </div>
                {handleCloseDialog && (
                    <div onClick={handleCloseDialog} className="cursor-pointer">
                        <TourCloseIcon />
                    </div>
                )}
            </div>

            {/* Content */}
            <div className="mdpt:p-[10px]  xlpt:p-[12px] bg-[#06AED4] mdpt:rounded-[6.4px] xlpt:rounded-[8px]">
                <PrimaryText
                    weight="book"
                    size="xs"
                    className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] mb-[10px]"
                >
                    Whenever there is a change to an ASIN, I mark it with a dot on the timeline. Each type of change has
                    <br />a corresponding icon, which can be clicked to reveal the specific detail
                    <br /> of that change.
                </PrimaryText>
                <PrimaryText
                    weight="book"
                    size="xs"
                    className="text-white mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px]"
                >
                    You won’t need to burn time browsing Amazon listings manually tracking changes ever again!
                </PrimaryText>
                {/* Footer */}
                <div className="flex justify-between items-center mdpt:mt-[20px] xlpt:mt-[24px]">
                    <PrimaryText weight="book" size="xs-medium" className="text-white mdpt:text-[8px] xlpt:text-[10px]">
                        {currentStep} / {totalSteps}
                    </PrimaryText>
                    <ButtonElement
                        size="small"
                        textClass="mdpt:mt-[0.5px] xlpt:mt-[0px] text-[500] mdpt:text-[10px] xlpt:text-[12px] mdpt:leading-[14px] xlpt:leading-[18px] text-white"
                        className=" mdpt:!w-[34.8px] mdpt:!h-[20.4px] xlpt:!w-[42px] xlpt:!h-[26px] mdpt:px-[6.4px] mdpt:py-[3.2px]  xlpt:px-[8px] xlpt:py-[4px] !bg-none text-white mdpt:rounded-[4.8px] xlpt:rounded-[6px] !hover:bg-transparent border border-white"
                        onClick={handleNext}
                    >
                        Next
                    </ButtonElement>
                </div>
            </div>
        </>
    )
}

export default PtQuickTourStepFive

import { all, call, fork, put, takeLatest } from "typed-redux-saga"
import { AccountAction, AccountActionsCreater } from "../actions/account.action"
import { AccountService } from "../services/account.service"

function* getUsersAccounts() {
    yield takeLatest(AccountAction.GET_USER_ACCOUNTS, getUserAccountsHandler)
}

function* getUserAccountsHandler<T extends ISagaAction>(action: T) {
    try {
        const result = yield* call(AccountService.getAllUsers)

        if (result) {
            yield put(AccountActionsCreater.setUserAccounts(result as any))
        }
    } catch (error) {
    } finally {
        action.onFinally && action.onFinally()
    }
}

export default function* rootSaga() {
    yield all([fork(getUsersAccounts)])
}

import dayjs from "dayjs"
import { useTranslation } from "react-i18next"

import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"

import { StockTooltipIcon } from "../../../assets/svgs/tooltips/popups/stock.svg"

export const Stock = (props: ITooltipData) => {
    const { t } = useTranslation("tooltip", {
        keyPrefix: "stock",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })
    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )

    const { active, history, setActive, total } = useProductHistory(props.data, "availability")

    return (
        <div
            className={`w-[216px]  p-[12px] bg-white rounded-2xl`}
            style={{ border: `1px solid ${props.color?.default}` }}
        >
            <InnerTooltipHeader
                title={t("Stock")}
                linkText={tCommon("Go to ASIN")}
                timestamp={tCommon("timestamp", {
                    timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                })}
                active={active}
                total={total}
                setActive={setActive}
                textColor={props.color?.default}
                color={props.color?.active}
                id={props.id}
                icon={<StockTooltipIcon stroke={props.color?.default} />}
            />
            <div className="mt-[32px]">
                {history.past && history.present ? (
                    <div className="w-full flex-col justify-start items-start gap-[8px] inline-flex">
                        <div
                            className={`p-2 w-full bg-[${props.color?.bgHundred}] rounded-lg flex-col justify-start items-start gap-1.5 flex`}
                        >
                            <PrimaryText
                                size={"xs-small"}
                                style={{
                                    color: props.color?.default,
                                }}
                                weight={"medium"}
                                className="text-[12px] leading-[10px] mx-[2px] mt-[2px] tracking-[0.96px]"
                            >
                                {tCommon("was")}
                            </PrimaryText>

                            <div className="w-full justify-between items-center gap-2.5 inline-flex">
                                <PrimaryText
                                    size={"sm-medium"}
                                    weight={"light"}
                                    className="w-full text-gray-600 mx-[2px]"
                                >
                                    {history?.past?.value ?? "N/A"}
                                </PrimaryText>
                            </div>
                        </div>
                        <div
                            className={`p-2 w-full bg-[${props.color?.bgHundred}] rounded-lg flex-col justify-start items-start gap-1.5 flex`}
                        >
                            <PrimaryText
                                size={"xs-small"}
                                color={`text-[${props.color?.default}]`}
                                weight={"medium"}
                                className="text-[12px] leading-[10px] mx-[2px] mt-[2px] tracking-[0.96px]"
                            >
                                {tCommon("now")}
                            </PrimaryText>

                            <div className="w-full justify-start items-center gap-2.5 inline-flex">
                                <PrimaryText
                                    size={"sm-medium"}
                                    weight={"light"}
                                    className="w-full text-gray-600 mx-[2px]"
                                >
                                    {history?.present?.value ?? "N/A"}
                                </PrimaryText>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className={`w-full p-2 bg-[${props.color?.bgHundred}] rounded-lg`}>
                        <PrimaryText
                            size={"xs-small"}
                            color={`text-[${props.color?.default}]`}
                            weight={"medium"}
                            className="text-[12px] leading-[10px] tracking-[0.96px] mx-[2px] mt-[2px] mb-[6px]"
                        >
                            {tCommon("now")}
                        </PrimaryText>
                        <PrimaryText size={"sm-medium"} weight={"light"} className="w-full text-gray-600 mx-[2px]">
                            {history?.present?.value ?? "N/A"}
                        </PrimaryText>
                    </div>
                )}
            </div>
            {/* {isSuccessWarning && (
                <div className="w-full  pt-[8px]">
                    <ErrorTooltipAlert />
                </div>
            )}

            {isWarning && (
                <div className="w-full  pt-[8px]">
                    <WarningTooltipAlert />
                </div>
            )} */}
        </div>
    )
}

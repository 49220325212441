export class ProductStateClass implements IProductState {
    selectedGroupProducts?: IGroupProductDataResponse | undefined
    selectedProductView?: IProductSelectedView | undefined
    selectedProductResult?: IProductSelectedResults | undefined
    selectedProducts?: ISelectedProducts
    selectedProductRange?: IProductSelectedRange
    selectedOption?: ISelectedOption | string
    selectedTrackpackPayload?: ISelectedTrackpackPayload
    selectedDropdownList?: IProductDropdownList
    storedRawData?: IGroupProductDataResponse | undefined
}

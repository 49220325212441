export const FlipArrowIcon = (props: IArrowProps) => {
    return (
        <svg
            onClick={props.onClick}
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M2 6.00065H11C12.6569 6.00065 14 7.3438 14 9.00065C14 10.6575 12.6569 12.0007 11 12.0007H8M2 6.00065L4.66667 3.33398M2 6.00065L4.66667 8.66732"
                stroke="#667085"
                stroke-width="0.6"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

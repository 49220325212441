import { API_ROUTES } from "../config/routes.config"
import { HttpHelper } from "../helpers/http.helper"
import { UtilHelper } from "../helpers/util.helper"

export class NotificationService {
    static async getAllNotifications(pathParams?: { [key: string]: string }, queryParams?: { [key: string]: string }) {
        const pathString =
            pathParams && Object.keys(pathParams).length > 0 ? `/${Object.values(pathParams).join("/")}` : ""
        const queryString = queryParams ? `?${new URLSearchParams({ ...queryParams }).toString()}` : ""
        const url = `${API_ROUTES.GET_ALL_NOTIFICATIONS}${pathString}${queryString}`
        const result = await HttpHelper.sendRequest(url, {}, "GET")

        return HttpHelper.validateResponse<IAlertSettingState>(result)
    }

    static async readNotifications(payload: any): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.READ_NOTIFICATION,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
    static async unreadNotifications(payload: any): Promise<Response> {
        const result = await HttpHelper.sendRequest(
            API_ROUTES.UNREAD_NOTIFICATION,
            {
                ...payload,
            },
            "POST"
        )

        return result && !UtilHelper.isEmptyObject(result) && !result.error ? result : undefined
    }
}

export const VideoUnavailable = () => {
    return (
        <>
            <svg width="44" height="44" viewBox="0 0 44 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M9.16699 9.1665C6.12943 9.1665 3.66699 11.6289 3.66699 14.6665V29.3332C3.66699 32.3707 6.12943 34.8332 9.16699 34.8332H25.667C28.1469 34.8332 30.2434 33.1919 30.9297 30.9364M31.167 21.9998L37.8299 15.3369C38.6153 14.5516 39.0079 14.1589 39.3451 14.1324C39.6376 14.1093 39.9235 14.2277 40.114 14.4509C40.3337 14.708 40.3337 15.2634 40.3337 16.374V27.6257C40.3337 28.7363 40.3337 29.2917 40.114 29.5488C39.9235 29.7719 39.6376 29.8903 39.3451 29.8673C39.0079 29.8408 38.6153 29.4481 37.8299 28.6627L31.167 21.9998ZM31.167 21.9998V17.9665C31.167 14.8862 31.167 13.3461 30.5675 12.1696C30.0402 11.1347 29.1988 10.2933 28.1639 9.76597C26.9874 9.1665 25.4473 9.1665 22.367 9.1665H17.417M3.66699 3.6665L40.3337 40.3332"
                    stroke="#475467"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}

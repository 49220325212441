export const XsVideoIcon = (props: IPropsIcon) => {
    return (
        <svg
            className={props.className}
            width="25"
            height="25"
            viewBox="0 0 25 25"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <g filter="url(#filter0_b_5241_146873)">
                <g filter="url(#filter1_dd_5241_146873)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M12.6406 14.4287C15.9543 14.4287 18.6406 11.7424 18.6406 8.42871C18.6406 5.115 15.9543 2.42871 12.6406 2.42871C9.32692 2.42871 6.64062 5.115 6.64062 8.42871C6.64062 11.7424 9.32692 14.4287 12.6406 14.4287ZM11.7031 10.6295L15.0781 8.74311C15.3281 8.60338 15.3281 8.25404 15.0781 8.11431L11.7031 6.22789C11.4531 6.08816 11.1406 6.26282 11.1406 6.54229V10.3151C11.1406 10.5946 11.4531 10.7693 11.7031 10.6295Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_b_5241_146873"
                    x="-5.35938"
                    y="-9.57129"
                    width="36"
                    height="36"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_5241_146873" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_5241_146873" result="shape" />
                </filter>
                <filter
                    id="filter1_dd_5241_146873"
                    x="0.640625"
                    y="0.428711"
                    width="24"
                    height="24"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="2"
                        operator="erode"
                        in="SourceAlpha"
                        result="effect1_dropShadow_5241_146873"
                    />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_5241_146873" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology
                        radius="2"
                        operator="erode"
                        in="SourceAlpha"
                        result="effect2_dropShadow_5241_146873"
                    />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
                    />
                    <feBlend
                        mode="normal"
                        in2="effect1_dropShadow_5241_146873"
                        result="effect2_dropShadow_5241_146873"
                    />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_5241_146873" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

import { NewErrorIcon } from "../../../assets/svgs/newErrorIcon.svg"
import { PrimaryText } from "../../elements/primaryText.element"

const ErrorTooltipAlert = (prop: IPropsIcon) => {
    return (
        <>
            <div
                className={`bg-[#FEE4E2] flex items-center justify-between gap-[11px]  ${
                    prop.className ?? "px-[6px] py-[4px]"
                }  rounded-[8px]`}
            >
                <NewErrorIcon />
                <PrimaryText className="font-[300] text-[#B42318] text-[10px] leading-[14px] flex items-cente whitespace-nowrapr">
                    <PrimaryText className="font-[500] text-[10px] leading-[14px]  whitespace-nowrap">
                        Data{" "}
                    </PrimaryText>{" "}
                    &nbsp;unavailable
                </PrimaryText>
                <NewErrorIcon />
            </div>
        </>
    )
}

export default ErrorTooltipAlert

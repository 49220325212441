import { useEffect, useMemo } from "react"
import { useForm } from "react-hook-form"
import { useDispatch, useSelector } from "react-redux"
import { ProductsActionCreator } from "../../../../actions/products.action"
import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { RuntimeHelper } from "../../../../helpers/runtime.helper"
import { AccountStateSelector } from "../../../../selectors/accountState.selector"
import { ProductStateSelector } from "../../../../selectors/product.selector"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
import TagInputElement from "../../../elements/tagInput.element"

const AddTrackPackStepOneView: React.FC<AddTrackPackProps> = ({
    totalSteps,
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handleNext,
    buttonText,
    currentStep,
    isPrevButton,
    handlePrev,
    isAddtionalSteps,
    isError,
    isSuccess,
}) => {
    const { control, handleSubmit, watch, setValue } = useForm()
    const dispatch = useDispatch()
    const total_asins = watch("total_asins")
    const data = useSelector(ProductStateSelector)
    const { selectedTrackpacksList, userActionData } = useSelector(AccountStateSelector)
    const { loading } = useSelector(RunTimeStateSelector)
    const isLoading: boolean = useMemo(() => RuntimeHelper.addAdditionalAsinLoading(), [loading])

    // const onSubmit = () => {
    //     const totalAsins = total_asins.split(",").map((asin: string) => asin.trim())
    //     dispatch(ProductsActionCreator.selectedTrackpackPayload({ total_asins: totalAsins }))
    //     handleNext && handleNext()
    // }

    const onSubmit = () => {
        if (isAddtionalSteps) {
            let competitor: string[] = []
            const totalAsins = total_asins.split(",").map((asin: string) => asin.trim())
            competitor = totalAsins || []
            const payload = {
                own_asins: [],
                competitor_asins: competitor?.map((val: string) => val?.toUpperCase()) ?? [],
                trackpack_id: selectedTrackpacksList?.id ? selectedTrackpacksList?.id : selectedTrackpacksList,
            }

            dispatch(
                ProductsActionCreator.addAsinToTrackpack(
                    { ...payload },
                    () => {
                        isSuccess && isSuccess(totalAsins?.length)
                        if (userActionData !== undefined) {
                            dispatch(
                                ProductsActionCreator.getDropdownList(
                                    {},
                                    { as_user: userActionData && userActionData?.id + "" }
                                )
                            )
                        } else dispatch(ProductsActionCreator.getDropdownList())
                    },
                    (res: { message: string }) => {
                        if (isError) {
                            isError()
                        }
                    }
                )
            )
        } else {
            // const selectedAsinsObject: AsinsSelection = {}
            // Object?.keys(asinsSelected)?.forEach((key) => {
            //     if (asinsSelected[key]) {
            //         selectedAsinsObject[key] = true
            //     }
            // })

            const totalAsins = total_asins.split(",").map((asin: string) => asin.trim())

            dispatch(ProductsActionCreator.selectedTrackpackPayload({ total_asins: totalAsins }))
            handleNext && handleNext()
        }
    }

    useEffect(() => {
        if (data?.selectedTrackpackPayload?.total_asins) {
            setValue("total_asins", data?.selectedTrackpackPayload.total_asins?.join(", "))
        }
    }, [data, setValue])

    const trackpackTitle = selectedTrackpacksList?.id
        ? selectedTrackpacksList?.title
        : data.selectedDropdownList?.trackpacks?.find((val: any) => val.metadata.id === selectedTrackpacksList)
              ?.metadata?.name

    return (
        <>
            <div className="flex items-center justify-between">
                <div className="bg-[#A5F0FC] rounded-[12px] p-[10px]">{icon}</div>
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText weight="medium" className="text-gray-700 text-[24px] mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>
            {asinHeaderDescription ? (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] mx-[6px]">
                    {asinHeaderDescription}
                </PrimaryText>
            ) : (
                <PrimaryText size="sm-medium" weight="light" className="text-gray-700 mt-[16px] mx-[6px]">
                    To add ASINs to your Trackpack called&nbsp;
                    <PrimaryText weight="medium" size="sm-medium" className="text-gray-700 inline-block">
                        {trackpackTitle}
                    </PrimaryText>
                    , simply give me one ASIN or URL at a time and hit Tab to enter.
                </PrimaryText>
            )}
            <form onSubmit={handleSubmit(onSubmit)} className=" mt-[36px]">
                <>
                    <TagInputElement
                        name="total_asins"
                        className=" border-[#D0D5DD] rounded-[12px] placeholder:italic tag-input"
                        placeholder="E.g. B08S6XWCN5 or www.amazon.com/DP/B08S6XWCN5"
                        reactHookControl={control}
                    />

                    <div>
                        <div
                            className={`flex ${
                                !isPrevButton ? "justify-end" : "justify-between"
                            }  items-center mt-[48px]`}
                        >
                            {isPrevButton && (
                                <div className="flex items-center">
                                    <BackChevronIcon />
                                    <PrimaryText
                                        onClick={handlePrev}
                                        className="text-[18px]  cursor-pointer text-[#667085]"
                                    >
                                        Back
                                    </PrimaryText>
                                </div>
                            )}

                            <ButtonElement
                                type="submit"
                                size="large"
                                textClass="text-[18px] leading-[28px]"
                                className={"w-[99px] bg-[#A5F0FC] rounded-[12px] px-[30px] py-[8px] text-[#0E7090] "}
                                loading={isLoading}
                                disabled={isLoading}
                                viewType={isLoading ? "loading-button" : "primary"}
                            >
                                {buttonText}
                            </ButtonElement>
                        </div>
                    </div>
                </>
            </form>
        </>
    )
}

export default AddTrackPackStepOneView

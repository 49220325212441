const MainImageIcon = ({ strokeColor }: { strokeColor?: string }) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
            <path
                d="M12.6674 14H13.341C13.9884 14 14.3122 14 14.4906 13.865C14.6461 13.7474 14.7423 13.5677 14.7539 13.3731C14.7672 13.1497 14.5876 12.8804 14.2285 12.3417L12.2216 9.33128C11.9248 8.88616 11.7765 8.66359 11.5895 8.58604C11.426 8.51823 11.2422 8.51823 11.0787 8.58604C10.8917 8.66359 10.7433 8.88616 10.4466 9.33128L9.95043 10.0755M12.6674 14L7.54441 6.60012C7.24979 6.17455 7.10247 5.96176 6.91846 5.88696C6.75749 5.82152 6.57733 5.82152 6.41636 5.88696C6.23235 5.96176 6.08503 6.17455 5.79041 6.60012L1.82621 12.3262C1.45086 12.8684 1.26318 13.1394 1.27387 13.3649C1.28317 13.5612 1.37866 13.7435 1.53479 13.8629C1.71408 14 2.04379 14 2.70322 14H12.6674ZM14.0007 4C14.0007 5.10457 13.1053 6 12.0007 6C10.8962 6 10.0007 5.10457 10.0007 4C10.0007 2.89543 10.8962 2 12.0007 2C13.1053 2 14.0007 2.89543 14.0007 4Z"
                stroke={strokeColor || "#667085"}
                stroke-width="0.8"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

export default MainImageIcon

export const BulletsIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
        >
            <path
                d="M4.66667 15.1668C5.23941 15.1668 5.83333 14.6445 5.83333 14.0002C5.83333 13.3558 5.23941 12.8335 4.66667 12.8335C4.09393 12.8335 3.5 13.3558 3.5 14.0002C3.5 14.6445 4.09393 15.1668 4.66667 15.1668Z"
                fill={props.stroke}
            />
            <path
                d="M4.66667 8.16683C5.23941 8.16683 5.83333 7.64449 5.83333 7.00016C5.83333 6.35583 5.23941 5.8335 4.66667 5.8335C4.09393 5.8335 3.5 6.35583 3.5 7.00016C3.5 7.6445 4.09393 8.16683 4.66667 8.16683Z"
                fill={props.stroke}
            />
            <path
                d="M4.66667 22.1668C5.23941 22.1668 5.83333 21.6445 5.83333 21.0002C5.83333 20.3558 5.23941 19.8335 4.66667 19.8335C4.09393 19.8335 3.5 20.3558 3.5 21.0002C3.5 21.6445 4.09393 22.1668 4.66667 22.1668Z"
                fill={props.stroke}
            />
            <path
                d="M24.5 14.0002H9.33333M24.5 7.00016H9.33333M24.5 21.0002H9.33333M5.83333 14.0002C5.83333 14.6445 5.23941 15.1668 4.66667 15.1668C4.09393 15.1668 3.5 14.6445 3.5 14.0002C3.5 13.3558 4.09393 12.8335 4.66667 12.8335C5.23941 12.8335 5.83333 13.3558 5.83333 14.0002ZM5.83333 7.00016C5.83333 7.64449 5.23941 8.16683 4.66667 8.16683C4.09393 8.16683 3.5 7.6445 3.5 7.00016C3.5 6.35583 4.09393 5.8335 4.66667 5.8335C5.23941 5.8335 5.83333 6.35583 5.83333 7.00016ZM5.83333 21.0002C5.83333 21.6445 5.23941 22.1668 4.66667 22.1668C4.09393 22.1668 3.5 21.6445 3.5 21.0002C3.5 20.3558 4.09393 19.8335 4.66667 19.8335C5.23941 19.8335 5.83333 20.3558 5.83333 21.0002Z"
                stroke={props.stroke}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

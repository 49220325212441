export const LogMainImageIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="image-05">
                    <path
                        id="Icon"
                        d="M9.50056 10.5H10.0057C10.4913 10.5 10.7341 10.5 10.868 10.3988C10.9845 10.3106 11.0567 10.1757 11.0654 10.0298C11.0754 9.86228 10.9407 9.66027 10.6714 9.25624L9.1662 6.99846C8.94364 6.66462 8.83235 6.4977 8.69209 6.43953C8.56947 6.38867 8.43165 6.38867 8.30902 6.43953C8.16876 6.4977 8.05748 6.66462 7.83492 6.99846L7.46282 7.5566M9.50056 10.5L5.65831 4.95009C5.43734 4.63091 5.32686 4.47132 5.18885 4.41522C5.06812 4.36614 4.933 4.36614 4.81227 4.41522C4.67426 4.47132 4.56377 4.63091 4.3428 4.95009L1.36966 9.24463C1.08814 9.65126 0.947388 9.85458 0.955402 10.0237C0.962381 10.1709 1.03399 10.3076 1.15109 10.3972C1.28556 10.5 1.53284 10.5 2.02741 10.5H9.50056ZM10.5006 3C10.5006 3.82843 9.82898 4.5 9.00056 4.5C8.17213 4.5 7.50056 3.82843 7.50056 3C7.50056 2.17157 8.17213 1.5 9.00056 1.5C9.82898 1.5 10.5006 2.17157 10.5006 3Z"
                        stroke={props.color}
                        stroke-width="0.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </svg>
        </>
    )
}

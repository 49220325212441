export const AlertDotIconSvg = (props: IArrowProps) => {
    return (
        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <circle
                cx="6"
                cy="6"
                r="4.5"
                fill={props.fillColor ?? "#7A5AF8"}
                stroke={props.color ?? "#D9D6FE"}
                stroke-width="3"
            />
        </svg>
    )
}

import { UserAccountsHeader } from "./userAccountsHeader.component"
import { UserAccountsTable } from "./userAccountsTable.component"

export const UserAccounts = () => {
    return (
        <>
            <div
                style={{
                    height: "calc(100vh - 162px)",
                    border: "1px solid",
                    borderImageSource: "linear-gradient(180deg, #F9FAFB 0%, #E7EBEF 100%)",
                }}
                className="px-[12px] py-[18px] rounded-[16px] bg-gray-50"
            >
                <UserAccountsHeader />
                <UserAccountsTable />
            </div>
        </>
    )
}

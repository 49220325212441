import { useMemo } from "react"
import { useSelector } from "react-redux"

import { ProductStateSelector } from "../selectors/product.selector"

export const useContainsChanges = (product?: IProductData) => {
    const { selectedProductView } = useSelector(ProductStateSelector)

    const checkIfAvailable = (item: IIndexable[] | undefined): boolean => {
        return item ? item.length > 0 : false
    }

    return useMemo(() => {
        if (product) {
            const {
                bullets,
                carousel_images,
                main_image,
                description,
                price,
                title,
                videos,
                seller_info,
                availability,
                actual_asin,
                main_video,
                // keywords
            } = product

            const hasActualAsin = checkIfAvailable(actual_asin)

            const showChanges = selectedProductView?.selectedViews
                .map((view) => {
                    switch (view) {
                        case "price":
                            return checkIfAvailable(price)
                        case "main_image":
                            return checkIfAvailable(main_image)
                        case "carousel_images":
                            return checkIfAvailable(carousel_images)
                        case "title":
                            return checkIfAvailable(title)
                        case "description":
                            return checkIfAvailable(description)
                        case "bullets":
                            return checkIfAvailable(bullets)
                        case "videos":
                            return checkIfAvailable(videos)
                        case "seller_info":
                            return checkIfAvailable(seller_info)
                        case "stock":
                            return checkIfAvailable(availability)
                        case "main_video":
                            return checkIfAvailable(main_video)
                        // case "keywords":
                        //     return checkIfAvailable(keywords)

                        default:
                            return false
                    }
                })
                .filter(Boolean)

            const hasChanges = showChanges && showChanges?.length > 0

            return { hasActualAsin, hasChanges }
        }

        return { hasActualAsin: false, hasChanges: false }
    }, [product, selectedProductView?.selectedViews])
}

export const TrackPackError = () => {
    return (
        <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="48" height="48" rx="12" fill="url(#paint0_linear_8029_267278)" />
            <path
                d="M24 21.3206V26.4358M24 29.505H24.0102M22.5834 16.0945L14.1689 30.6286C13.7022 31.4348 13.4688 31.8379 13.5033 32.1687C13.5334 32.4572 13.6846 32.7194 13.9192 32.89C14.1883 33.0856 14.654 33.0856 15.5855 33.0856H32.4145C33.346 33.0856 33.8117 33.0856 34.0808 32.89C34.3154 32.7194 34.4666 32.4572 34.4967 32.1687C34.5311 31.8379 34.2978 31.4348 33.8311 30.6286L25.4166 16.0945C24.9516 15.2913 24.719 14.8896 24.4157 14.7548C24.151 14.6371 23.849 14.6371 23.5843 14.7548C23.281 14.8896 23.0484 15.2913 22.5834 16.0945Z"
                stroke="#B42318"
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
            <defs>
                <linearGradient
                    id="paint0_linear_8029_267278"
                    x1="0"
                    y1="0"
                    x2="48"
                    y2="48"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stop-color="#FEE4E2" />
                    <stop offset="1" stop-color="#FECDCA" />
                </linearGradient>
            </defs>
        </svg>
    )
}

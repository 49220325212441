import { useState } from "react"
import { useController } from "react-hook-form"

import AlertIcon from "../../assets/svgs/alert-icon.svg"
import { PrimaryText } from "./primaryText.element"

export const NewCheckboxElement = (props: ICheckboxElementProps) => {
    const [currentValue, setCurrentValue] = useState<boolean>(props.defaultValue || false)

    const { field, fieldState } = useController({
        name: props.name,
        control: props.reactHookControl,
        rules: props.reactHookValidations,
        defaultValue: props.defaultValue,
    })

    const onInputChange = async (
        event: React.ChangeEvent<HTMLInputElement>,
        originalOnChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
    ) => {
        setCurrentValue(event.target?.checked)
        originalOnChange && originalOnChange(event)
        props.onChange && props.onChange(event)
    }

    return (
        <div className={`${props.labelMarginBottom ? "mb-[16px] sm:max-w-[240px] xs:max-w-[330px] text-left" : ""}`}>
            <div className={"md:flex sm:flex-none xs:flex-none sm:justify-start xs:justify-start md:justify-start"}>
                <label
                    className={`flex-none flex md:items-center sm:items-start xs:items-start md:justify-center lg:justify xl:justify cursor-pointer md:gap-[12px] sm:gap-[12px] xs:gap-[12px] ${
                        props.labelMarginBottom ? "pl-0" : ""
                    }`}
                >
                    {props.isAsin && (
                        <PrimaryText
                            // size={"xs"}
                            weight={"book"}
                            typography="p:round-onboarding-responsive"
                            className={`text-gray-700 !text-[14px] rounded-[6px] max-w-[108px] w-auto bg-gray-100 py-[2px] px-[7.5px] pt-[3px] !leading-[19px] uppercase`}
                        >
                            {props.label}
                        </PrimaryText>
                    )}

                    <div className="flex items-center justify-center ml-[1px]">
                        <input
                            className=" relative float-left cursor-pointer appearance-none border-[0.125rem] border-solid border-secondary-500  after:absolute after:chrome:top-[2px] after:safari:top-[1.5px] after:chrome:left-[5px] after:safari:left-[5px]  checked:border-primary checked:bg-[#ecfdff]  checked:after:absolute  checked:after:block checked:after:h-[8px] checked:after:w-[4px] checked:after:rotate-45 checked:after:border-[1px] checked:after:border-l-0 checked:after:border-t-0 checked:after:border-solid checked:after:border-[#088ab2] checked:after:bg-transparent checked:after:content-['']"
                            type="checkbox"
                            id="checkboxDefault"
                            {...field}
                            onChange={(e: any) => onInputChange(e, field.onChange)}
                            checked={currentValue || false}
                        />
                    </div>

                    {/* <label className="custom-checkbox">
                        <input
                            type="checkbox"
                            className="trackpack-checkbox"
                            {...field}
                            onChange={(e: any) => onInputChange(e, field.onChange)}
                            checked={currentValue || false}
                        />

                        <span className="checkmark"></span>
                    </label> */}
                    {!props.isAsin && (
                        <PrimaryText
                            size={"small"}
                            weight={"light"}
                            typography="p:round-onboarding-responsive"
                            className={`!leading-tight ${props.labelMarginBottom ? " text-gray-700" : ""}`}
                        >
                            {props.label}
                        </PrimaryText>
                    )}
                </label>
            </div>

            {fieldState.error && (
                <div
                    className={`${
                        props.labelMarginBottom
                            ? "flex items-center pl-[36px] mt-[8px] lg:mt-[0px] md:mt-[0] sm:mt-[0] xs:mt-[0]"
                            : "flex items-center mt-[8px]"
                    }`}
                >
                    <span className="">
                        <img src={AlertIcon} alt="alert icon" />
                    </span>

                    <PrimaryText size="xs" weight="book" className="ml-[5px]" color="text-red-600">
                        {fieldState.error.message}
                    </PrimaryText>
                </div>
            )}
        </div>
    )
}

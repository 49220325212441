import { ASIN_PRODUCTS_COLOR } from "../config/colors.config"
import { AsinIssuesList } from "../config/dashboard.config"
import { ASIN_AMAZON_LINK_BASE } from "../config/routes.config"

export class UtilHelper {
    /**
     * Return value or empty
     *
     * @param item
     */
    static toString(item: any): string {
        return item || ""
    }

    /**
     * Check if variable is object
     *
     * @param item
     */
    static isObject(item: any): boolean {
        return typeof item === "object" && item !== null
    }

    /**
     * Check if object is empty
     *
     * @param item
     */
    static isEmptyObject(item: any): boolean {
        return UtilHelper.isObject(item) && Object.keys(item).length === 0 && item.constructor === Object
    }
    static truncateTitle = (title: string, maxLength: number) => {
        if (title.length <= maxLength) {
            return title
        } else {
            return title.slice(0, maxLength) + "..."
        }
    }

    /**
     * Check if objects are equal
     *
     * @param obj1
     * @param obj2
     */
    static objectsEqual(obj1: Object, obj2: Object): boolean {
        return JSON.stringify(obj1) === JSON.stringify(obj2)
    }

    /**
     * Deep copy object
     *
     * @param obj
     */
    static deepCopyObject(obj: Object): any {
        return JSON.parse(JSON.stringify(obj))
    }

    /**
     * Stringify inner objects of some object
     */
    static stringifyInnerObjects(item: IIndexable): IIndexable {
        if (!UtilHelper.isObject(item)) {
            return item
        }

        const result = {} as IIndexable

        Object.keys(item).forEach((key) => {
            result[key] = UtilHelper.isObject(item[key]) ? JSON.stringify(item[key]) : item[key]
        })

        return result
    }

    /**
     * Convert to mysql date format: YYYY-MM-DD
     *
     * @param date
     */
    static dateToMysqlFormat(date: Date): string {
        const year = String(date.getFullYear())

        let month = String(date.getMonth() + 1)

        if (month.length === 1) {
            month = "0" + month
        }

        let day = String(date.getDate())

        if (day.length === 1) {
            day = "0" + day
        }

        return year + "-" + month + "-" + day
    }

    /**
     * Format cents to dollars
     *
     * @param amount
     */
    static formatCents(amount: number): number {
        return parseFloat((amount / 100).toFixed(2))
    }

    /**
     * Round
     *
     * @param amount
     */
    static round(amount: number): number {
        return Number(amount.toFixed(2))
    }
    /**
     * Format number in locale fashion, with comma delimiters for thousands for example
     *
     * @param num
     */
    static getFormattedLocaleNumber(num: number): string {
        return num.toLocaleString("en-US")
    }

    /**
     * Scroll to top of the page
     */
    static scrollTopPage() {
        // Hack to scroll to top of page
        // Needed because we can navigate to these pages with window scrolled - so it's glitchy without it
        // In catch so it doesn't fail where not supported...

        try {
            window.scrollTo(0, 0)
        } catch (e) {}
    }

    /**
     * Get url query string param
     *
     * @param name
     * @param url
     */
    static getUrlQueryParam(name: string, url: string = ""): string | undefined {
        try {
            url = url || window.location.href
            name = name.replace(/[[\]]/g, "\\$&")

            const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
            const results = regex.exec(url)

            if (!results) {
                return undefined
            }

            if (!results[2]) {
                return ""
            }

            return decodeURIComponent(results[2])
        } catch (e) {
            return undefined
        }
    }

    /**
     * Convert file to base 64 format
     *
     * @param file
     */
    static async fileToBase64(file: File) {
        return new Promise((resolve, reject) => {
            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = () => resolve(reader.result)
            reader.onerror = (error) => reject(error)
        })
    }

    /**
     * Capitalize first letter in string
     *
     * @param item
     */
    static capitalizeFirstLetter(item?: string): string {
        if (!item) {
            return ""
        }

        return item.charAt(0).toUpperCase() + item.slice(1)
    }

    /**
     * Get current url of app
     */
    static getCurrentAppDomain(): string {
        return (
            window.location.protocol +
            "//" +
            window.location.hostname +
            (window.location.port ? ":" + window.location.port : "")
        )
    }

    /**
     * convert to valid code
     *
     * @param code
     */
    static getValidCode(code: string) {
        const number = Number(code)
        if (isNaN(number)) {
            return null
        }
        return number
    }

    /**
     * Get periodic change in percentages for 2 values
     *
     * @param currentValue
     * @param previousPeriodValue
     */
    static getPeriodicChangePercentage(currentValue?: number, previousPeriodValue?: number): number {
        if (typeof currentValue === "undefined" || typeof previousPeriodValue === "undefined") {
            return 0
        }

        try {
            const result = (100 * (currentValue - previousPeriodValue)) / ((currentValue + previousPeriodValue) / 2)

            if (isNaN(result)) {
                return 0
            }

            return result
        } catch (e) {
            return 0
        }
    }

    /**
     * Check if current Device is IOS
     */
    static isIOS() {
        return (
            ["iPad Simulator", "iPhone Simulator", "iPod Simulator", "iPad", "iPhone", "iPod"].includes(
                navigator.platform
            ) ||
            // iPad on iOS 13 detection
            (navigator.userAgent.includes("Mac") && "ontouchend" in document)
        )
    }

    /**
     * Basically format seconds to "hh:mm:ss"
     */
    static getFormattedCountdown(secondsToFormat?: number): string | undefined {
        if (!secondsToFormat) {
            return undefined
        }

        const hours = Math.floor(secondsToFormat / 60 / 60)
        const minutes = Math.floor(secondsToFormat / 60) - hours * 60
        const seconds = secondsToFormat - hours * 60 * 60 - minutes * 60

        return (
            `${hours ? `${hours}h ` : ""}` +
            `${minutes ? `${minutes}m ` : "0m "}` +
            `${seconds ? `${seconds}s ` : "0s"}`
        )
    }

    /**
     * Give delay using Promise in asych call
     *
     * @param mil
     */
    static async sleep(num: number): Promise<null> {
        return new Promise((resolve) => {
            setTimeout(resolve, num)
        })
    }
    static areIdsSame(arr: IActualAsin[]) {
        if (!arr) {
            return false
        }
        if (arr.length === 0) {
            return false // If array is empty, return false
        }

        const firstId = arr[0].id
        for (let i = 1; i < arr.length; i++) {
            if (arr[i].id !== firstId) {
                return true // If any id doesn't match the first id, return false
            }
        }
        return false // If all ids match, return true
    }

    static removeSpacesFromStringArray = (arr: string[]): string[] => {
        for (let i = 0; i < arr.length; i++) {
            arr[i] = arr[i].replace(/\s/g, "") // Remove all spaces
        }
        return arr
    }
    static getInitials(username: string) {
        return username
            .split(" ") // Split the username into words
            .map((word) => word.charAt(0)) // Get the first character of each word
            .join("") // Join the characters to form the initials
            .toUpperCase() // Convert to uppercase
    }
    static createDefaultObject<T extends keyof IOnBoardUser>(keys: T[]): DefaultOnBoardUser {
        const defaultObject: DefaultOnBoardUser = {}
        keys.forEach((key) => {
            defaultObject[key as string] = undefined
        })
        return defaultObject
    }
    /**
     * Group array of objects by given keys
     * @param keys keys to be grouped by
     * @param array objects to be grouped
     * @returns an object with objects in `array` grouped by `keys`
     * @see <https://gist.github.com/mikaello/06a76bca33e5d79cdd80c162d7774e9c>
     */
    static groupBy =
        <T>(keys: (keyof T)[]) =>
        (array: T[]): Record<string, T[]> =>
            array.reduce(
                (objectsByKeyValue, obj) => {
                    const value = keys.map((key) => obj[key]).join("-")
                    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj)
                    return objectsByKeyValue
                },
                {} as Record<string, T[]>
            )
    static getCurrencySymbol(currency: string) {
        const currency_symbols: Record<string, string> = {
            USD: "$", // US Dollar
            EUR: "€", // Euro
            GBP: "£", // British Pound Sterling
            JPY: "¥", // Japanese Yen
            CNY: "¥", // Chinese Yuan Renminbi
            AUD: "A$", // Australian Dollar
            CAD: "$", // Canadian Dollar
            BRL: "R$", // Brazilian Real
            MXN: "$", // Mexican Peso
            INR: "₹", // Indian Rupee
            SGD: "$", // Singapore Dollar
            CRC: "₡", // Costa Rican Colón
            ILS: "₪", // Israeli New Sheqel
            KRW: "₩", // South Korean Won
            NGN: "₦", // Nigerian Naira
            PHP: "₱", // Philippine Peso
            PLN: "zł", // Polish Zloty
            PYG: "₲", // Paraguayan Guarani
            THB: "฿", // Thai Baht
            UAH: "₴", // Ukrainian Hryvnia
            VND: "₫", // Vietnamese Dong
        }
        return currency_symbols[currency] || (currency as string)
    }

    static async getJsonResponse(response: Response): Promise<any> {
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`)
        }

        try {
            const jsonData = await response.json()
            return jsonData
        } catch (error) {
            console.error("Failed to parse JSON:", error)
            throw error
        }
    }
}

export const isTokenExpired = (token: string | undefined) => {
    if (!token) return true

    const expiry = JSON.parse(atob(token.split(".")[1])).exp
    return expiry * 1000 < Date.now()
}

export const calculateDays = (datetimeFrom: string) => {
    const dateFrom = new Date(datetimeFrom)
    const dateUntil = new Date()
    const timeDifference = dateUntil.getTime() - dateFrom.getTime()

    const daysDifference = timeDifference / (1000 * 60 * 60 * 24)

    const remainingDays = Math.max(90 - Math.floor(daysDifference), 0)

    return remainingDays
}

export const isMatchingAttributeFormat = (attribute: string) => {
    return !!attribute && /^[A-Z_]+$/.test(attribute)
}

export const getJwtToken = () => {
    return localStorage.getItem("token")
}

export const getNameInitials = (first_name: string, last_name: string) => {
    const firstInitial = first_name[0] || ""
    const lastInitial = last_name[0] || ""
    return firstInitial + lastInitial
}

export const monthYearFormat = (date: string) => {
    const d = new Date(date)
    const month = d.toLocaleString("default", { month: "short" })
    const year = d.getFullYear()
    return `${month} ${year}`
}

export const getASINCount = (asinLength: number) => {
    if (asinLength <= 2) {
        return "T-FREE"
    } else if (asinLength <= 5) {
        return "T-5"
    } else if (asinLength <= 10) {
        return "T-10"
    } else if (asinLength <= 20) {
        return "T-20"
    } else if (asinLength <= 50) {
        return "T-50"
    } else if (asinLength <= 100) {
        return "T-100"
    } else {
        return "T-100"
    }
}

export const getTooltipMessage = (issue: string) => {
    switch (issue) {
        case "LISTING_CHANGE":
            return "Listing Change"
        case "DATA_UNAVAILABLE":
            return "ASIN data"
        case "DIFFERENT_ASIN_RETURNED":
            return "Different ASIN"
        case "CHILD_ASIN_RETURNED":
            return "Child ASIN"
        case "DIFFERENT_LARGE_CATEGORY":
            return "BSR L Cat"
        default:
            return "Unknown issue."
    }
}

export const renderNotificationIcon = (productNotifications: any) => {
    let allNotifications = Object.values(productNotifications)
        .flat()
        .filter((notification: any) => notification?.notification_id)
    let read = allNotifications?.filter((notification: any) => notification?.read_at !== null)
    if (read?.length === 0) {
        return "red"
    } else if (read?.length === allNotifications?.length) {
        return "gray"
    } else {
        return "half"
    }
}

export const getNotificationMessage = (prevTrackpack: any, updatedTrackpack: any): string => {
    const getPrevStatuses = (trackpack: any) =>
        trackpack?.trackpackChildrenList?.map((child: any) => child.Status).flat()

    const getUpdatedStatuses = (data: any): string[] => {
        return data.trackings.map((tracking: any) => tracking.status)
    }

    const hasAllArchived = (statuses: any[]) =>
        statuses?.every((status: any) => status === "ARCHIVED_DURING_PACK_ARCHIVAL" || status === "ARCHIVED")

    const hasMixedStatuses = (statuses: any[]) =>
        statuses?.some((status: any) => status === "ARCHIVED_DURING_PACK_ARCHIVAL" || status === "ARCHIVED") &&
        statuses?.some((status: any) => status === "ACTIVE" || status === "AWAITING_INITIAL_DATA")

    const prevStatuses = getPrevStatuses(prevTrackpack)
    const updatedStatuses = getUpdatedStatuses(updatedTrackpack)

    const prevAllArchived = hasAllArchived(prevStatuses)
    const prevMixedStatuses = hasMixedStatuses(prevStatuses)

    const updatedMixedStatuses = hasMixedStatuses(updatedStatuses)

    if (prevAllArchived && updatedMixedStatuses) {
        return "trackpackMessage"
    }

    if (prevMixedStatuses && updatedMixedStatuses) {
        return "ASINMessage"
    }

    return "No applicable message"
}

export const handleAmazonProductRedirect = (amazonmarketplace: string, id: string) => {
    const amazonTld = amazonmarketplace ?? "com"
    const url = ASIN_AMAZON_LINK_BASE.replace("com", amazonTld) + id
    window.open(url, "_blank")
}

export const extractVideoId = (url: string): string => {
    const match = url?.match(/\/([a-z0-9-]{36})\//i)
    return match ? match[1] : ""
}

export const extractIdentifier = (url: string) => {
    const parts = url.split("/")
    const filename = parts[parts.length - 1]
    return filename.split("_")[0]
}

export function removeObjectAtIndex(array: any, index: any) {
    if (index < 0 || index >= array.length) {
        throw new Error("Index out of bounds")
    }

    array.splice(index, 1)
}

export function ensureMaxLength(arr: any, maxLength: number) {
    if (arr.length > maxLength) {
        return arr.slice(0, maxLength)
    }
    return arr
}

export function determineNotificationType(extractedNotifications: any[]) {
    const allListingChange = extractedNotifications.every((notification) => notification.type === "LISTING_CHANGE")

    if (allListingChange) {
        return "listing_change"
    }

    const allAsinAdded = extractedNotifications.every(
        (notification) => notification.type === "ASIN_READY" && notification.context === "ASINS_ADDED"
    )

    if (allAsinAdded) {
        return "asin_added"
    }

    const anyNewTrackpack = extractedNotifications.some(
        (notification) => notification.type === "ASIN_READY" && notification.context === "NEW_TP"
    )

    if (anyNewTrackpack) {
        return "trackpack_ready"
    }

    const anyTrackpackError = extractedNotifications.some((notification) => AsinIssuesList.includes(notification.type))

    if (anyTrackpackError) {
        return "asin_error"
    }

    return "Unknown Notification Type"
}

export const countOccurrences = <T extends string | number | symbol>(arr: T[]): Record<T, number> => {
    return arr.reduce(
        (acc: Record<T, number>, item: T) => {
            acc[item] = (acc[item] || 0) + 1
            return acc
        },
        {} as Record<T, number>
    )
}

export const clearDynamicMenuIconKeys = () => {
    const keysToRemove = []

    for (let i = 0; i < sessionStorage.length; i++) {
        const key = sessionStorage.key(i)
        if (key?.startsWith("menuIconClicked_")) {
            keysToRemove.push(key)
        }
    }

    keysToRemove.forEach((key) => sessionStorage.removeItem(key))
}

export function updateReadAtForNotifications(
    notificationsData: any[],
    action: { notification_ids: string | any[]; isUnread: boolean }
) {
    const updatedNotifications = notificationsData.map((notificationItem) => {
        const { listing_changes, setup_alerts_and_issues } = notificationItem.product_notifications

        const updatedListingChanges = listing_changes.map((change: { notification_id: string }) => {
            if (action.notification_ids.includes(change.notification_id)) {
                return {
                    ...change,
                    read_at: action.isUnread ? null : new Date().toISOString(),
                }
            }
            return change
        })

        const updatedSetupAlertsAndIssues = setup_alerts_and_issues.map((issue: { notification_id: string }) => {
            if (action.notification_ids.includes(issue.notification_id)) {
                return {
                    ...issue,
                    read_at: action.isUnread ? null : new Date().toISOString(),
                }
            }
            return issue
        })

        return {
            ...notificationItem,
            product_notifications: {
                listing_changes: updatedListingChanges,
                setup_alerts_and_issues: updatedSetupAlertsAndIssues,
            },
        }
    })

    return updatedNotifications
}

export function getlatestProductId(data: IGroupProductData[]) {
    if (data.length === 0) return 0
    return Math.max(...data.map((item) => item?.main_product_data?.product_id!))
}

export const assignColorsToTrackings = (extendedTrackpacks: any) => {
    return extendedTrackpacks?.trackpacks?.map((trackpack: { trackings: any[] }) => {
        const coloredTrackings = trackpack.trackings.map((tracking: Tracking, index: number) => {
            const colorIndex = index % ASIN_PRODUCTS_COLOR.length
            const colors = ASIN_PRODUCTS_COLOR[colorIndex]
            return {
                ...tracking,
                color: colors,
            }
        })

        return {
            ...trackpack,
            trackings: coloredTrackings,
        }
    })
}

export function getColorsFromAsin(selectedDropdownList: any, actualAsin: string) {
    let colorMatch = null

    selectedDropdownList &&
        selectedDropdownList?.trackings.forEach((tracking: { asin: string; color: TAsinProductColor }) => {
            if (tracking.asin === actualAsin) {
                colorMatch = tracking.color
            }
        })

    return colorMatch
}

export type Root = Root2[]

export interface Root2 {
    notification_id: number
    read_at: any
    created_at: string
    requested_asin: string
    main_image_filename: string
    type: string
    context: string
    changes?: string[]
    total_changes?: number
    pd_timestamp?: string
}

// function combineListingChangeNotificationsC(notifications: any) {
//     const combinedNotifications: any = {}

//     notifications?.forEach((notification: any) => {
//         const trackpackId = notification?.trackpack?.id

//         if (!combinedNotifications[trackpackId]) {
//             combinedNotifications[trackpackId] = {
//                 trackpack: notification.trackpack,
//                 setup_alerts_and_issues: notification.product_notifications.setup_alerts_and_issues || [],
//                 listing_changes: [],
//             }
//         }

//         const listingChanges = notification.product_notifications.listing_changes
//         const setupAlertChnages = notification.product_notifications.setup_alerts_and_issues
//         debugger

//         if (listingChanges.length > 0) {
//             combinedNotifications[trackpackId].listing_changes.push(...listingChanges)
//         }
//         if (setupAlertChnages.length > 0) {
//             combinedNotifications[trackpackId].setup_alerts_and_issues.push(...setupAlertChnages)
//         }
//     })

//     // Convert the result back into an array
//     return Object.values(combinedNotifications)
// }

////////////////////////////////////////////////////////////////////////

function removeDuplicatesByNotificationId(notifications: any[], newNotifications: any[]) {
    const existingIds = notifications.map((n) => n.notification_id)
    const filteredNewNotifications = newNotifications.filter((n) => !existingIds.includes(n.notification_id))
    return [...notifications, ...filteredNewNotifications]
}

function combineListingChangeNotificationsC(notifications: any) {
    const combinedNotifications: any = {}

    notifications?.forEach((notification: any) => {
        const trackpackId = notification?.trackpack?.id

        if (!combinedNotifications[trackpackId]) {
            combinedNotifications[trackpackId] = {
                trackpack: notification.trackpack,
                setup_alerts_and_issues: [],
                listing_changes: [],
            }
        }

        const listingChanges = notification.product_notifications.listing_changes || []
        const setupAlertChanges = notification.product_notifications.setup_alerts_and_issues || []

        // Remove duplicates before pushing new notifications
        if (listingChanges.length > 0) {
            combinedNotifications[trackpackId].listing_changes = removeDuplicatesByNotificationId(
                combinedNotifications[trackpackId].listing_changes,
                listingChanges
            )
        }

        if (setupAlertChanges.length > 0) {
            combinedNotifications[trackpackId].setup_alerts_and_issues = removeDuplicatesByNotificationId(
                combinedNotifications[trackpackId].setup_alerts_and_issues,
                setupAlertChanges
            )
        }
    })

    // Convert the result back into an array
    return Object.values(combinedNotifications)
}

// function removeDuplicatesByNotificationId(notifications: any[], newNotifications: any[]) {
//     const existingIds = notifications.map((n) => n.notification_id)
//     const filteredNewNotifications = newNotifications.filter((n) => !existingIds.includes(n.notification_id))
//     return [...notifications, ...filteredNewNotifications]
// }

// function combineListingChangeNotificationsC(notifications: any[]) {
//     const combinedNotifications: any = {}

//     notifications?.forEach((notification: any) => {
//         const trackpackId = notification?.trackpack?.id

//         if (!combinedNotifications[trackpackId]) {
//             combinedNotifications[trackpackId] = {
//                 trackpack: notification.trackpack,
//                 setup_alerts_and_issues: [],
//                 listing_changes: [],
//             }
//         }

//         const listingChanges = notification.product_notifications.listing_changes || []
//         const setupAlertChanges = notification.product_notifications.setup_alerts_and_issues || []

//         // Helper function to group by requested_asin and error type
//         const combineNotifications = (existingNotifications: any[], newNotifications: any[]) => {
//             newNotifications.forEach((newNotification) => {
//                 const { requested_asin, type, created_at } = newNotification

//                 // Check if there's already a notification with the same requested_asin, type, and date
//                 const existingNotification = existingNotifications.find(
//                     (n) =>
//                         n.requested_asin === requested_asin &&
//                         n.type === type &&
//                         n.created_at.split("T")[0] === created_at.split("T")[0]
//                 )

//                 if (existingNotification) {
//                     // If a match exists, we can combine or update if needed (e.g., you can merge data here)
//                     existingNotification.notification_id =
//                         existingNotification.notification_id || newNotification.notification_id
//                 } else {
//                     // Otherwise, add as a new entry
//                     existingNotifications.push(newNotification)
//                 }
//             })

//             return existingNotifications
//         }

//         // Combine listing changes and setup alerts based on requested_asin, error type, and date
//         if (listingChanges.length > 0) {
//             combinedNotifications[trackpackId].listing_changes = combineNotifications(
//                 combinedNotifications[trackpackId].listing_changes,
//                 listingChanges
//             )
//         }

//         if (setupAlertChanges.length > 0) {
//             combinedNotifications[trackpackId].setup_alerts_and_issues = combineNotifications(
//                 combinedNotifications[trackpackId].setup_alerts_and_issues,
//                 setupAlertChanges
//             )
//         }
//     })

//     // Convert the result back into an array
//     return Object.values(combinedNotifications)
// }

///////////////////////////////////////////////////////////
function mergeDataByKeys(dataArray: any[], uniqueKeys: string[], idKey: string) {
    let mergedData: any[] = []

    dataArray.forEach((obj: any) => {
        if (mergedData.length === 0) {
            mergedData.push({
                ...obj,
                [idKey]: [obj[idKey]],
            })
        } else {
            let exists = mergedData.find((mergedObj: any) => uniqueKeys.every((key) => mergedObj[key] === obj[key]))

            if (exists) {
                exists[idKey].push(obj[idKey])
            } else {
                mergedData.push({
                    ...obj,
                    [idKey]: [obj[idKey]],
                })
            }
        }
    })

    return mergedData
}

//////////////////////////////////////////////////////////

export function transformNotificationsRecent(apiRespons: Notification[]) {
    const apiResponse: any = combineListingChangeNotificationsC(apiRespons)
    const result: any = []
    let notificationCounter = 1

    // Iterate over the API response
    apiResponse?.forEach((notification: any) => {
        const { trackpack } = notification
        const { listing_changes, setup_alerts_and_issues } = notification

        const allNotifications = [...listing_changes, ...setup_alerts_and_issues]

        // Group notifications by trackpack_id and date
        const groupedByTrackpackAndDate = allNotifications.reduce((acc: any, currentNotification: any) => {
            const notificationDate = currentNotification?.created_at?.split("T")[0]
            const trackpackId = trackpack.id

            const key = `${trackpackId}_${notificationDate}`

            if (!acc[key]) {
                acc[key] = { trackpack, notifications: [] }
            }

            acc[key].notifications.push(currentNotification)

            return acc
        }, {})

        // Sort dates in descending order
        const sortedKeys = Object.keys(groupedByTrackpackAndDate).sort((a, b) => {
            const dateA = a.split("_")[1]
            const dateB = b.split("_")[1]
            return new Date(dateB).getTime() - new Date(dateA).getTime()
        })

        // Process grouped notifications
        sortedKeys.forEach((key: string) => {
            const { trackpack, notifications } = groupedByTrackpackAndDate[key]

            // Filter and categorize notifications
            const listingChangesArray = notifications.filter((n: Root2) => n.type === "LISTING_CHANGE")
            const setupAlertsArray = notifications.filter(
                (n: Root2) =>
                    [...AsinIssuesList].includes(n.type) && n.context !== "NEW_TP" && n.context === "REGULAR_TRACKING"
            )
            const newTp = notifications.filter((n: Root2) => ["NEW_TP"].includes(n.context))
            const asinAdded = notifications.filter((n: Root2) => ["ASINS_ADDED"].includes(n.context))

            // Add each category to the final transformed notification
            if (listingChangesArray.length > 0) {
                result.push({
                    trackpack: {
                        ...trackpack,
                        notificationNumber: notificationCounter++,
                    },
                    listing_changes: listingChangesArray,
                })
            }

            if (setupAlertsArray.length > 0) {
                result.push({
                    trackpack: {
                        ...trackpack,
                        notificationNumber: notificationCounter++,
                    },
                    setup_alerts_and_issues: setupAlertsArray,
                })
            }

            if (newTp.length > 0) {
                result.push({
                    trackpack: {
                        ...trackpack,
                        notificationNumber: notificationCounter++,
                    },
                    newTp,
                })
            }

            if (asinAdded.length > 0) {
                result.push({
                    trackpack: {
                        ...trackpack,
                        notificationNumber: notificationCounter++,
                    },
                    asinAdded,
                })
            }
        })
    })
    const sortedData = result.sort((a: any, b: any) => {
        const aDate =
            (a.asinAdded?.[0]?.created_at && new Date(a.asinAdded[0].created_at).getTime()) ||
            (a.listing_changes?.[0]?.created_at && new Date(a.listing_changes[0].created_at).getTime()) ||
            (a.newTp?.[0]?.created_at && new Date(a.newTp[0].created_at).getTime()) ||
            (a.setup_alerts_and_issues?.[0]?.created_at && new Date(a.setup_alerts_and_issues[0].created_at).getTime())

        const bDate =
            (b.asinAdded?.[0]?.created_at && new Date(b.asinAdded[0].created_at).getTime()) ||
            (b.listing_changes?.[0]?.created_at && new Date(b.listing_changes[0].created_at).getTime()) ||
            (b.newTp?.[0]?.created_at && new Date(b.newTp[0].created_at).getTime()) ||
            (b.setup_alerts_and_issues?.[0]?.created_at && new Date(b.setup_alerts_and_issues[0].created_at).getTime())

        return (bDate || 0) - (aDate || 0)
    })
    let updatedData = sortedData.map((data: any) => {
        if ("setup_alerts_and_issues" in data) {
            return {
                trackpack: data.trackpack,
                setup_alerts_and_issues: mergeDataByKeys(
                    data?.setup_alerts_and_issues,
                    ["requested_asin", "type"],
                    "notification_id"
                ),
            }
        } else {
            return data
        }
    })

    return updatedData
}

function combineListingChangeNotifications(notifications: any[]) {
    return notifications.reduce(
        (
            acc: { [x: string]: any[] },
            notification: { created_at: string; requested_asin: any; notification_id: any; changes: string[] }
        ) => {
            const date = notification.created_at.split("T")[0]

            if (!acc[date]) {
                acc[date] = []
            }

            const existingNotification = acc[date].find(
                (n: { type: string; requested_asin: any }) =>
                    n.type === "LISTING_CHANGE" && n.requested_asin === notification.requested_asin
            )

            if (existingNotification) {
                if (!Array.isArray(existingNotification.notification_id)) {
                    existingNotification.notification_id = [existingNotification.notification_id]
                }
                existingNotification.notification_id.push(notification.notification_id)

                const changeCount: { [key: string]: number } = {}
                existingNotification.changes?.forEach((change: string) => {
                    changeCount[change] = (changeCount[change] || 0) + 1
                })
                notification.changes.forEach((change: string) => {
                    changeCount[change] = (changeCount[change] || 0) + 1
                })

                existingNotification.changes = Object.entries(changeCount).reduce(
                    (acc: string[], [change, count]) => [...acc, ...Array(count).fill(change)],
                    []
                )
            } else {
                notification.changes = notification.changes || []
                acc[date].push(notification)
            }

            return acc
        },
        {} as { [date: string]: Array<Notification & { type: string }> }
    )
}

function groupNotificationsByDate(apiResponse: any) {
    return apiResponse.map((item: any) => {
        const result: any = {
            trackpackInfo: item.trackpackInfo,
        }

        const groupedNotifications = combineListingChangeNotifications(item.notifications)
        const sortedDates = Object.keys(groupedNotifications).sort(
            (a, b) => new Date(b).getTime() - new Date(a).getTime()
        )

        sortedDates.forEach((date) => {
            if (!result[date]) {
                result[date] = []
            }
            result[date] = groupedNotifications[date]
        })
        return result
    })
}

export function combineNotificationsTarckpack(apiRespons: any) {
    const apiResponse: any = combineListingChangeNotificationsC(apiRespons && JSON.parse(JSON.stringify(apiRespons)))

    const result: any = []
    let notificationCounter = 1

    apiResponse?.forEach((item: any) => {
        const trackpackInfo = {
            id: item.trackpack.id,
            user: item.trackpack.user,
            name: item.trackpack.name,
            status: item.trackpack.status,
            amazon_tld: item.trackpack.amazon_tld,
            large_category: item.trackpack.large_category,
            created_at: item.trackpack.created_at,
            last_updated_at: item.trackpack.last_updated_at,
            next_tracking_order: item.trackpack.next_tracking_order,
            notificationNumber: notificationCounter++,
        }

        const notifications = item?.listing_changes.concat(item.setup_alerts_and_issues) || []

        // Create separate arrays for different notification types
        const asinReadyNotifications: any = []
        const specialTypeNotifications: any = []
        const listingChangeNotifications: any = []
        const newTrackpackNotifications: any = []

        notifications.forEach((notification: any) => {
            if (notification.type === "ASIN_READY") {
                asinReadyNotifications.push(notification)
            } else if (
                [
                    "DIFFERENT_ASIN_RETURNED",
                    "CHILD_ASIN_RETURNED",
                    "DATA_UNAVAILABLE",
                    "DIFFERENT_LARGE_CATEGORY",
                ].includes(notification.type)
            ) {
                specialTypeNotifications.push(notification)
            } else if (notification.type === "LISTING_CHANGE") {
                listingChangeNotifications.push(notification)
            } else if (notification.context === "NEW_TP") {
                newTrackpackNotifications.push(notification)
            }
        })

        if (asinReadyNotifications.length > 0) {
            result.push({
                trackpackInfo: {
                    ...trackpackInfo,
                    notificationNumber: notificationCounter++,
                },
                notifications: asinReadyNotifications,
            })
        }

        if (specialTypeNotifications.length > 0) {
            result.push({
                trackpackInfo: {
                    ...trackpackInfo,
                    notificationNumber: notificationCounter++,
                },
                notifications: specialTypeNotifications,
            })
        }

        if (listingChangeNotifications.length > 0) {
            result.push({
                trackpackInfo: {
                    ...trackpackInfo,
                    notificationNumber: notificationCounter++,
                },
                notifications: listingChangeNotifications,
            })
        }
        if (newTrackpackNotifications.length > 0) {
            result.push({
                trackpackInfo: {
                    ...trackpackInfo,
                    notificationNumber: notificationCounter++,
                },
                notifications: newTrackpackNotifications,
            })
        }
    })

    const data = groupNotificationsByDate(result)
    const sortedResult = data.sort((a: {}, b: {}) => {
        const dateA = Object.keys(a).find((key) => key.includes("-"))
        const dateB = Object.keys(b).find((key) => key.includes("-"))
        // @ts-ignore
        return new Date(dateB).getTime() - new Date(dateA).getTime()
    })

    const mergeNotifications = (data: any) => {
        data.forEach((trackpack: any) => {
            const dateKeys = Object.keys(trackpack).filter((key) => key !== "trackpackInfo")

            dateKeys.forEach((date) => {
                const notifications = trackpack[date]
                const merged = {}

                notifications.forEach((notification: any) => {
                    const key = `${notification.requested_asin}-${notification.type}`
                    // @ts-ignore
                    if (!merged[key]) {
                        // @ts-ignore
                        merged[key] = {
                            ...notification,
                            notification_id: [notification.notification_id],
                        }
                    } else {
                        // @ts-ignore
                        merged[key].notification_id.push(notification.notification_id)
                    }
                })

                trackpack[date] = Object.values(merged).map((item: any) => ({
                    ...item,
                    notification_id: item.notification_id,
                }))
            })
        })
    }

    mergeNotifications(sortedResult)

    return sortedResult
}

export const checkArchivedStatusAndNavigate = (
    trackpacks: any[],
    trackpackId: number | string,
    requested_asin: string | string[]
) => {
    const selectedObject = trackpacks?.find(
        (item: { metadata: { id: number | string } }) => item.metadata.id == trackpackId
    )
    const requestedAsinsArray = Array.isArray(requested_asin) ? requested_asin : [requested_asin]
    if (selectedObject) {
        const isArchived = requestedAsinsArray.some(
            (asin) =>
                selectedObject.trackings?.some(
                    (tracking: { asin: string; status: string }) =>
                        tracking.asin === asin && tracking.status === "ARCHIVED"
                )
        )
        if (isArchived) {
            localStorage.setItem("trackpackHubId", trackpackId.toString())
            localStorage.setItem("trackpackHubAsin", JSON.stringify(requestedAsinsArray))
            return true
        }
    }

    return false
}

import { useSelector } from "react-redux"
import { RunTimeStateSelector } from "../../../../selectors/RunTimeStateSelector"
import ArchiveStepOne from "./archiveStepOne.component"
import ArchiveStepTwo from "./archiveStepTwo.component"

const ArchiveOutlet = () => {
    const { archiveState } = useSelector(RunTimeStateSelector)

    const getModalBody = () => {
        switch (archiveState?.stepNumber) {
            case "one":
                return <ArchiveStepOne />
            case "two":
                return <ArchiveStepTwo />
            default:
                return <></>
        }
    }

    return <>{archiveState?.open ? <>{getModalBody()}</> : null}</>
}

export default ArchiveOutlet

import { useMemo } from "react"
import { twMerge } from "tailwind-merge"

import { PrimaryText } from "./primaryText.element"

export const ButtonElement = (props: IButtonElementProps) => {
    const buttonStyle = useMemo<{
        buttonClass: string
        viewType: string
        textProps?: IPrimaryTextProps
    }>(() => {
        let buttonClass = ""
        let viewType = ""
        let textProps: IPrimaryTextProps | undefined = undefined

        switch (props.size) {
            case "small":
                buttonClass = "px-[4px] py-[8px] rounded"
                textProps = {
                    size: "xs",
                    weight: "medium",
                    className: props.textClass,
                }
                break
            case "medium":
                buttonClass = "px-[24px] py-[8px] rounded-lg"
                textProps = {
                    size: "small",
                    weight: "medium",
                    className: props.textClass,
                }
                break
            case "large":
                buttonClass = "px-[24px] py-[6px] rounded-xl "
                textProps = {
                    size: "lg",
                    weight: "medium",
                    className: `${props.textClass} xs:text-[0.875rem] sm:text-[0.875rem] md:text-[1.125rem] lg:text-[1.125rem]`,
                }
                break
        }

        switch (props.viewType) {
            case "secondary":
                viewType = `
                border
                bg-cyan-50  border-cyan-300
                [&>*]:text-cyan-500
                
                hover:bg-cyan-100 hover:border-cyan-300
                
                active:bg-cyan-200 active:border-cyan-300 [&>*]:active:text-cyan-600 
                
                disabled:bg-gray-100 disabled:border-gray-300 [&>*]:disabled:text-gray-300
                `
                break
            case "post-icon":
                viewType = `
                    hover:bg-none
                    `
                break
            case "stay-same":
                viewType = ``
                break
            case "hover-state":
                viewType = `
                        hover:bg-modal-btn-gradient
                        `
                break
            case "button-text":
                viewType = `
                        text-[16px] font-[500]
                        `
                break
            case "loading-button":
                viewType = `
                            !bg-[#A5F0FC] !cursor-not-allowed !pointer-events-none !w-[106px] !h-[40px] flex items-center justify-center
                            `
                break
            case "trackpack-loading-button":
                viewType = `
                            !bg-[#A5F0FC] !cursor-not-allowed !pointer-events-none !w-[268px] !h-[44px] flex items-center justify-center
                            `
                break
            case "only-text":
                viewType = `
                !p-0 
                [&>*]:text-cyan-500
                  [&>*]:hover:text-cyan-600
                   [&>*]:active:text-cyan-800
                     [&>*]:disabled:text-gray-300`
                break
            case "only-icon":
                viewType = `
                disabled:opacity-[0.5]
                    `
                break
            default:
                viewType = `
                bg-button-gradient
                hover:bg-gradient-to-r hover:from-cyan-200 hover:to-cyan-300
                
                active:from-cyan-300 active:to-cyan-400 [&>*]:active:text-cyan-800
                
                disabled:bg-none disabled:bg-gray-100 [&>*]:disabled:text-gray-400
                `
                break
        }

        return { buttonClass, textProps, viewType }
    }, [props.size, props.viewType, props.textClass])

    return (
        <div className={`${props.parentClass ? props.parentClass : "relative"}`}>
            {props.prefix && props.prefix}

            <button
                type={props.type}
                className={twMerge(
                    buttonStyle.buttonClass,
                    buttonStyle.viewType,
                    "justify-center items-center inline-flex",
                    props.loading && "cursor-not-allowed bg-[#A5F0FC] opacity-100",
                    props.size === "large" && props.postfix
                        ? "pl-[18px] pr-[10px] py-[8px] rounded-xl"
                        : props.size === "medium" && props.postfix
                        ? "px-[12px] py-[8px] rounded-lg"
                        : props.size === "small" && props.postfix
                        ? " px-[8px] py-[4px] rounded"
                        : "",
                    props.className
                )}
                onClick={!props.disabled ? props.onClick : undefined}
                disabled={props.disabled}
            >
                {props.viewType === "only-icon" ? (
                    props.children
                ) : props.viewType === "loading-button" ? (
                    <div className="w-6 h-6 rounded-full animate-spin border  border-[#0E7090] border-t-transparent"></div>
                ) : props.viewType === "trackpack-loading-button" ? (
                    <div className="w-6 h-6 rounded-full animate-spin border cursor-not-allowed  border-[#FFFFFF] border-t-transparent"></div>
                ) : (
                    <PrimaryText {...buttonStyle.textProps}>{props.children}</PrimaryText>
                )}

                {props.postfix && (
                    <img src={props.postfix} alt="postfix icon" className={"flex items-center justify-center"} />
                )}
                {props.postIcon && (
                    <div className={`${props.postIcon && "pl-[8px]"}`}>{props.postIcon && props.postIcon}</div>
                )}
            </button>
        </div>
    )
}

import { AnimatedAmaizingIcon } from "../../../../assets/svgs/amaizing-bubble.svg"
import { TourCloseIcon } from "../../../../assets/svgs/tourCloseIcon.svg"
import { PrimaryText } from "../../../elements/primaryText.element"
import TrackpackHubQuickTourContent from "./trackpackHubQuickTour.component"

export const QuickTourTrackpackModal = ({ onClose }: any) => {
    const displayCloseIcon = true

    return (
        <>
            <div className="">
                <div className="flex justify-between w-full mb-[26px]">
                    <div className="flex items-center space-x-2">
                        <AnimatedAmaizingIcon className={"h-[26px] w-[28px]"} />
                        <PrimaryText size="small" weight="medium" className="text-white">
                            Quick Tour
                        </PrimaryText>
                    </div>
                    {displayCloseIcon && (
                        <button className=" text-gray-400 hover:text-gray-600" onClick={onClose}>
                            <TourCloseIcon />
                        </button>
                    )}
                </div>
                <TrackpackHubQuickTourContent displayCloseIcon={displayCloseIcon} onClose={onClose} />
            </div>
        </>
    )
}

export default QuickTourTrackpackModal

export const LogCarusolIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="layers-three-01" clip-path="url(#clip0_1878_18550)">
                    <path
                        id="Icon"
                        d="M1 6.00003L5.82111 8.41059C5.88671 8.44339 5.9195 8.45978 5.9539 8.46624C5.98437 8.47195 6.01563 8.47195 6.0461 8.46624C6.0805 8.45978 6.11329 8.44339 6.17889 8.41059L11 6.00003M1 8.50003L5.82111 10.9106C5.88671 10.9434 5.9195 10.9598 5.9539 10.9662C5.98437 10.972 6.01563 10.972 6.0461 10.9662C6.0805 10.9598 6.11329 10.9434 6.17889 10.9106L11 8.50003M1 3.50003L5.82111 1.08948C5.88671 1.05668 5.9195 1.04028 5.9539 1.03383C5.98437 1.02811 6.01563 1.02811 6.0461 1.03383C6.0805 1.04028 6.11329 1.05668 6.17889 1.08948L11 3.50003L6.17889 5.91059C6.11329 5.94339 6.0805 5.95978 6.0461 5.96624C6.01563 5.97195 5.98437 5.97195 5.9539 5.96624C5.9195 5.95978 5.88671 5.94339 5.82111 5.91059L1 3.50003Z"
                        stroke={props.color}
                        stroke-width="0.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
                <defs>
                    <clipPath id="clip0_1878_18550">
                        <rect width="16" height="16" fill={props.color} />
                    </clipPath>
                </defs>
            </svg>
        </>
    )
}

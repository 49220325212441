import PerformanceTrackerTourOutlet from "./ptQuickTourOutlet.comopnent"
import { PerformanceTrackerQuickTourWrapper } from "./ptQuickTourWrapperModal.component"

const PerformanceTrackerQuickTourModalComponent = () => {
    return (
        <PerformanceTrackerQuickTourWrapper>
            <PerformanceTrackerTourOutlet />
        </PerformanceTrackerQuickTourWrapper>
    )
}

export default PerformanceTrackerQuickTourModalComponent

import { useTranslation } from "react-i18next"
import { Outlet, useNavigation, useSearchParams } from "react-router-dom"

import { useEffect } from "react"
import { Header } from "../components/header.component"
import LoadingComponent from "../loading.component"

export const MarketingContainer = () => {
    let navigation = useNavigation()
    let [searchParams] = useSearchParams()

    useEffect(() => {
        if (searchParams?.get("utm_source")?.toLowerCase()?.includes("linkedin")) {
            sessionStorage.setItem("utm_source", searchParams?.get("utm_source") as string)
            ;(window as any)._linkedin_partner_id = "6888505"
            ;(window as any)._linkedin_data_partner_ids = (window as any)._linkedin_data_partner_ids || []
            ;(window as any)._linkedin_data_partner_ids.push((window as any)._linkedin_partner_id)

            // LinkedIn Insight script
            ;(function (l: any) {
                if (!l) {
                    // @ts-ignore
                    window.lintrk = function (a: any, b: any) {
                        // @ts-ignore
                        window.lintrk.q.push([a, b])
                    }
                    // @ts-ignore
                    window.lintrk.q = []
                }
                var s = document.getElementsByTagName("script")[0]
                var b = document.createElement("script")
                b.type = "text/javascript"
                b.async = true
                b.src = "https://snap.licdn.com/li.lms-analytics/insight.min.js"
                s.parentNode!.insertBefore(b, s)
                // @ts-ignore
            })(window.lintrk)
        }
    }, [searchParams])

    const { t } = useTranslation("input")

    return (
        <div className=" bg-gray-50 md:bg-white h-[100vh]">
            <Header />
            <div className="content xl:px-[32px] xs:px-[20px] sm:px-[20px] xl:pb-[24px] xs:pb-[20px] sm:pb-[20px] md:pb-[20px] lg:pb-[24px]">
                {navigation.state !== "idle" ? <LoadingComponent /> : null}
                <Outlet />
            </div>
            {searchParams?.get("utm_source")?.toLowerCase()?.includes("linkedin") ? (
                <>
                    <img
                        height="1"
                        width="1"
                        style={{ display: "none" }}
                        alt=""
                        src="https://px.ads.linkedin.com/collect/?pid=6888505&fmt=gif"
                    />
                </>
            ) : null}
        </div>
    )
}

export const PopupPlayIcon = (props: IArrowProps) => {
    return (
        <svg width="50" height="50" viewBox="0 0 50 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_b_1751_4935)">
                <g filter="url(#filter1_dd_1751_4935)">
                    <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M24.9538 40.0013C35.2631 40.0013 43.6204 31.644 43.6204 21.3346C43.6204 11.0253 35.2631 2.66797 24.9538 2.66797C14.6445 2.66797 6.28711 11.0253 6.28711 21.3346C6.28711 31.644 14.6445 40.0013 24.9538 40.0013ZM22.0371 28.1816L32.5371 22.3128C33.3149 21.8781 33.3149 20.7912 32.5371 20.3565L22.0371 14.4876C21.2593 14.0529 20.2871 14.5963 20.2871 15.4658L20.2871 27.2035C20.2871 28.073 21.2593 28.6164 22.0371 28.1816Z"
                        fill="white"
                    />
                </g>
            </g>
            <defs>
                <filter
                    id="filter0_b_1751_4935"
                    x="-5.71289"
                    y="-9.33203"
                    width="61.334"
                    height="61.3335"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feGaussianBlur in="BackgroundImageFix" stdDeviation="6" />
                    <feComposite in2="SourceAlpha" operator="in" result="effect1_backgroundBlur_1751_4935" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_backgroundBlur_1751_4935" result="shape" />
                </filter>
                <filter
                    id="filter1_dd_1751_4935"
                    x="0.287109"
                    y="0.667969"
                    width="49.334"
                    height="49.3335"
                    filterUnits="userSpaceOnUse"
                    color-interpolation-filters="sRGB"
                >
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect1_dropShadow_1751_4935" />
                    <feOffset dy="2" />
                    <feGaussianBlur stdDeviation="2" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.06 0"
                    />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_1751_4935" />
                    <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feMorphology radius="2" operator="erode" in="SourceAlpha" result="effect2_dropShadow_1751_4935" />
                    <feOffset dy="4" />
                    <feGaussianBlur stdDeviation="4" />
                    <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0.0627451 0 0 0 0 0.0941176 0 0 0 0 0.156863 0 0 0 0.1 0"
                    />
                    <feBlend mode="normal" in2="effect1_dropShadow_1751_4935" result="effect2_dropShadow_1751_4935" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect2_dropShadow_1751_4935" result="shape" />
                </filter>
            </defs>
        </svg>
    )
}

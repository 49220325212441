import { useEffect, useState } from "react"
import { useLocation, useNavigate } from "react-router-dom"

import { useDispatch } from "react-redux"
import { AuthActionCreator } from "../../actions/auth.action"
import { EmailIcon } from "../../assets/svgs/email.svg"
import { EmailSentIcon } from "../../assets/svgs/emailSentIcon.svg"
import { ROUTES_CONFIG } from "../../config/routes.config"
import { ButtonElement } from "../elements/button.element"
import { PrimaryText } from "../elements/primaryText.element"

interface CheckEmailProps {
    isRecoverPassword: boolean
}

export const CheckEmail = (isRecoverPassword: CheckEmailProps) => {
    const dispatch = useDispatch()
    const location = useLocation()
    const [isLoading, setIsLoading] = useState(false)
    const [showResendMessage, setShowResendMessage] = useState(true)
    const navigate = useNavigate()
    const queryParams = new URLSearchParams(location.search)
    const token = queryParams.get("token")
    const userEmail = localStorage.getItem("userEmail")

    useEffect(() => {
        if (isRecoverPassword.isRecoverPassword && token) {
            navigate(ROUTES_CONFIG.resetPassword, { state: { token: token } })
        } else if (token) {
            const payload = { token: token }

            dispatch(
                AuthActionCreator.verifyEmail(payload, () => {
                    navigate(ROUTES_CONFIG.verifiedEmail)
                    localStorage.removeItem("userEmail")
                })
            )
        }
    }, [token, dispatch, navigate])

    const handleResend = () => {
        setShowResendMessage(false)
        setIsLoading(true)
        dispatch(AuthActionCreator.resendVerifyEmail({ email: userEmail }))
        setTimeout(() => {
            setIsLoading(false)
            setShowResendMessage(true)
        }, 5000)
    }

    return (
        <>
            {/* warning email send notification */}
            {!showResendMessage && (
                <div
                    className="py-[4px] pl-[4px] pr-[12px] m-auto max-w-[396px] rounded-[12px] border border-[#FEC84B] bg-[#FFFAEB]"
                    style={{ boxShadow: "0px 4px 8px 4px #1018281A" }}
                >
                    <div className="flex items-center gap-[12px]">
                        <div className="flex items-center gap-[4px] rounded-[8px] border border-[#FEC84B] bg-white py-[2px] px-[8px] pl-[4px]">
                            <svg
                                width="13"
                                height="12"
                                viewBox="0 0 13 12"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <circle cx="6.5" cy="6" r="4.5" fill="#F79009" stroke="#FEDF89" stroke-width="3" />
                            </svg>

                            <PrimaryText
                                weight="light"
                                size="small"
                                className="text-[#B54708] pt-[2px] whitespace-nowrap"
                            >
                                Email Sent
                            </PrimaryText>
                        </div>
                        <div className="flex items-center gap-[4px] py-[2px]">
                            <PrimaryText weight="light" size="small" className="text-[#B54708] whitespace-nowrap">
                                Please wait a few minutes before retrying
                            </PrimaryText>
                            <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.5 6.46882V9.39182M8.5 11.1456H8.50585M7.69052 3.48252L2.88225 11.7877C2.61555 12.2484 2.4822 12.4787 2.50191 12.6677C2.5191 12.8326 2.60549 12.9825 2.73957 13.08C2.89329 13.1917 3.15944 13.1917 3.69173 13.1917H13.3083C13.8406 13.1917 14.1067 13.1917 14.2604 13.08C14.3945 12.9825 14.4809 12.8326 14.4981 12.6677C14.5178 12.4787 14.3845 12.2484 14.1178 11.7877L9.30948 3.48252C9.04374 3.02352 8.91087 2.79401 8.73752 2.71693C8.58631 2.64969 8.41369 2.64969 8.26248 2.71693C8.08913 2.79401 7.95626 3.02352 7.69052 3.48252Z"
                                    stroke="#FDB022"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            )}
            {/* end warning email send notification */}

            {/* email send notification */}
            {showResendMessage && (
                <div
                    className="py-[4px] pl-[4px] pr-[12px] m-auto max-w-[300px] rounded-[12px] border border-[#A5F0FC] bg-[#ECFDFF]"
                    style={{ boxShadow: "0px 4px 8px 4px #1018281A" }}
                >
                    <div className="flex items-center gap-[12px]">
                        <div className="flex items-center gap-[4px] rounded-[8px] border border-[#A5F0FC] bg-white py-[2px] px-[8px] pl-[4px]">
                            <EmailSentIcon />
                            <PrimaryText
                                weight="light"
                                size="small"
                                className="text-[#0E7090] pt-[2px] whitespace-nowrap"
                            >
                                Email Sent
                            </PrimaryText>
                        </div>
                        <div className="flex items-center gap-[4px] py-[2px]">
                            <PrimaryText weight="light" size="small" className="text-[#0E7090] whitespace-nowrap">
                                Please check your inbox
                            </PrimaryText>
                            <svg
                                width="17"
                                height="16"
                                viewBox="0 0 17 16"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M1.83325 4.66675L7.27653 8.47704C7.71731 8.78559 7.9377 8.93986 8.17743 8.99962C8.38918 9.0524 8.61066 9.0524 8.82241 8.99962C9.06213 8.93986 9.28252 8.78559 9.7233 8.47704L15.1666 4.66675M5.03325 13.3334H11.9666C13.0867 13.3334 13.6467 13.3334 14.0746 13.1154C14.4509 12.9237 14.7569 12.6177 14.9486 12.2414C15.1666 11.8136 15.1666 11.2535 15.1666 10.1334V5.86675C15.1666 4.74664 15.1666 4.18659 14.9486 3.75877C14.7569 3.38244 14.4509 3.07648 14.0746 2.88473C13.6467 2.66675 13.0867 2.66675 11.9666 2.66675H5.03325C3.91315 2.66675 3.35309 2.66675 2.92527 2.88473C2.54895 3.07648 2.24299 3.38244 2.05124 3.75877C1.83325 4.18659 1.83325 4.74664 1.83325 5.86675V10.1334C1.83325 11.2535 1.83325 11.8136 2.05124 12.2414C2.24299 12.6177 2.54895 12.9237 2.92527 13.1154C3.35309 13.3334 3.91315 13.3334 5.03325 13.3334Z"
                                    stroke="#06AED4"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                />
                            </svg>
                        </div>
                    </div>
                </div>
            )}
            {/* end email send notification */}

            <div className="w-[400px] bg-[#ECFDFF] p-[40px] pb-[32px] m-auto rounded-[16px] mt-[96px] custom-shadow">
                <div className="flex justify-center items-center">
                    <EmailIcon />
                </div>
                <div className="mt-[24px] flex justify-center mb-[8px]">
                    <PrimaryText typography={"3xl"} className="leading-[38px] text-gray-900">
                        Check your email
                    </PrimaryText>
                </div>

                {isRecoverPassword?.isRecoverPassword ? (
                    <PrimaryText size={"md"} weight={"light"} className="text-gray-600 text-center">
                        If this email belongs to an Amaizing user,  a password reset link has just been sent.
                    </PrimaryText>
                ) : (
                    <PrimaryText size={"md"} weight={"light"} className="text-gray-600 text-center">
                        Almost there. Just verify your email address via the link in the email I've just sent to you.
                    </PrimaryText>
                )}

                <div className="flex justify-center items-center gap-[4px] h-[20px] mt-[40px]">
                    <PrimaryText size="small" weight="light" className="text-gray-600">
                        Didn’t receive the email?
                    </PrimaryText>
                    <ButtonElement viewType="only-text" size="medium" className="mb-[3px]" onClick={handleResend}>
                        Click to resend
                    </ButtonElement>
                </div>
            </div>
        </>
    )
}

export const LogSellerInfoIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 16 16" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M7.91797 9.88905C5.92654 9.88905 4.15558 10.8528 3.02809 12.3484C2.78543 12.6703 2.66409 12.8312 2.66806 13.0487C2.67113 13.2168 2.77642 13.4288 2.90834 13.5325C3.0791 13.6668 3.31573 13.6668 3.78899 13.6668H12.047C12.5202 13.6668 12.7568 13.6668 12.9276 13.5325C13.0595 13.4288 13.1648 13.2168 13.1679 13.0487C13.1718 12.8312 13.0505 12.6703 12.8078 12.3484C11.6804 10.8528 9.9094 9.88905 7.91797 9.88905Z"
                    stroke={props.color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
                <path
                    d="M7.91797 8.00016C9.47921 8.00016 10.7448 6.73164 10.7448 5.16683C10.7448 3.60202 9.47921 2.3335 7.91797 2.3335C6.35673 2.3335 5.09109 3.60202 5.09109 5.16683C5.09109 6.73164 6.35673 8.00016 7.91797 8.00016Z"
                    stroke={props.color}
                    stroke-linecap="round"
                    stroke-linejoin="round"
                />
            </svg>
        </>
    )
}

import { OnBoardingUserActions } from "../actions/onBoardingUser.action"
import { OnboardingUserStateClass } from "../classes/onBoardingUser.class"

//Get Data from guess account
export const OnBoardingUserReducer = (
    state: IOnboardingState = new OnboardingUserStateClass(), //initial state set through class
    action: ISagaAction
): Partial<IOnboardingState> => {
    switch (action.type) {
        case OnBoardingUserActions.CREATE_ONBOARDING_USER:
            return {
                ...state,
                ...action.payload,
            }
        case OnBoardingUserActions.GET_ONBOARDING_USER:
            return {
                ...state,
            }

        default:
            return state
    }
}

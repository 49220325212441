import { AnimatedAmaizingIcon } from "../../assets/svgs/amaizing-bubble.svg"
import { BubbleListToTop, BubblesList } from "../elements/bubblesList.element"

export const MarketingHeader = () => {
    return (
        <BubbleListToTop>
            <BubblesList
                className="xs:hidden sm:hidden md:block"
                interval={1000}
                conversationItems={[
                    { src: <AnimatedAmaizingIcon className={"h-[46px] w-[48px]"} />, srcType: "component" },
                ]}
            />
        </BubbleListToTop>
    )
}

import { Dialog, Transition } from "@headlessui/react"
import { Fragment, ReactNode } from "react"
import { useDispatch, useSelector } from "react-redux"

import { RuntimeActionCreators } from "../../../../../actions/runTime.action"
import { RunTimeStateSelector } from "../../../../../selectors/RunTimeStateSelector"

export const PerformanceTrackerQuickTourWrapper = (props: { children?: ReactNode; className?: string }) => {
    const dispatch = useDispatch()
    const { performanceTrackerQuickTour } = useSelector(RunTimeStateSelector)
    const { children, className } = props

    const handleCloseDialog = () => {
        dispatch(RuntimeActionCreators.closePerformanceTourModal())
    }

    return (
        <Transition appear show={performanceTrackerQuickTour?.open ?? false} as={Fragment}>
            <Dialog as="div" className="relative !z-50" onClose={handleCloseDialog}>
                <div className="fixed inset-0 h-screen w-screen">
                    <div className="min-h-full">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className={className}>{children}</Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    )
}

export enum OnBoardingUserActions {
    CREATE_ONBOARDING_USER = "CREATE_ONBOARDING_USER",
    GET_ONBOARDING_USER = "GET_ONBOARDING_USER",
    VERIFY_ALPHA_EMAIL = "VERIFY_ALPHA_EMAIL",
    ALPHA_USER_REGISTER = "ALPHA_USER_REGISTER",
}

export const OnboardingUserActionCreators = {
    createOnboardingUser: (userData: IOnBoardUser): ISagaAction => {
        return {
            type: OnBoardingUserActions.CREATE_ONBOARDING_USER,
            payload: userData,
        }
    },
    fetchOnboardingUser: (keyPrefix: string): ISagaAction => {
        return {
            type: OnBoardingUserActions.GET_ONBOARDING_USER,
            payload: keyPrefix,
        }
    },
    verifyAlphaEmail: (payload: {email: string}, onSuccess?: () => void, onError?: (data:{message:string, code?:number}) => void ): ISagaAction => {
        return {
            type: OnBoardingUserActions.VERIFY_ALPHA_EMAIL,
            payload,
            onSuccess,
            onError
        }
    },
    alphaUserRegister: (payload: Partial<AlphaUser>, onSuccess?: () => void, onError?: (data:{message:string}) => void ): ISagaAction => {
        return {
            type: OnBoardingUserActions.ALPHA_USER_REGISTER,
            payload,
            onSuccess,
            onError
        }
    },
}

export enum NotificationsAction {
    GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS",
    SET_NOTIFICATIONS_DATA = "SET_NOTIFICATIONS_DATA",
    READ_NOTIFICATION = "READ_NOTIFICATION",
    NOTIFI_SELECTION = "NOTIFI_SELECTION",
    UNREAD_NOTIFICATION = "UNREAD_NOTIFICATION",
    GRAPH_NOTIFI_READ = "GRAPH_NOTIFI_READ",
}

export const NotificationsActionsCreator = {
    getAllNotifications: (pathParams?: { [key: string]: string }, queryParams?: { [key: string]: string }) => {
        return {
            type: NotificationsAction.GET_ALL_NOTIFICATIONS,
            payload: { pathParams, queryParams },
        }
    },
    setNotificationsData: (data: any) => ({
        type: NotificationsAction.SET_NOTIFICATIONS_DATA,
        payload: data,
    }),

    notifiSelection: (payload: any) => ({
        type: NotificationsAction.NOTIFI_SELECTION,
        payload,
    }),
    graphNotifiRead: (payload: any) => ({
        type: NotificationsAction.GRAPH_NOTIFI_READ,
        payload,
    }),

    markNotificationAsRead: (
        payload: Partial<ReadNotifications>,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: NotificationsAction.READ_NOTIFICATION,
            payload,
            onSuccess,
            onError,
        }
    },
    markNotificationAsUnRead: (
        payload: Partial<ReadNotifications>,
        onSuccess?: () => void,
        onError?: (data: { message: string }) => void
    ): ISagaAction => {
        return {
            type: NotificationsAction.UNREAD_NOTIFICATION,
            payload,
            onSuccess,
            onError,
        }
    },
}

import { BackChevronIcon } from "../../../../assets/svgs/backChevron.svg"
import { CrossNewIcon } from "../../../../assets/svgs/xIcon.svg"
import { ButtonElement } from "../../../elements/button.element"
import { PrimaryText } from "../../../elements/primaryText.element"
const SemiAutomatedStepSixView: React.FC<AddTrackPackProps> = ({
    asinHeaderText,
    asinHeaderDescription,
    icon,
    handleCloseDialog,
    handlePrev,
    buttonText,
}) => {
    return (
        <>
            <div className="flex items-center justify-between">
                {icon}
                <div className="cursor-pointer">
                    <CrossNewIcon onClick={handleCloseDialog} />
                </div>
            </div>
            <PrimaryText className="text-[#344054] text-[24px] font-medium mt-[24px] ml-[6px]">
                {asinHeaderText}
            </PrimaryText>
            <PrimaryText className="text-[16px] font-[300] mt-[16px] text-[#667085] ml-[6px] ">
                I couldn’t add new ASINs to your Trackpack at this time
                <br /> due to a server communication issue. Please try again later.
            </PrimaryText>

            <div className="flex justify-between  items-center mt-[48px]">
                <div className="flex items-center">
                    <BackChevronIcon />
                    <PrimaryText onClick={handlePrev} className="text-[18px]  cursor-pointer text-[#667085]">
                        Back
                    </PrimaryText>
                </div>
                <ButtonElement
                    type="submit"
                    onClick={handleCloseDialog}
                    size="large"
                    className={`bg-gradient-to-r from-[#475467] to-[#667085] text-white hover:bg-gradient-to-r hover:from-[#475467] hover:to-[#667085]`}
                >
                    {buttonText}
                </ButtonElement>
            </div>
        </>
    )
}
export default SemiAutomatedStepSixView

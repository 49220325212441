export const AsinChangeTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            className={props.className}
            width="28"
            height="28"
            stroke={props.stroke}
            viewBox="0 0 28 28"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <path
                d="M24 20.1111H4M4 20.1111L9 15.2222M4 20.1111L9 25M4 7.88889H24M24 7.88889L19 3M24 7.88889L19 12.7778"
                stroke={props.stroke}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

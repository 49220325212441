import { AuthAction } from "../actions/auth.action"
import { AuthStateClass } from "../classes/authState.class"

//Get Data from guess account
export const AuthReducer = (
    state: IAuthState = new AuthStateClass(), //initial state set through class
    action: ISagaAction
): Partial<IAuthState> => {
    switch (action.type) {
        case AuthAction.STORE_GUEST_ACCOUNT:
            return {
                ...state,
                token: action.payload,
            }
        case AuthAction.STORE_TOKEN:
            localStorage.setItem("token", action.payload.access)
            return {
                ...state,
                token: action.payload,
            }
        case AuthAction.CREATE_GUEST_ACCOUNT: {
            return {}
        }
        case AuthAction.UPDATE_ASIN_USER:
            return {
                ...state,
            }
        case AuthAction.UPDATE_GUEST_ACCOUNT: {
            return {}
        }
        case AuthAction.SET_ACCESS_LEVEL_RESPONSE: {
            return {
                ...state,
                getAccessLevel: action.payload,
            }
        }

        case AuthAction.SET_REFRESH_TOKEN:
            localStorage.setItem("token", action.payload.access)
            return {
                ...state,
                token: { ...state.token, ...action.payload },
            }

        case AuthAction.USER_SIGNUP:
            return {
                ...state,
            }
        case AuthAction.RECOVER_PASSWORD:
            return {
                ...state,
            }
        case AuthAction.VERIFY_PASSWORD_EMAIL:
            return {
                ...state,
            }
        case AuthAction.VERIFY_SIGNUP_EMAIL:
            return {
                ...state,
            }
        case AuthAction.RESEND_VERIFY_SIGNUP_EMAIL:
            return {
                ...state,
            }
        default:
            return state
    }
}

export const TitleIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            className={props.className}
            width={props.width ?? "16"}
            height={props.height ?? "16"}
            viewBox="0 0 16 16"
            fill="none"
        >
            <path
                d="M2.66797 4.66699C2.66797 4.04574 2.66797 3.73511 2.76946 3.49008C2.90479 3.16338 3.16435 2.90381 3.49106 2.76849C3.73609 2.66699 4.04671 2.66699 4.66797 2.66699H11.3346C11.9559 2.66699 12.2665 2.66699 12.5115 2.76849C12.8382 2.90381 13.0978 3.16338 13.2331 3.49008C13.3346 3.73511 13.3346 4.04574 13.3346 4.66699M6.0013 13.3337H10.0013M8.0013 2.66699V13.3337"
                stroke={props.stroke}
                stroke-width="1"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

import { useCallback, useRef, useState } from "react"
import { useSelector } from "react-redux"
import { Tooltip, TooltipRefProps } from "react-tooltip"
import { RunTimeStateSelector } from "../../selectors/RunTimeStateSelector"
import { AsinChange } from "../dashboard/tooltipItems/asinChange.component"
import { Carousel } from "../dashboard/tooltipItems/carousel.component"
import { DescriptionTooltip } from "../dashboard/tooltipItems/description.component"
import { MainImage } from "../dashboard/tooltipItems/mainImage.component"
import { PriceInfo } from "../dashboard/tooltipItems/priceInfo.component"
import { SellerInfo } from "../dashboard/tooltipItems/sellerInfo.component"
import { Stock } from "../dashboard/tooltipItems/stock.component"
import { TooltipBullets } from "../dashboard/tooltipItems/tooltipBullets.component"
import { TooltipTitle } from "../dashboard/tooltipItems/tooltipTitle.component"
import { TooltipVideo } from "../dashboard/tooltipItems/tooltipVideo.component"
export const CustomTooltip = (prop: ICustomTooltipProps) => {
    const tooltipRef = useRef<TooltipRefProps>(null)

    const { popups } = useSelector(RunTimeStateSelector)

    const [productId, setProductId] = useState<string>()
    const [offset, setOffset] = useState<number>(14)
    const setOffsetValue = useCallback((subChild: HTMLElement) => {
        const parent = subChild.closest("#tooltip-parent")
        const parentRect = parent?.getBoundingClientRect()
        const subChildRect = subChild?.getBoundingClientRect()

        const parentGraph = subChild.closest("#tooltip-parent-graph")
        const parentRectGraph = parentGraph?.getBoundingClientRect()
        const subChildRectGraph = subChild?.getBoundingClientRect()

        if (parentRect && subChildRect) {
            const topMargin = subChildRect.top - parentRect.top
            setOffset(topMargin + 4)
        }

        if (parentRectGraph && subChildRectGraph) {
            const topMargin = subChildRectGraph.top - parentRectGraph.top
            setOffset(topMargin + 8)
        }
    }, [])
    const TooltipContent = (obj: ITooltipContent) => {
        // prop.selectedView === "Table" && afterHide()
        setProductId(obj.id + obj.data.timestamp)
        // prop.selectedView === "Table" && afterShow()

        switch (obj.type) {
            case "carousel_images":
                return <Carousel color={obj.color} data={obj.data} id={obj.id} />
            case "main_image":
                return <MainImage color={obj.color} data={obj.data} id={obj.id} />
            case "price":
                return <PriceInfo color={obj.color} data={obj.data} id={obj.id} />
            case "description":
                return <DescriptionTooltip color={obj.color} data={obj.data} id={obj.id} />
            case "bullets":
                return <TooltipBullets color={obj.color} data={obj.data} id={obj.id} />
            case "videos":
                return <TooltipVideo color={obj.color} data={obj.data} id={obj.id} />
            case "title":
                return <TooltipTitle color={obj.color} data={obj.data} id={obj.id} />
            case "actual_asin":
                return <AsinChange color={obj.color} data={obj.data} id={obj.id} />
            case "seller_info":
                return <SellerInfo color={obj.color} data={obj.data} id={obj.id} />
            case "stock":
                return <Stock color={obj.color} data={obj.data} id={obj.id} />
            // case "main_video":
            //     return <Stock color={obj.color} data={obj.data} id={obj.id} />
            default:
                return null
        }
    }

    return (
        <Tooltip
            id={prop.id}
            clickable
            globalCloseEvents={{
                escape: true,
                scroll: true,
                resize: true,
                clickOutsideAnchor: false,
            }}
            offset={offset}
            openOnClick
            ref={tooltipRef}
            delayShow={200}
            isOpen={popups?.includes(prop.id + productId)}
            className="tooltip-style"
            classNameArrow="custom-arrow blue"
            render={({ content, activeAnchor }) => {
                if (!content) return null
                activeAnchor && setOffsetValue(activeAnchor)
                const obj = JSON.parse(content)
                return TooltipContent(obj)
            }}
        />
    )
}

// import { DiffMethod, StringDiff } from "react-string-diff"

// export const StringDiffElement = (props: IStringDiffElementProps) => {
//     const styles: {
//         added: React.CSSProperties
//         removed: React.CSSProperties
//         default: React.CSSProperties
//     } = {
//         added: {
//             color: "#17B26A",
//         },
//         removed: {
//             color: "#F04438",
//             textDecorationLine: "line-through",
//         },
//         default: {},
//     }
//     return (
//         <>
//             <StringDiff
//                 method={DiffMethod.WordsWithSpace}
//                 styles={styles}
//                 oldValue={props.pastValue || ""}
//                 newValue={props.presentValue || ""}
//             />
//         </>
//     )
// }

import { DiffMethod, StringDiff } from "react-string-diff"

interface IStringDiffElementProps {
    pastValue: string
    presentValue: string
    showType?: "added" | "removed" | "both"
}

export const StringDiffElement = (props: IStringDiffElementProps) => {
    const { pastValue, presentValue, showType = "both" } = props

    const styles: {
        added: React.CSSProperties
        removed: React.CSSProperties
        default: React.CSSProperties
    } = {
        added: {
            color: "#17B26A",
        },
        removed: {
            color: "#F04438",
        },
        default: {},
    }

    return (
        <>
            {showType === "added" && (
                <StringDiff
                    method={DiffMethod.WordsWithSpace}
                    styles={{ ...styles, removed: { display: "none" } }}
                    oldValue={pastValue || ""}
                    newValue={presentValue || ""}
                />
            )}
            {showType === "removed" && (
                <StringDiff
                    method={DiffMethod.WordsWithSpace}
                    styles={{ ...styles, added: { display: "none" } }}
                    oldValue={pastValue || ""}
                    newValue={presentValue || ""}
                />
            )}
            {showType === "both" && (
                <StringDiff
                    method={DiffMethod.WordsWithSpace}
                    styles={styles}
                    oldValue={pastValue || ""}
                    newValue={presentValue || ""}
                />
            )}
        </>
    )
}

import { SettingsAction } from "../actions/settings.action"
import { SettingStateClass } from "../classes/settingState.class"

export const SettingsReducer = (
    state: ISettingState = new SettingStateClass(),
    action: ISagaAction
): Partial<ISettingState> => {
    switch (action.type) {
        case SettingsAction.GET_ALERT_SETTING: {
            return {
                ...state,
            }
        }
        case SettingsAction.SET_ALERT_SETTING:
            return {
                ...state,
                selectedAlertSetting: action.payload,
            }
        case SettingsAction.UPDATE_ALERT_SETTING:
            return {
                ...state,
            }
        default:
            return state
    }
}

export const TitleIconTooltip = (props: IIconTooltipProps) => {
    return (
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="28"
            height="28"
            viewBox="0 0 28 28"
            fill="none"
            className={props.className}
        >
            <path
                d="M4.66699 8.1665C4.66699 7.07931 4.66699 6.53571 4.84461 6.10691C5.08143 5.53518 5.53567 5.08094 6.1074 4.84412C6.5362 4.6665 7.0798 4.6665 8.16699 4.6665H19.8337C20.9209 4.6665 21.4645 4.6665 21.8933 4.84412C22.465 5.08094 22.9192 5.53518 23.156 6.10691C23.3337 6.53571 23.3337 7.07931 23.3337 8.1665M10.5003 23.3332H17.5003M14.0003 4.6665V23.3332"
                stroke={props.stroke}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>
    )
}

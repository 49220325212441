// /* eslint-disable @typescript-eslint/no-unused-vars */
// import { FocusEvent, useState } from "react"
// import { Controller } from "react-hook-form"
// // @ts-ignore
// import CreatableSelect from "react-select/creatable"
// import { ReactComponent as AlertIcon } from "../../assets/svgs/alert-icon.svg"

// const SemiAutomatedInputElement = (props: ITagInputElementProps) => {
//     const createOption = (label: string, asin: string, url?: string) => ({
//         label,
//         value: { asin, url },
//     })

//     const [inputValue, setInputValue] = useState("")

//     const extractASIN = (input: string) => {
//         const asinRegex = /(?:\/dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})/
//         const match = input.match(asinRegex)

//         if (match) {
//             return { asin: match[1], url: input }
//         }

//         return { asin: input, url: undefined }
//     }

//     const isValidASINOrURL = (asin: string, url?: string) => {
//         const asinRegex = /^[A-Z0-9]{10}$/
//         const amazonAsinRegex =
//             /^(?:https?:\/\/)?(?:www\.)?amazon\.[a-z.]+\/(?:dp|gp\/product|ASIN)\/([A-Z0-9]{10})(?:\/)?$/

//         if (asinRegex.test(asin)) {
//             return true
//         }

//         if (url && amazonAsinRegex.test(url)) {
//             return true
//         }

//         return false
//     }

//     const validateTags = (value: any) => {
//         let updatedValue = value?.[0]
//         if (!updatedValue || updatedValue.asin.trim() === "") {
//             return "Please enter at least one ASIN number to continue"
//         }

//         const extractedASIN = extractASIN(updatedValue.asin)

//         if (extractedASIN.asin.length !== 10) {
//             return "ASIN numbers must contain 10 characters"
//         }

//         if (!isValidASINOrURL(extractedASIN.asin, updatedValue.url)) {
//             return "Please enter a valid ASIN or URL to continue"
//         }

//         return true
//     }

//     return (
//         <Controller
//             rules={{ validate: validateTags }}
//             control={props.reactHookControl}
//             name={props.name}
//             render={({ field: { onChange, value, name, ref }, fieldState: { error } }) => {
//                 const isSelectedError = error

//                 const handleBlur = (event: FocusEvent<HTMLInputElement>) => {
//                     event.preventDefault()
//                     if (inputValue.trim()) {
//                         const { asin, url } = extractASIN(inputValue.trim())
//                         const newOption = createOption(inputValue.trim(), asin, url)

//                         if (value) {
//                             onChange([...value, newOption.value])
//                         } else {
//                             onChange([newOption.value])
//                         }
//                         setInputValue("")
//                     }
//                 }

//                 return (
//                     <>
//                         <CreatableSelect
//                             styles={{
//                                 control: (baseStyles) => ({
//                                     ...baseStyles,
//                                     borderColor: error?.message ? "#FECDCA" : "#D0D5DD",
//                                     boxShadow: value && "0px 1px 2px 0px #1018280D",
//                                     borderRadius: "12px",
//                                     padding: "10px",
//                                     "&:hover": {
//                                         borderColor: "#67E8F9",
//                                     },
//                                 }),
//                                 input: (baseStyles) => ({
//                                     ...baseStyles,
//                                     height: 37,
//                                     minHeight: 37,
//                                     position: "relative",
//                                     top: 0,
//                                 }),
//                             }}
//                             ref={ref}
//                             name={name}
//                             value={value}
//                             components={{
//                                 DropdownIndicator: null,
//                                 ClearIndicator: () => {
//                                     return (
//                                         <>
//                                             {error?.message ? <AlertIcon /> : ""}
//                                             <div className="pr-2">
//                                                 {props.postIcon && !error?.message && props.postIcon}
//                                             </div>
//                                         </>
//                                     )
//                                 },
//                             }}
//                             inputValue={inputValue}
//                             onBlur={handleBlur}
//                             isClearable
//                             menuIsOpen={false}
//                             onInputChange={(newValue: string) => setInputValue(newValue)}
//                             onChange={(newValue) => {
//                                 let value

//                                 if (Array.isArray(newValue)) {
//                                     value = newValue.map((option: any) => option.value)
//                                 } else if (newValue) {
//                                     value = [newValue.value]
//                                 } else {
//                                     value = []
//                                 }

//                                 onChange(value)
//                             }}
//                             onKeyDown={(event) => {
//                                 if (!inputValue) return
//                                 switch (event.key) {
//                                     case "Enter":
//                                     case ",":
//                                     case "Tab":
//                                         const { asin, url } = extractASIN(inputValue.trim())
//                                         const newOption = createOption(inputValue.trim(), asin, url)
//                                         if (value) {
//                                             onChange([...value, newOption.value])
//                                         } else {
//                                             onChange([newOption.value])
//                                         }
//                                         setInputValue("")
//                                         event.preventDefault()
//                                 }
//                             }}
//                             placeholder={props.placeholder}
//                             className={props.className}
//                             isMulti={false}
//                             isDisabled={props.disabled}
//                         />

//                         {error && (
//                             <span
//                                 className="text-[#F04438] pl-[14px] pt-2 text-[12px] font-[300]"
//                                 style={{ display: "block" }}
//                             >
//                                 {error.message}
//                             </span>
//                         )}
//                     </>
//                 )
//             }}
//         />
//     )
// }

// export default SemiAutomatedInputElement

import { useState } from "react"
import { Controller } from "react-hook-form"
import { ReactComponent as AlertIcon } from "../../assets/svgs/alert-icon.svg"
import { TagCloseIcon } from "../../assets/svgs/tagCloseIcon.svg"
import { PrimaryText } from "./primaryText.element"

const SemiAutomatedInputElement = (props: ITagInputElementProps) => {
    const [tag, setTag] = useState<{ asin: string; url?: string } | null>(null)
    const [inputValue, setInputValue] = useState("")
    const [originalUrl, setOriginalUrl] = useState<string | null>(null)

    const extractASIN = (input: string) => {
        const asinRegex = /(?:\/dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})(?:[/?]|$)/
        const match = input.match(asinRegex)

        if (match) {
            return { asin: match[1], url: input }
        }

        return { asin: "", url: undefined }
    }

    const isValidASINOrURL = (asin: string, url?: string) => {
        const asinRegex = /^[A-Z0-9]{10}$/
        const amazonAsinRegex =
            /^(?:https?:\/\/)?(?:www\.)?amazon\.(com|com\.au|com\.be|com\.br|ca|cn|eg|fr|de|in|it|co\.jp|com\.mx|nl|pl|sa|sg|es|se|com\.tr|ae|co\.uk)(?:\/(?:[^\/]+\/)?dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})(?:[/?]|$)/

        if (url) {
            return amazonAsinRegex.test(url)
        }

        return asinRegex.test(asin)
    }

    const truncateURL = (url: string) => {
        const asinRegex = /(?:\/dp\/|\/gp\/product\/|\/ASIN\/)([A-Z0-9]{10})(?:[/?]|$)/
        const match = url.match(asinRegex)
        const asin = match ? match[1] : null

        if (asin) {
            const domain = url.match(/amazon\.[a-z\.]+/i)?.[0] || ""
            return `${domain}/.../${asin}`
        }

        return url
    }

    const validateTag = () => {
        if (!tag) {
            return "Please enter an ASIN number or URL to continue"
        }

        const { asin, url } = tag

        if (asin.length !== 10) {
            return "ASIN numbers must contain 10 characters"
        }

        if (!isValidASINOrURL(asin, url)) {
            return "Please enter a valid ASIN or Amazon URL to continue"
        }

        return true
    }

    const handleTagDelete = () => {
        setTag(null)
        setInputValue("")
        setOriginalUrl(null)
        props.reactHookControl.setValues(props.name, {}, { shouldValidate: true })
    }

    return (
        <Controller
            rules={{ validate: validateTag }}
            control={props.reactHookControl}
            name={props.name}
            render={({ field: { onChange, name, ref }, fieldState: { error } }) => {
                const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
                    const input = event.target.value.trim()

                    if (input.startsWith("http")) {
                        const truncatedURL = truncateURL(input)
                        setInputValue(truncatedURL)
                        setOriginalUrl(input)
                    } else {
                        setInputValue(input)
                        setOriginalUrl(null)
                    }

                    event.preventDefault()
                }

                const handleInputSubmit = (event: React.KeyboardEvent<HTMLInputElement>) => {
                    if (event.key === "Enter" || event.key === "Tab") {
                        event.preventDefault()

                        const parts = inputValue.split("/")
                        const asin = parts[parts.length - 1]

                        const newTag = { asin, url: originalUrl || undefined }
                        setTag(newTag)
                        onChange(newTag)
                        setInputValue("")
                        setOriginalUrl(null)
                    }
                }

                const handleBlur = (event: any) => {
                    event.preventDefault()
                    const parts = inputValue.split("/")
                    const asin = parts[parts.length - 1]

                    if (asin.length === 10) {
                        const newTag = { asin, url: originalUrl || undefined }
                        setTag(newTag)
                        onChange(newTag)
                        setInputValue("")
                        setOriginalUrl(null)
                    }
                }

                return (
                    <div className="relative">
                        <div
                            className={`flex items-center border rounded-[12px] px-[10px] py-[9px] w-full relative ${
                                error ? "border-red-600" : "border-gray-300"
                            }`}
                        >
                            {tag && (
                                <div className="tag flex items-center justify-between gap-[8px] bg-gray-100 py-[2px] pl-[6px] pr-[4px] rounded-[6px]">
                                    <PrimaryText size="small" weight="book" className="text-gray-700">
                                        {tag.asin} {/* Only showing ASIN */}
                                    </PrimaryText>
                                    <TagCloseIcon onClick={handleTagDelete} className="my-[2px] cursor-pointer" />
                                </div>
                            )}

                            <input
                                ref={ref}
                                name={name}
                                value={inputValue}
                                onChange={handleInputChange}
                                onKeyDown={handleInputSubmit}
                                onBlur={handleBlur}
                                placeholder={!tag ? props.placeholder : ""}
                                className="flex-grow focus:outline-none"
                                disabled={props.disabled}
                                style={{ minWidth: "100px" }}
                            />
                        </div>

                        {error && (
                            <div className="flex gap-1 items-center mt-1">
                                <AlertIcon />
                                <span className="text-red-600 text-xs">{error.message}</span>
                            </div>
                        )}
                    </div>
                )
            }}
        />
    )
}

export default SemiAutomatedInputElement

import dayjs from "dayjs"
import { useState } from "react"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import { RuntimeActionCreators } from "../../../actions/runTime.action"
import { ExpandIcon } from "../../../assets/svgs/expandIcon.svg"
import { DescriptionIconTooltip } from "../../../assets/svgs/tooltips/popups/description.svg"
import { useProductHistory } from "../../../hooks/productHistory.hook"
import { InnerTooltipHeader } from "../../common/innerTooltipHeader.component"
import { PrimaryText } from "../../elements/primaryText.element"
import { StringDiffElement } from "../../elements/stringDiff.element"
export const DescriptionTooltip = (props: ITooltipData) => {
    const dispatch = useDispatch()
    const { t } = useTranslation("tooltip", {
        keyPrefix: "descriptions",
    })
    const { t: tCommon } = useTranslation("tooltip", {
        keyPrefix: "commonLabels",
    })
    const [activeDiff, setActiveDiff] = useState<"before" | "after" | "change">("change")
    const { active, history, setActive, total } = useProductHistory(props.data, "description")
    // const { selectedProductResult } = useSelector(ProductStateSelector)

    // const isSuccessWarning = props?.data?.warnings?.includes("success")
    // const selectedValue = selectedProductResult?.selectedResults?.value ?? "bsr_large"
    // const isWarning = props?.data?.warnings?.includes(
    //     selectedValue as "bsr_large" | "bsr_small" | "ratings_count" | "rating" | "actual_asin"
    // )

    const handleOpen = () => {
        dispatch(
            RuntimeActionCreators.openModal({
                color: props?.color,
                open: true,
                type: "description",
                data: props?.data,
                activeDiff: activeDiff,
            })
        )
    }

    return (
        <>
            <div className="w-[318px] p-[12px] bg-gray-900 rounded-2xl">
                <InnerTooltipHeader
                    title={t("Description")}
                    linkText={tCommon("Go to ASIN")}
                    timestamp={tCommon("timestamp", {
                        timestamp: dayjs(history.present?.timestamp).format("MMM-DD-YYYY @ HH:mm [GMT]"),
                    })}
                    active={active}
                    total={total}
                    setActive={setActive}
                    color={props.color?.active}
                    id={props.id}
                    icon={<DescriptionIconTooltip stroke={props.color?.default} />}
                />
                {history.past && (
                    <div className="w-[102px] h-[16px] overflow-hidden rounded-[8px]  border border-gray-600 justify-center items-center inline-flex mt-[32px]">
                        <div
                            onClick={() => setActiveDiff("before")}
                            className={`cursor-pointer  ${
                                activeDiff === "before"
                                    ? "bg-gray-600 text-white h-full w-[28px]"
                                    : "text-gray-500 h-full w-[28px]"
                            }  justify-center rounded-l-[8px] items-center flex`}
                        >
                            <PrimaryText
                                size={"xs-small"}
                                weight={"book"}
                                className="pt-[2px] pl-[0px] whitespace-nowrap flex items-center justify-center"
                            >
                                {tCommon("was")}
                            </PrimaryText>
                        </div>

                        <div
                            onClick={() => setActiveDiff("change")}
                            className={`cursor-pointer  ${
                                activeDiff === "change"
                                    ? "bg-gray-600   text-white h-full w-[47px]"
                                    : "text-gray-500 border-l border-r border-[#475467] h-full w-[47px]"
                            }  justify-center items-center flex`}
                        >
                            <PrimaryText
                                size={"xs-small"}
                                weight={"book"}
                                className="flex justify-center whitespace-nowrap pt-[2px]"
                            >
                                {tCommon("Changes")}
                            </PrimaryText>
                        </div>

                        <div
                            onClick={() => setActiveDiff("after")}
                            className={`rounded-r-[8px] cursor-pointer  ${
                                activeDiff === "after"
                                    ? "bg-gray-600 h-full w-[29px] text-white flex items-center justify-center"
                                    : "text-gray-500  h-full  w-[29px]"
                            }  justify-center items-center flex`}
                        >
                            <PrimaryText
                                size={"xs-small"}
                                weight={"book"}
                                className="pt-[2px] pr-[2px] flex justify-center whitespace-nowrap"
                            >
                                {tCommon("now")}
                            </PrimaryText>
                        </div>
                    </div>
                )}

                <div className="mt-[24px]">
                    {history.past && activeDiff === "before" ? (
                        <div className=" overflow-hidden   px-2 pt-2 pb-3 bg-slate-600 rounded-lg w-full relative ">
                            <ExpandIcon
                                onClick={handleOpen}
                                color={props?.color?.active}
                                className="cursor-pointer absolute right-[8px] top-[8px]"
                            />
                            <div className=" flex-col justify-start items-start gap-2 inline-flex w-full">
                                <div className="max-h-[180px] relative min-h-[180px] overflow-y-auto w-full overflow-x-hidden custom scrollbar-pr flex-col justify-start pb-[2px] items-start gap-2.5 flex">
                                    <div className=" w-[264px] justify-start items-start pr-[6px] gap-2.5 inline-flex">
                                        <PrimaryText
                                            size="xs"
                                            weight={"light"}
                                            className=" text-white leading-[14px] max-w-[264px]"
                                        >
                                            {history.past?.value}
                                        </PrimaryText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : activeDiff === "change" && history.past && history.present ? (
                        <div className=" overflow-hidden  px-2 pt-2 pb-3 bg-slate-600 rounded-lg w-full relative">
                            <ExpandIcon
                                onClick={handleOpen}
                                color={props?.color?.active}
                                className="cursor-pointer absolute right-[8px] top-[8px]"
                            />
                            <div className=" flex-col justify-start items-start gap-2 inline-flex w-full">
                                <div className="max-h-[180px] relative min-h-[180px] w-full overflow-y-auto overflow-x-hidden custom scrollbar-pr flex-col justify-start pb-[2px] items-start gap-2.5 flex">
                                    <div className=" justify-start items-start pr-[6px] gap-2.5 inline-flex">
                                        <div className="w-[264px] text-white max-w-[264px] text-[12px] font-light font-['F37 Bolton'] leading-[14px]">
                                            <StringDiffElement
                                                pastValue={history.past?.value}
                                                presentValue={history.present?.value}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : activeDiff === "after" && history.past && history.present ? (
                        <div className=" overflow-hidden  px-2 pt-2 pb-3 bg-slate-600 rounded-lg w-full relative">
                            <ExpandIcon
                                onClick={handleOpen}
                                color={props?.color?.active}
                                className="cursor-pointer absolute right-[8px] top-[8px]"
                            />
                            <div className=" flex-col justify-start items-start gap-2 inline-flex w-full">
                                <div className="max-h-[180px] relative min-h-[180px] w-full overflow-y-auto overflow-x-hidden custom scrollbar-pr flex-col justify-start pb-[2px] items-start gap-2.5 flex">
                                    <div className=" w-[264px] justify-start items-start pr-[6px] gap-2.5 inline-flex">
                                        <PrimaryText
                                            size={"xs"}
                                            weight={"light"}
                                            className=" text-white leading-[14px]  max-w-[264px]"
                                        >
                                            {history.present?.value}
                                        </PrimaryText>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div className=" overflow-hidden w-full px-2 pt-2 pb-3 bg-slate-600 rounded-lg relative">
                            <ExpandIcon
                                onClick={handleOpen}
                                color={props?.color?.active}
                                className="cursor-pointer absolute right-[8px] top-[8px]"
                            />

                            <div className="max-h-[284px] overflow-y-auto overflow-x-hidden custom scrollbar-pr pb-[2px]">
                                <div className="w-[264px] justify-start items-start pr-[6px] gap-2.5 inline-flex">
                                    <PrimaryText size={"xs"} weight={"light"} className=" text-white leading-[14px]">
                                        {history.present?.value}
                                    </PrimaryText>
                                </div>
                            </div>
                        </div>
                    )}
                </div>
                {/* {isSuccessWarning && (
                    <div className="w-full  pt-[8px]">
                        <ErrorTooltipAlert />
                    </div>
                )}

                {isWarning && (
                    <div className="w-full  pt-[8px]">
                        <WarningTooltipAlert />
                    </div>
                )} */}
            </div>
        </>
    )
}

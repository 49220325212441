export const GraphIconSidebar = (props: IArrowProps) => {
    return (
        <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M17.5 7.5L12.1262 11.3384C11.963 11.455 11.8813 11.5133 11.7939 11.5309C11.7168 11.5464 11.637 11.5398 11.5635 11.5119C11.4801 11.4801 11.4092 11.4092 11.2673 11.2673L8.73267 8.73267C8.5908 8.5908 8.51986 8.51986 8.43652 8.48814C8.36305 8.46017 8.28317 8.45356 8.2061 8.46907C8.11868 8.48666 8.03704 8.54497 7.87378 8.66159L2.5 12.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
                stroke={props.color}
                stroke-width="1.2"
                stroke-linecap="round"
                stroke-linejoin="round"
            />
        </svg>

        // <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
        //     <path
        //         d="M17.5 7.5L12.1262 11.3384C11.963 11.455 11.8813 11.5133 11.7939 11.5309C11.7168 11.5464 11.637 11.5398 11.5635 11.5119C11.4801 11.4801 11.4092 11.4092 11.2673 11.2673L8.73267 8.73267C8.5908 8.5908 8.51986 8.51986 8.43652 8.48814C8.36305 8.46017 8.28317 8.45356 8.2061 8.46907C8.11868 8.48666 8.03704 8.54497 7.87378 8.66159L2.5 12.5M6.5 17.5H13.5C14.9001 17.5 15.6002 17.5 16.135 17.2275C16.6054 16.9878 16.9878 16.6054 17.2275 16.135C17.5 15.6002 17.5 14.9001 17.5 13.5V6.5C17.5 5.09987 17.5 4.3998 17.2275 3.86502C16.9878 3.39462 16.6054 3.01217 16.135 2.77248C15.6002 2.5 14.9001 2.5 13.5 2.5H6.5C5.09987 2.5 4.3998 2.5 3.86502 2.77248C3.39462 3.01217 3.01217 3.39462 2.77248 3.86502C2.5 4.3998 2.5 5.09987 2.5 6.5V13.5C2.5 14.9001 2.5 15.6002 2.77248 16.135C3.01217 16.6054 3.39462 16.9878 3.86502 17.2275C4.3998 17.5 5.09987 17.5 6.5 17.5Z"
        //         stroke={props.color}
        //         stroke-linecap="round"
        //         stroke-linejoin="round"
        //     />
        // </svg>
    )
}

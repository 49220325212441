export const CurrencyDollarIcon = (props: IIconTooltipProps) => {
    return (
        <>
            <svg width="16" height="16" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                <g id="currency-dollar">
                    <path
                        id="Icon"
                        d="M3 8C3 9.10457 3.89543 10 5 10H7C8.10457 10 9 9.10457 9 8C9 6.89543 8.10457 6 7 6H5C3.89543 6 3 5.10457 3 4C3 2.89543 3.89543 2 5 2H7C8.10457 2 9 2.89543 9 4M6 1V11"
                        stroke={props.color}
                        stroke-width="0.8"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </g>
            </svg>
        </>
    )
}
